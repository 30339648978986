import { Component, Inject } from '@angular/core';
import { StateParams } from '@app/ajs-upgraded-providers';
import { TenantAuthority } from '../auth/auth.interface';
import { AuthService } from '../auth';

@Component({
  selector: 'select-tenant',
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.scss'],
})
export class SelectTenantComponent {

  tenants: TenantAuthority[];

  // TODO: remove when multi tenant is implemented
  mockTenants: TenantAuthority[] = [
    {
      authorities: ['Tenant Administrator'],
      id: '13364567-1234-1223-1278567840AB',
      name: 'TenantName1',
      region: 'Europe',
      userId: '53364567-1234-1223-1278567840AD'
    },
    {
      authorities: ['Application Owner'],
      id: '17856356-5678-5666-1467894560CD',
      name: 'TenantName2',
      region: 'North America',
      userId: '37856356-5678-5666-1467894560CF',
    }
  ];

  private nextState: string;

  constructor(private authService: AuthService, @Inject(StateParams) $stateParams) {
    this.nextState = $stateParams.nextState;
    this.tenants = $stateParams.tenants || [];
  }

  tenantSelected(tenant?: TenantAuthority) {
    // TODO: Get rid of stub data
    this.authService.selectTenant(tenant?.id || '652b2ce1-4016-493b-b685-c3f480c5d615', this.nextState);
  }
}
