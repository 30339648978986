import { InjectionToken, Provider } from '@angular/core';
import { default as toggles, FeatureToggles } from './feature-toggles.constants';

// For convenience, reexport the interface
export { FeatureToggles } from './feature-toggles.constants';

export const FEATURE_TOGGLES = new InjectionToken<FeatureToggles>('FeatureTogglesToken');
export const FeatureTogglesProvider: Provider = {
  provide: FEATURE_TOGGLES, useValue: toggles
};

// AngularJS compatibility
import angular from 'angular';

export default angular.module('gem.featuretoggles', [])
  .constant('FeatureToggles', toggles)
  .name;
