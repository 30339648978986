import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delete-service-dialog',
  templateUrl: './delete-service-dialog.component.html',
})
export class DeleteServiceDialogComponent {
  @Input() serviceNameToDelete: string;
  @Input() customWarning: string;

  constructor(public self: NgbActiveModal) { }
}
