import { Observable , ConnectableObservable , Subscription , of } from 'rxjs';
import { filter, map, publish } from 'rxjs/operators';

type Id = string;

type DataStoreEvent<T> = Id | [Id, T];

// Haro emits the 2-element array [id, entity] after certain changes
type HaroUpdate<T> = [Id, T];

type Callback<T> = (value: T) => void;

interface Haro {
  // TODO
  onset: ([Id, any]) => any;
  ondelete: (Id) => any;
}

/**
 * Abstract class that manages a data store based on the Haro library.
 *
 * Provides the following API for querying the data store:
 *  - get()
 *  - getAll()
 *  - subscribe()
 *  - subscribeTo()
 */
export abstract class DataStore<T> {
  protected haroFactory: any; // TODO types for Haro
  protected store: any; // TODO types for Haro
  protected storeStream: ConnectableObservable<DataStoreEvent<T>>;

  constructor(haroFactory) {
    this.haroFactory = haroFactory;
    this.store = this.createStore();

    // create an observable that watches for datastore updates. every time data is pushed
    // to the datastore, the observable will emit an event. datastore updates happen in the
    // background independently of the Observable, so we call publish() and connect() to
    // make it a "hot" Observable -- see
    // https://github.com/Reactive-Extensions/RxJS/blob/master/doc/gettingstarted/creating.md#cold-vs-hot-observables
    this.storeStream = new Observable(observer => {
      // at the moment we don't differentiate between events. they're simply triggers for the
      // listener to fetch the latest data from this service
      this.store.onset = (data: HaroUpdate<T>) => observer.next(data);
      this.store.ondelete = id => observer.next(id);
    })
      .pipe(publish()) as ConnectableObservable<DataStoreEvent<T>>;

    this.storeStream.connect();
  }

  /**
   * Subscribes for changes to the datastore.  Any time a record is added/deleted/updated,
   * the passed-in function is called.
   *
   * @param fn Called when the datastore changes. On change, the function is passed an array as an
   *   argument; the first element contains the id of the modified resource, and the second
   *   element contains the resource data itself. On deletes, the function is passed just an id.
   */
  subscribe(fn: Callback<DataStoreEvent<T>>): Subscription {
    return this.storeStream.subscribe(fn);
  }

  /**
   * Subscribe for changes to a resource with the given id.
   *
   * @param {string} id - The id of the resource to subscribe to
   * @param {function} fn - Called when the resource changes. The function is passed the modified
   *   resource object as an immutable argument.
   * @returns {Subscription}
   */
  subscribeTo(id: string, fn: Callback<T>): Subscription {
    const record = this.get(id);

    if (record) { // if record exists, we immediately push it
      return of(record).subscribe(fn);
    }

    return this.storeStream
      .pipe(
        filter(data => data[0] === id),
        map(data => data[1])
      )
      .subscribe(fn);
  }

  /**
   * Gets an array of all resources currently in the data store. Does NOT fetch from the server.
   *
   * @param sortFn Returns the data sorted in a specific way.  Works identical to Array.sort()
   * @returns {array} All resources in the data store. The returned elements are immutable.
   */
  getAll(sortFn?: (a: T, b: T) => number): T[] {
    if (sortFn) {
      return this.store.sort(sortFn);
    }
    return this.store.toArray();
  }

  /**
   * Gets the resource with the given id. If no resource exists with that id, returns null.
   *
   * @param id The id of the resource.
   * @returns The requested resource. This object is immutable.
   */
  get(id: string): T {
    const record = this.store.get(id);
    return record ? record[1] : null;
  }

  /**
   * Create the haro data store
   */
  protected createStore(): Haro {
    return this.haroFactory(null, { key: this.getKey() });
  }

  /**
     * @returns Name of the field uniquely identifying a data object. Default is "id".
     * Subclasses must override if they use a different field name.
     */
  protected getKey(): string {
    return 'id';
  }

  /**
   * Updates the data store. Internal use only.
   * @returns Promise
   */
  protected set(obj): Promise<HaroUpdate<T>> {
    return this.store.set(null, obj, false, true);
  }

}
