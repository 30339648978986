import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { RequiresRoleService } from '@app/shared/services';

/**
 * Structural directive that renders its content only when the logged-in user possesses
 * the given role.
 *
 * Usage:
 * ```html
 * <div *requiresRole="'dpod.tenant.appowner'">
 *     You are an app owner
 * </div>
 * ```
 */
@Directive({
  selector: '[requiresRole]',
})
export class RequiresRoleDirective implements OnInit {
  private role: string;
  private viewRef: EmbeddedViewRef<any>;

  constructor(
    private requiresRoleService: RequiresRoleService,
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  @Input()
  set requiresRole(value) {
    this.role = value;
    this.updateView();
  }

  ngOnInit() {
    this.updateView();
  }

  updateView() {
    this.viewContainer.clear();
    if (this.hasRole()) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef = null;
    }
  }

  hasRole() {
    if (!this.role) {
      return true;
    }
    return this.requiresRoleService.hasRole(this.role);
  }

}
