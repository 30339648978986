// This is the Angular part of the module
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogService } from './dialog.service';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ErrorModalComponent } from './error/error-modal.component';
import { ProgressModalComponent } from './progress/progress-modal.component';
import { SpinnerModule } from '../spinner';

@NgModule({
  declarations: [
    ConfirmModalComponent,
    ErrorModalComponent,
    ProgressModalComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
  ],
  exports: [],
  providers: [DialogService],
})
export class DialogsModule {}

// This is the AngularJS part of the module
import angular from 'angular';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

// Using require() here because TypeScript barfs when I import a .js file
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const ng1DialogsModule = require('./_init').default
  // For the time being, these modals have to be registered as Angular1 components
  // so that uibModal can instantiate them
  .directive('confirmModal', downgradeComponent({component: ConfirmModalComponent}) as angular.IDirectiveFactory)
  .directive('errorModal', downgradeComponent({component: ErrorModalComponent}) as angular.IDirectiveFactory)
  .directive('progressModal', downgradeComponent({component: ProgressModalComponent}) as angular.IDirectiveFactory)
  .service('DialogService', downgradeInjectable(DialogService) as any);

