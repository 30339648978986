import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '@app/components/gem-dialogs';
import { TilesService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { Tile } from '../../../marketplace/tiles.interface';
import { ServiceCategoriesService } from '@app/shared/services/service-categories.service';
import { ServiceCategory } from '@app/shared/services/service-categories.interface';

@Component({
  selector: 'services-marketplace',
  templateUrl: './marketplace.component.html',
})
export class ServicesMarketplaceComponent {
  @ViewChild('disable', { static: true }) disablePrompt: TemplateRef<any>;
  @ViewChild('enable', { static: true }) enablePrompt: TemplateRef<any>;

  tiles: Observable<Tile[]>;
  serviceCategories: Observable<ServiceCategory[]>;

  constructor(private tilesService: TilesService, private serviceCategoryService: ServiceCategoriesService,
    private dialogService: DialogService,) {
    this.tiles = this.tilesService.tiles();
    this.serviceCategories = this.serviceCategoryService.serviceCategories();
  }

  tileClick(tile: Tile): void {
    const ctx = { tileName: tile.name };
    if (tile.enabled) {
      this.dialogService.confirm({
        title: 'Disable Service?',
        content: this.disablePrompt,
        context: ctx,
        yesLabel: 'Disable Service',
        noLabel: 'Cancel'
      }).then(() => this.tilesService.setEnabled(tile.id, false));
    } else {
      this.dialogService.confirm({
        title: 'Enable Service?',
        content: this.enablePrompt,
        context: ctx,
        yesLabel: 'Enable Service',
        noLabel: 'Cancel'
      }).then(() => this.tilesService.setEnabled(tile.id, true));
    }
  }

}
