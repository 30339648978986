import spadminusersModule from './_init';
import template from './spAdminUsers.html';
import UsersController from '../accounts/users.component';
import { columnNames as usersTableCols } from '../../components/users-table/users-table.component';

class SpAdminUsersController extends UsersController {

  constructor($scope, DialogService, UsersApi, SubscribersApi) {
    super($scope, DialogService, UsersApi, SubscribersApi);
    this.cards = [
      {
        title: 'Service Provider Administrator',
        svg: '#admin-user',
        content: 'Creates tenant accounts, runs tenant service reports and manages other Service Provider Administrators.',
        action: this.openWizard.bind(this, 'spadmin'),
        locator: 'add-sp-admin',
      },
    ];
    this.userCols = usersTableCols.filter(c => !(/accountRole|subscriberGroup|created/.test(c)));
  }

}

spadminusersModule.component('spadminusers', {
  controller: SpAdminUsersController,
  template,
});
