import { Component, Inject } from '@angular/core';
import { State, StateParams } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { AvailableTermIds } from '@app/features/eval-convert/eval-convert.constants';
import {
  EvalConvertSubmittedModalComponent
} from '@app/features/eval-convert/submitted-modal/eval-convert-submitted.modal.component';
import { MBU, Plan, TenantParentInfo } from '@app/features/tenant/tenant.model';
import { TenantsService } from '@app/features/tenant/tenants.service';
import { TilesService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tile } from '../marketplace/tiles.interface';
import { BackofficeService } from '@app/shared/services/backoffice.service';
import { TenantSubscriptions } from '@app/features/gem-services/services/tenant-subscription/subscription.constants';
import { AvailableTerm, EvalConvertTermsService } from './eval-convert-terms.service';

export interface TermSelection {
  id: number;
  duration: number;
  requestedActionDate: string;
}

const DEFAULT_TERM_SELECTION = AvailableTermIds.THREE_YEARS;

function isProvisionable(t: Tile): boolean {
  return typeof t.redirectionUrl !== 'string';
}

@Component({
  selector: 'eval-convert-component',
  styleUrls: ['./eval-convert.scss'],
  templateUrl: './eval-convert.component.html',
})
export class EvalConvertComponent {
  tilesStream: Observable<Tile[]>;
  tileSelection: MBU[];
  planSelection: Plan;
  termSelection: TermSelection;
  availableTerms: AvailableTerm[];
  hideButtons = true;
  // parent name is used on the 'last slide' button
  parentName = '';
  step = 0;
  disableNextButton = false;
  selectedServiceType;
  servicePlans: Plan[];
  subscriptionType = TenantSubscriptions.TYPE.TRIAL;

  constructor(
    private tenantsService: TenantsService,
    @Inject(State) private $state: any,
    private dialogService: DialogService,
    private tilesService: TilesService,
    private backofficeService: BackofficeService,
    @Inject(StateParams) private $stateParams: any,
    private termsService: EvalConvertTermsService
  ) {
    this.tenantsService.getParentTenantInfo().subscribe((data: TenantParentInfo) => this.parentName = data.name);

    // For billing/backoffice purposes, we only deal with provisionable tiles,
    // not redirection tiles
    this.tilesStream = tilesService.tiles().pipe(map(tiles => tiles.filter(isProvisionable)));

    // show buttons after fetching tiles
    this.tilesStream.subscribe(() => this.hideButtons = false);

    this.selectedServiceType = $stateParams.serviceType;
    this.servicePlans = $stateParams.servicePlans;
    this.subscriptionType = $stateParams.subscriptionType;

    this.availableTerms = termsService.buildAvailableTerms(this.subscriptionType);
    const defaultTermSelection = this.availableTerms.find(term => term.id === DEFAULT_TERM_SELECTION);
    this.termSelection = {
      id: defaultTermSelection.id,
      duration: defaultTermSelection.duration,
      requestedActionDate: termsService.getFirstDayOfMonth(undefined, 1)
    };
  }

  finish() {
    this.disableNextButton = true;
    // after service submission, return to previous page regardless of success or failure
    const orderRequest = this.backofficeService.getOrderRequest(this.tileSelection[0],
      this.termSelection.requestedActionDate, this.termSelection.duration);
    return this.backofficeService.submitOrder(orderRequest).then(() => {
      const modal = this.dialogService.open(EvalConvertSubmittedModalComponent);
      const modalInstance = modal.componentInstance as EvalConvertSubmittedModalComponent;
      modalInstance.parentName = this.parentName;
      modalInstance.closeModal.subscribe(() => modal.close());
    }).finally(() => this.$state.go(this.$state.previous.name));
  }

  tilesChanged(tiles: MBU[]) {
    this.tileSelection = tiles;
  }

  planChanged(plan: Plan) {
    this.planSelection = plan;
    this.disableNextButton = !this.planSelection;
  }

  termSelectionChanged(term: TermSelection) {
    this.termSelection = term;
  }

  // the reason we want to have the step is because the <embed> PDF stops displaying when you go back and forth between the last step
  // therefore we only display the PDF once the user has gone to the last step
  stepChange(step: number) {
    this.step = step;
  }

}
