import { UIRouter } from '@uirouter/core';

/**
 * If the user is redirected from a third-party vendor, we can again redirect them via the router
 * Note: this will have to be upgraded when we remove UI-Router Hybrid
 */
export function redirectHook(uiRouter: UIRouter) {
  const transitionService = uiRouter.transitionService;

  const redirect = () => {
    const locationService = (uiRouter.locationService as any).$location;
    const {id, type} = locationService.search();

    if (type === 'service' && id) {
      const values = locationService.search();
      locationService.search({}).replace(); // this prevents a redirect loop by removing the query string
      uiRouter.stateService.go('services.details', values);
      return false;
    }
  };

  transitionService.onBefore({
  }, redirect, {priority: 900});

}
