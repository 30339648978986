import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'purchase-service-submitted',
  templateUrl: './purchase-service.modal.component.html',
  styleUrls: ['./purchase-service.modal.component.scss']
})
export class PurchaseServiceModalComponent {
  @Input() projectedContent: TemplateRef<any>;
  @Input() bodyText: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  constructor(public self: NgbActiveModal) { }

}
