import { UsersEditComponent } from '@app/components/users-edit/users-edit.component';
import { Ng2StateDeclaration, Ng2ViewDeclaration } from '@uirouter/angular';
import { ResolvableLiteral, Transition } from '@uirouter/core';
import * as AuthScopes from '../auth/roles.constants';
import { AccountsComponent } from './accounts.component';
import { SubscriberGroupsComponent } from './subscriber-groups/subscriber-groups.component';
import { SubscriberGroupEditComponent } from './subscribers-edit/subscriber-group-edit.component';
import { UsersComponent } from './users/users.component';

export function getIdFn(t: Transition) {
  return t.params().id;
}

const getIdResolvable: ResolvableLiteral = {
  token: 'id',
  deps: [Transition],
  resolveFn: getIdFn,
};

/**
 * Helper for building the `view` configuration for a sub-state of `accounts`. The returned
 * views object tells the router to mount the given `component` in the ui-view slot named
 * `accountsSubView` in parent state `accounts`.
 *
 * I can't believe you need to explicitly name the nested view & state here… there must be an easier way
 * @param component The component class
 */
export function buildAccountsViewConfig(component: any): { [key: string]: Ng2ViewDeclaration } {
  return {
    'accountsSubView@accounts': { component }
  };
}

export const accountsStates: Ng2StateDeclaration[] = [
  {
    name: 'accounts',
    url: '/accounts',
    redirectTo: 'accounts.users',
    component: AccountsComponent,
  },

  // Users states
  {
    name: 'accounts.users',
    url: '/users',
    redirectTo: 'accounts.users.list',
  },
  {
    name: 'accounts.users.list',
    url: '',
    params: {
      requiresRole: `${AuthScopes.admin}`,
    },
    views: buildAccountsViewConfig(UsersComponent),
  },
  {
    name: 'accounts.users.details',
    url: '/{id}',
    params: {
      requiresRole: `${AuthScopes.admin}`,
    },
    resolve: [getIdResolvable],
    views: buildAccountsViewConfig(UsersEditComponent),
  },

  // Subscriber Groups states
  {
    name: 'accounts.subscribers',
    url: '/subscribers',
    redirectTo: 'accounts.subscribers.list',
  },
  {
    name: 'accounts.subscribers.list',
    url: '',
    params: {
      requiresRole: `${AuthScopes.admin}`,
    },
    views: buildAccountsViewConfig(SubscriberGroupsComponent),
  },
  {
    name: 'accounts.subscribers.details',
    url: '/{id}',
    params: {
      requiresRole: `${AuthScopes.admin}`,
    },
    resolve: [getIdResolvable],
    views: buildAccountsViewConfig(SubscriberGroupEditComponent),
  },
];
