<input class="form-control"
       [placeholder]="inputPlaceholder"
       (click)="onDateInputClick()"
       (keyup.enter)="onDateInputEnterKeyup()"
       (input)="datesRangeInputChange($event)"
       (datesUpdated)="dateRangeApplied()"
       ngxDaterangepickerBootstrap
       [linkedCalendars]="dateRangePickerOptions.linkedCalendars"
       [showCancel]="dateRangePickerOptions.showCancel"
       [timePicker]="dateRangePickerOptions.timePicker"
       [timePicker24Hour]="dateRangePickerOptions.timePicker24Hour"
       [timePickerSeconds]="dateRangePickerOptions.timePickerSeconds"
       [minDate]="dateRangePickerOptions.minDate"
       [maxDate]="dateRangePickerOptions.maxDate"
       [dateLimit]="dateRangePickerOptions.dateLimit"
       [isTooltipDate]="dateRangePickerOptions.isTooltipDate"
>
