import { Attribute, Directive, forwardRef, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * Used to invalidate a control when the control's value does not match the value of a
 * paired control.
 *
 * <form>
 *   <input name="main">
 *   <input name="confirm" confirmInput [confirmInput]="main">
 * </form>
 *
 * When validation fails the `mismatch` error property is set to true.
 */
@Directive({
  selector: '[confirmInput][formControlName],[confirmInput][formControl],[confirmInput][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ConfirmInputDirective), multi: true }
  ]
})
export class ConfirmInputDirective implements Validator, OnDestroy {
  private sub: Subscription;

  constructor(@Attribute('confirmInput') private confirmInput: string) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const other = c.parent.get(this.confirmInput);
    if (!this.sub) {
      this.sub = other.valueChanges.subscribe(() => {
        c.updateValueAndValidity();
      });
    }

    return c.value === other.value ? null : {mismatch: true};
  }
}
