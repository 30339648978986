import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SharedModule } from '../../shared';
import { CredentialsListModule } from '../../shared/components/credentials-table';
import { Office365Module } from './cloud/office365';
import { ProtectVModule } from './cloud/protectV';
import { SalesforceModule } from './cloud/salesforce';
import { CloudHSMClientsTableComponent } from './cloudHSM/details/cloudHSM-clients.table.component';
import { CloudHSMDetailsTabsComponent } from './cloudHSM/details/cloudHSM-details-tabs.component';
import { DownloadClientDetailsComponent } from './cloudHSM/details/download-client-details.component';
import { HSMonDemandWizard } from './cloudHSM/wizard';
import { ServiceDetailsComponent } from './details/service-details.component';
import { TileRedirectDialogComponent } from './redirect/tile-redirect-dialog/tile-redirect-dialog.component';
import { serviceDetailsState, servicesListState, servicesState } from './service.states';
import { ServicesMarketplaceComponent } from './services/marketplace/marketplace.component';
import { ServicesComponent } from './services/services.component';
import { DeleteServiceDialogComponent } from './dialogs/delete-service-dialog.component';
import { ServiceConfigurationComponent } from '@app/features/gem-services/details/service-configuration.component';
import { ProvisionDialogComponent } from '@app/features/gem-services/provision/provision-dialog.component';

@NgModule({
  declarations: [
    CloudHSMDetailsTabsComponent,
    CloudHSMClientsTableComponent,
    DeleteServiceDialogComponent,
    ServicesComponent,
    ServiceDetailsComponent,
    ServiceConfigurationComponent,
    ServicesMarketplaceComponent,
    DownloadClientDetailsComponent,
    TileRedirectDialogComponent,
    ProvisionDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: [servicesState, servicesListState, serviceDetailsState],
    }),
    HSMonDemandWizard,
    SalesforceModule,
    ProtectVModule,
    Office365Module,
    CredentialsListModule, // this is here because cloudHSM doesn't have it's own Module
  ]
})
export class ServicesModule {}

// AngularJS compatibility module
require('../../components/service-card/service-card.component');

// eslint-disable-next-line @typescript-eslint/no-var-requires
export default require('./_init').default
  .directive('gemServices', downgradeComponent({component: ServicesComponent}))
  .directive('serviceConfiguration', downgradeComponent({component: ServiceConfigurationComponent}))
  .directive('cloudHsmDetailsTabs', downgradeComponent({component: CloudHSMDetailsTabsComponent}))
  .directive('downloadClientDetails', downgradeComponent({component: DownloadClientDetailsComponent}))
  .name;
