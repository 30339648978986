import {Component, Input, HostBinding} from '@angular/core';

// Shows the input `value` if it is truthy, otherwise shows "Not Provided"
@Component({
  selector: 'optional',
  templateUrl: './optional.component.html',
})
export class OptionalComponent {
  @Input()
    value: string;

  @HostBinding('class.optional') useClass = true;
}
