<form #stepForm="ngForm">
    <div class="step-instructions">
        <span>Selected service</span>
    </div>
    <div class="details-panel main-panel">
        <div class="details-panel-section tile-selection">
            <div class="tile tile-height" *ngFor="let selection of tileSelection">
                <div class="picture">
                    <img class="card-icon" *ngIf="getImageUrl(selection)" src="{{getImageUrl(selection)}}" alt="Icon" aria-label="service icon" />
                </div>

                <div class="title">
                    {{selection.serviceType.name}}
                </div>

                <div *ngIf="selection.quantity" class="quantity-section metrics">
                    Service Quantity
                    <span>{{selection.quantity}}</span>
                </div>
                <div *ngIf="planSelection" class="quantity-section metrics">
                    Plan
                    <span>{{planSelection.displayName}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="step-instructions">
        <span>Requested term</span>
        <img alt="Additional information" class="additional-info-icon"
             customTooltip="Dependent on approval by Service Provider" src="images/additionalInfo.svg">
    </div>
    <div class="details-panel main-panel">
        <div class="details-panel-section tile-height" data-locator="selected-term">
            {{termSelection.duration}} {{termSelection.duration === 1 ? 'Month' : 'Months' }}
            beginning {{termSelection.requestedActionDate | date: 'MMMM d, y'}}
        </div>
    </div>

    <div class="terms-of-service-section">
        <div class="step-instructions">
            <span>Terms of service</span>
        </div>
        <div class="details-panel main-panel">
            <div class="details-panel-section">
                <!-- only show on step 2, the PDF stops working if you flip back and forth without show/hide -->
                <!-- 'embed' does not work in Safari, hence use 'object' -->
                <object class="pdf" height="591px" [data]="pdfSource" *ngIf="displayPDF()"
                        width="100%"
                        type="application/pdf">
                </object>
                <div class="accept-terms">
                    <label>
                        <!-- todo custom checkbox -->
                        <input type="checkbox" name="accepted" [disabled]="!checkboxEnabled()" [ngModel]="accepted"
                               required/>
                        I have read and accept the Terms of Service.</label>
                </div>
            </div>
        </div>
    </div>
</form>
