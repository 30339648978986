import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProtectVKeyFormatted } from '../../protectV.constants';

@Component({
  selector: 'protectv-key-restore-modal',
  styleUrls: ['./modal-protectV.scss'],
  templateUrl: './modal-protectV-key-restore.component.html',
})
export class ProtectVKeysRestoreModalComponent {

  @Input() key: ProtectVKeyFormatted;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() restore: EventEmitter<{ key: ProtectVKeyFormatted}> = new EventEmitter();

}
