import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from '@app/components';
import { DialogService } from '@app/components/gem-dialogs/dialog.service';
import { WindowToken } from '@app/shared/services';
import { TenantCreateBody } from '../tenant.model';
import { getAccountTypeLabel } from '../tenant.util';

@Component({
  selector: 'gem-tenant-wizard',
  templateUrl: './tenant.wizard.component.html',
  styleUrls: ['./tenant.wizard.scss']
})
export class TenantWizardComponent extends WizardComponent implements OnInit {
  @Input() accountType: string;
  @Input() accountTypeLabel: string;

  isHostnameChanged = false;
  defaultServiceQuota = 5;
  maxServiceQuota = 0x7fffffff;

  modalTitle: string;
  confirmPassword: string;
  tenant: TenantCreateBody;

  constructor(
    activeModal: NgbActiveModal,
    @Inject(WindowToken) private $window: Window,
    private dialogService: DialogService,
  ) {
    super(activeModal);
  }

  ngOnInit() {
    const accountType = this.accountType;
    this.modalTitle = `Add ${this.accountTypeLabel} Tenant`;
    this.confirmPassword = ''; // used in UI only
    if (accountType === 'service provider') {
      this.defaultServiceQuota = this.maxServiceQuota;
    }
    this.tenant = this.emptyTenant(accountType);
  }

  getAccountTypeLabel() {
    return getAccountTypeLabel(this.accountType);
  }

  // TODO this is just a guess
  // @returns {string} the page host with the first segment chopped off, otherwise .dpod.io
  getDomainSuffix() {
    const host = this.$window.location.hostname;
    const idx = host.indexOf('.');
    return idx === -1 ? '.market.dpondemand.io' : host.slice(idx);
  }

  emptyTenant(accountType) {
    return {
      accountType,
      accountStatus: 'active',
      adminPassword: '',
      adminEmail: '',
      adminGivenName: '',
      adminFamilyName: '',
      adminPhoneNumber: '',
      companyName: null,
      departmentName: null,
      billingAddress: {
        streetAddress: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
      hostname: '',
      name: '',
      serviceQuota: this.defaultServiceQuota,
    };
  }

  finish() {
    super.close(this.tenant);
  }
}
