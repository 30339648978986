import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@app/components';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { Subscription } from 'rxjs';
import { SubscriberGroup } from '../accounts.model';
import { SubscribersApi } from '../api/subscribersApi.service';
import * as msgs from '../subscribers.messages';

@Component({
  selector: 'subscriber-groups-table',
  templateUrl: './subscriber-groups-table.component.html',
  styleUrls: ['./subscriber-groups-table.component.scss']
})
export class SubscriberGroupsTableComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<SubscriberGroup>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  readonly displayedColumns = [
    'name',
    'description',
    'totalUsers',
    'totalServices',
    'totalClients',
    'actions'
  ];

  dataSource = new MatTableDataSource<SubscriberGroup>();

  private dataSub: Subscription;

  constructor(
    private subscribersApi: SubscribersApi,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.dataSource.data = this.subscribersApi.getAll();
    this.dataSub = this.subscribersApi.subscribe(this.onSubscriberGroupsChanged.bind(this));
  }

  ngAfterViewInit() {
    // dpodTable and sort are only available in AfterViewInit
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe();
  }

  onSubscriberGroupsChanged() {
    this.dataSource.data = this.subscribersApi.getAll();
    this.table.renderRows();
  }

  showDeleteDialog(id: string) {
    this.dialogService.confirm({
      title: msgs.confirmTitle,
      content: msgs.confirmText,
      yesLabel: 'Delete',
      noLabel: 'Cancel',
    }).then(() => this.deleteGroup(id));
  }

  deleteGroup(id: string) {
    const progress = this.dialogService.progress(msgs.deleting);
    return this.subscribersApi.delete(id)
      .finally(() => progress.close());
  }

  save(entity: SubscriberGroup) {
    const progress = this.dialogService.progress(msgs.saving);
    return this.subscribersApi.save(entity)
      .finally(() => progress.close());
  }

  canDelete(subGrp: SubscriberGroup): boolean {
    if (subGrp) {
      return subGrp.totalServices === 0 && subGrp.totalUsers === 0;
    }
    return false;
  }

  onFilter(value: string) {
    this.dataSource.filter = value;
  }

}
