/**
 * This file wraps a native browser API responsible for web worker creation.
 * It is needed because of the unit tests - so that it is easier to mock the browser API.
 */
import { Provider } from '@angular/core';
import { AUDIT_LOGS_WORKER_FACTORY } from '../logs.constants';

function createWorker() {
  // The annotation below is needed only because of the unit tests - they won't run without it
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore: @ts-expect-error is unusable somehow
  return new Worker(new URL('./audit-logs-table.worker', import.meta.url));
}

export const AuditLogsWorkerFactory: Provider = {
  provide: AUDIT_LOGS_WORKER_FACTORY,
  useValue: createWorker
};
