import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SalesforceService } from '@app/ajs-upgraded-providers';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { Subscription } from 'rxjs';
import { SalesforceTenantSecret } from '../../salesforce.model';

const columnNames = [
  'status',
  'sfdcSecretVersion',
  'formattedSfdcType',
  'createdAt',
  'sfdcCreatedByUsername',
  'modifiedAt',
  'modifiedBy',
  'actions',
];

@Component({
  selector: 'salesforce-secrets-table',
  templateUrl: './salesforce-secrets-table.component.html',
  styleUrls: ['./salesforce-secrets-table.component.scss']
})
export class SalesforceSecretsTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() canAlter: boolean;

  /** TODO what's the point of this if we're just calling getAll to get the same data?? */
  @Input() secrets: SalesforceTenantSecret[];

  @Output() restore = new EventEmitter<SalesforceTenantSecret>();
  @Output() revoke = new EventEmitter<SalesforceTenantSecret>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<SalesforceTenantSecret>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  displayedColumns = columnNames.slice();

  dataSource = new MatTableDataSource<SalesforceTenantSecret>();

  private sub: Subscription;

  constructor(
    @Inject(SalesforceService) private salesforceService: any,
  ) { }

  ngOnInit() {
    if (!this.canAlter) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }

    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    this.dataSource.data = this.secrets;

    this.sub = this.salesforceService.subscribe((/* tenant secrets */) => {
      this.dataSource.data = this.salesforceService.getAll((a, b) => a.sfdcSecretVersion < b.sfdcSecretVersion ? 1 : -1);
    });
  }

  ngAfterViewInit() {
    // sort and dpodTable are only available in AfterViewInit
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
  }

  ngOnChanges(cs: SimpleChanges) {
    if (cs['secrets']) {
      this.dataSource.data = cs['secrets'].currentValue;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getState(secret: SalesforceTenantSecret): string {
    return secret.state;
  }

  sortingDataAccessor(secret: SalesforceTenantSecret, columnName: string): string | Date {
    switch (columnName) {
    case 'createdAt': return new Date(secret.sfdcCreatedDate); // Date needed for sorting
    case 'modifiedAt': return new Date(secret.sfdcModifiedDate); // Date
    case 'status': return secret.state;
    }
    // Otherwise sort on the field having the column name
    return secret[columnName];
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

}
