import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DownloadModalComponent } from '@app/components/download-modal/download-modal.component';

@Injectable()
export class DownloadService {

  constructor(private ngbModal: NgbModal) {}

  /**
   * Saves the blob data to disk with the given filename
   */
  downloadFile(blob, filename, artifact) {
    const modalRef = this.ngbModal.open(DownloadModalComponent, {
      backdrop: 'static',
      windowClass: 'open-modal'
    });
    const instance = modalRef.componentInstance as DownloadModalComponent;
    instance.blob = blob;
    instance.filename = filename;
    instance.artifact = artifact;
  }

}
