import { Component } from '@angular/core';
import angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

// this is box with a copy icon inside to be used alongside inputs
// note: has no functionality, functionality should be done on the tag
// ex. <copy-input (cbOnSuccess)="copiedId = true" [ngxClipboard]="clientId" (click)="copiedSecret = false"></copy-input>
@Component({
  selector: 'copy-input',
  styleUrls: ['./copy-input.scss'],
  template: `
    <div title="Copy to clipboard" tabindex="0">
      <svg viewBox="0 0 24 24" class="icon-svg align-middle">
        <use xlink:href="#copy"/>
      </svg>
    </div>`,
})
export class CopyInputComponent {
}

export default angular.module('gem.copy-input', [])
  .directive('copyInput', downgradeComponent({component: CopyInputComponent}) as angular.IDirectiveFactory).name;
