export * from './header.component';

// AngularJS compatibility
import { downgradeComponent } from '@angular/upgrade/static';
import { HeaderComponent } from './header.component';
import angular from 'angular';

export default angular.module('dpaas.header', [])
  .directive('gemHeader', downgradeComponent({component: HeaderComponent}))
  .name;
