<div id="users-edit" (click)="cancel()" class="panel-width" [ngClass]="{'page': hasPageClass()}">

    <div class="row form-group">
        <div class="col-sm-6">
            <!-- display breadcrumb -->
            <app-breadcrumb [prevLabel]="'Users'" [currLabel]="placeholders.username"></app-breadcrumb>
        </div>
        <div class="col-sm-6 text-end">
            <button name="reset-mfa" [disabled]="editing" class="btn btn-next" (click)="resetMfaToken()">
                Reset MFA Token
            </button>
            <button *ngIf="showResetPasswordOption && !isLoggedInUser(user.username)" name="reset" [disabled]="editing" class="btn btn-next"
                    (click)="resetPassword()">Reset
                User Password
            </button>
            <button *ngIf="isLoggedInUser(user.username)" name="reset" [disabled]="editing" class="btn btn-next"
                    (click)="changePassword()">Change Password
            </button>
            <button *ngIf="canDeleteUser(user)"
                    data-locator="delete-button"
                    name="delete" [disabled]="editing" class="btn btn-next" (click)="delete()">Delete
                User
            </button>
        </div>
    </div>

    <div (click)="$event.stopPropagation()" class="details-panel main-panel">
        <div class="details-panel-section">
            <form #editUser="ngForm" name="editUser">

                <div class="row form-group">
                    <div class="col-sm-6">
                        <label for="username">Email Address</label>
                        <br />
                        <strong id="username">{{user.username}}</strong>
                    </div>
                    <div class="col-sm-6 text-end">
                        <div *ngIf="editing">
                            <button class="btn btn-next" name="save" (click)="save()" [disabled]="editUser.invalid">Save
                                Changes
                            </button>
                            <button class="btn btn-next" name="cancel" (click)="cancel()">Cancel</button>
                        </div>
                        <div *ngIf="!editing">
                            <gem-feedback text="Changes Saved!" [(trigger)]="saved"></gem-feedback>

                            <button type="button" title="Edit user" name="edit" class="btn btn-default p-0 border-0" (click)="edit()">
                                <svg class="icon-svg align-sub">
                                    <use xlink:href="#edit"></use>
                                </svg>
                            </button>

                        </div>
                    </div>
                </div>

                <hr />

                <div class="row form-group">
                    <div class="col-sm-3">
                        <label for="firstName">First Name</label>
                        <br />

                        <ng-container *ngIf="editing ; else firstNameReadonly">
                            <input id="firstName" #firstName="ngModel" name="firstName" maxlength="255"
                                class="form-control" [(ngModel)]="user.givenName" validationIcon required>
                            <div id="error-message-firstName" class="text-danger error-message" role="alert"
                                *ngIf="firstName?.dirty">
                                <div *ngIf="firstName.errors?.required">Please enter a first name</div>
                            </div>
                        </ng-container>
                        <ng-template #firstNameReadonly>
                            <strong data-locator="firstName">{{user.givenName}}</strong>
                        </ng-template>
                    </div>
                    <div class="col-sm-3">
                        <label for="lastName" class="control-label">Last Name</label>
                        <br />

                        <ng-container *ngIf="editing ; else lastNameReadonly">
                            <input id="lastName" #lastName="ngModel" name="lastName" class="form-control"
                                maxlength="255" [(ngModel)]="user.familyName" validationIcon required>
                            <div id="error-message-lastName" class="text-danger error-message" role="alert"
                                *ngIf="lastName?.dirty">
                                <div *ngIf="lastName.errors?.required">Please enter a last name</div>
                            </div>
                        </ng-container>

                        <ng-template #lastNameReadonly>
                            <strong data-locator="lastName">{{user.familyName}}</strong>
                        </ng-template>
                    </div>
                    <div class="col-sm-3">
                        <label for="accountRole" class="control-label">Role</label>
                        <br />
                        <strong id="accountRole">{{getAccountRole()}}</strong>
                    </div>

                    <div class="col-sm-3" *ngIf="showSubscriberGroups">
                        <label for="subscriberGroups" class="control-label">Subscriber Group</label>
                        <br />
                        <strong id="subscriberGroups">{{getSubscriberGroup()}}</strong>
                        <span class="text-muted" *ngIf="getSubscriberGroup() === null">None</span>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-sm-3">
                        <label for="createdBy" class="control-label">Created By</label>
                        <br />
                        <strong id="createdBy">{{getCreatedBy()}}</strong>
                    </div>
                    <div class="col-sm-3">
                        <label for="createdAt" class="control-label">Date Created</label>
                        <br />
                        <strong id="createdAt">{{user.createdAt | date: 'd-MMM-yyyy H:mm' }}</strong>
                    </div>
                </div>

            </form>

        </div>
    </div>

</div>
