import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

import * as AuthScopes from './roles.constants';
import { LoginAction } from './auth.interface';
import { Subject } from 'rxjs';

export const MOCK_SGUID = '00001111-2222-3333-444455550003';

/**
 * A mock authentication service for testing. Acts as if all user scopes are present.
 */
@Injectable()
export class AuthServiceMock {
  tenantId = 'tuid0000-aaaa-bbbb-1111-222222222222';
  subscriberGroupId = 'sguid00001111-2222-3333-444455550003';
  tenantChangedSubject = new Subject<string>();

  reset() { }

  hasScope(scope) {
    const allScopes = Object.values(AuthScopes);
    return allScopes.includes(scope);
  }

  hasAnyScope(...scopes: string[]) {
    const allScopes = Object.values(AuthScopes);
    return allScopes.find(userScope => scopes.includes(userScope));
  }

  getToken() {
    return 'Bearer fjkjffjs';
  }

  getIdentity() {
    return {
      user_name: 'Manfred Schmidt',
      email: 'manfred@xyz.de',
      scope: [
        'openid',
        `dpod.tuid.${this.tenantId}.sguid.${this.subscriberGroupId}`,
      ],
    };
  }

  getTenantId() {
    return this.tenantId;
  }

  hasSubscriberGroupId(sguid: string) {
    return sguid === MOCK_SGUID;
  }

  isRedirecting() {
    return false;
  }

  isEmailVerified() {
    return true;
  }

  login() { }

  logout() { }

  isStateValid() {
    return true;
  }

  determineLoginState() {
    return Promise.resolve({
      loginAction: LoginAction.LoggedIn
    });
  }

  redirectToDpodUiState() {
    // The function left blank intentionally
  }

  isTenantSelectionRequired() {
    return false;
  }

  getAllUserTenants() {
    return [];
  }

  readTenantIdFromUrl() {
    return '';
  }

  selectTenant() {
    // The function left blank intentionally
  }

  getUserId() {
    return '';
  }
}

export const MockAuthServiceProvider = {
  provide: AuthService,
  useClass: AuthServiceMock
};
