<div class="modal-body row">
    <h5>{{marketingTile.name}}</h5>

    <div>
        <p>{{marketingTile.name}} is accessed through our {{parentTile.name}} service and can be subscribed
            through a {{parentTile.name}} plan.
            Simply create or navigate to your service and launch {{marketingTile.name}} from there.</p>
    </div>
</div>

<div class="row g-0">
    <div class="float-sm-start">
        <button class="btn btn-next" name="next" type="button" (click)="close()">{{closeLabel}}</button>
    </div>
</div>
