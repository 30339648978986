export const admin = 'dpod.tenant.admin';
export const owner = 'dpod.tenant.appowner';
export const spadmin = 'dpod.tenant.spadmin';
export const operator = 'dpod.operator';

const unverifiedSuffix = '.unverified';
export const adminUnverified = admin + unverifiedSuffix;
export const ownerUnverified = owner + unverifiedSuffix;
export const spadminUnverified = spadmin + unverifiedSuffix;

/**
 * A special fake role for the Select Tenant page.
 * The Select Tenant page is available for any authorized user.
 * But if a user sees the Select Tenant page,
 * it means the user belongs to multiple tenants, with potentially different roles.
 * We cannot identify the role of the user unless they select a tenant. But we need to:
 * 1. Make the Select Tenant page available only when the user has to select a tenant.
 * 2. Make the Select Tenant page unavailable to unauthorized users.
 * This is why we need a fake role for it.
 */
export const multiTenant = 'multiTenant';
