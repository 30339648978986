import { Injectable } from '@angular/core';
import { TenantSubscriptions } from '@app/features/gem-services/services/tenant-subscription/subscription.constants';
import { AvailableTermIds } from './eval-convert.constants';
import { DateTimeHelperService } from '@app/shared/services/date-time-helper.service';

interface DurationOption {
  id: number;
  label: string;
}

export interface AvailableTerm {
  id: number;
  duration: number;
  label: string;
  requestedActionDate?: string;
  durationOptions?: DurationOption[];
  requestedActionDateOptions?: string[];
}

const CUSTOM_DURATION_MIN = 1;
const CUSTOM_DURATION_MAX = 36;
const CUSTOM_DURATION_DEFAULT = 12;

// Min and max month offsets from the current month
const CUSTOM_FIRST_MONTH = 0;
const CUSTOM_LAST_MONTH = 3;

const TARGET_TIME_ZONE_FORMAT: Intl.DateTimeFormatOptions = {
  timeZone: 'Europe/Paris', hour12: false, year: 'numeric', month: '2-digit', day: '2-digit'
};

@Injectable()
export class EvalConvertTermsService {
  constructor(private dateTimeHelper: DateTimeHelperService) {}
  /**
   * Given a type of subscriptions, builds an array of available term options.
   * Both trial and production subscriptions include 12, 24 and 36 months options.
   * For production subscriptions, the "Custom" option will be appended.
   *
   * @param {string} subscriptionType Either 'PRODUCTION' (ASE) or 'TRIAL' (ISE)
   * @returns {AvailableTerm[]} Array of term options
   */
  buildAvailableTerms(subscriptionType: string): AvailableTerm[] {
    const availableTerms = this.buildCommonTerms();
    if (subscriptionType === TenantSubscriptions.TYPE.PRODUCTION) {
      availableTerms.push(this.buildCustomTerm());
    }
    return availableTerms;
  }

  /**
   * Given a date and an offset in months, returns a string that represents the first day of
   * the month calculated by adding the month offset to the given date.
   * Before calculating the date, converts the given date into the Europe/Paris time zone.
   *
   * @param {Date} date A starting point for the calculation; the current date by default
   * @param {number} monthOffset A month offset to apply to the given date; 0 by default
   * @returns {string} A date string that represents the first day of the calculated month, without time, converted into the Paris time zone
   */
  getFirstDayOfMonth(date: Date = new Date(), monthOffset: number = 0): string {
    const parisDate = this.convertToParisTime(date);
    return this.formatTermDateForBackend(new Date(parisDate.getFullYear(), parisDate.getMonth() + monthOffset, 1));
  }

  private formatTermDateForBackend(date: Date): string {
    return this.dateTimeHelper.formatDate(date, 'YYYY-MM-DD');
  }

  private buildCommonTerms(): AvailableTerm[] {
    return [
      {
        id: AvailableTermIds.THREE_YEARS,
        duration: AvailableTermIds.THREE_YEARS,
        label: `${AvailableTermIds.THREE_YEARS} Months`,
      },
      {
        id: AvailableTermIds.TWO_YEAR,
        duration: AvailableTermIds.TWO_YEAR,
        label: `${AvailableTermIds.TWO_YEAR} Months`,
      },
      {
        id: AvailableTermIds.ONE_YEAR,
        duration: AvailableTermIds.ONE_YEAR,
        label: `${AvailableTermIds.ONE_YEAR} Months`,
      },
    ];
  }

  private buildCustomTerm(): AvailableTerm {
    const requestedActionDateOptions = this.generateRequestedActionDateOptions();
    return {
      id: AvailableTermIds.CUSTOM,
      duration: CUSTOM_DURATION_DEFAULT,
      label: 'Custom',
      requestedActionDate: requestedActionDateOptions[0],
      durationOptions: this.generateCustomDurations(),
      requestedActionDateOptions: requestedActionDateOptions,
    };
  }

  private generateCustomDurations(): DurationOption[] {
    const durationOptions: DurationOption[] = [];
    for (let i = CUSTOM_DURATION_MIN; i <= CUSTOM_DURATION_MAX; i++) {
      durationOptions.push({ id: i, label: i === 1 ? `${i} Month` : `${i} Months` });
    }
    return durationOptions;
  }

  private generateRequestedActionDateOptions(): string[] {
    const currentDate = new Date();
    const requestedActionDateOptions: string[] = [];
    for (let i = CUSTOM_FIRST_MONTH; i <= CUSTOM_LAST_MONTH; i++) {
      requestedActionDateOptions.push(this.getFirstDayOfMonth(currentDate, i));
    }
    return requestedActionDateOptions;
  }

  private convertToParisTime(date: Date): Date {
    // Format the date according to the Paris time zone,
    // so that we know what date in Paris corresponds to the given date.
    // The en-US locale is used as it's the most common locale supported by all browsers.
    const formattedDateParts = date.toLocaleDateString('en-US', TARGET_TIME_ZONE_FORMAT).split('/');
    // Year-month-day, plus start of the day, using the current time zone
    return new Date(`${formattedDateParts[2]}-${formattedDateParts[0]}-${formattedDateParts[1]}T00:00:00`);
  }
}
