import { NgModule } from '@angular/core';
import { NgxDaterangepickerBootstrapModule } from 'ngx-daterangepicker-bootstrap';
import { DateRangePickerComponent } from './date-range-picker.component';
import { DATE_FORMAT, DATE_SEPARATOR, dateRangePickerLabels } from './date-range-picker.constants';
import { DateRangePickerService } from './date-range-picker.service';

@NgModule({
  declarations: [
    DateRangePickerComponent
  ],
  exports: [
    DateRangePickerComponent
  ],
  providers: [
    DateRangePickerService
  ],
  imports: [
    // Configuration of daterangepicker's locale
    NgxDaterangepickerBootstrapModule.forRoot({
      separator: DATE_SEPARATOR,
      format: DATE_FORMAT,
      applyLabel: dateRangePickerLabels.apply,
      cancelLabel: dateRangePickerLabels.cancel
    })
  ]
})
export class DateRangePickerModule {}
