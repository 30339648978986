import { Component, Inject, Input, OnInit } from '@angular/core';
import { ProtectVService } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { ApiClient } from '../../../../credentials/credentials.interface';
import { FEATURE_TOGGLES, FeatureToggles } from '../../../../feature-toggles';
import { AuthService } from '../../../../auth';
import { keystoreEndpoint, ProtectVKey, ProtectVKeyState } from '../protectV.constants';
import { HSMonDemandCreatedService } from '../../../cloudHSM/cloudHSM.model';

@Component({
  selector: 'protectv-details, vm_keystore-details',
  templateUrl: './protectV-details.component.html',
  styleUrls: ['./protectV-details.scss'],
})
export class ProtectVDetailsComponent implements OnInit {

  @Input() service: HSMonDemandCreatedService;

  keys: ProtectVKey[] = null; // keep `null` to prevent parts of the UI showing until the API call has returned
  updatingKeysTable = true;
  stateFilter: ProtectVKeyState | '' = '';
  copiedKeystoreUrl = false;
  copiedOAuthUrl = false;
  credentials: ApiClient[] = [];
  hasKeys = false;

  permissions = [
    'Connect to Data Protection On Demand using a ProtectV Manager',
    'Revoke & restore keys',
  ];

  constructor(@Inject(ProtectVService) private protectVService: any,
              private dialogService: DialogService,
              private authService: AuthService,
              @Inject(FEATURE_TOGGLES) private featureToggles: FeatureToggles) {
  }

  ngOnInit() {
    this.resync();
  }

  hideAllCopiedMessages() {
    this.copiedOAuthUrl = false;
    this.copiedKeystoreUrl = false;
  }

  getServiceId(): string {
    return this.service.service_id;
  }

  // assuming server side filtering
  resync(): void {
    this.updatingKeysTable = true;
    this.protectVService.resync({
      state: this.stateFilter,
      serviceId: this.service.service_id,
    }, 'resources')
      .then((keys: ProtectVKey[]) => {
        if (keys.length > 0) {
          // there's three states for the keys, has keys, no keys, and updating table
          // to properly display all three, the call gets all keys if any are returned, we won't display the "you haven't connected"
          this.hasKeys = true;
        }
        this.keys = keys;
      })
      .finally(() => this.updatingKeysTable = false);
  }

  isConnected(): boolean {
    return this.hasKeys;
  }

  getKeystoreUrl(): string {
    return `${window.location.origin}${keystoreEndpoint}/`;
  }

  getOAuthUrl(): string {
    return this.authService.getIdentity() ? this.authService.getIdentity().iss : '';
  }

}

