import cloudHSMModule from './_init';
import template from './templates/modal-createClient.content.html';

class CreateClientController {
  constructor() {
    this.clientName = '';
  }

  $onInit() {
    this.existingClients = (this.resolve.existingClients || []).map(c => c.name);
  }

  create() {
    this.close({$value: this.clientName});
  }
}

cloudHSMModule.component('gemCreateClient', {
  template,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: CreateClientController,
});
