<h5 class="mb-3 error-modal">
    <svg class="icon-svg title-icon"><use [attr.xlink:href]="icon" /></svg>
    {{ title }}
</h5>


<p *ngIf="cleanedText" class="mb-0" [innerHTML]="cleanedText"></p>
<ng-container *ngIf="projectedContent" [ngTemplateOutlet]="projectedContent"></ng-container>

<ng-container *ngIf="traceid">
    <p> {{ contact }} </p>

    <div class="alert alert-light-gray mt-3 word-break-break" role="alert">
        <strong class="error-traceid">Incident ID:</strong> {{ traceid }}
    </div>
</ng-container>


<div class="row g-0">
    <div class="col-6">
        <div class="float-sm-start">
            <button class="btn btn-next" name="next" type="button" data-error-ok (click)="close()">Close</button>
        </div>
    </div>
</div>
