import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { EvalConvertModule } from '@app/features/eval-convert/eval-convert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import * as prov from './ajs-upgraded-providers';
import { CoreModule } from './core';
import { AccountsModule } from './features/accounts/accounts.module';
import { AdministrationModule } from './features/administration/administration.module';
import { redirectHook } from './features/application/redirect.hook';
import { CredentialsModule } from './features/credentials';
import { ServicesModule } from './features/gem-services';
import { ReportsModule } from './features/reports';
import { LinkageModule } from './features/linkage';
import { SessionLoggedOutModule } from './features/session-logged-out';
import { SpAdminUsersModule } from './features/spAdminUsers';
import { TenantModule } from './features/tenant';
import { UnsupportedBrowserModule } from './features/unsupported-browser';
import { UnverifiedModule } from './features/unverified/unverified.module';
import { SharedModule } from './shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubscriptionsModule } from './features/subscriptions';
import {
  CiphertrustWizardComponent
} from './features/gem-services/cloud/ciphertrust/wizard/ciphertrust-wizard/ciphertrust-wizard.component';
import { TermsOfServiceModule } from '@app/features/gem-services/cloudHSM/wizard/common/terms-of-service';
import {
  CiphertrustConfigureComponent
} from './features/gem-services/cloud/ciphertrust/wizard-steps/ciphertrust-configure/ciphertrust-configure.component';
import { SummaryComponent } from './features/gem-services/cloud/ciphertrust/wizard-steps/ciphertrust-summary/ciphertrust-summary.component';
import { AsyncServiceDialogComponent } from './features/gem-services/dialogs/async-service-dialog.component';
import { PayshieldWizardComponent } from './features/gem-services/cloud/payshield/wizard/payshield-wizard/payshield-wizard.component';
import { PayshieldConfigureComponent } from './features/gem-services/cloud/payshield/wizard-steps/payshield-configure/payshield-configure.component';
import { PayShieldSummaryComponent } from './features/gem-services/cloud/payshield/wizard-steps/payshield-summary/payshield-summary.component';
import { LogsModule } from './features/logs/logs.module';
import { SelectTenantModule } from './features/select-tenant';

@NgModule({
  imports: [
    BrowserModule,
    CredentialsModule,
    UpgradeModule,
    NgbModule,
    CoreModule.forRoot(),
    SharedModule,
    ReportsModule,
    LinkageModule,
    ServicesModule,
    SpAdminUsersModule,
    AccountsModule,
    UnsupportedBrowserModule,
    TenantModule,
    AdministrationModule,
    UIRouterUpgradeModule,
    SessionLoggedOutModule,
    UnverifiedModule,
    EvalConvertModule,
    SubscriptionsModule,
    LogsModule,
    SelectTenantModule,

    UIRouterUpgradeModule.forRoot({
      config: redirectHook,
    }),

    BrowserAnimationsModule,
    TermsOfServiceModule,
  ],
  // Upgraded AngularJS services
  providers: [
    prov.AuthScopesProvider,
    prov.IHttpServiceProvider,
    prov.IInjectorServiceProvider,
    prov.ILocationServiceProvider,
    prov.IModalServiceProvider,
    prov.IModalStackServiceProvider,
    prov.ISCEServiceProvider,
    prov.ProtectVServiceProvider,
    prov.SalesforceServiceProvider,
    prov.StateServiceProvider,
    prov.StateParamsServiceProvider,
    prov.QProvider,
    prov.VHsmApiServiceProvider,
    prov.ServiceBrokerServiceProvider,
  ],
  declarations: [
    CiphertrustWizardComponent,
    CiphertrustConfigureComponent,
    SummaryComponent,
    AsyncServiceDialogComponent,
    PayshieldWizardComponent,
    PayshieldConfigureComponent,
    PayShieldSummaryComponent
  ],
})
export class AppModule {
  strictDi: boolean;

  constructor(
      private upgrade: UpgradeModule,
  ) { }

  ngDoBootstrap() {
    const strictDi = typeof this.strictDi === 'boolean' ? this.strictDi : true;
    this.upgrade.bootstrap(document.documentElement, ['app'], { strictDi });
  }
}
