<div *ngIf="tenantSettings" class="details-panel main-panel">
    <header class="details-header d-flex">
        <span class="gem-header panel-header">Tenant Registration</span>

    </header>

    <!-- SP Admin: enabling/disabling tenant registration for their tenant -->
    <div class="details-panel-section">
        <div class="col-sm-6">
            <div class="row">
                <label>Allow access to <a href="/signup" class="signuplink" externalLink>tenant registration</a></label>
            </div>
            <div class="row">
                <toggleable-switch (click)="toggleTenantRegistration()"
                                   [(val)]="tenantSettings.automaticTenantOnboarding"></toggleable-switch>
            </div>
        </div>
    </div>
</div>
