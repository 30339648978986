<div class="table-holder mb-4 pt-3">
    <div class="float-start">
        <input #filter type="search" placeholder="Search"
               class="filter form-control form-control-sm ms-4"
               (keyup)="onFilter($event)"/>
        <div *ngIf="description" data-locator="description" class="d-inline-block mt-4 ms-4">{{description}}</div>
    </div>

    <!-- caller provides the table here -->
    <ng-content></ng-content>

    <mat-paginator
        [pageSize]="10"
        (page)="page.emit($event)"
        showFirstLastButtons="true"
        hidePageSize="true">
    </mat-paginator>
</div>
