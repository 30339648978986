import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StepsModule } from '@app/components/steps';
import { WizardInlineComponent } from './wizard-inline.component';

/**
 * Inline Wizard module that is not used as a modal but built right into the page
 */
@NgModule({
  declarations: [WizardInlineComponent],
  imports: [CommonModule, FormsModule, StepsModule],
  exports: [WizardInlineComponent],
})
export class WizardInlineModule {
}
