import { Component, forwardRef, Input } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';

@Component({
  selector: 'payshield-summary',
  templateUrl: './payshield-summary.component.html',
  styleUrls: ['./payshield-summary.component.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => PayShieldSummaryComponent)}
  ]
})
export class PayShieldSummaryComponent extends WizardStepBaseComponent {

  @Input() serviceName: string;
  @Input() subscriptionTier;
  @Input() dataCenter;

}
