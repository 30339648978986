import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import {
  TenantSubscription
} from '@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface';
import { AuthService } from '@app/features/auth';
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Duration, DateTimeHelperService } from '@app/shared/services/date-time-helper.service';
import { TermIso8601Pipe } from '@app/shared/pipes/term-iso8601.pipe';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import { RequiresRoleService, TilesService } from '@app/shared/services';
import { PurchaseDialogService } from '@app/shared/services/PurchaseDialogService';
import { IseErrorMessagesComponent } from '@app/components/gem-partials/ise-messages/ise-error-messages.component';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { Purchase } from '@app/features/gem-services/services/tenant-subscription/subscription.constants';
import * as Roles from '@app/features/auth/roles.constants';
import { TableSyncStatus } from '@app/features/tenant/details/tenant-subscriptions.component';

export const columnNames = [
  'serviceType',
  'state',
  'number',
  'startDate',
  'endDate',
  'plan',
  'quantity',
  'purchase'
];

export const columnNamesSP = [
  'serviceType',
  'state',
  'number',
  'startDate',
  'endDate',
  'autoRenewalPeriod',
  'plan',
  'quantity',
];

@Component({
  selector: 'subscriptions-table',
  templateUrl: './subscriptions-table.component.html',
  styleUrls: ['./subscriptions-table.component.scss']
})

export class SubscriptionsTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  /** Overrides the list of columns to show. */
  @Input() displayedColumns: string[];
  @Input() tableData: TenantSubscription[];
  @Input() services;
  @Input() tilesWithSubscriptionInfo: Tile[];
  @Input() syncStatus = TableSyncStatus.Syncing;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('iseErrorMessages') iseErrorMessages: TemplateRef<IseErrorMessagesComponent>;
  @ViewChild('table', { static: true }) private table: MatTable<TenantSubscription>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  dataSource = new MatTableDataSource<TenantSubscription>();
  termIso8601Pipe: TermIso8601Pipe;
  dateFormatPipe: DateFormatPipe;
  Purchase = Purchase;
  TableSyncStatus = TableSyncStatus;
  isAdminOwnerAndVerified = false;
  isOperatorSPAndVerified = false;

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              @Inject(ConfigToken) public config: DpodUiConfig,
              private changeDetectorRef: ChangeDetectorRef,
              private tilesService: TilesService,
              private purchaseDialogService: PurchaseDialogService,
              private requiresRoleService: RequiresRoleService,
              private dateTimeHelper: DateTimeHelperService,) {
    this.isAdminOwnerAndVerified = (this.requiresRoleService.hasRole(Roles.admin) || this.requiresRoleService.hasRole(Roles.owner))
                  && this.authService.isEmailVerified();
    this.isOperatorSPAndVerified = (this.requiresRoleService.hasRole(Roles.operator) || this.requiresRoleService.hasRole(Roles.spadmin))
                  && this.authService.isEmailVerified();

    this.termIso8601Pipe = new TermIso8601Pipe(dateTimeHelper);
    this.dateFormatPipe = new DateFormatPipe(dateTimeHelper);
    this.displayedColumns = this.isAdminOwnerAndVerified ? columnNames.slice() : columnNamesSP.slice();
  }

  ngOnInit(): void {
    this.dataSource.data = this.tableData || [];
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData']) {
      this.dataSource.data = changes['tableData'].currentValue;
    }
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    // reset and set the sort by serviceType value
    this.sort.sort({ id: '', start: 'asc', disableClear: false });
    this.sort.sort({ id: 'serviceType', start: 'asc', disableClear: false });
    (this.sort.sortables.get('serviceType') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
    this.changeDetectorRef.detectChanges();

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
    this.table.renderRows();
  }

  ngOnDestroy() {
    this.sort.sort({id: '', start: 'asc', disableClear: false});
  }

  sortingDataAccessor(subscription: TenantSubscription, columnName: string): string|Date|number|Duration {
    switch (columnName) {
    case 'serviceType':
      return this.getServiceTypeName(subscription.serviceType).toLowerCase();
    case 'autoRenewalPeriod':
      return this.dateTimeHelper.duration(subscription.autoRenewalPeriod) || '';
    case 'quantity':
      return this.getQuantity(subscription.features);
    case 'startDate':
      return this.dateFormatPipe.transform(subscription.startDate);
    case 'endDate':
      return this.dateFormatPipe.transform(subscription.endDate);
    default:
      // Otherwise the column name matches the field name
      return subscription[columnName];
    }
  }

  getServicePurchaseState(serviceType: string): Purchase | null {
    const tile = this.tilesWithSubscriptionInfo?.find(t => t.shortCode === serviceType);
    return this.tilesService.determinePurchaseState(tile);
  }

  async convertEvalButton(serviceType: string, purchaseState: Purchase) {
    await this.purchaseDialogService.openDialog(serviceType, '', {
      noPlansDialogContent: this.iseErrorMessages['emptyPlanListMessage'],
      errorGettingPlansDialogContent: this.iseErrorMessages['retrievingPlansErrorMessage'],
    }, this.getSubscriptionTypeFromServiceType(serviceType, purchaseState), this.tilesWithSubscriptionInfo);
  }

  getSubscriptionTypeFromServiceType(serviceType: string, purchaseState: Purchase): string {
    if (purchaseState === Purchase.ISE) {
      return '';
    }
    return this.tilesWithSubscriptionInfo?.find(t => t.shortCode === serviceType).subscriptionInfo[0].type;
  }

  /**
   * See https://material.angular.io/components/table/api
   * @param subscription the subscription element considered
   * @param filter the string used to filter
   */
  filterPredicate(subscription: TenantSubscription, filter: string): boolean {
    // Reuse the sorting data accessor to provide the data to check the filter against.
    // If any column's sorting data matches the filter, return true.
    return this.displayedColumns.some(column => {
      const sortingData = (column === 'autoRenewalPeriod') ?
        this.termIso8601Pipe.transform(subscription.autoRenewalPeriod) :
        this.sortingDataAccessor(subscription, column);
      return typeof sortingData === 'string' && sortingData.toLowerCase().includes(filter.toLowerCase());
    });
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  /**
   * @param features lists of subscriptions features
   * @returns Value of Quantity, if Quantity is available in features
   */
  getQuantity(features: any): string {
    if (features && features['quantity']) {
      return features['quantity'];
    }
    return '';
  }

  /**
   * @param shortCode code corresponding to a service type
   * @returns Service Type Name associated with shortCode, or shortcode if Name is not available
   */
  getServiceTypeName(shortCode: string): string {
    return this.services[shortCode] || shortCode;
  }

}
