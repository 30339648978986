export * from './optional.component';

// TODO this module is used only for Angular compatibility, should remove it
import { OptionalComponent } from './optional.component';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

export default angular.module('gem.optional', [])
  .directive('optional', downgradeComponent({component: OptionalComponent}))
  .name;
