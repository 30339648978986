import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IHttpResponse } from 'angular';
import { DialogService } from '@app/components';
import { WizardComponent } from '@app/components/wizard-ng2';
import { ServiceBrokerService } from '@app/shared/services';
import {
  ServiceBindingParams,
  ServiceInstance,
  ServiceInstanceCreateParams
} from '@app/shared/services/service-broker.interface';
import { CredentialType } from '../../../../credentials/credentials.interface';
import { CredentialsService } from '../../../../credentials/credentials.service';
import { Tile, TileServicePlan } from '../../../../marketplace/tiles.interface';
import { ServiceCreationWizard } from '../../../cloudHSM/wizard/service.wizard-interface';
import { ProtectVServiceCreateParams, VMEncryptionServiceBindingResponse } from './protectV.interface';
import { PROGRESS_CREATE_MSG } from '@app/features/gem-services/services.constants';

@Component({
  selector: 'protectv-wizard',
  templateUrl: './protectV-wizard.component.html',
})
export class ProtectVWizardComponent extends WizardComponent implements OnInit, ServiceCreationWizard {
  @Input() tile: Tile;
  @Input() servicePlan: TileServicePlan[];
  @Input() serviceType: string;
  @Input() service: ProtectVServiceCreateParams = {
    name: '',
  };

  @Input() credentials = {
    credentialsName: ''
  };

  @Input() loading = false;

  modalTitle = 'Add VM Encryption Service';
  apiClientId = null;
  apiClientSecret = null;
  credentialType: CredentialType = CredentialType.Service;

  // if true, prevents the `finish` method from being called again
  private finished = false;

  constructor(activeModal: NgbActiveModal,
              private dialogService: DialogService,
              private credentialsService: CredentialsService,
              @Inject(ServiceBrokerService) private serviceBrokerService: ServiceBrokerService) {
    super(activeModal);
  }

  get step(): number {
    return this.currentStepIdx;
  }

  // the reason we use a setter is to listen to when the steps change from inside the wizard
  set step(step: number) {
    if (step === 2 && !this.finished) {
      this.create();
    } else {
      this.currentStepIdx = step;
    }
  }

  ngOnInit() {
    const {apiClientId, apiClientSecret, finished, service} = this;
    if (service && finished && apiClientId && apiClientSecret) {
      // finished prevents the `step` method from calling `create`
      this.finished = true;
      this.step = 2;
      this.service = service;
      this.apiClientId = apiClientId;
      this.apiClientSecret = apiClientSecret;
    }
  }

  // after step 2, we generate the service, then re-open the wizard on the third step
  create(): void {
    const {service} = this;
    const {name: servicePlan} = this.servicePlan[0];
    const serviceInstanceCreateParams: ServiceInstanceCreateParams = {
      name: this.service.name,
      servicePlan,
      serviceType: this.serviceType,
    };

    const promise = () => {
      const progress = this.dialogService.progress(PROGRESS_CREATE_MSG);
      let createdService;
      return this.serviceBrokerService.create(serviceInstanceCreateParams)
        .then((createdServiceInstanceUrl: ServiceInstance) => {
          const bindParams: ServiceBindingParams = {
            name: this.credentials.credentialsName,
            bindingParams: {
              'dpod_service_id': createdServiceInstanceUrl.service_id,
            },
          };
          createdService = {
            service_id : createdServiceInstanceUrl.service_id
          };
          return this.serviceBrokerService.bindServiceInstance<VMEncryptionServiceBindingResponse>(createdServiceInstanceUrl.service_id, bindParams);
        })
        .then((data: IHttpResponse<VMEncryptionServiceBindingResponse>) => {
          const {client_id: clientId, client_secret: clientSecret} = data.data.credentials;
          const modal = this.dialogService.open<ProtectVWizardComponent>(ProtectVWizardComponent, {
            windowClass: 'modal-wide',
          });
          const servicesWizard = modal.componentInstance;
          servicesWizard.apiClientId = clientId;
          servicesWizard.apiClientSecret = clientSecret;
          servicesWizard.finished = true;
          servicesWizard.service = service;
          servicesWizard.credentials = this.credentials;
          servicesWizard.serviceType = this.serviceType;

          // the createdService object is expected by services.component to retrieve service_id
          return createdService;
        })
        .catch(error => this.dialogService.error(error))
        .finally(() => progress.close());
    };
    this.close(promise);
  }

  finish(): void {
    this.close();
  }

}
