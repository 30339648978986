import { Injectable } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NgxDaterangepickerBootstrapComponent, SideEnum } from 'ngx-daterangepicker-bootstrap';
import { dateRangePickerLabels } from './date-range-picker.constants';

export const correctEndDate = '_correctEndDate';
export const forceRenderRightTimePicker = '_forceRenderRightTimePicker';
export const calcMaxEndDate = '_calcMaxEndDate';

@Injectable()
export class DateRangePickerService {

  constructor() {
    // The dayjs library is used by the ngx-daterangepicker-bootstrap library.
    // The utc plugin is added to dayjs - the date range picker works only in UTC.
    dayjs.extend(utc);
  }

  /**
   * The method overrides the NgxDaterangepickerBootstrapComponent class by extending some methods
   * and defining a few new ones.
   * We need to alter the behavior of the ngx-daterangepicker-bootstrap library.
   * https://github.com/iulius-ciorica/ngx-daterangepicker-bootstrap/tree/master/projects/ngx-daterangepicker-bootstrap
   */
  patchDateRangePickerComponent() {
    // If the NgxDaterangepickerBootstrapComponent has already been patched - do nothing
    if (NgxDaterangepickerBootstrapComponent.prototype[correctEndDate]) {
      return;
    }
    this.overrideExistingLibraryMethods();
    this.addNewMethodsToTheLibrary();
  }

  /**
   * This method is called by the ngx-daterangepicker-bootstrap library when the calendars are being re-rendered.
   * It returns tooltip for the disabled dates.
   * @param date The current rendered date
   * @param datepickerInstance The instance of NgxDaterangepickerBootstrapComponent currently displayed to the user
   * @returns The tooltip for the given date
   */
  getDateTooltip(date: Dayjs, datepickerInstance: NgxDaterangepickerBootstrapComponent): string | undefined {
    if (!datepickerInstance) {
      return;
    }
    if (date.isAfter(datepickerInstance['_maxDate'])) {
      return dateRangePickerLabels.invalidEndDate;
    }
    if (date.isBefore(datepickerInstance['_minDate'])) {
      return dateRangePickerLabels.invalidStartDate;
    }
    if (datepickerInstance?.applyBtn?.disabled) {
      const dateLimit = datepickerInstance[calcMaxEndDate]().endOf('day');
      if (dateLimit && date.isAfter(dateLimit)) {
        // The actual limit is equal to dateLimit-1
        // The ngx-daterangepicker-bootstrap library need the limit to be dateLimit+1
        return dateRangePickerLabels.invalidDuration(datepickerInstance.dateLimit - 1);
      }
    }
  }

  /**
   * This method enabled the date range picker's Apply button and re-renders the calendars.
   * @param datepickerInstance The instance of NgxDaterangepickerBootstrapComponent currently displayed to the user
   */
  enableApplyButton(datepickerInstance: NgxDaterangepickerBootstrapComponent) {
    datepickerInstance.applyBtn.disabled = false;
    datepickerInstance.updateMonthsInView();
    datepickerInstance.updateCalendars();
  }

  private overrideExistingLibraryMethods() {
    /**
    * The setEndDate is provided by the library.
    * We need to check if the selected end meets our validation and correct it if needed.
    */
    const originalSetEndDate = NgxDaterangepickerBootstrapComponent.prototype.setEndDate;
    NgxDaterangepickerBootstrapComponent.prototype.setEndDate = function(endDate: any) {
      originalSetEndDate.call(this, endDate);
      this[correctEndDate]();
    };

    /** The getMaxDate is provided by the library.
     *  We need this method to return minimum of maxDate and startDate+dateLimit when:
     *  1. The Apply button is disabled - it means the user is selecting the endDate.
     *  2. The Apply button is enabled and the right-hand time picker is being rendered after the user has changed selected time.
     *  This ensures the date and time pickers display correct max selectable date/time.
     */
    const originalGetMaxDate = NgxDaterangepickerBootstrapComponent.prototype.getMaxDate;
    NgxDaterangepickerBootstrapComponent.prototype.getMaxDate = function() {
      const maxDate = originalGetMaxDate.call(this);
      if (this.applyBtn.disabled || (!this.applyBtn.disabled && this._selectedTimeChanged && this._renderingRightTimePicker)) {
        const maxEndDate = this[calcMaxEndDate]();
        return maxDate.isBefore(maxEndDate) ? maxDate : maxEndDate;
      }
      return maxDate;
    };

    /** The timeChanged is provided by the library.
     *  We need to extend this method so that:
     *  1. Every time the selected time has changed, the right-hand time picker is re-rendered.
     *  2. If the start time has changed and the endDate is selected - recalculate the endDate.
     *  This ensures the end time picker displays correct max selectable date/time.
     */
    const originalTimeChanged = NgxDaterangepickerBootstrapComponent.prototype.timeChanged;
    NgxDaterangepickerBootstrapComponent.prototype.timeChanged = function($event: any, side: SideEnum) {
      originalTimeChanged.call(this, $event, side);
      this[forceRenderRightTimePicker]();
      if (side === SideEnum.left && !this.applyBtn.disabled) {
        this[correctEndDate]();
      }
    };

    /** The renderTimePicker is provided by the library.
     *  We need to extend this method so that it detects that the right-hand side time picker (end time) is being rendered,
     *  and, if this is the case, the _renderingRightTimePicker is set to true, making getMaxDate return proper result.
     */
    const originalRenderTimePicker = NgxDaterangepickerBootstrapComponent.prototype.renderTimePicker;
    NgxDaterangepickerBootstrapComponent.prototype.renderTimePicker = function(side: SideEnum) {
      if (side === SideEnum.right) {
        this._renderingRightTimePicker = true;
      }
      originalRenderTimePicker.call(this, side);
      this._renderingRightTimePicker = false;
    };

    /** The clickDate is provided by the library.
     *  We need to extend this method so that it re-renders the right-hand side time picker (end time) in case the endDate
     *  has been selected in the calendar.
     *  This ensures the end time picker displays correct max selectable time.
     */
    const originalClickDate = NgxDaterangepickerBootstrapComponent.prototype.clickDate;
    NgxDaterangepickerBootstrapComponent.prototype.clickDate = function($event: any, side: SideEnum, row: number, col: number) {
      originalClickDate.call(this, $event, side, row, col);
      if (this.endDate) {
        this[forceRenderRightTimePicker]();
      }
    };
  }

  private addNewMethodsToTheLibrary() {
    /** The _correctEndDate method is added to the library.
     *  It is called in two cases:
     *  1. The user has set the endDate (clicked the date in the calendar).
     *  2. The start time has been changed (in the left-hand side time picker).
     *  This method uses the custom _calcMaxEndDate method to calculate the proper max end date,
     *  and, if the current selected endDate is later than the max end date, force
     *  endDate be equal to the max end date and re-render the calendar and the right-hand time picker.
     *  The re-rendering ensures the end time picker displays correct max selectable time.
     */
    NgxDaterangepickerBootstrapComponent.prototype[correctEndDate] = function() {
      if (!this.startDate) {
        return;
      }
      const maxEndDate = this[calcMaxEndDate]();
      if (this.dateLimit && maxEndDate.isBefore(this.endDate)) {
        this.endDate = maxEndDate.clone();
        this.endDateChanged.emit({ endDate: this.endDate });
        this.updateCalendars();
        this[forceRenderRightTimePicker]();
      }
    };

    /** The _forceRenderRightTimePicker method is added to the library.
     *  It is called whenever the start date/time or end date has changed.
     *  This method calls renderTimePicker for the right-hand time picker, setting
     *  the _selectedTimeChanged to true, so that getMaxDate returns the proper max end date.
     */
    NgxDaterangepickerBootstrapComponent.prototype[forceRenderRightTimePicker] = function() {
      this._selectedTimeChanged = true;
      this.renderTimePicker(SideEnum.right);
      this._selectedTimeChanged = false;
    };

    /** The _calcMaxEndDate method is added to the library.
     *  It returns a proper max end date dictated by the Audit Query API:
     *  startDate plus 32 days minus 1 second.
     *  It ensures the user will never select an incorrect date/time range.
     */
    NgxDaterangepickerBootstrapComponent.prototype[calcMaxEndDate] = function() {
      return this.startDate.clone().add(this.dateLimit, 'day').add(-1, 'second');
    };
  }
}
