// this overrides any routing and data in the mock data
// note: in the current state it shouldn't be used for complex responses
import {
  angularBinding,
  breakJSON,
  closingHTMLTags,
  outsideScriptTag,
  scriptTag,
  strongTag
} from './xss-test-vars.constants';

export default angular.module('mock.fuzzData', []).run(function($httpBackend) {

  // passing in certain words triggers changePassword to return with errors
  // opening up the error dialog with the response
  $httpBackend.whenRoute('PATCH', '/v1/users/changePassword')
    .respond((method, url, data, headers, params) => {

      const parsedData = JSON.parse(data);

      let errorDescription = null;

      switch (parsedData.newPassword) {
      case 'script tag':
        errorDescription = scriptTag;
        break;
      case 'closing html tags':
        errorDescription = closingHTMLTags;
        break;
      case 'outside script tag':
        errorDescription = outsideScriptTag;
        break;
      case 'strong tag':
        errorDescription = strongTag;
        break;
      case 'break JSON':
        errorDescription = breakJSON;
        break;
      case 'angular binding':
        errorDescription = angularBinding;
        break;
      }


      if (errorDescription) {
        return [500, {errorDescription}];
      }
      return [201];


    });
})
  .name;
