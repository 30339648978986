import { SharedModule } from '../../index';
import { CommonModule } from '@angular/common';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgModule } from '@angular/core';
import { CredentialsTableComponent } from './credentials.component.table';
import { CredentialsDetailsComponent } from './dialogs/modal-credentials-details';
import { RegeneratedClientSecretComponent } from './dialogs/regenerated-client-secret.component';
import { NewCredentialsWizardComponent } from '../new-credentials-wizard/new-credentials-wizard.component';
import { ReviewPermissionsStepComponent } from '../new-credentials-wizard/review-permissions-step.component';
import { ServiceCredentialSummaryComponent } from './service-credential-summary/service-credential-summary.component';
import { CredentialsSectionComponent } from './credentials-section.component';
import { UIRouterModule } from '@uirouter/angular';

@NgModule({
  declarations: [
    CredentialsTableComponent,
    CredentialsSectionComponent,
    CredentialsDetailsComponent,
    NewCredentialsWizardComponent,
    RegeneratedClientSecretComponent,
    ReviewPermissionsStepComponent,
    ServiceCredentialSummaryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UIRouterModule,
  ],
  exports: [
    CredentialsTableComponent,
    CredentialsSectionComponent,
    ServiceCredentialSummaryComponent,
    ReviewPermissionsStepComponent
  ]
})
export class CredentialsListModule {
}

// required because gem-services details is still in AngularJS
require('./dialogs/modal-credentials-details');
// eslint-disable-next-line @typescript-eslint/no-var-requires
export default require('./_init').default
  .directive('credentialsSection', downgradeComponent({ component: CredentialsSectionComponent }))
  .name;
