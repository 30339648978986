import { Inject, Injectable } from '@angular/core';
import { AuthScopes, HttpService } from '@app/ajs-upgraded-providers';
import { IHttpResponse, IHttpService, IPromise } from 'angular';
import { DownloadService } from '@app/shared/services/download.service';
import { HSMonDemandCreatedService } from '@app/features/gem-services/cloudHSM/cloudHSM.model';
import { Haro } from './haro.service';
import { ServiceBindingParams, ServiceInstance } from './service-broker.interface';
import { HSMService } from '@app/features/gem-services/cloudHSM/cloudHSM.service';
import { TilesService } from './tiles.service';
import { ServiceCommonClass } from '@app/shared/services/service-common.class';
import { AuthService } from '../../features/auth';

/**
 * Some documentation on the Service Broker
 * https://confluence.gemalto.com/display/DPAAS/Draft+Open+Service+Broker+API+integration+with+DPOD+platform
 */
@Injectable()
export class ServiceBrokerService extends ServiceCommonClass {

  constructor(
    @Inject(Haro) haro: any,
    @Inject(HttpService) protected http: IHttpService,
    @Inject(AuthScopes) private authScopes: any,
    protected tilesService: TilesService,
    private hsmService: HSMService,
    private authService: AuthService,
    private downloadService: DownloadService,
  ) {
    super(haro, http, '/v1/service_instances', tilesService);

    // don't automatically retrieve tiles or services for spadmin/operator
    // do not have permissions
    if (!this.authService.hasScope(this.authScopes.spadmin) && !this.authService.hasScope(this.authScopes.operator)) {
      this.reannotateServices();
    }

  }

  getKey() {
    return 'service_id';
  }

  // todo should this remain as HSMonDemandCreatedService?
  annotate(service): HSMonDemandCreatedService {
    service.clients.forEach(c => {
      c.formattedCreatedAt = ServiceCommonClass.formatDate(c.createdAt);
      // temporary to support non service broker old client credentials table values
      c.clientId = c.id;
      c.createdBy = c.created_by_username;
    });
    return super.annotate(service);
  }

  /**
   * @param {string} instanceId                           instance id of the newly created service instance
   * @param {ServiceBindingParams} serviceBindingParams   associated params to bind the service instance by service type
   * @returns {Promise<IHttpResponse<T>>}
   */
  bindServiceInstance<T>(instanceId: string, serviceBindingParams: ServiceBindingParams): Promise<IHttpResponse<T>> {
    // replace spaces as they aren't supported currently
    serviceBindingParams.name = serviceBindingParams.name.replace(/\s/g, '_');
    return Promise.resolve(this.http.put<T>(`${this.baseUrl}/${instanceId}/bindings`, serviceBindingParams));
  }

  deleteBinding(instanceId: string, clientId: string): Promise<any> {
    return Promise.resolve(this.http.delete(`${this.baseUrl}/${instanceId}/bindings/${clientId}`))
      .then(() => this.resync()); // todo resync individual service
  }

  doDelete(id: string): Promise<any> {
    return super.doDelete((id))
      .then(response => {
        // Until HSMService goes away, changes done in this class have to be done
        // again in the HSM service to keep its clients up-to-date. Remove the deleted
        // service from HSMService
        (this.hsmService as any).store.del(id);
        return response;
      });
  }

  deleteService(id: string): Promise<any> {
    return super.doDelete((id))
      .then(response => {
        return response;
      });
  }

  doSave() {
    return Promise.resolve();
  }

  getServiceCredentialsByServiceId(serviceId: string) {
    const serviceInstance: ServiceInstance = this.get(serviceId);
    return serviceInstance ? serviceInstance.clients : [];
  }

  /**
   * Get the Tenant Administrator's usage reports with one line per service instance
   * @param startDate date in ISO format
   * @param endDate date in ISO format
   */
  getUsageReport(startDate: string, endDate: string): IPromise<boolean> {
    return this.http.get(`${this.baseUrl}/usageReport`, {
      params: {
        startDate,
        endDate,
      }
    })
      .then(response => {
        const file = new Blob([response.data as string], {
          type: 'text/csv',
        });
        this.downloadService.downloadFile(file, `DPoD_ServiceReport_${startDate.substring(0, 7)}.csv`, 'report');
        return true;
      },
      response => {
        throw response.data;
      });
  }

  /**
   * Get the usage reports for billing purposes with one line per service instance
   * Downloads it as a file
   * @param startDate date in ISO format
   * @param endDate date in ISO format
   * @param tenantId  UUID of tenant
   * @param shortCode can specify a specific service type
   */
  getUsageBillingReport(startDate: string, endDate: string, tenantId?: string, shortCode?: string): IPromise<boolean> {
    return this.http.get(`${this.baseUrl}/usageBillingReport`, {
      params: {
        startDate,
        endDate,
        tenantId,
        shortCode,
      },
    })
      .then(response => {
        const file = new Blob([response.data as string], {type: 'text/csv'});
        this.downloadService.downloadFile(file, `DPoD_ServiceUsageReport_${startDate.substring(0, 7)}.csv`, 'report');
        return true;
      },
      response => {
        throw response.data;
      });
  }

}
