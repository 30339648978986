<div class="details-panel">
    <div class="details-panel-section">
        <div class="row">
            <div class="col">
                <span class="gem-header panel-header">Configuration</span>
            </div>
        </div>
        <hr/>
        <div class="row form-group">

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">Service Name</div>
                </div>

                <div class="row">
                    <div class="col fw-bold">
                        {{service.name}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">Azure Subscription</div>
                </div>

                <div class="row">
                    <div class="col fw-bold">
                        {{getAzureSubscriptionDisplayName()}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">Azure Resource Group</div>
                </div>

                <div class="row">
                    <div class="col fw-bold">
                        {{getResourceGroup()}}
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">Azure Key Vault</div>
                </div>

                <div class="row">
                    <div class="col fw-bold">
                        {{getKeyVault()}}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
