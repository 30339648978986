import { Inject, Injectable, OnDestroy, Provider } from '@angular/core';
import { WindowToken } from '@app/shared/services/window';
import * as jwtDecodeLibrary from 'jwt-decode';
import { User, UserManager } from 'oidc-client-ts';
import { LocationService } from '../../ajs-upgraded-providers';
import { unverifiedScopeSuffix } from './auth.constants';
import { IAuthService, JWT, LoginState, LoginAction, TenantAuthority, tenantIdParam, dpodUiTokenKey, dpodTenantIdKey } from './auth.interface';
import { extractTenantIdFromUri, getTenantId, hasSubscriberGroupId, isEmailVerified, removeTenantIdFromUri } from './auth.utils';
import { JwtDecode } from './jwt-decode';
import * as AuthScopes from './roles.constants';
import ValidStates from './valid-states.constants';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { UserManagerToken, oneWelcomeResponseType } from './user-manager';
import { Subject } from 'rxjs';

/**
 * Creates an AuthService.
 *
 * After the constructor returns, the AuthService is in one of two states:
 *
 * - The user is logged in. `getToken()` and `getIdentity()` return nonnull.
 * - The user is not logged in. `getToken()` and `getIdentity()` return null.
 */
@Injectable()
export class AuthService implements IAuthService, OnDestroy {
  // After the user selects their tenant the DPoD-UI has to update components in the header.
  // The AuthService uses this subject to communicate it to other components.
  tenantChangedSubject = new Subject<string>();

  private identity = null;
  private scopes: readonly string[] = [];
  private redirecting = false;
  private user: User | null = null;
  private dpodUiUserKey: string;
  private tenantId: string;

  constructor(
    @Inject(WindowToken) private window: Window,
    @Inject(LocationService) private $location: ng.ILocationService,
    @Inject(JwtDecode) private jwtDecodeLib: JWTDecodeLib,
    @Inject(ConfigToken) private config: DpodUiConfig,
    @Inject(UserManagerToken) private userManager: UserManager
  ) {
    this.init();
  }

  // Helper function called only from the constructor
  init() {
    const authority = this.config.OW_AUTHORITY;
    const clientId = this.config.OW_CLIENT_ID;
    this.dpodUiUserKey = `oidc.user:${authority}:${clientId}`;
    this.tryToRestoreTenantId();
    this.tryToRestoreUser();
  }

  /**
   * Determine whether the user is allowed to view the given UI-router state. In other words,
   * check whether the user has any scope for which the state is valid.
   */
  isStateValid(state): boolean {
    return Object.keys(AuthScopes).some(scopeName => {
      const scope = AuthScopes[scopeName];
      return this.hasScope(scope) && (ValidStates[scopeName] || []).includes(state);
    });
  }

  /**
   * @returns Whether the user has verified their email address
   */
  isEmailVerified(): boolean {
    return isEmailVerified(this.scopes);
  }

  /**
   * generates a cookie for use with public marketplace
   * parses the hostname and gets everything after the last '.' for staging-dpondemand.io (STAGING), dpod.live (DEV), dpondemand.io (PROD)
   */
  generatePublicMarketCookie(origin) {
    const name = 'dpod.url';
    const value = origin;

    // generate domain
    const secondLastPeriod = (value.match(/\./g) || []).length - 1;
    const periodIndex = value.split('.', secondLastPeriod).join('.').length;
    const domain = value.substr(periodIndex + 1);
    return `${name}=${value};domain=${domain};`;
  }

  /**
   * @returns Whether we've started redirecting the user to the login or logout page.
   */
  isRedirecting(): boolean {
    return this.redirecting;
  }

  /**
   * @returns The encoded JWT (also called access_token)
   */
  getToken(): string {
    return this.user?.access_token;
  }

  /**
   * @returns The decoded JWT
   */
  getIdentity(): JWT {
    return this.identity;
  }

  /**
   * @returns The current user id
   */
  getUserId(): string {
    // TODO: Remove this.identity?.user_id when OW and backend support the custom tenants claim
    return this.extractTenant()?.userId || this.identity?.user_id;
  }

  /**
   * @returns Information about all the tenants the user has signed up for.
   */
  getAllUserTenants(): TenantAuthority[] {
    return this.identity?.tenants;
  }

  /**
   * @returns The id of the tenant that the user is logged into.
   */
  getTenantId(): string {
    // TODO: Remove getTenantId(this.scopes) call after OW returns the custom claim
    return this.tenantId || this.extractTenant()?.id || getTenantId(this.scopes);
  }

  /**
   * @returns Whether the user has to go through the Select Tenant page.
   */
  isTenantSelectionRequired() {
    return !!this.identity && !this.extractTenant();
  }

  /**
   * @returns `true` if the user belongs to the subscriber group with id `sguid`, otherwise `false`.
   */
  hasSubscriberGroupId(sguid: string): boolean {
    return hasSubscriberGroupId(this.scopes, sguid);
  }

  /**
   * todo verify that nothing is using this incorrectly as we also include unverified scopes
   * @returns `true` if the user has the given scope or an "unverified" variant thereof.
   */
  hasScope(scope: string): boolean {
    if (this.isTenantSelectionRequired()) {
      return scope === AuthScopes.multiTenant;
    }
    return this.scopes.includes(scope) || this.scopes.includes(scope + unverifiedScopeSuffix);
  }

  /**
   * Checks if one of the scopes exist for this user
   * use this when possible instead of `hasScope`
   * which also checks for `unverified` which may lead to something unintended
   * @param scopes  array of passed in scopes
   */
  hasAnyScope(...scopes: string[]): boolean {
    return !!this.scopes.find(userScope => scopes.includes(userScope));
  }

  async logout() {
    const idToken = this.user.id_token;
    this.redirecting = true;
    await this.reset();
    await this.userManager.signoutRedirect({
      state: (this.window.location.hash || '').slice('#!'.length),
      post_logout_redirect_uri: this.window.location.origin,
      id_token_hint: idToken
    });
  }

  /**
   * Returns the AuthService to the "not logged in" state.
   */
  async reset() {
    this.deleteDpodTokens();
    await this.userManager.clearStaleState();
    await this.userManager.removeUser();
    await this.userManager.revokeTokens();
  }

  /**
   * @param returnTo The $location path to restore after login (default: current path)
   */
  async login(returnTo?: string) {
    if (this.redirecting) {
      return;
    }
    this.redirecting = true;
    // Since the user is redirected to the login - all their tokens have to be deleted
    await this.reset();
    try {
      await this.userManager.signinRedirect({ state: returnTo || this.$location.path() });
    } catch (error) {
      // Re-throwing the error to trigger the Angular global error handler
      this.handleLoginError(error);
    } finally {
      this.redirecting = false;
    }
  }

  // This method is called by login.hook
  redirectToDpodUiState(state = '') {
    if (!this.isCodePresentInUrl()) {
      // If a code is not present in the URL the method has been called by login.hook because of repeating ui-router transitions.
      // We ignore such calls - otherwise, the browser will redirect to the same URL repeatedly which will lead to console errors.
      return;
    }
    this.redirectToState(state);
  }

  // This method is called by login.hook
  async determineLoginState(): Promise<LoginState> {
    if (this.user) {
      return this.determineStateWhenTokenIsPresent();
    }
    if (this.isCodePresentInUrl()) {
      // If there is no assignment to `result` Sonar complains
      const result = await this.determineStateWhenCodeIsPresent();
      return result;
    }
    // The user is not and cannot be logged in
    return {
      loginAction: LoginAction.OwRedirect
    };
  }

  readTenantIdFromUrl() {
    // The tenant id param may come in both query params and browser hash.
    // It will come as a query param from the use-case UIs, and as a hash param after OW redirect.
    // We prefer query param over hash, so that the user can bookmark links to their tenants.
    const queryTenantId = new URLSearchParams(this.window.location.search).get(tenantIdParam);
    const hashTenantId = this.$location.search()?.[tenantIdParam];
    return queryTenantId || hashTenantId;
  }

  // This method is called by the select-tenant component
  selectTenant(tenantId: string, state: string) {
    if (!this.isTenantSelectionRequired()) {
      return;
    }
    this.tenantId = tenantId;
    this.storeDpodTokens();
    this.tenantChanged();
    this.redirectToState(state);
  }

  ngOnDestroy() {
    this.tenantChangedSubject.complete();
  }

  private tenantChanged() {
    this.tenantChangedSubject.next(this.tenantId);
  }

  private redirectToState(state: string) {
    // Dpod-ui uses hash routing backed by ui-router. Because of that dpod-ui's location and ui-router services have
    // direct access only to the hash part of the URL.
    // OW passes the code and state params in the URL's query: https://dpod.ui/?code=value&state=value.
    // The Angular location and ui-router services cannot change the query params that precede the hash.
    // This is why a native browser API is used - it rewrites the previous page (the one with the code) in browser history.
    this.window.location.replace(state ? `/#!${state}` : '/#!/');
  }

  private determineStateWhenTokenIsPresent(): LoginState {
    // The user object is present in the auth service; it means the user has been logged in
    if (this.isTenantSelectionRequired()) {
      // The user is logged in and needs to go through tenant selection
      return {
        loginAction: LoginAction.MultiChoice
      };
    }
    // The user has only 1 tenant or already selected a tenant
    return {
      loginAction: LoginAction.LoggedIn
    };
  }

  private async determineStateWhenCodeIsPresent(): Promise<LoginState> {
    // The URL has a code that can be exchanged for the user token
    const urlState = await this.fetchTokenAndState();
    if (!this.user) {
      return {
        loginAction: LoginAction.OwRedirect
      };
    }
    if (this.isTenantSelectionRequired()) {
      // If tenant selection is required - try to extract tenantId from the urlState
      this.tenantId = extractTenantIdFromUri(urlState);
      this.processDpodTokens();
    }
    // Always return LoggedIn even if tenant selection is required.
    // This constraint is coming from the legacy UI Router.
    return {
      loginAction: LoginAction.LoggedIn,
      dpodUiState: removeTenantIdFromUri(urlState) // Redirect the user to the restored dpod-ui state without the tenantId param
    };
  }

  private tryToRestoreTenantId() {
    this.tenantId = this.readTenantIdFromLocalStorage() || this.readTenantIdFromUrl();
  }

  private readTenantIdFromLocalStorage() {
    return this.window.localStorage.getItem(dpodTenantIdKey);
  }

  private tryToRestoreUser() {
    const dpodUiUserString = this.window.localStorage.getItem(this.dpodUiUserKey);
    if (dpodUiUserString) {
      try {
        const dpodUiUser = JSON.parse(dpodUiUserString);
        this.user = new User(dpodUiUser);
        this.processDpodTokens();
      } catch (error) {
        // The token is invalid; we can just ignore it - the user is not authenticated
        console.error('Invalid token', error);
      }
    }
  }

  private refreshTenantId(tenant: TenantAuthority) {
    // The tenantId read from local storage/URL may not be present in the token.
    // If that is the case, it has to be emptied.
    if (!this.tenantId) {
      return;
    }
    if (!tenant || tenant.id !== this.tenantId) {
      this.tenantId = '';
    }
  }

  private isCodePresentInUrl() {
    const args = new URLSearchParams(this.window.location.search);
    return !!args.get(oneWelcomeResponseType);
  }

  private async fetchTokenAndState(): Promise<string | undefined> {
    const user = await this.userManager.signinCallback();
    if (!user) {
      return undefined;
    }
    this.user = user;
    this.processDpodTokens();
    return this.user.state as string || '';
  }

  private processDpodTokens() {
    this.identity = this.jwtDecodeLib.jwtDecode(this.user.access_token);
    // Take email from user profile - the email is absent in the access token
    this.identity.email = this.user.profile.email;
    // Uncomment the line below to play with multiple tenants in a OW namespace
    // this.addFakeTenantAuthorities();
    const tenant = this.extractTenant();
    this.refreshTenantId(tenant);
    if (!tenant) {
      return;
    }
    this.storeDpodTokens();
  }

  private storeDpodTokens() {
    // This method is private; so we can safely assume tenantId will always be present in the tenants claim
    this.scopes = this.extractTenant().authorities;
    this.window.localStorage.setItem(dpodUiTokenKey, this.user.access_token);
    if (this.tenantId && this.identity.tenants?.length > 1) {
      this.window.localStorage.setItem(dpodTenantIdKey, this.tenantId);
    }
    document.cookie = this.generatePublicMarketCookie(this.window.location.origin);
  }

  private extractTenant(): TenantAuthority | null {
    // TODO: Remove the !tenants?.length check when OW sends the custom claim
    if (!this.identity.tenants?.length) {
      return this.identity;
    }
    if (this.identity.tenants.length === 1) {
      // If there is only one tenant - we use it
      return this.identity.tenants[0];
    }
    return this.tenantId ? this.identity.tenants.find(tenant => tenant.id === this.tenantId) : null;
  }

  private deleteDpodTokens() {
    this.window.localStorage.removeItem(this.dpodUiUserKey);
    this.window.localStorage.removeItem(dpodUiTokenKey);
    this.window.localStorage.removeItem(dpodTenantIdKey);
    this.user = null;
    this.identity = null;
    this.scopes = [];
  }

  // The following code is encapsulated into a method only because of unit tests
  private handleLoginError(error: Error) {
    // Re-throwing the error to trigger the Angular global error handler
    throw error;
  }

  // TODO: Remove this method
  private addFakeTenantAuthorities() {
    this.identity.tenants = [
      {
        authorities: [
          'dpod.tenant.admin',
          'dpod.tenant.appowner',
          'dpod.tuid.de545868-9639-4570-afd7-723afbe356b4',
          'dpod.tuid.de545868-9639-4570-afd7-723afbe356b4.sguid.c15e5cfa-00b3-40c8-a19d-14d8a7a306e7'
        ],
        id: 'de545868-9639-4570-afd7-723afbe356b4',
        name: 'Tenant One',
        region: 'NA',
        userId: 'ff545868-9639-4570-afd7-134afbe356b5'
      },
      {
        authorities: [
          'dpod.operator',
          'dpod.tuid.652b2ce1-4016-493b-b685-c3f480c5d615.sguid.50183e1f-0c8a-4503-a854-14d8a7a306e7',
          'dpod.tuid.652b2ce1-4016-493b-b685-c3f480c5d615'
        ],
        id: '652b2ce1-4016-493b-b685-c3f480c5d615',
        name: 'Tenant Two',
        region: 'EU',
        userId: 'ab2b2ce1-4016-493b-b685-3df480c5d69a'
      }
    ] as TenantAuthority[];
  }
}

export const AuthServiceProvider: Provider = AuthService;

// Can't seem to directly reference this as a type from jwt-decode library
interface JWTDecodeLib {
  jwtDecode: (token: string, options?: jwtDecodeLibrary.JwtDecodeOptions) => JWT;
}
