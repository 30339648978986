<div class="wizard-step-container">
    <ul class="nav nav-justified">
        <ng-template ngFor let-step let-index="index" let-end="last" [ngForOf]="steps">
            <li class="nav-item" [ngClass]="getStepClasses(index)">
                <div class="wrapper">
                    <span class="badge d-flex align-items-center justify-content-center">
                        <svg class="icon-svg">
                            <use xlink:href="#check"/>
                        </svg>
                        <span class="step-index">{{index + 1}}</span>
                    </span>
                    <span class="step-name">{{step}}</span>
                </div>
            </li>
            <li *ngIf="!end" class="separator" [ngClass]="getStepClasses(index)">
                <hr/>
            </li>
        </ng-template>
    </ul>
</div>
