import { Component, Inject, Input, OnInit } from '@angular/core';
import { TileServicePlan, Tile } from '@app/features/marketplace/tiles.interface';
import { ServiceBrokerService } from '@app/shared/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VHsmApiService } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { WizardComponent } from '@app/components/wizard-ng2';
import { HSMService } from '../cloudHSM.service';
import { ServiceCreationWizard } from './service.wizard-interface';

@Component({
  selector: 'services-wizard',
  templateUrl: './cloudHSM-wizard.component.html',
})
export class ServicesWizardComponent extends WizardComponent implements OnInit, ServiceCreationWizard {
  @Input() tile: Tile;
  @Input() servicePlan: TileServicePlan[];
  @Input() serviceType: string;
  @Input() loading = false;

  serviceTypeName: string;
  serviceName: string;
  deviceType: string;
  modalTitle: string;

  constructor(
    private dialogService: DialogService,
    activeModal: NgbActiveModal,
    @Inject(VHsmApiService) private api: HSMService,
    private sbs: ServiceBrokerService,
  ) {
    super(activeModal);
  }

  ngOnInit() {
    const value = (this.tile && this.tile.name) || this.serviceType;
    this.modalTitle = `Add ${value} Service`;
    this.serviceTypeName = value;
  }

  /**
   * If the deviceType key exists in the properties, return true
   */
  deviceTypeExistsInServicePlanProperties(): boolean {
    // this only checks for one service plan, if we ever implement more than one we'll have to reevaluate how we handle this
    const {properties} = this.servicePlan[0].schemas.service_instance.create.parameters;
    return Object.keys(properties).includes('deviceType');
  }

  finish() {
    const creatorFn = () => {
      let createParams: object = {};
      const servicePlan = this.servicePlan[0].name;
      if (servicePlan && /^fake-plan-/.test(servicePlan)) {
        createParams = {
          'billing-account': '1'
        };
      } else if (this.deviceTypeExistsInServicePlanProperties()) {
        // if the service plan properties includes `deviceType`, the createParams object will
        // also send the deviceType to the service broker
        createParams = {
          deviceType: this.deviceType,
        };
      }
      // One of {tileId, servicePlan} or {serviceType} is required
      return this.sbs.create({
        name: this.serviceName,
        tileId: this.tile.id,
        servicePlan,
        serviceType: this.serviceType,
        createParams,
      });
    };
    super.close(creatorFn);
  }

}
