import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MAX_DURATION, auditLogsLabels, ALL_SERVICES } from '../logs.constants';
import { AuditLogFilters, AuditLogService } from '../logs.model';
import { ServiceBrokerService } from '@app/shared/services/service-broker.service';
import { DialogService } from '@app/components';
import { AuditLogsFilterService } from './audit-logs-filter.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ServiceInstance } from '@app/shared/services/service-broker.interface';
import { dateRangeValidator, parseDateRangeText } from '@app/components/date-range-picker/date-range-picker.validators';
import { dateRangePickerLabels } from '@app/components/date-range-picker/date-range-picker.constants';

@Component({
  selector: 'audit-logs-filter',
  templateUrl: './audit-logs-filter.component.html',
  styleUrls: ['./audit-logs-filter.component.scss']
})
export class AuditLogsFilterComponent implements OnInit {
  @Output() displayLog = new EventEmitter<AuditLogFilters>();
  @Output() downloadLog = new EventEmitter<AuditLogFilters>();

  services: AuditLogService[] = [ALL_SERVICES];
  minDate: string;
  maxDate: string;
  dateLimit = MAX_DURATION;
  auditLogsFilterForm: FormGroup;
  dateRangePickerLabels = dateRangePickerLabels;
  // invalidDuration is dynamic - we process it in advance to avoid a function call from the template
  invalidDurationMessage = dateRangePickerLabels.invalidDuration(this.dateLimit);

  constructor(private serviceBrokerService: ServiceBrokerService, private dialogService: DialogService,
    private auditLogsFilterService: AuditLogsFilterService) {
    this.initDateRangeLimits();
  }

  ngOnInit() {
    this.setupForm();
    const progressModal = this.dialogService.progress(auditLogsLabels.loading);
    this.serviceBrokerService.resync()
      .then(services => this.appendServices(services))
      .catch(err => this.dialogService.error(err)) // See DPS-11535
      .finally(() => progressModal.close());
  }

  async onDisplayLogClick() {
    if (!await this.isFormValid()) {
      return;
    }
    this.displayLog.emit(this.readFormValue());
  }

  async onDownloadLogClick() {
    if (!await this.isFormValid()) {
      return;
    }
    this.downloadLog.emit(this.readFormValue());
  }

  submitForm(event: SubmitEvent) {
    event?.preventDefault();
  }

  private setupForm() {
    this.auditLogsFilterForm = new FormGroup({
      logsService: new FormControl(ALL_SERVICES),
      logsDateRange: new FormControl(this.auditLogsFilterService.generateDefaultValue(this.maxDate), {
        validators: [
          dateRangeValidator({
            minDate: this.minDate,
            maxDate: this.maxDate,
            dateLimit: this.dateLimit
          })
        ]
      })
    });
  }

  private appendServices(services: ServiceInstance[]) {
    if (!services?.length) {
      return;
    }
    this.services = this.services.concat(this.auditLogsFilterService.processServices(services));
  }

  private initDateRangeLimits() {
    this.minDate = this.auditLogsFilterService.getMinDate();
    this.maxDate = this.auditLogsFilterService.getMaxDate();
  }

  // eslint-disable-next-line require-await
  private async isFormValid() {
    this.auditLogsFilterForm.controls.logsDateRange.markAsDirty();
    // The markAsDirty method is async - this is why the validation is run in setTimeout
    return new Promise(resolve => {
      setTimeout(() => {
        this.auditLogsFilterForm.controls.logsDateRange.updateValueAndValidity();
        resolve(this.auditLogsFilterForm.valid);
      });
    });
  }

  private readFormValue(): AuditLogFilters {
    const dateRange = parseDateRangeText(this.auditLogsFilterForm.value.logsDateRange);
    return {
      resourceId: this.auditLogsFilterForm.value.logsService.id,
      resourceName: this.auditLogsFilterForm.value.logsService.name,
      from: dateRange.from.toISOString(),
      to: dateRange.to.toISOString()
    };
  }
}
