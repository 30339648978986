import { InjectionToken, Provider } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const OAuth2ClientJSImpl = require('@zalando/oauth2-client-js');

export const OAuth2ClientJS = new InjectionToken('OAuth2ClientJS');

export function OAuth2ClientJSFactory() {
  return OAuth2ClientJSImpl;
}

// Expose the oauth2-client-js library to Angular DI
export const OAuth2ClientJSProvider: Provider = {
  provide: OAuth2ClientJS,
  useFactory: OAuth2ClientJSFactory
};
