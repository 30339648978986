import { Component, Input } from '@angular/core';

@Component({
  selector: 'custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  @Input() text: string;
  @Input() left: number;
  @Input() top: number;
  @Input() bottom: number;

  SHIFT_X = 22;
  SHIFT_Y = 10;
  TOOLTIP_HEIGHT = 35;
}
