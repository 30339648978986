import { Component, Inject, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { AuthService } from '../auth';
import { FEATURE_TOGGLES } from '../feature-toggles';
import { TenantCreateBody } from './tenant.model';
import { TenantsService } from './tenants.service';
import { TenantWizardComponent } from './wizard/tenant.wizard.component';

interface Cards {
  title: string;
  svg: string;
  content: string;
  action: () => Record<string, any>;
  locator?: string;
}

export enum AccountTypeLabel {
  ServiceProvider = 'Service Provider',
  Evaluation = 'Evaluation',
}

@Component({
  templateUrl: './tenants.html',
  styleUrls: ['./tenants.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TenantsComponent {
  @ViewChild('contactSupport', { static: true }) contactSupport: TemplateRef<any>;

  cards: Cards[];

  constructor(private dialogService: DialogService,
              private tenantsApiService: TenantsService,
              private authService: AuthService,
              @Inject(AuthScopes) private authScopes: any,
              @Inject(FEATURE_TOGGLES) private featureToggles: any,) {


    this.cards = [
      {
        title: 'Service Provider',
        svg: '#card-virtualServiceProvider',
        content: 'A tenant account for customers that want to manage other tenants.',
        action: this.openTenantWizard.bind(this, 'service provider', AccountTypeLabel.ServiceProvider),
        locator: 'add-service-provider',
      },
      {
        title: 'Tenant',
        svg: '#card-evaluation',
        content: 'A tenant account allowing customers to try and purchase Data Protection on Demand services.',
        action: this.openTenantWizard.bind(this, 'subscriber', ''),
        locator: 'add-evaluation',
      },
    ];
  }

  openTenantWizard(accountType: 'service provider' | 'subscriber', accountTypeLabel: AccountTypeLabel): void {
    const modal = this.dialogService.open<TenantWizardComponent>(TenantWizardComponent, {
      windowClass: 'modal-wide'
    });
    const wizard = modal.componentInstance;
    wizard.accountType = accountType;
    wizard.accountTypeLabel = accountTypeLabel;

    const sub = wizard.submitResponse.subscribe((tenantInfo: TenantCreateBody) => {
      const progress = this.dialogService.progress('Creating tenant...');
      this.tenantsApiService.create(tenantInfo)
        .catch(error => {
          if (error?.status === 403 || error?.status === 404) {
            error.title = 'Awaiting Approval';
            error.contact = '';
            error.icon = '#bang-triangle';
            error.projectedContent = this.contactSupport;
          }
          this.dialogService.error(error, { text: error.message });
        })
        .finally(() => progress.close());

      sub.unsubscribe();
    });
  }

  hasTenants(): boolean {
    return this.tenantsApiService.getAll().length > 0;
  }

  isSpAdmin(): boolean {
    return this.authService.hasScope(this.authScopes.spadmin);
  }
}
