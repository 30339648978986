<div class="page panel-width">
    <section>
        <header class="gem-header section-header">Administration</header>
    </section>

    <section class="details-panel main-panel">
        <gem-app-logo></gem-app-logo>
    </section>
    <section>
        <gem-tenant-registration></gem-tenant-registration>
    </section>
</div>
