import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CredentialType } from '../../../../features/credentials/credentials.interface';

@Component({
  selector: 'gem-regenerated-client-secret',
  templateUrl: './regenerated-client-secret.component.html',
  styleUrls: ['./regenerated-client-secret.component.scss']
})
export class RegeneratedClientSecretComponent {

  @Input() serviceName: string;
  @Input() credentialType: CredentialType;
  @Input() credentialName: string;
  @Input() secret: string;

  copied = false;

  constructor(public activeModal: NgbActiveModal) { }

}
