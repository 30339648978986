import { Injectable, Inject } from '@angular/core';
import { HttpService } from '@app/ajs-upgraded-providers';
import { IHttpService } from 'angular';
import { concatMap, first, from, interval, throwError } from 'rxjs';
import { AuditLogFilters, AuditLogJob, AuditLogJobState } from './logs.model';
import { AUDIT_LOG_ASSETS_URL, AUDIT_QUERY_URL, LOG_FILE_RESPONSE_TYPE, POLLING_INTERVAL, auditLogsLabels } from './logs.constants';

@Injectable()
export class LogsService {

  constructor(@Inject(HttpService) private http: IHttpService) {}

  getAuditLogs(filter: AuditLogFilters) {
    const { resourceName, ...auditLogFilter } = filter; // We don't need to send resourceName to the API
    return from(this.http.post<AuditLogJob>(AUDIT_QUERY_URL, auditLogFilter))
      .pipe(
        concatMap(response => {
          if (!response?.data?.jobId) {
            // A very rare case when Google Cloud does not start an audit log job (e.g., a network issue).
            // The response will not have any message - we show the default one.
            return throwError(() => new Error(auditLogsLabels.defaultErrorMessage));
          }
          return this.poll(response.data.jobId);
        })
      );
  }

  generateAuditLogFileName(filters: AuditLogFilters) {
    return `AuditLogs_${filters.resourceName}_${this.formatISODate(filters.from)}_${this.formatISODate(filters.to)}.gzip`;
  }

  private poll(jobId: string) {
    return interval(POLLING_INTERVAL)
      .pipe(
        concatMap(() => from(this.http.get<AuditLogJob>(`${AUDIT_QUERY_URL}/${jobId}`)))
      )
      .pipe(
        first(response => response?.data?.state !== AuditLogJobState.ACTIVE),
        concatMap(response => {
          if (response?.data?.state === AuditLogJobState.SUCCEEDED) {
            return from(this.getLogFile(response.data.location));
          }
          // If the status is FAILED - the response will have the 400 error code and will not be processed here.
          // It will trigger the global error handler directly.
          // If the status is not ACTIVE, SUCCEEDED or FAILED, the status code will be 200. Those cases are extremely rare.
          // Usually they happen when Google Cloud stops execution of the audit log job (e.g., a network issue).
          // In this case, the response will not have any message - we show the default one.
          return throwError(() => new Error(auditLogsLabels.defaultErrorMessage));
        })
      );
  }

  private async getLogFile(logFileUrl: string) {
    // The audit-log-assets endpoint is provided by nginx (Angular mocks in the local env).
    // The endpoint acts as a reverse proxy.
    try {
      const response = await this.http.get<Blob>(`${AUDIT_LOG_ASSETS_URL}${this.extractLogFileUri(logFileUrl)}`, {
        responseType: LOG_FILE_RESPONSE_TYPE
      });
      return response.data;
    } catch {
      // If audit-log-assets errors out - most likely it is an issue with GCS.
      // It is highly unlikely to happen, but by default the error message will be empty.
      // This is why an error with a custom message is thrown.
      throw Error(auditLogsLabels.defaultErrorMessage);
    }
  }

  private formatISODate(dateString: string) {
    // Remove milliseconds and replace colons with dashes since colons cannot be in Windows file names
    return dateString.substring(0, dateString.indexOf('.')).replaceAll(':', '-');
  }

  // The logFileUrl argument always looks like:
  // https://storage.googleapis.com/gcp-bucket-name-audit-query-exports/file-name.gzip?search=search
  // The audit-log-assets reverse proxy forwards all requests to:
  // https://storage.googleapis.com/gcp-bucket-name-audit-query-exports
  // This function parses the logFileUrl and extracts its /file-name.gzip?search=search part.
  private extractLogFileUri(logFileUrl: string) {
    const url = new URL(logFileUrl);
    const secondSlashIndex = url.pathname.indexOf('/', 1);
    return `${url.pathname.substring(secondSlashIndex)}${url.search}`;
  }
}
