import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ApiClientUI, CredentialType } from '../../../../features/credentials/credentials.interface';

@Component({
  selector: 'credentials-details',
  templateUrl: './modal-credentials-details.html',
})
export class CredentialsDetailsComponent {

  @Input() credentials: ApiClientUI;
  @Input() serviceName: string;
  @Input() type: CredentialType = null;

  /* Emits the updated credentials when user clicks save button. */
  @Output() closeModal = new EventEmitter<ApiClientUI>();

  copied = false;

  done() {
    this.closeModal.emit(this.credentials);
  }

}
