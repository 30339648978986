import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IHttpResponse } from 'angular';
import { WizardStepBaseComponent } from '@app/components';
import { Office365Subscription, Office365SubscriptionsResponse } from '../office365.common';
import { Office365Service } from '../office365.service';

@Component({
  selector: 'configure-step',
  templateUrl: './configure.step.component.html',
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => ConfigureStepOffice365Component)}
  ]
})
export class ConfigureStepOffice365Component extends WizardStepBaseComponent implements OnChanges {

  @Input() service = {
    name: '',
  };

  @Input() accessToken;
  @Input() azureSubscription: Office365Subscription;
  @Output() azureSubscriptionChange = new EventEmitter();
  azureSubscriptionList: Office365Subscription[] = [];

  constructor(private office365Service: Office365Service) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accessToken && changes.accessToken.currentValue) {
      const accessToken: string = changes.accessToken.currentValue;
      this.office365Service.listSubscriptions(accessToken)
        .then((subscriptionsListResponse: IHttpResponse<Office365SubscriptionsResponse>) => this.azureSubscriptionList = subscriptionsListResponse.data.value);
    }
  }

}
