<div class="page panel-width">
    <div class="row">
        <div class="col-6">
            <!-- display breadcrumb -->
            <app-breadcrumb [prevLabel]="'Tenants'" [currLabel]="pristineTenant.name"></app-breadcrumb>
        </div>
        <div class="col-6">
            <div class="float-sm-end">
                <button data-locator="delete-tenant" class="btn btn-next" name="next" type="button" (click)="confirmDelete()">
                    Delete Tenant
                </button>
            </div>
        </div>
    </div>

    <ul ngbNav #nav="ngbNav" class="nav-pills">
        <li ngbNavItem="info">
            <button ngbNavLink data-locator="tab-info">Info</button>
            <ng-template ngbNavContent>
                <tenant-info [tenant]="mutableTenant" [saved]="saved" [tenantStatus]="tenantStatus"
                             [serviceAgreement]="serviceAgreement"
                             (save)="save($event)" (cancel)="cancel()"></tenant-info>
            </ng-template>
        </li>

        <li ngbNavItem="subscriptions" *ngIf="isTenantSubscriptionsAvailable(mutableTenant)">
            <button ngbNavLink data-locator="tab-info">Subscriptions</button>
            <ng-template ngbNavContent>
                <tenant-subscriptions [tenantId]="tenantId" [sectionHeader]="'View Service Subscriptions'"></tenant-subscriptions>
            </ng-template>
        </li>

        <li ngbNavItem="configureServices">
            <button ngbNavLink data-locator="tab-configure-services">Configure Services</button>
            <ng-template ngbNavContent>
                <tenant-marketplace [tenantId]="tenantId"></tenant-marketplace>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
