import { NgModule } from '@angular/core';
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SharedModule } from '../../shared';
import { CredentialsComponent } from './credentials.component';
import { CredentialsListModule } from '../../shared/components/credentials-table';
import { CredentialsService } from './credentials.service';
import { HttpClientModule } from '@angular/common/http';

import * as AuthScopes from '../auth/roles.constants';

const credentialsStates: NgHybridStateDeclaration[] = [
  {
    component: CredentialsComponent,
    name: 'credentials',
    url: '/credentials',
    params: {
      requiresRole: `${AuthScopes.owner},${AuthScopes.spadmin}`,
    },
  },
];

@NgModule({
  declarations: [CredentialsComponent],
  imports: [
    CredentialsListModule,
    HttpClientModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: credentialsStates,
    }),
  ],
  providers: [CredentialsService]
})
export class CredentialsModule {
}
