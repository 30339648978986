import { StepsComponent } from '@app/components/steps/steps.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export * from './steps.component';

@NgModule({
  declarations: [StepsComponent],
  imports: [CommonModule],
  exports: [StepsComponent],
})
export class StepsModule {
}

// AngularJS compatibility

import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

export default angular.module('steps', [])
  .directive('steps', downgradeComponent({component: StepsComponent}))
  .name;

