<form #stepForm="ngForm">
    <fieldset class="card-box-margins">
        <legend class="d-none">Service name</legend>
        <div class="row">
            <div class="col form-group">
                <div class="details-panel">
                    <div class="details-panel-section">
                        <div class="col-sm-12">
                            <label for="service-name" class="control-label">Service Name</label>
                            <input id="service-name" #name="ngModel" name="serviceName" class="form-control"
                                   minlength="4"
                                   maxlen="45"
                                   [(ngModel)]="service.name" validationDebounce required validationIcon
                                   trim="blur"
                                   autofocus>
                            <div *ngIf="name.dirty" id="error-message-serviceName" class="text-danger error-message"
                                 role="alert">
                                <div *ngIf="name.errors?.required">Please enter a service name</div>
                                <div *ngIf="name.errors?.maxlen">Maximum 45 characters</div>
                                <div *ngIf="name.errors?.minlength">Minimum 4 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col form-group">
                <div class="details-panel">
                    <div class="details-panel-section">
                        <p>
                            Would you like to generate and push a tenant secret to Salesforce?
                        </p>

                        <div class="row">
                            <div class="col-sm-5">
                                <div>
                                    <label>
                                        <input type="radio" name="generate" [(ngModel)]="showGenerateTenantSecret"
                                               (ngModelChange)="generateSecrets($event)"
                                               [value]="false"/>
                                        I'll do it later
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" name="generate" [(ngModel)]="showGenerateTenantSecret"
                                               (ngModelChange)="generateSecrets($event)"
                                               [value]="true"/>
                                        Generate Salesforce tenant secret now
                                    </label>
                                </div>
                            </div>

                            <div class="col-border-left col-sm-7" *ngIf="showGenerateTenantSecret">
                                <generate-multiple-secret-types
                                    (selectedSecretsChange)="secretsToGenerateChanged($event)"
                                    [selectedSecrets]="generateMultipleSecretsList">
                                </generate-multiple-secret-types>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>
