import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProtectVKeyFormatted } from '../../protectV.constants';

@Component({
  selector: 'protectv-key-destroy-modal',
  styleUrls: ['./modal-protectV.scss'],
  templateUrl: './modal-protectV-key-destroy.component.html',
})
export class ProtectVKeysDestroyModalComponent {

  @Input() key: ProtectVKeyFormatted;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() destroy: EventEmitter<{ key: ProtectVKeyFormatted}> = new EventEmitter();
  destroyInput = '';

  isDisabled(): boolean {
    return this.destroyInput.toLowerCase() !== 'destroy';
  }

}
