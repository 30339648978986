import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { TileServicePlan } from '@app/features/marketplace/tiles.interface';

/**
 * HSM on Demand Configure Step
 */
@Component({
  selector: 'configure-step',
  templateUrl: './configure.step.component.html',
  styleUrls: ['../cloudHSM.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => ConfigureStepComponent)}
  ]
})
export class ConfigureStepComponent extends WizardStepBaseComponent implements OnInit {
  @Input() servicePlan: TileServicePlan[];
  @Output() serviceName = new EventEmitter<string>();
  @Output() deviceType = new EventEmitter<string>();

  serviceNameInternal = '';
  allowNonFips = false;

  ngOnInit() {
    this.check();
  }

  check() {
    this.deviceType.emit(this.allowNonFips ? 'cryptovisor' : 'cryptovisor_fips');
  }

  nameChange() {
    this.serviceName.emit(this.serviceNameInternal);
  }

  /**
   * if the properties have a deviceType key, we show the FIPS section
   * example: P2PE does not have a deviceType property, therefore it is hidden
   */
  showRemoveFipsSection(): boolean {
    // this only checks for one service plan, if we ever implement more than one we'll have to reevaluate how we handle this
    if (!this.servicePlan) {
      return false;
    }
    const {properties} = this.servicePlan[0].schemas.service_instance.create.parameters;
    return Object.keys(properties).includes('deviceType');
  }

}
