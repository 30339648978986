import {AgreementApprovalStatus, EvaluationStatus, TenantAccountStatus} from '@app/features/tenant/tenant.model';

/**
 * Helper function file for tenants related requests
 */

/**
 * Generates an account status
 * @param tenantId
 * @param evaluationStatus
 * @param agreementApprovalStatus
 */
export const generateAccountStatus = (
  tenantId: string,
  evaluationStatus: EvaluationStatus = EvaluationStatus.evaluating,
  agreementApprovalStatus: AgreementApprovalStatus = AgreementApprovalStatus.notRequested,
): TenantAccountStatus => {
  let agreementStartDate, agreementEndDate, agreementRequestDate; // default to undefined
  const d = new Date();

  // if it's approved (subscriber), we set the additional dates
  if (agreementApprovalStatus === AgreementApprovalStatus.approved) {
    agreementRequestDate = d.toISOString();
    agreementStartDate = d.toISOString();
    d.setFullYear(d.getFullYear() + 2);
    agreementEndDate = d.toISOString();
  } else if (agreementApprovalStatus === AgreementApprovalStatus.requested) {
    agreementRequestDate = d.toISOString();
  }

  return {
    tenantId,
    evaluationStartDate: '2019-02-18T19:52:54.219Z',
    evaluationEndDate: '2037-02-19T19:52:54.219Z',
    evaluationStatus,
    agreementApprovalStatus,
    agreementRequestDate, // change this to a previous date to see the "requested state"
    agreementStartDate, // change this to a previous date to see the "subscriber tenant" state (nothing)
    agreementEndDate,
  };
};

// we want to ensure we get a number of different statuses for testing
// be aware of changing the order of these or adding additional tenants to the mock data as being an Service Provider overrides these
// evaluating -> not requested (eval tenant)
// inAgreement-> approved (subscriber tenant)
// evaluating -> requested (eval tenant)
// expired    -> requested (eval tenant)
export const requiredAccountStatusesForMockData = {
  evaluatingNotRequested: [EvaluationStatus.evaluating, AgreementApprovalStatus.notRequested],
  subscriber: [EvaluationStatus.inAgreement, AgreementApprovalStatus.approved],
  subscriberAgreementEnded: [EvaluationStatus.agreementEnded, AgreementApprovalStatus.approved],
  evaluatingRequested: [EvaluationStatus.evaluating, AgreementApprovalStatus.requested],
  evaluatingExpiredRequested: [EvaluationStatus.expired, AgreementApprovalStatus.requested],
};

/**
 * Iterates through the account status array required for mocking and returns a generated account status
 * @param tenantId
 * @param tenantPos   by setting the tenantPos we can choose what kind of status we want to set for this tenant
 * @param tenantStatus  by passing a tenantStatus, it will by default use that status over the tenantPos
 */
export const getAndCreateAccountStatusOnTenantPosition = (tenantId: string, tenantPos = 0, tenantStatus?: string): TenantAccountStatus => {
  let status;

  const requiredAccountStatusesForMockDataArr = Object.entries(requiredAccountStatusesForMockData);

  if (tenantStatus) {
    status = [
      tenantStatus,
      requiredAccountStatusesForMockData[tenantStatus],
    ];
  } else if (tenantPos !== -1) {
    status = requiredAccountStatusesForMockDataArr[tenantPos % requiredAccountStatusesForMockDataArr.length];
  } else {
    status = requiredAccountStatusesForMockDataArr[0];
  }

  return generateAccountStatus(tenantId, status[1][0], status[1][1]);
};
