import { Component, Inject, Input, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProtectVService as ProtectVServiceToken } from '@app/ajs-upgraded-providers';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { DialogService } from '@app/components/gem-dialogs';
import { RequiresRoleService } from '@app/shared/services';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { ProtectVKeyFormatted, ProtectVKeyState } from '../protectV.constants';
import { ProtectVService } from '../protectV.service';
import { ProtectVKeysDestroyModalComponent } from './modal/modal-protectV-key-destroy.component';
import { ProtectVKeysDetailsModalComponent } from './modal/modal-protectV-key-details.component';
import { ProtectVKeysRestoreModalComponent } from './modal/modal-protectV-key-restore.component';
import { ProtectVKeysRevokeModalComponent } from './modal/modal-protectV-key-revoke.component';
import { HSMonDemandCreatedService } from '@app/features/gem-services/cloudHSM/cloudHSM.model';
import { AuthService } from '@app/features/auth';

const columnNames = [
  'state',
  'name',
  'id',
  'createdAt',
  'actions',
];

@Component({
  selector: 'protectv-keys-list',
  styleUrls: ['./protectV-details.table.keys.scss'],
  templateUrl: './protectV-details.table.keys.html',
})
export class ProtectVDetailsTableKeysComponent implements OnInit, OnDestroy {
  @Input() stateFilter: ProtectVKeyState;
  @Input() service: HSMonDemandCreatedService;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<ProtectVKeyFormatted>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  dataSource = new MatTableDataSource<ProtectVKeyFormatted>();
  readonly displayedColumns = columnNames.slice();
  private sub: Subscription;

  constructor(
    private clipboard: ClipboardService,
    private dialogService: DialogService,
    private requiresRoleService: RequiresRoleService,
    private authService: AuthService,
    @Inject(ProtectVServiceToken) private protectVService: ProtectVService,
  ) {
    this.sub = this.protectVService.subscribe((/* protectv keys */) => {
      this.dataSource.data = this.protectVService.getAll();
    });
  }

  ngOnInit() {
    if (!this.isSameSubscriberGroup()) {
      this.removeColumn('actions');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // this doesn't return the state but returns the unexportable state
  // if the `unexportable` is `true`, it is considered 'revoked' on DPOD
  // if the `unexportable` is `false`, it is considered 'active' on DPOD
  // there are some others but the service should remove them beforehand
  getUIDisplayState(key: ProtectVKeyFormatted) {
    if (key.state === 'Active') {
      return key.unexportable ? 'Revoked' : 'Active';
    }

    // other states are unknown/`Destroyed`/not shown
    return 'Active';
  }

  copy(str: string) {
    this.clipboard.copyFromContent(str);
  }

  destroy(keyToDestroy: ProtectVKeyFormatted) {
    const modal: NgbModalRef = this.dialogService.open<ProtectVKeysDestroyModalComponent>(ProtectVKeysDestroyModalComponent);
    const modalInstance = modal.componentInstance as ProtectVKeysDestroyModalComponent;
    modalInstance.key = keyToDestroy;

    modalInstance.destroy.subscribe(({key}) => {
      modal.close();

      const progress = this.dialogService.progress('Destroying Key…');
      this.protectVService.destroy(key.id)
        .then(() => this.resync())
        .finally(() => progress.close());
    });

    modalInstance.closeModal.subscribe(() => modal.close());
  }

  restore(keyToRestore: ProtectVKeyFormatted): void {
    const modal: NgbModalRef = this.dialogService.open<ProtectVKeysRestoreModalComponent>(ProtectVKeysRestoreModalComponent);
    const modalInstance = modal.componentInstance as ProtectVKeysRestoreModalComponent;
    modalInstance.key = keyToRestore;

    modalInstance.restore.subscribe(({key}) => {
      modal.close();

      const progress = this.dialogService.progress('Restoring Key…');
      this.protectVService.restore(key.id)
        .then(() => this.resync())
        .finally(() => progress.close());
    });

    modalInstance.closeModal.subscribe(() => modal.close());
  }

  revoke(keyToRevoke: ProtectVKeyFormatted): void {
    const modal: NgbModalRef = this.dialogService.open<ProtectVKeysRevokeModalComponent>(ProtectVKeysRevokeModalComponent);
    const modalInstance = modal.componentInstance as ProtectVKeysRevokeModalComponent;
    modalInstance.key = keyToRevoke;

    modalInstance.revoke.subscribe(({key}) => {
      modal.close();

      const progress = this.dialogService.progress('Revoking Key…');
      this.protectVService.revoke(key.id)
        .then(() => this.resync())
        .finally(() => progress.close());
    });

    modalInstance.closeModal.subscribe(() => modal.close());
  }

  viewInfo(key: ProtectVKeyFormatted): void {
    const modal: NgbModalRef = this.dialogService.open<ProtectVKeysDetailsModalComponent>(ProtectVKeysDetailsModalComponent);
    const modalInstance = modal.componentInstance as ProtectVKeysDetailsModalComponent;
    modalInstance.key = key;
    modalInstance.closeModal.subscribe(() => modal.close());
  }

  resync(): Promise<any> {
    return this.protectVService.resync({
      state: this.stateFilter,
      serviceId: this.service.service_id,
    }, 'resources');
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  isSameSubscriberGroup() {
    if (this.service) {
      return this.authService.hasSubscriberGroupId(this.service.subscriberGroup);
    }
    return false;
  }

  private removeColumn(name: string) {
    this.displayedColumns.splice(this.displayedColumns.indexOf(name), 1);
  }

}
