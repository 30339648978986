<section>
    <header class="gem-header sub-header">Configure this tenant's available services.</header>
    <div class="no-resource-prompt xmb-4">Changes made here will affect this tenant only.</div>

    <hr class="mt-4 mb-4"/>

    <tiles-configure-view [serviceCategoriesStream]="this.serviceCategories" [tilesStream]="this.tiles" (tileClick)="tileClick($event)"></tiles-configure-view>
</section>

<ng-template #launchPrompt let-ctx>
    The <strong>{{ctx.name}}</strong> service will appear in your tenant's services list; all users from this tenant will be able to deploy it.
    <p class="mt-4">Once launched, a service cannot be removed. Do you wish to continue?</p>
</ng-template>
