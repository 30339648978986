<dpod-table (filter)="onFilter($event)" [dataLength]="dataSource.data.length">
    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of subscriber groups</caption>

        <!-- Name column -->
        <ng-container matColumnDef="name">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let subGrp" class="name-column">
                <a class="gem-link" [uiSref]="'accounts.subscribers.details'"
                    [uiParams]="{ id: subGrp.id }">{{subGrp.name}}</a>
            </td>
        </ng-container>

        <!-- Description column -->
        <ng-container matColumnDef="description">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let subGrp" class="description-column">
                <span [ngClass]="{ 'description-column': subGrp.description }">
                    {{ subGrp.description | dashIfEmpty }}
                </span>
            </td>
        </ng-container>

        <!-- Users column -->
        <ng-container matColumnDef="totalUsers">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>No. Users</th>
            <td mat-cell *matCellDef="let subGrp" class="total-users-column">
                {{ subGrp.totalUsers }}
            </td>
        </ng-container>

        <!-- Services column -->
        <ng-container matColumnDef="totalServices">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>No. Services</th>
            <td mat-cell *matCellDef="let subGrp" class="total-services-column">
                {{ subGrp.totalServices }}
            </td>
        </ng-container>

        <!-- Clients olumn -->
        <ng-container matColumnDef="totalClients">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>No. Clients</th>
            <td mat-cell *matCellDef="let subGrp" class="total-clients-column">
                {{ subGrp.totalClients }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let subGrp" class="actions-column">
                <div class="action-cell" [attr.data-group-name]="subGrp.name">
                    <div class="icon-inside-icon">
                        <!-- TODO <button> not span -->
                        <span title="Delete subscriber group"
                                [ngClass]="{ 'fieldset-disabled': !canDelete(subGrp) }"
                                role="button"
                                tabindex="0"
                                data-locator="delete"
                                class="grid-action" (click)="showDeleteDialog(subGrp.id)">
                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#trash" />
                            </svg>
                        </span>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let subGrp; columns: displayedColumns;" [attr.data-subscriber-group-id]="subGrp.id">
        </tr>
    </table>
</dpod-table>
