<div class="page panel-width">

    <wizard-inline [title]="'Purchase Service Subscription'"
                   [hideButtons]="hideButtons"
                   (currentStepIdxChange)="stepChange($event)"
                   (submitResponse)="finish()"
                   [disableNextButton]="disableNextButton"
    >

        <eval-convert-services-component
            nextButtonText="Next - Select Terms"
            name="Select Plan"
            instructions="Please select the number of services and plan you would like to purchase as a service election."
            [selectedServiceType]="selectedServiceType"
            [servicePlans]="servicePlans"
            [tiles]="tilesStream | async"
            (tilesRequestComplete)="hideButtons = !$event"
            (planSelectionChange)="planChanged($event)"
            (tileSelectionChange)="tilesChanged($event)"></eval-convert-services-component>

        <eval-convert-terms-component
            previousButtonText="Previous"
            nextButtonText="Next - Terms of Service"
            name="Select Term"
            [availableTerms]="availableTerms"
            [termSelection]="termSelection"
            instructions="Please select your term"
            (termSelectionChange)="termSelectionChanged($event)"
        ></eval-convert-terms-component>

        <eval-convert-summary-component
            previousButtonText="Previous"
            nextButtonText="Submit Purchase to {{parentName}}"
            [tiles]="tilesStream | async"
            [tileSelection]="tileSelection"
            [planSelection]="planSelection"
            [termSelection]="termSelection"
            [step]="step"
            name="Terms of Service">
        </eval-convert-summary-component>

    </wizard-inline>

</div>
