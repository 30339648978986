<ng-container *ngFor="let cat of tileCategories | keyvalue: sortServiceCategoriesByOrderNum">
    <section *ngIf="cat.value.tiles.length > 0">
        <header data-locator="category-name" class="gem-header section-subheader tile-category-header">{{cat.key}}</header>

        <div class="pt-2 pb-4 card-deck row-cols-5">
            <card-tile *ngFor="let tile of cat.value.tiles"
                       [cardTitle]="tile.name"
                       [disabled]="getTileDisplayState(tile) === 'disabled'"
                       [imageUrl]="tile.imageUrl"
                       [locator]="tile.shortCode"
                       [tooltip]="getTooltip(tile)"
            >

                <div content class="tile-content">
                    <p class="mb-0 tile-desc">{{tile.description}}</p>
                    <p class="mt-2 mb-0" *ngIf="tile.helpUrl">
                        <a [href]="tile.helpUrl" externalLink>More Info</a>
                    </p>
                </div>

                <div actions>
                    <!-- App Owner: provision tile -->
                    <button *ngIf="getTileDisplayState(tile) != 'disabled'"
                            class="btn btn-next btn-lg btn-block border-0 h-100 text-start purchase"
                            [class.loading]="loading" (click)="onTileClick(tile)">
                        <span><span class="align-text-bottom">+ </span>{{getTileBtnLabel(tile)}}</span>
                        <div *ngIf="loading" class="loading-status">
                            <mat-progress-spinner diameter="16" mode="indeterminate"></mat-progress-spinner>
                        </div>
                    </button>
                </div>
            </card-tile>
        </div>
    </section>
</ng-container>
