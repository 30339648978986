<div class="tenant-card" role="button"
     [attr.aria-label]="'Select Tenant '+tenant.name"
     (click)="selectTenant()"
     (keyup.enter)="selectTenant()">
    <header class="card-header">
        <p>{{tenant.name}}</p>
    </header>
    <div class="card-body">
        <p class="card-desc">{{tenant.authorities}}</p>
        <p class="card-desc desc-region">Region: {{tenant.region}}</p>
        <p class="card-desc desc-id">ID: {{tenant.id}}</p>
    </div>
    <img class="card-arrow" src="images/right-arrow.png" alt="right arrow">
</div>
