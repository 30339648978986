import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { State, VHsmApiService } from '@app/ajs-upgraded-providers';
import { SubscriberGroup } from '@app/features/accounts/accounts.model';
import { SubscribersApi } from '@app/features/accounts/api/subscribersApi.service';
import * as Roles from '@app/features/auth/roles.constants';
import { deleteServiceWarning as protectVDeleteServiceWarning } from '@app/features/gem-services/cloud/protectV/protectV.constants';
import { deleteServiceWarning as cloudHSMDeleteServiceWarning } from '@app/features/gem-services/cloudHSM/cloudHSM.constants';
import { HSMonDemandCreatedService, MarketplaceDPoD } from '@app/features/gem-services/cloudHSM/cloudHSM.model';
import { HSMService } from '@app/features/gem-services/cloudHSM/cloudHSM.service';
import { RequiresRoleService, ServiceBrokerService } from '@app/shared/services';
import { Subscription } from 'rxjs';
import { DpodTableComponent } from '../dpod-table/dpod-table.component';
import { DialogService, ProgressModal } from '../gem-dialogs';
import { DeleteServiceDialogComponent } from '@app/features/gem-services/dialogs/delete-service-dialog.component';
import { AuthService } from '@app/features/auth';
import { TilesService } from '@app/shared/services/tiles.service';
import { PurchaseDialogService } from '@app/shared/services/PurchaseDialogService';
import { payShieldServices, PROGRESS_DELETE_MSG, ServiceStatus } from '@app/features/gem-services/services.constants';
import { ProvisionDialogComponent } from '@app/features/gem-services/provision/provision-dialog.component';
import { AsyncServiceDialogComponent } from '@app/features/gem-services/dialogs/async-service-dialog.component';

export const columnNames = [
  'name',
  'serviceType',
  'status',
  'createdAt',
  'subscriberGroup',
  'createdBy',
  'actions',
];

@Component({
  selector: 'services-table',
  templateUrl: './services-table.component.html',
  styleUrls: ['./services-table.component.scss']
})
export class ServicesTableComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Overrides the list of columns to show. */
  @Input() displayedColumns = columnNames.slice();

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<HSMonDemandCreatedService>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  dataSource = new MatTableDataSource<HSMonDemandCreatedService>();
  public marketplaceDPoD = MarketplaceDPoD;
  private groupsMap = new Map<string, SubscriberGroup>(); // group id -> group
  private servicesSub: Subscription;
  private groupsSub: Subscription;

  constructor(
    // Things are a bit weird right now. Most service operations go through
    // HSMService but deletes are done by the (new) ServiceBrokerService
    @Inject(VHsmApiService) private hSMService: HSMService,
    @Inject(State) private $state: any,
    private serviceBrokerService: ServiceBrokerService,
    private subscribersApi: SubscribersApi,
    private dialogService: DialogService,
    private requiresRoleService: RequiresRoleService,
    private authService: AuthService,
    private tilesService: TilesService,
    private purchaseDialogService: PurchaseDialogService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  /** Optional filter applied to the services returned by the API. */
  @Input() filter = (_service: HSMonDemandCreatedService) => true;

  ngOnInit() {
    if (!this.requiresRoleService.hasRole(Roles.owner)) {
      // Remove actions column for non-App-Owners
      this.removeColumn('actions');
    }
    if (!this.requiresRoleService.hasRole(Roles.admin)) {
      // Remove subscriber group column for non-Tenant-Admins
      this.removeColumn('subscriberGroup');
    }

    this.servicesSub = this.hSMService.subscribe(this.onServicesChanged.bind(this));
    this.groupsSub = this.subscribersApi.subscribe(() => {
      this.groupsMap = new Map(this.subscribersApi.getAll().map(group => [group.id, group]));
    });

    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);

    if (this.displayedColumns.includes('subscriberGroup')) {
      // Fetch subscriber groups from server so the groupsMap can be populated
      this.subscribersApi.resync();
    }
  }

  displayExternalLink(service: HSMonDemandCreatedService): boolean {
    return (service && service.dashboard_url && service.dashboard_url !== '') || false;
  }

  ngAfterViewInit() {
    // reset and set the sort by createdAt value
    this.sort.sort({ id: '', start: 'desc', disableClear: false });
    this.sort.sort({ id: 'createdAt', start: 'desc', disableClear: false });
    (this.sort.sortables.get('createdAt') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
    this.changeDetectorRef.detectChanges();
    // sort and dpodTable are not available until AfterViewInit
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;

    // Set initial data & render
    this.onServicesChanged();
  }

  ngOnDestroy() {
    this.servicesSub.unsubscribe();
    this.groupsSub.unsubscribe();
    this.sort.sort({id: '', start: 'desc', disableClear: false});
  }

  isSameSubscriberGroup(service: HSMonDemandCreatedService): boolean {
    return this.authService.hasSubscriberGroupId(service.subscriberGroup);
  }

  /**
   * @returns The name of the subscriber group `service` is in, if we could find it.
   */
  getSubscriberGroupName(service: HSMonDemandCreatedService): string {
    const group = this.groupsMap.get(service.subscriberGroup);
    return group ? group.name : '…';
  }

  async openDeleteServiceDialog(service: HSMonDemandCreatedService) {
    console.log(`Deleting service ${service.service_id}`);
    const { name, serviceType } = service;
    let customWarningText: string;
    if (serviceType === 'vm_keystore') {
      customWarningText = protectVDeleteServiceWarning;
    } else {
      customWarningText = cloudHSMDeleteServiceWarning;
    }

    const ref = this.dialogService.open<DeleteServiceDialogComponent>(DeleteServiceDialogComponent);
    const modal = ref.componentInstance;
    modal.serviceNameToDelete = name;
    modal.customWarning = customWarningText;
    let progress: ProgressModal;
    let response;
    await ref.result;
    // We never reach this point if the user cancels
    try {
      progress = this.dialogService.progress(PROGRESS_DELETE_MSG);
      response = await this.serviceBrokerService.deleteService(service.service_id);
      // after deleting, we need to trigger the resync to get the list of services info
      await this.hSMService.resync();
      if (response && response.status && response.status === 202) {
        progress.close();
        this.openDeleteDialog();
      }
    } finally {
      if (progress) {
        progress.close();
      }
    }
  }

  sortingDataAccessor(service: HSMonDemandCreatedService, columnName: string): string|Date {
    switch (columnName) {
    case 'createdBy': return service.created_by_username;
    case 'createdAt': return new Date(service.createdAt); // need Date for chronological sort
    case 'serviceType': return service.formattedServiceType;
    case 'subscriberGroup': return this.getSubscriberGroupName(service);
    }
    return service[columnName];
  }

  filterPredicate(service: HSMonDemandCreatedService, filter: string): boolean {
    // Special case for searching on the subscriber group name
    if (this.displayedColumns.includes('subscriberGroup') &&
        this.getSubscriberGroupName(service).toLowerCase().includes(filter)) {
      return true;
    }

    return this.displayedColumns.some(column => {
      const sortingData = this.sortingDataAccessor(service, column);
      return sortingData && String(sortingData).toLowerCase().includes(filter.toLowerCase());
    });
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  isStatusFailed(status: string) {
    // Returns true if the service Failed in Provisioning or Deprovisioning
    return ServiceStatus.ProvisioningFailed === status || ServiceStatus.DeprovisioningFailed === status;
  }

  isStatusDone(status: string) {
    // Returns true if service is provisioned or deprovisioned
    return ServiceStatus.Provisioned === status || ServiceStatus.Deprovisioned === status;
  }

  openServiceDialog(service: HSMonDemandCreatedService) {
    const ref = this.dialogService.open<ProvisionDialogComponent>(ProvisionDialogComponent);
    const modal = ref.componentInstance;
    modal.service = service;
    modal.staticMessage = this.getStaticMessage(service);
    modal.isStatusFailed = this.isStatusFailed(service.status);
  }

  openDeleteDialog() {
    const ref = this.dialogService.open<AsyncServiceDialogComponent>(AsyncServiceDialogComponent);
    const modal = ref.componentInstance;
    modal.status = ServiceStatus.Deprovisioning;
  }

  private removeColumn(name: string) {
    const index = this.displayedColumns.indexOf(name);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  private onServicesChanged() {
    this.dataSource.data = this.hSMService.getAll().filter(this.filter);
    const externalMPAs = this.dataSource.data.find(item => item.marketplace === 'DPoD');
    if (!externalMPAs) {
      this.removeColumn('actions');
    }
    this.table.renderRows();
  }

  private getStaticMessage(service: HSMonDemandCreatedService) {
    const staticMessageKey = `${service.status}_MSG`;
    // for payshield service type, there is no static message - we rely on the statusMessage
    if (!payShieldServices.includes(service.serviceType)) {
      return ServiceStatus[staticMessageKey];
    }
    return '';
  }
}
