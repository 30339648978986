<ul ngbNav #nav="ngbNav" class="nav-pills">
    <li ngbNavItem="serviceClients">
        <button ngbNavLink data-locator="tab-service-clients">Service Clients</button>
        <ng-template ngbNavContent>
            <cloud-hsm-clients-table
                [clients]="getClients()"
                (create)="addClient()"
                [service]="service"
                (download)="downloadClient($event)"
                (delete)="deleteClient($event)">
            </cloud-hsm-clients-table>
        </ng-template>
    </li>

    <li ngbNavItem="credentials">
        <button ngbNavLink data-locator="tab-credentials">Credentials</button>
        <ng-template ngbNavContent>
            <credentials-section
                [service]="service"
                [permissions]="['Create, regenerate & delete service clients']"
            ></credentials-section>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
