<dpod-table (filter)="onFilter($event)">
    <ng-content></ng-content>

    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of VM Encryption keys</caption>

        <!-- State column -->
        <ng-container matColumnDef="state">
            <th scope="col" class="status-column" mat-header-cell mat-sort-header *matHeaderCellDef>State</th>
            <td mat-cell *matCellDef="let key" class="state-column">
                <span [ngSwitch]="getUIDisplayState(key)">
                    <span title="Active" *ngSwitchCase="'Active'">
                        <svg class="icon-svg check align-middle">
                            <use xlink:href="#check" />
                        </svg>
                    </span>
                    <span title="Revoked" *ngSwitchCase="'Revoked'">
                        <svg class="icon-svg revoke align-middle">
                            <use xlink:href="#revoke" />
                        </svg>
                    </span>
                </span>
            </td>
        </ng-container>

        <!-- Key Name column -->
        <ng-container matColumnDef="name">
            <th scope="col" class="key-name-column" mat-header-cell mat-sort-header *matHeaderCellDef>Key Name</th>
            <td mat-cell *matCellDef="let key" class="key-name-column">
                <div class="copy-column">{{key.name}}</div>
                <div title="Copy to clipboard" class="icon-inside-icon copy">
                    <span class="grid-action" (click)="copy(key.name)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#copy" />
                        </svg>
                    </span>
                </div>
            </td>
        </ng-container>


        <!-- Key ID column -->
        <ng-container matColumnDef="id">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Key ID</th>
            <td mat-cell *matCellDef="let key" class="key-id-column">
                <div class="copy-column">{{key.id}}</div>
                <div title="Copy to clipboard" class="icon-inside-icon copy">
                    <span class="grid-action" (click)="copy(key.id)">
                        <svg class="icon-svg outer-icon">
                            <use xlink:href="#action-box" />
                        </svg>
                        <svg class="icon-svg inner-icon">
                            <use xlink:href="#copy" />
                        </svg>
                    </span>
                </div>
            </td>
        </ng-container>

        <!-- Created At column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
            <td mat-cell *matCellDef="let key" class="created-at-column">
                {{ key.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Last Accessed column -->
        <ng-container matColumnDef="updatedAt">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Last Accessed</th>
            <td mat-cell *matCellDef="let key" class="lastAccessed-column">
                {{ key.formattedUpdatedAt }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let key" class="actions-column">
                <div class="action-cell">
                    <div ngbDropdown class="gem-dropdown">
                        <span role="button" tabindex="0" class="grid-action" ngbDropdownToggle id="pv-actions-{{key.id}}">
                            <svg class="icon-svg">
                                <use xlink:href="#dropdown" />
                            </svg>
                        </span>

                        <ul class="dropdown-menu gem" ngbDropdownMenu role="menu">
                            <ng-template [ngIf]="getUIDisplayState(key) === 'Active'">
                                <li class="dropdown-item" data-locator="view-key-info"
                                    role="button" (click)="viewInfo(key)">View Key Info</li>
                                <li class="dropdown-divider"></li>
                                <li class="dropdown-item" data-locator="revoke-key"
                                    role="button" (click)="revoke(key)">Revoke Key</li>
                            </ng-template>

                            <ng-template [ngIf]="getUIDisplayState(key) === 'Revoked'">
                                <li class="dropdown-item" data-locator="restore-key"
                                    role="button" (click)="restore(key)">Restore Key</li>
                            </ng-template>

                            <li class="dropdown-item" data-locator="destroy-key"
                                role="button" (click)="destroy(key)">Destroy Key
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let key; columns: displayedColumns;" [attr.data-state]="getUIDisplayState(key)">
        </tr>
    </table>

</dpod-table>
