<tenant-details-service-elections *ngIf="showServiceAgreement()" [serviceAgreement]="serviceAgreement"
                                  [tenantStatus]="tenantStatus"
                                  [tenant]="tenant"></tenant-details-service-elections>

<div class="details-panel main-panel">
    <div class="details-header gem-header panel-header">Tenant Account</div>

    <form #editTenant="ngForm" class="details-panel-section">
        <div class="row form-group">
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="accountName">Tenant Name</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <strong *ngIf="!editing">{{tenant.name}}</strong>
                        <ng-container *ngIf="editing">
                            <input id="accountName" name="accountName" [(ngModel)]="tenant.name" #accountName="ngModel" maxlen="64" class="form-control" required>
                            <div *ngIf="accountName.dirty" id="error-message-account-name" class="text-danger error-message" role="alert">
                                <div *ngIf="accountName.errors?.maxlen">Maximum 64 characters</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="accountId">Account ID</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <strong class="pre" id="accountId">{{tenant.id}}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" data-locator="tenant-hostname" *ngIf="!config.FF_ONE_WELCOME">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-3">
                                <label for="hostname">Hostname</label>
                            </div>
                            <div class="col-sm-9 text-end">
                                <div *ngIf="editing">
                                    <button data-locator="save" class="btn btn-next" (click)="doSave()" [disabled]="editTenant.invalid || addressForm.invalid">Save
                                    </button>
                                    <button data-locator="cancel" class="btn btn-default btn-cancel" (click)="doCancel()">Cancel</button>
                                </div>
                                <div *ngIf="!editing">
                                    <gem-feedback text="Changes Saved!" [(trigger)]="saved"></gem-feedback>
                                    <svg tabindex="0" data-locator="edit" class="icon-svg align-sub" role="button" (click)="doEdit()">
                                        <title>Edit tenant</title>
                                        <use xlink:href="#edit"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <strong id="hostname">
                            <a target="_blank" class="gem-link d-inline" href="https://{{getDomain()}}">{{getDomain()}}</a>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row form-group">
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="accountTypeString">Account Type</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <strong id="accountTypeString">{{getAccountType()}}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="maximumServices">HSMoD Service Quota</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <strong data-locator="serviceQuotaValue" *ngIf="!editing">{{getTenantQuota()}}</strong>
                        <gem-tenant-quota *ngIf="editing" name="maximumServices" [showUnlimitedToggle]="this.tenant.accountType === 'service provider'"
                            [maxQuota]="unlimitedQuota" [(ngModel)]="tenant.serviceQuota" required>
                        </gem-tenant-quota>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <label for="status">Account Status</label>
                <br/>
                <strong id="status">{{getAccountStatus()}}</strong>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="companyName">Company Name</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional [value]="tenant.companyName"></optional>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="departmentName">Company Department</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional *ngIf="!editing" [value]="tenant.departmentName"></optional>
                        <ng-container *ngIf="editing">
                            <input id="departmentName" name="departmentName" maxlen="32" [(ngModel)]="tenant.departmentName" #departmentName="ngModel" class="form-control">
                            <div *ngIf="departmentName.dirty" id="error-message-department-name" class="text-danger error-message" role="alert">
                                <div *ngIf="departmentName.errors?.maxlen">Maximum 32 characters</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="country">Country</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional *ngIf="!editing" [value]="tenant.billingAddress.country"></optional>
                        <gem-country-select *ngIf="editing" id="country" name="country" [(ngModel)]="tenant.billingAddress.country"
                            (ngModelChange)="countryChange($event)" required>
                        </gem-country-select>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="city">City</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional *ngIf="!editing" [value]="tenant.billingAddress.city"></optional>
                        <ng-container *ngIf="editing">
                            <input id="city" name="city" [(ngModel)]="tenant.billingAddress.city" class="form-control" maxlen="64" #city="ngModel" required>
                            <div *ngIf="city.dirty" id="error-message-city-name" class="text-danger error-message" role="alert">
                                <div *ngIf="city.errors?.maxlen">Maximum 64 characters</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </div>

        <form #addressForm="ngForm" class="row form-group">

            <div class="col-sm-6">
                <div class="row">
                    <div class="col">
                        <label for="streetAddress">Billing Address</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional *ngIf="!editing" [value]="tenant.billingAddress.streetAddress"></optional>
                        <ng-container *ngIf="editing">
                            <input id="streetAddress" name="streetAddress" [(ngModel)]="tenant.billingAddress.streetAddress" class="form-control" #streetAddress="ngModel" maxlen="255" required>
                            <div *ngIf="streetAddress.dirty" id="error-message-addr" class="text-danger error-message" role="alert">
                                <div *ngIf="streetAddress.errors?.maxlen">Maximum 255 characters</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="row">
                    <div class="col">
                        <label for="zip">Zip/Postal Code</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <optional *ngIf="!editing" [value]="tenant.billingAddress.zip"></optional>
                        <ng-container *ngIf="editing">
                            <input id="zip" name="zip" [(ngModel)]="tenant.billingAddress.zip" class="form-control"
                                   #zip="ngModel" minlength="2" maxlength="12" required>
                            <div *ngIf="zip.dirty" id="error-message-zip-code" class="text-danger error-message" role="alert">
                                <div *ngIf="zip.errors?.minlength">Minimum 2 characters</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <label for="state">State/Province/Region</label>
                <br/>
                <optional *ngIf="!editing" [value]="tenant.billingAddress.state"></optional>
                <gem-region-select *ngIf="editing" id="state" name="state" [country]="tenant.billingAddress.country"
                    [regionDefaultSelection]="'Select State / Province / Region'" [(ngModel)]="tenant.billingAddress.state"
                                   [required]="stateRequired ? 'required' : null">
                </gem-region-select>
            </div>

        </form>
        <hr/>
        <div class="row">
            <div class="col-sm-3">
                <label for="created">Created At</label>
                <br/>
                <strong id="created">{{tenant.createdAt | date: 'd-MMM-yyyy H:mm'}}</strong>
            </div>
            <div class="col-sm-3">
                <label for="updated">Updated At</label>
                <br/>
                <strong id="updated">{{tenant.updatedAt | date: 'd-MMM-yyyy H:mm'}}</strong>
            </div>
        </div>
    </form>

</div>

<div class="details-panel main-panel">

    <div class="details-header gem-header panel-header" [ngClass]="{'dimmed': editing}">
        <div class="row">
            <div class="col">
                <span class="float-start">Admin User</span>
            </div>
        </div>
    </div>
    <div class="details-panel-section" [ngClass]="{'dimmed': editing}">
        <div class="row form-group">
            <div class="col-sm-6">
                <label for="email">Email Address</label>
                <br/>
                <strong id="email">{{tenant.admin?.email}}</strong>
            </div>
            <div class="col-6 text-end" *ngIf="!editing">
            </div>
        </div>
        <hr/>
        <div class="row form-group">
            <div class="col-sm-3">
                <label for="first-name">First Name</label>
                <br/>
                <strong id="first-name">{{tenant.admin?.givenName}}</strong>
            </div>
            <div class="col-sm-3">
                <label for="last-name">Last Name</label>
                <br/>
                <strong id="last-name">{{tenant.admin?.familyName}}</strong>
            </div>
            <div class="col-sm-3">
                <label for="phone">Phone</label>
                <br/>
                <span id="phone">
                    <optional [value]="tenant.admin?.phoneNumber"></optional>
                </span>
            </div>
        </div>
    </div>

</div>
