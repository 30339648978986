import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogService } from '@app/components';
import { TilesService } from '@app/shared/services/tiles.service';
import { Tile } from '../../marketplace/tiles.interface';
import { ServiceCategory } from '@app/shared/services/service-categories.interface';
import { ServiceCategoriesService } from '@app/shared/services';

@Component({
  selector: 'tenant-marketplace',
  templateUrl: './tenant-marketplace.component.html',
})
export class TenantMarketplaceComponent implements OnInit {

  @Input() tenantId: string;
  @ViewChild('launchPrompt', { static: true }) launchPrompt: TemplateRef<any>;
  tiles: Observable<Tile[]>;
  serviceCategories: Observable<ServiceCategory[]>;

  constructor(
    private tilesService: TilesService,
    private serviceCategoryService: ServiceCategoriesService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    // Fetch tiles for the tenant that we're interested in
    this.tilesService.list(this.tenantId);

    // Subscribe for changes to the tenant's tiles
    this.tiles = this.tilesService.tilesFor(this.tenantId);
    this.serviceCategories = this.serviceCategoryService.serviceCategories();
  }

  tileClick(t: Tile) {
    if (t.enabled) {
      // TODO disable a tile
      return;
    }

    this.dialogService.confirm({
      title: 'Launch Service?',
      content: this.launchPrompt,
      yesLabel: 'Launch Service',
      noLabel: 'Cancel',
    })
      .then(() => this.tilesService.setEnabled(t.id, true));
  }
}
