function sanitize(exception) {
  if (typeof exception !== 'string') {
    return exception;
  }

  return exception.replace(/(Bearer) [.A-Za-z0-9+\\=_-]+/, '$1 \u2026');
}

/**
 * Decorator that removes the bearer token from exceptions
 */
export default function SanitizerDecorator($delegate) {

  return (exception, cause) => {
    const sanitizedException = sanitize(exception);
    $delegate(sanitizedException, cause);
  };
}

SanitizerDecorator.$inject = ['$delegate'];
