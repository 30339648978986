<div class="picture">
    <img aria-label="service icon" class="card-icon" *ngIf="tile.imageUrl" src="{{tile.imageUrl}}" alt="icon" />
</div>

<div class="content">
    <div class="title">
        {{tile.name}}
    </div>

    <div class="description">
        {{tile.description}}
    </div>
</div>

<div class="quantity-section" *ngIf="showQuantity">
    <div>
        <div class="select-quantity text-center">Select quantity</div>

        <div class="quantity-select">
            <div data-locator="lower-quantity" class="circle" (click)="changeQuantity(-1)">-</div>

            <div class="quantity" [ngClass]="quantity > 0 ? 'has-quantity': ''">
                {{quantity}}
            </div>

            <div data-locator="add-quantity" class="circle" (click)="changeQuantity(1)">+</div>
        </div>
    </div>

</div>
