export interface AuditLogService {
  id: string;
  name: string;
}

export interface AuditLogFilters {
  resourceId: string;
  resourceName: string;
  from: string;
  to: string;
}

export interface AuditLog {
  time: string;
  _timeParsed?: number;
  source: string;
  resourceID: string;
  actorID: string;
  tenantID: string;
  action: string;
  status: string;
  traceID: string;
  _metaShown?: boolean;
  meta: Record<string, any> | string;
}

export enum AuditLogJobState {
  SUCCEEDED = 'SUCCEEDED',
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface AuditLogJob {
  jobId: string;
  startedAt: string;
  endedAt: string;
  state: AuditLogJobState;
  location: string;
}

/**
 * A custom enum for sort direction is used instead of the standard Angular enum,
 * so that it can be imported in the audit-logs-table.worker file.
 * Using the Angular enum leads to import errors in the worker.
 * The custom SortDirection enum mimics the Angular SortDirection enum.
 */
export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = ''
}

export interface SortDescriptor {
  field: string;
  direction: SortDirection;
}

export interface FilterDescriptor {
  value: string;
  fields: string[];
}

export interface PageDescriptor {
  limit: number;
  offset: number;
  sortDescriptors: SortDescriptor[];
  filterDescriptor: FilterDescriptor;
}

export enum AuditLogWorkerTask {
  INIT = 'INIT',
  CALCULATE_PAGE = 'CALCULATE_PAGE',
  CANCEL = 'CANCEL'
}

export interface WorkerRequestMessage {
  task: AuditLogWorkerTask;
  logFile?: Blob;
  pageDescriptor?: PageDescriptor;
}

export interface WorkerResponseData {
  task: AuditLogWorkerTask;
  data?: AuditLog[]; // A page data to display in the table
  totalCount?: number; // Total count of all log records in a gzip file, NOT only those that meet the applied filter
  paginatorLength?: number; // Number of the log records in a gzip file that meet the applied filter
}

export interface WorkerResponseMessage {
  data: WorkerResponseData;
}

export type onDataReceivedCallback = (workerResponse: WorkerResponseMessage) => void;

export type LogComparator = (log1: AuditLog, log2: AuditLog, sortDescriptor: SortDescriptor) => number;
