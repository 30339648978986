import { MfeComponents } from '@app/mfe/mfe-registry.service';
import { FeatureToggles } from '../../../feature-toggles';
import { HSMonDemandCreatedService } from '../cloudHSM.model';
import { DocsService } from '@app/shared/services';
import { ServiceShortCode } from '../../services.constants';
import template from './cloudHSM-details.component.html';
import cloudHSMDetailsModule from './_init';

const nonMfeServiceTypes = ['p2pe', 'luna_dke'];

class CloudHSMController {
  bannerValue = {
    'p2pe': [
      'Configure the P2PE Docker Container using the P2PE Service Software and the Service Client. Refer to',
      'documentation portal',
      'for setup instructions.'],
    'luna_dke': [
      'Download the Luna DKE Service Software and create a Service Client. Refer to the DKE ',
      'integration guide',
      'for more information about implementation.',]
  };

  MfeComponents = MfeComponents;
  payShieldServiceType: string[] = [
    ServiceShortCode.payshield_na_lab,
    ServiceShortCode.payshield_na,
    ServiceShortCode.payshield_eu_lab,
    ServiceShortCode.payshield_eu,
  ];

  private service: HSMonDemandCreatedService;
  private openDownloadClientBundleSection = false;

  // both dependencies being used within the template
  constructor(private featureToggles: FeatureToggles, private docsService: DocsService) {
  }

  doDisplayBanner() {
    return this.service && this.bannerValue[this.service.serviceType];
  }

  doLoadHsmDetailsMfe() {
    return !nonMfeServiceTypes.includes(this.service.serviceType);
  }

  whichMFEtoLoad() {
    if (this.payShieldServiceType.includes(this.service.serviceType)) {
      return MfeComponents.PayShieldServiceDetails;
    }
    return MfeComponents.HsmServiceDetails;
  }

}

CloudHSMController.$inject = ['FeatureToggles', 'DocsService'];

cloudHSMDetailsModule.component('cloudHsmDetails', {
  template,
  bindings: {
    service: '<',
    openDownloadClientBundleSection: '<',
  },
  controller: CloudHSMController,
});
