import {DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {KeyRotationComponent} from './key-rotation.component';
import {SharedModule} from '@app/shared';
import {KeyRotationPolicyComponent} from './key-rotation-policy.component';
import { CredentialsListModule } from '@app/shared/components/credentials-table';
import { SalesforceSecretsTableComponent } from './salesforce-secrets-table/salesforce-secrets-table.component';

@NgModule({
  declarations: [KeyRotationComponent, KeyRotationPolicyComponent, SalesforceSecretsTableComponent],
  imports: [SharedModule, CredentialsListModule],
  exports: [SalesforceSecretsTableComponent],
  providers: [DatePipe],
})
export class SalesforceDetailsModule {
}
