export * from './email.component';

// AngularJS
import angular from 'angular';
import EmailDirective from './email.directive.ng1';

export default angular.module('gem.email', [])
  .directive('email', EmailDirective)
  .name;

