import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangePickerService } from './date-range-picker.service';
import { INPUT_PLACEHOLDER } from './date-range-picker.constants';
import { Dayjs } from 'dayjs';
import { NgxDaterangepickerBootstrapDirective } from 'ngx-daterangepicker-bootstrap';

interface DateRangePickerOptions {
  minDate: string; // minimal selectable date
  maxDate: string; // maximal selectable date
  dateLimit: number; // maximal amount of days between start and end dates
  linkedCalendars: boolean; // if true, the right calendar always displays the month after the one in the left calendar
  showCancel: boolean; // if true, the cancel button is shown
  timePicker: boolean; // if true, enables the timepicker
  timePicker24Hour: boolean; // if true, disables AM/PM and displays 0-23 hour range in the timepicker
  timePickerSeconds: boolean; // if true, enables selection of seconds
  isTooltipDate: (date: Dayjs) => string; // given the current rendered date, returns a tooltip for it
}

/**
 * This component wraps an input with the ngxDaterangepickerBootstrap directive applied to it.
 * The ngxDaterangepickerBootstrap comes from the ngx-daterangepicker-bootstrap library.
 * Please find the source code of the library at
 * https://github.com/iulius-ciorica/ngx-daterangepicker-bootstrap/tree/master/projects/ngx-daterangepicker-bootstrap
 */
@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangePickerComponent
    }
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() dateLimit: number;

  @ViewChild(NgxDaterangepickerBootstrapDirective, { static: false }) datepicker: NgxDaterangepickerBootstrapDirective;

  /**
   * The ngxDaterangepickerBootstrap directive modifies ngModel - makes it an object with startDate and endDate properties.
   * The object is used even when the input contains something invalid.
   * The usage of the object makes it impossible to read values from the input, which leads to:
   * 1. Inability to read input values manually entered by the user.
   * 2. Inability to validate input values manually entered by the user.
   * This is why we use rawInputValue and:
   * 1. Sync this property with input's value property, so that rawInputValue always contains the value of the input.
   * 2. Expose rawInputValue as DateRangePickerComponent's value, making it possible to validate the input value.
   */
  rawInputValue = '';
  dateRangePickerOptions: DateRangePickerOptions;
  inputPlaceholder = INPUT_PLACEHOLDER;

  private touched = false;
  private onTouched: () => void;
  private onChange: (value: string) => void;

  constructor(private dateRangePickerService: DateRangePickerService) {
    this.dateRangePickerService.patchDateRangePickerComponent();
  }

  ngOnInit(): void {
    this.initDateRangeOptions();
  }

  ngAfterViewInit(): void {
    // As soon as the input is rendered - set its value to rawInputValue
    this.datepicker['elementRef'].nativeElement.value = this.rawInputValue;
  }

  writeValue(rawValue: string): void {
    // rawInputValue is exposed as DateRangePickerComponent's value
    // We should NOT call setRawInputValue here - writeValue is called only once during component's initialization
    this.rawInputValue = rawValue;
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    // We don't support disabling for now
  }

  /**
   * This method is called by the ngxDaterangepickerBootstrap directive after the user has clicked
   * the Apply button in the date range picker.
   * We read the input's value and update rawInputValue of DateRangePickerComponent.
   */
  dateRangeApplied() {
    if (this.datepicker) {
      this.setRawInputValue(this.datepicker['elementRef'].nativeElement.value);
    }
  }

  /**
   * This method is called by the browser after the user has updated input's value manually.
   * We read the input's value and update rawInputValue of DateRangePickerComponent.
   */
  datesRangeInputChange(event: InputEvent) {
    this.setRawInputValue(event.target['value']);
  }

  /**
   * This method is called by the browser after the user has clicked the input.
   * The date range picker's Apply button is disabled by default;
   * however, the date range picker always has a valid date range pre-selected.
   * This is why we manually enable the date range picker's Apply button immediately after it's open.
   */
  onDateInputClick() {
    // The enableApplyButton is wrapped into a timeout because the rendering of daterangepicker (provided
    // by the ngx-daterangepicker-bootstrap library) is async.
    // The setTimeout guarantees that this.datepicker.daterangepicker is properly initialized.
    setTimeout(() => this.dateRangePickerService.enableApplyButton(this.datepicker.daterangepicker));
  }

  /**
   * This method is called by the browser after the user has pressed the Enter key.
   * The user has focused the input and clicked Enter - the date range picker should be hidden.
   */
  onDateInputEnterKeyup() {
    if (!this.datepicker?.daterangepicker?.['isShown']) {
      return;
    }
    this.datepicker.hide();
  }

  private initDateRangeOptions() {
    this.dateRangePickerOptions = {
      maxDate: this.maxDate,
      minDate: this.minDate,
      dateLimit: this.dateLimit + 1, // As required by the ngx-daterangepicker-bootstrap library
      linkedCalendars: true,
      showCancel: true,
      timePicker: true,
      timePicker24Hour: true,
      timePickerSeconds: true,
      isTooltipDate: (date: Dayjs) => this.dateRangePickerService.getDateTooltip(date, this.datepicker?.daterangepicker)
    };
  }

  private setRawInputValue(newValue: string) {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
    // Keeping rawInputValue in sync with the latest input's value and
    // calling onChange to propagate rawInputValue to the outside
    this.rawInputValue = newValue;
    this.onChange(this.rawInputValue);
  }
}
