import { Inject, Injectable, TemplateRef } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IQService } from 'angular';
import type { IModalStackService } from 'angular-ui-bootstrap';
import { IModalServiceToken, IModalStackServiceToken, Q } from '../../ajs-upgraded-providers';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ErrInput, ErrorModalComponent } from './error/error-modal.component';
import { ProgressModalComponent } from './progress/progress-modal.component';

type IModalService = angular.ui.bootstrap.IModalService;

interface ErrorOptions {
  text?: string;
}

/**
 * Options for the `DialogService.confirm()` method.
 */
interface ConfirmOptions {
  /** Title for the confirmation dialog */
  title: string;

  yesLabel?: string;
  noLabel?: string;

  /** Gives the content to be rendered in the confirmation dialog. The content can be:
   *
   * - a string (plain text, no HTML)
   * - a TemplateRef
   * - a component class
   *
   * In the latter 2 cases, the `context` object may be used to pass properties into
   * the template.
   */
  content: string | TemplateRef<any> | any;

  /** Properties object that will be applied to the component instance or rendered template.
   * Any properties in this object can be referenced from the template using `{{ }}` binding.
   */
  context?: { [key: string]: any };
}

/**
 * Controls how rejections of the underlying NgbModal dialog are reported to the caller.
 */
export enum RejectPolicy {
  /** Reject on any error including dismissal */
  ALL = 1,

  /** Reject only on non-dismissals */
  IGNORE_DISMISS = 2,
}

const ESC = 'escape key press';

/**
 * Convenience interface extending NgbModalRef so we can infer the type of componentInstance.
 */
declare interface ComponentModalRef<T> extends NgbModalRef {
  componentInstance: T;
}

export interface ProgressModal { close: () => void }

/**
 * Synthetic "error" indicating that the user dismissed a dialog.
 */
export class DismissalError extends Error {
  static code = 'dismiss';
  private code = DismissalError.code;
  constructor() {
    super(DismissalError.code);
  }
}

function isNgBootstrapDismissal(error: any) {
  return error === undefined || error === ModalDismissReasons.ESC || error === ModalDismissReasons.BACKDROP_CLICK;
}

/**
 * Opens a dialog of the specified type.
 */
@Injectable()
export class DialogService {
  constructor(
    @Inject(Q) private $q: IQService,
    @Inject(IModalServiceToken) private $uibModal: IModalService,
    @Inject(IModalStackServiceToken) private $uibModalStack: IModalStackService,
    private ngbModal: NgbModal,
  ) {
    this.$q = $q;
    this.$uibModal = $uibModal;
  }

  /**
   * Opens a model dialog and returns a promise with the result.
   *
   * @deprecated This method can only open AngularJS components. To open an Angular component, use
   * `open`.
   *
   * @param {String}  component     The component to load into the modal
   * @param {Object}  resolves      Parameters to pass to the component via the 'resolves' binding.
   *   null or undefined means no resolves are bound
   * @param {Object}  params        Params to override the uibModal hardcoded parameters
   * @param {boolean} returnError   by default errors are not returned, `true` will return the error to the parent component
   */
  openAJS(component, resolves = {}, params = {}, returnError = false): Promise<any> {
    const deferred = this.$q.defer();
    // uibModal expects resolves to be functions that return the desired value
    // (string resolves are passed to $injector). to simplify life for the caller
    // of this method, we'll wrap any non-function field in the given 'resolves'
    // object in a lambda
    const lambdaResolves = {};

    if (resolves) {
      Object.keys(resolves).forEach(key => {
        const value = resolves[key];
        lambdaResolves[key] = typeof value === 'function' ? value : () => value;
      });
    }

    const baseParams = {
      animation: true,
      backdrop: 'static',
      component,
      resolve: lambdaResolves,
      windowClass: 'open-modal',
    };

    const uibModalParams = Object.assign({}, baseParams, params); // merge the two objects together

    this.$uibModal.open(uibModalParams).result.then(result => deferred.resolve(result), error => {

      if (returnError) {
        deferred.reject(error);
        return;
      }

      if (this.isDismissal(error)) {
        return; // if key press to dismiss dialog or error is "undefined", promise is never fulfilled
      }

      throw new Error(error);
    });

    return deferred.promise as Promise<any>;
  }

  /**
   * Opens an ng-bootstrap modal showing the given component.
   *
   * The generic type parameter determines the type of the returned ComponentModalRef.
   *
   * Usage:
   * ```
   *  const ref = dialogService.open<MyDialog>(MyDialog);
   *  const dlg = ref.componentInstance; // type is MyDialog
   *  dlg.someInput = 'foo';
   *  dlg.someOutput.subscribe();
   * ```
   *
   * @param component The component to show in the modal
   * @param options Overrides the default modal options
   * @param rejectPolicy By default rejections due to dialog dismissal are not reported on the returned
   * modal's `result` promise. Use a different `rejectPolicy` if you wish to override this.
   * @returns Reference to the modal
   */
  open<T>(component: any, options: NgbModalOptions = {}, rejectPolicy = RejectPolicy.IGNORE_DISMISS) {
    options = Object.assign({
      backdrop: 'static',
      windowClass: 'open-modal',
    }, options);

    const modal: ComponentModalRef<T> = this.ngbModal.open(component, options);
    modal.result = modal.result.then(null, (error: any) => {
      switch (rejectPolicy) {
      case RejectPolicy.IGNORE_DISMISS:
        if (isNgBootstrapDismissal(error)) {
          // Return a promise that never resolves, effectively never delivering the rejection
          return new Promise(() => {});
        }
        /* falls through */

      case RejectPolicy.ALL:
      default:
        throw error;
      }
    });
    return modal;
  }

  /**
   * Opens a confirmation dialog.
   *
   * @param options Options controlling the appearance of the dialog
   * @returns Promise resolving if the user picked yes, rejecting if they picked no or cancelled.
   */
  confirm(options: ConfirmOptions): Promise<void> {
    options = Object.assign({}, options);
    const modal: ComponentModalRef<ConfirmModalComponent> = this.ngbModal.open(ConfirmModalComponent, {
      backdrop: 'static',
      windowClass: 'confirm-modal',
    });
    Object.assign(modal.componentInstance, options);

    // Return a new promise here instead of chaining so we can skip error propagation in some cases
    return new Promise((resolve, reject) => {
      modal.result.then(resolve, (error: any) => {
        if (isNgBootstrapDismissal(error)) {
          reject(new DismissalError());
          return;
        }
        // Got a real error. Re-throw without propagating to the caller of confirm().
        // This will trigger the global error dialog for the uncaught exception.
        throw error;
      });
    });
  }

  /**
   * Opens a new progress modal. The caller of this method is responsible for closing the progress
   * modal when its operation is finished.
   *
   * @param text The main text of the progress modal
   * @param subText The text to display below `text`
   * @param cancelCallback The callback that is triggered when the user clicks the Cancel button. It also enables the Cancel button.
   * @returns A handle that can be used to close the progress dialog.
   */
  progress(text: string, subText: string = null, cancelCallback: () => void = null): ProgressModal {
    const modal: ComponentModalRef<ProgressModalComponent> = this.ngbModal.open(ProgressModalComponent, {
      backdrop: 'static',
      keyboard: false, // don't allow users to close it with ESC
      windowClass: 'progress-modal',
    });
    const comp = modal.componentInstance;
    comp.text = text;
    comp.subText = subText;
    comp.cancelCallback = cancelCallback;
    return {
      close: modal.close.bind(modal)
    };
  }

  /**
   * Opens a new modal showing an error.
   *
   * @param error The error response received from the server.
   * @param options Options controlling the appearance of the dialog
   * @returns A promise that resolves when the dialog is closed. This promise will not reject
   * since the error dialog cannot be cancelled.
   */
  error(error: ErrInput, options: ErrorOptions = {}): Promise<void> {
    const modal: ComponentModalRef<ErrorModalComponent> = this.ngbModal.open(ErrorModalComponent, {
      backdrop: 'static',
      windowClass: 'error-modal',
    });
    const comp = modal.componentInstance;
    comp.error = error;
    comp.text = options.text;
    return modal.result;
  }

  /**
  * Opens a model dialog then performs a CRUD operation on the entity in the dialog
  *
  * @param {fn} fn - The function that performs the operation, must return a promise.
  *   The function is called passing the result value of the modal.
  * @param {String} compName - The component to load into the modal
  * @param {Object} resolves - The `resolve` to pass into the modal.
  *   null or undefined means no resolves are bound
  * @param {String} progressMsg - The message to show in the progress dialog
  *   to use for the modal
  * @returns {promise}
  */
  entityFn(fn, compName, resolves, progressMsg) {
    let progress = { close: () => { } };
    return this.openAJS(compName, resolves)
      .then(entity => {
        progress = this.progress(progressMsg);
        return fn(entity);
      })
      .catch(error => {
        if (this.isDismissal(error)) {
          return;
        }
        throw error;
      })
      .finally(() => progress.close());
  }

  /*
   * @returns {boolean} True if error is a fake error produced by dismissing a dialog
   */
  isDismissal(error) {
    return typeof error === 'undefined' || error === ESC || (error && error.message === ESC);
  }

  /**
   * Dismisses all open dialogs
   */
  dismissAll() {
    this.ngbModal.dismissAll();
    this.$uibModalStack.dismissAll();
  }
}
