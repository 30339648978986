import { environment } from '@env/environment';
import angular from 'angular';

import mockData from './mock-data';
import mockDelay from './mock-delay';
import ngMockE2E from 'angular-mocks/ngMockE2E';
// const mockDelay = require('./mock-delay').default;
// const ngMockE2E = require('angular-mocks/ngMockE2E');

import mockFuzzData from './fuzz/mock-fuzz-data';
import mockXSS from './mock-xss.interceptor';

const deps = [];

if (environment.fuzzAjax) {
  // this must be loaded before `mockData`
  // const mockFuzzData = require('./fuzz/mock-fuzz-data').default;
  deps.push(mockFuzzData);
}

deps.push(ngMockE2E, mockData, mockDelay);

if (environment.fuzzAjax) {
  // TODO typescript?
  // const mockXSS = require('./mock-xss.interceptor').default;
  deps.push(mockXSS);
}

export default angular.module('dpaas.mocks', deps);
