<div class="key-rotation details-panel">

    <div *ngIf="canEdit" class="uib-tab-heading details-panel-section"
         [class.border-bottom]="enabled || updating || !hasRotationPolicies()">
        <div class="form-check-inline">
            <label for="enable-rotation" class="mb-0">
                <input type="checkbox" class="toggle-rotation align-middle" (click)="togglePolicy()"
                       id="enable-rotation"
                       [disabled]="updating || disabled"
                       [checked]="enabled"
                       name="enable-rotation"/>Enable automatic key rotation</label>
        </div>
    </div>

    <div *ngIf="!disabled">
        <div *ngIf="enabled && !updating" class="details-panel-section">

            <alert-with-icon *ngIf="showRotationDateText()" class="alert-custom-info rotation-date-text mb-4" [icon]="'#info-round'">
                <div [innerHTML]="getRotationDateText()"></div>
            </alert-with-icon>

            <div *ngIf="rotationWillHappen()[0] && editing">
                <alert-with-icon class="rotation-imminent-warning" [icon]="'#bang-triangle'"
                                 [class]="'alert-custom-warning'">
                    <strong>Your secrets will be rotated today.</strong>
                    <span [innerHTML]="rotationWillHappen()[1]"></span>
                </alert-with-icon>
            </div>

            <div class="mb-3">
                Rotate tenant secrets after a usage period of
            </div>

            <div *ngIf="editing">

                <div class="form-inline">

                    <select name="intervalSelection"
                            [(ngModel)]="intervalSelection"
                            (ngModelChange)="updateIntervalValues($event)" class="form-control">
                        <option value="3 months">3 months</option>
                        <option value="6 months">6 months</option>
                        <option value="12 months">12 months</option>
                        <option value="24 months">24 months</option>
                    </select>

                </div>
            </div>

            <div *ngIf="!editing && getKeyRotationText() !== null">
                <strong class="current-interval"><span>{{getKeyRotationText()}}</span><span
                    [ngPlural]="getRotationIntervalValue()"><ng-template
                    ngPluralCase="1"></ng-template><ng-template ngPluralCase="other">s</ng-template></span>
                </strong>
            </div>

            <br/>

            <div *ngIf="editing">
                <button (click)="savePolicy(keyRotationFields)" name="save-schedule"
                        class="btn btn-next">Save Schedule
                </button>
                <button (click)="cancelSetKeyRotationPolicy()" name="cancel-schedule" class="btn btn-default">Cancel
                </button>
            </div>

            <div *ngIf="!editing">
                <button *ngIf="canEdit" (click)="editPolicy()" name="edit-schedule" class="btn btn-next">Edit Schedule
                </button>
            </div>

        </div>

        <div *ngIf="!enabled && !hasRotationPolicies() && !updating"
             class="details-panel-section no-rotation-policy-set">

            <div *ngIf="canEdit">
                <h5>You have not set a rotation policy.</h5>
                Enable a rotation policy above to set a schedule.
            </div>

            <div *ngIf="!canEdit">
                <h5>A rotation policy has not been set for this service.</h5>
                Only Application Owners can set a rotation schedule.
            </div>

        </div>

        <div class="text-center details-panel-section updating-block" *ngIf="updating">
            <spinner class="d-inline-block align-middle"></spinner>
            <h4 class="d-inline align-middle">Updating...</h4>
        </div>

    </div>

</div>
