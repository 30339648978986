/* eslint angular/document-service: 2*/

import _module from './_init';
import template from './salesforce-details.component.html';
import {learnMoreAboutSecretsError, redirectSalesforcePrompt} from '../salesforce.common';
import * as roles from '../../../../auth/roles.constants';
import {lastRotationErrorMessageFn, sfdcTypes} from '../salesforce.constants';

class SalesforceDetailsController {

  constructor($sce, $location, $filter, SalesforceService, DialogService, RequiresRoleService, ConfigToken, AuthService) {

    this.$sce = $sce;
    this.$location = $location;
    this.$filter = $filter;
    this.salesforceService = SalesforceService;
    this.dialogService = DialogService;
    this.config = ConfigToken;
    this.authService = AuthService;

    this.secrets = [];
    this.serviceConfig = {};
    this.rotationPoliciesList = [];
    this.service = {};

    this.credentials = [];

    // set this true and set to false after tenant secrets are returned
    // reason is because retrieving tenant secrets is slow and once retrieved pushes a date to
    // the rotation policies that potentially changes the UI of the key-rotation component
    // hides all rotation policies
    this.updatingKeyRotationPolicies = true;
    this.updatingTable = false;
    this.hasSynced = false;

    // the following if set to true are never returned to false
    this.deniedAccess = false;
    this.disabledRotationPolicies = false;
    this.canGenerateSecrets = false;
    this.hasSecrets = false;

    this.isAppOwner = RequiresRoleService.hasRole(roles.owner);
    this.permissions = ['Create, regenerate & delete service clients'];


    // UI form filters
    this.typeFilter = null;
    this.stateFilter = null;

    this.learnMoreAboutSecretsError = learnMoreAboutSecretsError.bind(this);

    this.sfdcTypes = sfdcTypes;

    this.rotatationPoliciesBindings = [];

    this.rotatationPoliciesBindings = Object.keys(sfdcTypes).map(item => ({
      secretType: item,
      updating: false,
    }));
  }

  $onInit() {

    const {code, error, errorDescription} = this.$location.search(); // get error query params if they exist // replace prevents appending new record to history

    if (code) { // with a code provided, we attempt to update the config
      this.patchConfig(this.service.name, code)
        .then(() => this.getConfig(this.service.name), () => this.hasSynced = true);
    } else if (error) {
      this.getConfig(this.service.name);
      this.errorAuthorizing({error, errorDescription});
      this.deniedAccess = true;
    } else {
      this.getConfig(this.service.name);
    }

    this.redirectPrompt = redirectSalesforcePrompt.bind(this, {
      hostName: 'Salesforce',
      goToText: 'Go to Salesforce',
      redirectTitle: 'Redirecting to Salesforce',
    }, {
      id: this.service.service_id,
      serviceType: 'salesforce_key_broker', // not necessarily needed but we'll use it anyways
      type: 'service',
    });

    this.$location.search({}).replace(); // sets the query string, replace the state
  }

  /**
     * Return boolean depending on if service is in the same subscriber group as the user
     * @return {boolean}
     */
  isSameSubscriberGroup() {
    if (this.service) {
      return this.authService.hasSubscriberGroupId(this.service.subscriberGroup);
    }
    return false;
  }

  /**
     * if the user has reauthorized and a code is provided, we attempt to update the config with the new owners credentials
     * this will fail if the user is not part of the same Salesforce Organization
     * @param {string} name         name of the config
     * @param {string} accessCode   accessCode returned by Salesforce
     * @returns {Promise}
     */
  patchConfig(name, accessCode) {
    return this.salesforceService.updateConfigs(name, {accessCode})
      .catch(error => this.dialogService.error(error));
  }

  getConfig(name) {
    return this.salesforceService.getConfigs(name)
      .then(config => {
        this.serviceConfig = config.data;
        this.resync();
      }, error => {
        this.dialogService.error(error);
        this.hasSynced = true; // if getting the config has failed, the tenant secrets will fail as well.  The spinner will stop being displayed
        this.disabledRotationPolicies = true; // error at this point, we just disable the `key-rotation` component
      });
  }

  /**
     * Occurs when redirecting back from Salesforce, trying to reauthorize the user and the user has declined
     * @param {Object} error    is an object returned from Salesforce, contains `error` and `errorDescription`
     */
  errorAuthorizing(error) {
    error.error = 'Cannot Authorize Access';
    error.errorDescription = ['Access to this Salesforce account could not be reauthorized.', 'Please contact your Salesforce administrator for assistance.'];
    this.dialogService.error(error);
  }

  showNoSecretsPane() {
    return this.hasSynced && !this.hasSecrets && !this.updatingTable;
  }

  // if false, the table is not rendered
  // this is userful when filter changes or an action has been made on the secrets table
  renderSecretsTable() {
    return this.hasSecrets && this.hasSynced;
  }

  resync(params = {}) {
    // if it's an app owner, and they haven't "refreshed", we set refresh=true
    // if they are an admin, or they have made an API call as app owner,
    // possible side effect: sending refresh=false does not retrieve any new secrets that may have been generated on the Salesforce side while this component has been loaded
    const secretsParams = Object.assign({
      configId: this.serviceConfig.id,
      refresh: this.isAppOwner && !this.hasSecrets,
      state: this.stateFilter,
      sfdcType: this.typeFilter,
    }, params);

    this.hasSynced = false;

    return this.salesforceService.resync(secretsParams, 'resources').then(secrets => {
      this.setSecrets(secrets);
      this.canGenerateSecrets = true;
    }, error => {

      if (error.data.code === 14) { // the system does not have access to retrieve tenant secrets from Salesforce
        secretsParams.refresh = false; // we can retrieve the secrets this way for display purposes
        this.deniedAccess = true;
        return this.salesforceService.resync(secretsParams, 'resources').then(secrets => this.setSecrets(secrets));
      }

      return this.dialogService.error(error);
    }).finally(() => {
      this.hasSynced = true;
      this.listKeyRotationPolicies();
    });
  }

  setSecrets(secrets) {
    if (!this.hasSecrets) { // once a secret is generated, we don't want to show the notice that they don't have any secrets at all
      this.hasSecrets = secrets.length > 0;
    }
    this.secrets = secrets;
  }

  promptGenerateSecret() {
    return this.confirmGenerateMultipleSecrets()
      .then(secrets => this.startGeneratingSecrets(secrets));

  }

  promptGenerateSecretIndividual(configId, secretType) {
    return this.confirmGenerateSecret()
      .then(() => {
        this.updatingTable = true;
        this.updatingKeyRotationPolicies = true;
        return this.salesforceService.save(configId, secretType)
        // after successfully generating a secret we want to resync and retrieve rotation policies
        // to clear any/all rotation policy `lastError`s that may have been displayed
          .then(() => this.resync())
          .finally(() => {
            this.updatingKeyRotationPolicies = false;
            this.updatingTable = false;
          })
          .catch(error => this.dialogService.error(error));
      });
  }

  /**
     * sets up multiple promises depending on "secrets", when all have resolved, determine if error has occurred and open error dialog
     * @param {string[]} secrets    secrets to generate ex. ["Data", "Analytics"]
     */
  // TODO: Rewrite to reduce complexity
  startGeneratingSecrets(secrets) {
    const secretsStatusList = [];
    const getSecretIndexByName = name => secretsStatusList.findIndex(item => item.name === name);

    if (secrets.length) {
      this.updatingTable = true;
      this.updatingKeyRotationPolicies = true;
    }

    secrets.forEach(secret => {

      secretsStatusList.push({
        name: secret,
        status: {
          message: 'pending',
        },
      });

      // push a promise for each secret we need to generate
      this.generateSecret(this.serviceConfig.id, secret)
        .then(() => secretsStatusList[getSecretIndexByName(secret)].status.message = 'success')
        .catch(error => secretsStatusList[getSecretIndexByName(secret)].status = error)
        .finally(() => this.assessSecretsStatusList(secretsStatusList));
    });
  }

  assessSecretsStatusList(secretsStatusList) {
    let secretGenerationFailed = false;
    let hasFinished = true;
    for (const secretGeneration of secretsStatusList) {
      if (secretGeneration.status.message !== 'success') {
        if (secretGeneration.status.message === 'pending') {
          hasFinished = false;
        } else {
          secretGenerationFailed = true;
        }
      }
    }

    if (hasFinished) {
      if (secretGenerationFailed) { // once finished and failure has occurred, open dialog error
        this.learnMoreAboutSecretsError(secretsStatusList);
      }
      this.updatingKeyRotationPolicies = false;
      this.updatingTable = false;
      this.resync();
    }
  }

  // todo can be removed when FeatureToggle `keybrokerMultipleSecretTypes` is removed
  confirmGenerateSecret() {
    return this.dialogService.confirm('Generate Secret',
      this.$sce.trustAsHtml(`<p>This will replace the currently active Tenant Secret and immediately upload it to Salesforce</p>
            Do you wish to continue?`),
      'Generate', 'Cancel');
  }

  confirmGenerateMultipleSecrets() {
    return this.dialogService.openAJS('generateMultipleSecret');
  }

  /**
     * Generates a tenant secret
     * @param {string} configID
     * @param {string} sfdcType     ex. Analytics, Data, SearchIndex
     * @return Promise
     */
  generateSecret(configID, sfdcType) {
    return this.salesforceService.save(configID, sfdcType);
  }

  canAlterSecrets() {
    return this.deniedAccess === false && this.isSameSubscriberGroup();
  }


  revoke(tenantSecret) {
    this.confirmRevokeSecret().then(() => {
      this.updatingTable = true;
      return this.salesforceService.revokeSecret(tenantSecret)
        .then(() => this.resync())
        .catch(error => this.dialogService.error(error));
    }).finally(() => this.updatingTable = false);
  }

  confirmRevokeSecret() {
    return this.dialogService.confirm({
      title: 'Revoke Tenant Secret?',
      content: 'This will destroy the Tenant Secret on Salesforce. Data Protection on Demand will keep a copy of the destroyed secret.',
      yesLabel: 'Revoke Secret',
      noLabel: 'Cancel',
    });
  }

  restore(tenantSecret) {
    this.updatingTable = true;
    return this.salesforceService.restoreSecret(tenantSecret)
      .then(() => this.resync())
      .finally(() => this.updatingTable = false);
  }

  getKeyRotationPolicyByName(rotationPolicy) {
    return this.rotationPoliciesList.find(policy => policy.secretType === rotationPolicy);
  }

  listKeyRotationPolicies() {
    const configID = this.serviceConfig.id;
    return this.salesforceService.listKeyRotationPolicies(configID)
      .then(rotationPolicies => {
        this.rotationPoliciesList = rotationPolicies.data.resources;
        this.updatingKeyRotationPolicies = false;
      });
  }

  errorKeyRotationPolicy(keyRotationPolicy) {
    const {lastSecretDate, secretType, lastSecretID} = keyRotationPolicy;
    const formattedName = sfdcTypes[secretType];
    if (lastSecretDate !== null) {
      const dateFormat = this.$filter('date')(lastSecretDate, 'longDate');
      const timeFormat = this.$filter('date')(lastSecretDate, 'HH:mm');

      let dateString = '';

      // backend sends this when you create a rotation policy for a secret type that you do not have access to.
      // do not change this for lastSecretDate as it will return `0001-01-01T00:00:00Z` if lastSecretID is not set
      if (lastSecretID) {
        dateString = ` on <strong>${dateFormat}</strong> at <strong>${timeFormat}</strong>`;
      }

      return `An error occurred, and your ${formattedName} secrets were not rotated${dateString}. `;
    }
    return null;
  }

  loadRotationErrorMessage(lastError) {
    const errorMessageFormat = {
      data: {
        error: 'Tenant Secret Rotation Error',
        message: lastRotationErrorMessageFn(lastError),
      },
    };
    this.dialogService.error(errorMessageFormat);
  }

  setKeyRotationPolicy(keyRotationPolicy) {
    // determine if creating or updating an existing policy
    keyRotationPolicy.configID = this.serviceConfig.id;

    this.setKeyRotationUpdating(keyRotationPolicy.secretType, true);

    if (keyRotationPolicy.id !== null) {
      this.updateKeyRotationPolicy(keyRotationPolicy);
    } else {
      this.createKeyRotationPolicy(keyRotationPolicy);
    }
  }

  /**
     * Sets individual Rotation Policy to updating mode
     * @param {string}  secretType
     * @param {boolean} isUpdating
     */
  setKeyRotationUpdating(secretType, isUpdating) {
    const policy = this.rotatationPoliciesBindings.find(item => item.secretType === secretType);
    policy.updating = isUpdating;
  }

  createKeyRotationPolicy(keyRotationPolicy) {
    return this.salesforceService.createKeyRotationPolicy(keyRotationPolicy)
      .then(createdPolicy => this.rotationPoliciesList.push(createdPolicy.data))
      .finally(() => this.setKeyRotationUpdating(keyRotationPolicy.secretType, false));
  }

  updateKeyRotationPolicy(keyRotationPolicy) {
    const {active, id, intervalType, intervalValue} = keyRotationPolicy; // the values we care about when updating a policy
    return this.salesforceService.updateKeyRotationPolicy({
      active,
      id,
      intervalType,
      intervalValue,
    }).then(updatedPolicy => {
      const updatedPolicyId = updatedPolicy.data.id;
      const index = this.rotationPoliciesList.findIndex(policy => updatedPolicyId === policy.id);
      this.rotationPoliciesList[index] = updatedPolicy.data;
    }).finally(() => this.setKeyRotationUpdating(keyRotationPolicy.secretType, false));
  }

  // key rotation can use this to display a supposed next rotation date when a key rotation has never been made
  getLastSecretDate() {
    if (this.secrets.length) {
      return this.secrets[0].sfdcCreatedDate;
    }
    return null;
  }

}

_module.component('salesforceKeyBrokerDetails', {
  template,
  bindings: {
    service: '<',
  },
  controller: SalesforceDetailsController,
});
