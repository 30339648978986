import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthScopes, State } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { ServiceAgreementDetails, Tenant, TenantAccountStatus, TenantAccountType } from '../tenant.model';
import { TenantsService } from '../tenants.service';
import cloneDeep from 'lodash/cloneDeep';
import { RequiresRoleService } from '@app/shared/services';
import * as Roles from '../../auth/roles.constants';
import { BackofficeService } from '@app/shared/services/backoffice.service';
import { DeleteTenantModalComponent } from '../delete-tenant-modal/delete-tenant-modal.component';
import { AuthService } from '@app/features/auth';

/**
 * @returns Tenant skeleton that can be used for initial render pass, before the real data is loaded
 */
function emptyTenant() {
  return {
    admin: {},
    billingAddress: {},
  } as any as Tenant;
}


@Component({
  selector: 'tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.scss'],
})
export class TenantDetailsComponent implements OnInit, OnDestroy {

  /* This component owns the Tenant object. We hand out clones of it to child components that
   * they can mutate freely. When a child wishes to save or cancel changes to the tenant, it
   * notifies us via binding and we do the actual work.
   */
  pristineTenant: Tenant = emptyTenant(); // original, unmodified Tenant
  mutableTenant: Tenant = emptyTenant(); // mutable Tenant passed down to children
  tenantSubscription: any = null;

  saved = false;

  tenantStatus: TenantAccountStatus;
  serviceAgreement: ServiceAgreementDetails;

  constructor(
    @Inject(State) private $state: any, // TODO ui router2 service instead?
    private api: TenantsService,
    private dialogService: DialogService,
    @Inject('tenant_id') public tenantId: string,
    private requiresRoleService: RequiresRoleService,
    private backofficeService: BackofficeService,
    private authService: AuthService,
    @Inject(AuthScopes) private scopes: any,
  ) { }

  ngOnInit() {
    if (!this.requiresRoleService.hasRole(Roles.operator)) {
      if (this.backofficeService.accountStatusMap) {
        const accountStatusMap = this.backofficeService.accountStatusMap.get(this.tenantId);

        if (accountStatusMap) {
          this.tenantStatus = accountStatusMap;
        }
      }

      this.getServiceAgreement();
    }

    this.tenantSubscription = this.api.subscribeTo(this.tenantId, tenant => {
      if (tenant) {
        this.pristineTenant = tenant;
        this.mutableTenant = cloneDeep(tenant);
      } else {
        this.$state.go('^');
      }
    }, true);
  }

  /**
   * Gets a service agreement
   * Expects calling function to have checked if Purple Packet enabled
   */
  getServiceAgreement() {
    if (!this.serviceAgreement) {
      this.backofficeService.getServiceAgreement(this.tenantId)
        .then(serviceAgreement => this.serviceAgreement = serviceAgreement)
        .catch(err => {
          if (err.status === 404) {
            // eval tenant has not submitted a service agreement
            this.serviceAgreement = null; // setting to null tells child component one has not been submitted
          }
          // otherwise do nothing because service provider tenant details would throw errors
        });
    }
  }

  ngOnDestroy() {
    if (this.tenantSubscription) {
      this.tenantSubscription.unsubscribe();
    }
  }

  save(modifiedTenant: Tenant) {
    const progress = this.dialogService.progress('Saving Tenant...');
    this.saved = false;
    this.api.save(modifiedTenant).then(() => {
      const updatedTenant = this.api.get(modifiedTenant.id);
      this.saved = true;
      this.pristineTenant = updatedTenant;
      this.mutableTenant = cloneDeep(updatedTenant);
    })
      .finally(() => progress.close())
      .catch(error => this.dialogService.error(error));
  }

  // Called when a child component is cancelling its edits to the Tenant
  cancel() {
    // Send them a new, unmodified Tenant to render.
    this.saved = false;
    this.mutableTenant = cloneDeep(this.pristineTenant);
  }

  // TODO replace with simple link <a ui-sref="^.list"> after we upgrade router
  clickTenantName(e: MouseEvent) {
    e.stopPropagation();
    this.$state.go('^.list');
  }

  confirmDelete() {
    const modalRef = this.dialogService.open<DeleteTenantModalComponent>(DeleteTenantModalComponent);
    modalRef.componentInstance.tenant = cloneDeep(this.pristineTenant);
    modalRef.result.then(() => this.deleteTenant(this.pristineTenant.id));
  }

  deleteTenant(id) {
    const progress = this.dialogService.progress('Deleting tenant...');
    return this.api.delete(id)
      .then(() => this.$state.go('^'))
      .finally(progress.close);
  }

  isTenantSubscriptionsAvailable(tenant: Tenant): boolean {
    return (this.authService.hasScope(this.scopes.spadmin) || this.authService.hasScope(this.scopes.operator)) &&
      tenant.accountType !== TenantAccountType.serviceProvider;
  }

}
