<form [formGroup]="auditLogsFilterForm" (ngSubmit)="submitForm($event)">
  <div class="details-panel main-panel mt-4">
    <div class="details-panel-section">
      <div class="service-name-block">
        <label for="logsService" class="control-label">Service Name</label>
        <select name="logsService" formControlName="logsService" class="form-control" [title]="auditLogsFilterForm?.value.logsService.name">
          <option *ngFor="let service of services" [ngValue]="service" [title]="service.name">{{service.name}}</option>
        </select>
      </div>
      <div class="date-range-block">
        <label for="logsDateRange" class="control-label">Date Range (UTC)</label>
        <date-range-picker
          [maxDate]="maxDate"
          [minDate]="minDate"
          [dateLimit]="dateLimit"
          name="logsDateRange"
          formControlName="logsDateRange"
        />
        <div *ngIf="auditLogsFilterForm.controls?.logsDateRange?.dirty &&
                    auditLogsFilterForm.controls?.logsDateRange?.errors"
              class="text-danger error-message"
              data-locator="date-range-error" role="alert">
          <ng-container *ngIf="auditLogsFilterForm.controls?.logsDateRange?.errors.invalidDateRange">{{dateRangePickerLabels.invalidDateRange}}</ng-container>
          <ng-container *ngIf="auditLogsFilterForm.controls?.logsDateRange?.errors.invalidStartDate">{{dateRangePickerLabels.invalidStartDate}}</ng-container>
          <ng-container *ngIf="auditLogsFilterForm.controls?.logsDateRange?.errors.invalidEndDate">{{dateRangePickerLabels.invalidEndDate}}</ng-container>
          <ng-container *ngIf="auditLogsFilterForm.controls?.logsDateRange?.errors.invalidDuration">{{invalidDurationMessage}}</ng-container>
        </div>
      </div>
      <button class="btn btn-next form-control" data-locator="display-log" name="displayLog" type="button" (click)="onDisplayLogClick()">
        Display Log
      </button>
      <button class="btn btn-next form-control" data-locator="download-log" name="downloadLog" type="button" (click)="onDownloadLogClick()">
        Download Log
      </button>
    </div>
  </div>
</form>
