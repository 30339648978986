<form #stepForm="ngForm">
    <div class="card col">
        <div class="card-block terms-of-service">
            <p class="tos-header">{{getWizardTitle()}}</p>
            <p>{{getTosInfo()}}</p>
            <p *ngIf="hasNoSubscriptionOrActiveTrialSubscription()"
               class="card-text" [innerHtml]="termsOfService | bypassSecurityTrustHtml"></p>
            <p *ngIf="serviceSpecificTerms" class="card-text" [innerHtml]="serviceSpecificTerms | bypassSecurityTrustHtml"></p>
        </div>
    </div>
</form>
