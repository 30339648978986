import { Component, Inject } from '@angular/core';
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { AuthService } from '../auth';
import { ReportType } from '@app/features/reports/monthly-report.component';

@Component({
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.scss'],
})
export class ReportsComponent {

  constructor(@Inject(AuthScopes) private authScopes: any,
              private authService: AuthService) {
  }

  get ReportType() {
    return ReportType;
  }

  // below are sections and reports labelled in breadcrumb style fashion
  // ex. `show` `Section` `Summary` `Reports`

  // the following methods are for sections of reports
  showSectionSummaryReports(): boolean {
    const {operator, spadmin} = this.authScopes;
    return this.authService.hasAnyScope(operator, spadmin);
  }

  showSectionMonthlyReports(): boolean {
    return this.showReportMonthlyService() || this.showReportMonthlyServiceUsage();
  }

  // the following methods are for reports of a section

  /**
   * This method calls the getUsageBillingReport/usageBillingReport endpoint
   */
  showReportMonthlyService(): boolean {
    const {operator, spadmin} = this.authScopes;
    return this.authService.hasAnyScope(operator, spadmin);
  }

  showReportMonthlyServiceUsage(): boolean {
    const {admin} = this.authScopes;
    return this.authService.hasAnyScope(admin);
  }

}
