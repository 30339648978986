import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from '@app/components';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TenantsService } from '../../tenant/tenants.service';
import { FileError, ImgUploadComponent } from '../img-upload/img-upload.component';
import { InvalidImageModalComponent } from '../img-upload/invalid-image.modal.component';

type saveState = 'none' | 'saving' | 'saved';

@Component({
  selector: 'gem-app-logo',
  templateUrl: './app-logo.component.html',
})
export class AppLogoComponent {
  @ViewChildren(ImgUploadComponent) imgUploaders: QueryList<ImgUploadComponent>;
  @ViewChild(ImgUploadComponent, { static: true }) logoUploader: ImgUploadComponent;

  initialLogo: string;
  editing = false;
  private saveState: saveState;
  private logoSub: Subscription;

  constructor(private dialogService: DialogService,
    private tenantsService: TenantsService,
    private sanitizer: DomSanitizer) {
    this.initialLogo = this.tenantsService.getLogoUrl();
  }

  edit() {
    this.editing = true;
    this.saveState = 'none';
  }

  cancel() {
    this.editing = false;
    this.imgUploaders.forEach(iu => iu.reset());
  }

  save() {
    this.saveState = 'saving';
    this.tenantsService.setLogo(this.logoUploader.file)
      .then(() => {
        this.saveState = 'saved';
      })
      .finally(() => {
        this.editing = false;
        if (this.saveState === 'saving') {
          this.saveState = 'none';
        }
      });
  }

  isSaving() {
    return this.saveState === 'saving';
  }

  isSaved() {
    return this.saveState === 'saved';
  }

  filePicked() {
    return this.imgUploaders.toArray().some(iu => !!iu.file);
  }

  showImageError(event: FileError, culprit: string) {
    let reqs: string[];
    if (culprit === 'logo') {
      reqs = ['240 × 96 pixels', 'PNG format'];
    } else if (culprit === 'icon') {
      reqs = ['16 × 16 pixels', 'ICO format'];
    }

    event.target.reset();

    const modal = this.dialogService.open<InvalidImageModalComponent>(InvalidImageModalComponent);
    const comp = modal.componentInstance;
    comp.requirements = reqs;
    comp.tryAgainClicked.pipe(first()).subscribe(() => event.target.uploadClick());
  }

}
