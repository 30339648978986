import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { DialogService } from '@app/components';
import { TenantsService } from '../tenant/tenants.service';
import { ServiceBrokerService } from '@app/shared/services';

export enum ReportType {
  Tenants = 'tenants',
  Services = 'services'
}

const allMonths = [
  {key: 0, value: 'January'},
  {key: 1, value: 'February'},
  {key: 2, value: 'March'},
  {key: 3, value: 'April'},
  {key: 4, value: 'May'},
  {key: 5, value: 'June'},
  {key: 6, value: 'July'},
  {key: 7, value: 'August'},
  {key: 8, value: 'September'},
  {key: 9, value: 'October'},
  {key: 10, value: 'November'},
  {key: 11, value: 'December'},
];

@Component({
  selector: 'monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./reports.scss'],
})
export class MonthlyReportComponent implements OnInit {
  // report type determines what endpoint we will use for generating the report
  @Input() reportType: ReportType;
  @Input() header: string;
  @Input() tagline: string;
  // by default, the endpoint will be getUsageReport but going forward we may have to have monthly reports with different
  // service methods
  @Input() serviceMethod = 'getUsageReport';

  selectedYear: number;
  selectedMonth: number;

  // Visual properties
  months: {key: number; value: string}[];
  years: number[];

  private api: any;

  private readonly currentMonth: number;
  private readonly currentYear: number;
  private readonly isFirstMonth: boolean;

  constructor(@Inject(Injector) private injector: any, private dialogService: DialogService) {
    const currentYear = this.currentYear = new Date().getFullYear();
    const currentMonth = this.currentMonth = new Date().getMonth();
    if (currentMonth === 0) {
      // Reports are only provided for previous months. Therefore the current year does not
      // appear in the dropdown during January.
      this.isFirstMonth = true;
      this.selectedYear = currentYear - 1;
      this.selectedMonth = 11;
    } else {
      this.selectedYear = currentYear;
      this.selectedMonth = currentMonth - 1;
    }
  }

  ngOnInit() {
    // Monthly report generation is provided either by the Tenant service or the Service Broker service
    // this is determined by the reportType parameter and a serviceMethod param
    if (this.reportType === ReportType.Tenants) {
      this.api = this.injector.get(TenantsService);
    } else if (this.reportType === ReportType.Services) {
      this.api = this.injector.get(ServiceBrokerService);
    } else {
      throw new Error('Unknown report type');
    }
    this.setupDropdowns();
  }

  setupDropdowns() {
    let months = allMonths;
    if (this.selectedYear === this.currentYear) {
      // only show past months in the list
      months = months.filter(m => m.key < this.currentMonth);
    }

    const startYear = 2017;
    const maxYear = this.isFirstMonth ? this.currentYear - 1 : this.currentYear;
    const years = [];
    for (let i = startYear; i <= maxYear; i++) {
      years.push(i);
    }

    this.months = months;
    this.years = years;
  }

  onYearSelect() {
    this.selectedMonth = 0;
    this.setupDropdowns();
  }

  generate(): void {
    const startDateLocal = new Date(Date.UTC(this.selectedYear, this.selectedMonth, 1, 0, 0, 0));
    const endDateLocal = new Date(Date.UTC(this.selectedYear, this.selectedMonth + 1, 1, 0, 0, 0));
    endDateLocal.setMilliseconds(endDateLocal.getMilliseconds() - 1);
    const progress = this.dialogService.progress('Generating report...');
    if (this.api[this.serviceMethod]) {
      this.api[this.serviceMethod](startDateLocal.toISOString(), endDateLocal.toISOString())
        .catch(error => this.dialogService.error(error))
        .finally(() => progress.close());
    } else {
      progress.close();
      // if the api method does not exist we need to not make a request and just throw an error
      this.dialogService.error(new Error('Could not generate report'));
    }
  }
}
