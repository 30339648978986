import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountStatusDirective } from '@app/components/account-status/account-status.directive';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { GhostItemDirective } from '@app/components/ghost-item/ghost-item.directive';
import { ConvertDatePipe } from '@app/components/convert-date/convert-date.pipe';
import { ResetPasswordDialogComponent } from '@app/components/password-reset/reset-password-dialog.component';
import { RadioButtonComponent } from '@app/components/radio-button/radio-button.component';
import { ServicesTableComponent } from '@app/components/services-table/services-table.component';
import { StepsModule } from '@app/components/steps';
import { UsersTableComponent } from '@app/components/users-table/users-table.component';
import { WizardInlineModule } from '@app/components/wizard-inline';
import { GemToastModule } from '@dpod/gem-ui-common-ng/toast';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterModule } from '@uirouter/angular';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { AlertWithIconComponent } from '../components/alert-with-icon';
import { BannerImageComponent } from '../components/banner-image';
import { BypassSecurityTrustHtmlPipe } from '../components/bypass-security-trust-html';
import { CardTileComponent } from '../components/card-tile';
import { CardPlanComponent } from '../components/card-plan';
import { ConfirmInputDirective } from '../components/confirm-input';
import { CopyInputComponent } from '../components/copy-input';
import { DownloadModalComponent } from '../components/download-modal/download-modal.component';
import { EmailComponent } from '../components/email';
import { FeatureFlagDirective } from '@app/components';
import { FeedbackComponent } from '../components/feedback';
import { FooterComponent } from '../components/footer';
import { DialogsModule } from '../components/gem-dialogs';
import { HeaderComponent } from '../components/header';
import { HelpDrawerComponent } from '../components/help-drawer/help-drawer.component';
import { MaxlenDirective } from '../components/maxlen';
import { OptionalComponent } from '../components/optional';
import { PromptRedirectComponent } from '../components/prompt-redirect';
import { ReplaceCharactersWithPipe } from '../components/replace-characters-with';
import { RequiresRoleDirective } from '../components/requires-role';
import { ServiceCardComponent } from '../components/service-card/service-card.component';
import { SpinnerModule } from '../components/spinner';
import { ToggleableSwitchComponent } from '../components/toggleable-switch';
import { ValidationIconComponent } from '../components/validation-icon';
import { WizardModule } from '../components/wizard-ng2';
import { ResetMfaDialogComponent } from './components/reset-mfa-dialog/reset-mfa-dialog.component';
import { TilesConfigureViewComponent } from './components/tiles/tiles-view/tiles-configure-view.component';
import { TilesCreateViewComponent } from './components/tiles/tiles-view/tiles-create-view.component';
import { ExternalLinkDirective } from './directives/external-link.directive';
import { DocLinkPipe } from './pipes/doc-link.pipe';
import { DocLinkByIdPipe } from '@app/shared/pipes/doc-link-by-id.pipe';
import { BreadcrumbComponent } from '../components/breadcrumb';
import { PurchaseServiceModalComponent } from '@app/components/service-purchase/purchase-service.modal.component';
import { DateFormatPipe } from '@app/shared/pipes/date-format.pipe';
import { TermIso8601Pipe } from '@app/shared/pipes/term-iso8601.pipe';
import { SubscriptionsTableComponent } from '@app/components/subscriptions-table/subscriptions-table.component';
import { UnderscoreToSpacePipe } from '@app/shared/pipes/underscore-to-space.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { DashIfEmptyPipe } from '@app/shared/pipes/dash-if-empty.pipe';
import { JsonFormatPipe } from '@app/shared/pipes/json-format.pipe';
import { IseErrorMessagesComponent } from '@app/components/gem-partials/ise-messages/ise-error-messages.component';
import { CustomTooltipDirective } from '@app/components/custom-tooltip/custom-tooltip.directive';
import { CustomTooltipComponent } from '@app/components/custom-tooltip/custom-tooltip.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { MfeWrapperComponent } from '@app/mfe/mfe-wrapper.component';

/*
 * The shared module declares all our reuseable UI components and exports frequently-used
 * modules like Common and Forms.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DialogsModule,
    NgbModule,
    SpinnerModule,
    ClipboardModule,
    MatDividerModule,
    WizardModule,
    StepsModule,
    WizardInlineModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    GemToastModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    UIRouterModule,
  ],
  declarations: [
    AccountStatusDirective,
    AlertWithIconComponent,
    BannerImageComponent,
    CardTileComponent,
    CardPlanComponent,
    CopyInputComponent,
    FeedbackComponent,
    ConfirmInputDirective,
    DownloadModalComponent,
    ExternalLinkDirective,
    FooterComponent,
    HeaderComponent,
    HelpDrawerComponent,
    OptionalComponent,
    MaxlenDirective,
    EmailComponent,
    RequiresRoleDirective,
    FeatureFlagDirective,
    ValidationIconComponent,
    ServiceCardComponent,
    BypassSecurityTrustHtmlPipe,
    TilesConfigureViewComponent,
    TilesCreateViewComponent,
    ToggleableSwitchComponent,
    ReplaceCharactersWithPipe,
    PromptRedirectComponent,
    ResetMfaDialogComponent,
    RadioButtonComponent,
    ConvertDatePipe,
    GhostItemDirective,
    DpodTableComponent,
    UsersTableComponent,
    ServicesTableComponent,
    SubscriptionsTableComponent,
    PurchaseServiceModalComponent,
    DocLinkPipe,
    DocLinkByIdPipe,
    ResetPasswordDialogComponent,
    BreadcrumbComponent,
    DateFormatPipe,
    TermIso8601Pipe,
    UnderscoreToSpacePipe,
    DashIfEmptyPipe,
    JsonFormatPipe,
    IseErrorMessagesComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    InputTrimDirective,
    MfeWrapperComponent
  ],
  exports: [
    AccountStatusDirective,
    AlertWithIconComponent,
    CommonModule,
    CardTileComponent,
    CardPlanComponent,
    CopyInputComponent,
    FeedbackComponent,
    ConfirmInputDirective,
    DialogsModule,
    ExternalLinkDirective,
    FormsModule,
    WizardModule,
    FooterComponent,
    HeaderComponent,
    HelpDrawerComponent,
    NgbModule,
    OptionalComponent,
    MaxlenDirective,
    EmailComponent,
    RequiresRoleDirective,
    FeatureFlagDirective,
    SpinnerModule,
    ValidationIconComponent,
    ServiceCardComponent,
    BypassSecurityTrustHtmlPipe,
    ClipboardModule,
    TilesConfigureViewComponent,
    TilesCreateViewComponent,
    ToggleableSwitchComponent,
    ReplaceCharactersWithPipe,
    PromptRedirectComponent,
    WizardInlineModule,
    RadioButtonComponent,
    ConvertDatePipe,
    GhostItemDirective,
    DpodTableComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    UsersTableComponent,
    ServicesTableComponent,
    SubscriptionsTableComponent,
    DocLinkPipe,
    DocLinkByIdPipe,
    ResetPasswordDialogComponent,
    BreadcrumbComponent,
    DateFormatPipe,
    TermIso8601Pipe,
    UnderscoreToSpacePipe,
    DashIfEmptyPipe,
    JsonFormatPipe,
    IseErrorMessagesComponent,
    CustomTooltipDirective,
    InputTrimDirective,
    MfeWrapperComponent
  ]
})
export class SharedModule {
}
