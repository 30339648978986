import { Component, forwardRef, Input } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';

@Component({
  selector: 'ciphertrust-summary',
  templateUrl: './ciphertrust-summary.component.html',
  styleUrls: ['./ciphertrust-summary.component.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => SummaryComponent)}
  ]
})
export class SummaryComponent extends WizardStepBaseComponent {

  @Input() serviceName: string;
  @Input() cloudProvider;
  @Input() cloudRegion;
  @Input() userName: string;
  @Input() adminPassword: string;

}
