import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { StepsModule } from '@app/components/steps';

import { WizardComponent } from './wizard.component';
import { WizardStepComponent } from './wizard-step.component';
import { SpinnerModule } from '@app/components';

// public API
export * from './wizard.component';
export * from './wizard-step.component';
export * from './wizard-step';

@NgModule({
  declarations: [WizardComponent, WizardStepComponent],
  imports: [CommonModule, FormsModule, StepsModule, SpinnerModule],
  exports: [WizardComponent, WizardStepComponent],
})
export class WizardModule {}
