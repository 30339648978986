import ServiceBase from '@app/components/service/service-base';
import {DateTimeHelperService} from '@app/shared/services/date-time-helper.service';
import {sfdcTypes} from './salesforce.constants';

const sfdcSources = {
  'HSM': 'HSM Generated',
  'USER': 'Gemalto HSM',
};

// todo to be replaced by keybroker service
export default class SalesforceService extends ServiceBase {
  dateTimeHelper;

  constructor(haro, $http) {
    'ngInject';
    super(haro, '/keybroker/salesforce-keybroker');
    this.$http = $http;
    this.dateTimeHelper = new DateTimeHelperService();
  }

  annotate(tenantSecret) {
    tenantSecret.formattedCreatedAt = this.dateTimeHelper.formatDate(tenantSecret.sfdcCreatedDate, 'DD-MMM-YYYY HH:mm');
    tenantSecret.formattedModifiedAt = this.dateTimeHelper.formatDate(tenantSecret.sfdcModifiedDate, 'DD-MMM-YYYY HH:mm');

    // if the sfdcType does not exist, we use the default Salesforce value instead
    tenantSecret.formattedSfdcType = sfdcTypes[tenantSecret.sfdcType] || tenantSecret.sfdcType;
    tenantSecret.formattedSfdcSource = sfdcTypes[tenantSecret.sfdcSource];
    return tenantSecret;
  }

  /**
     * Resync tenantSecrets
     * @param params    the resync that we do requires a configId, this gets tenant-secrets and not a list of configs
     * @returns {*}
     */
  doResync(params) {
    return this.listSecrets(params.configId, params.refresh, params.sfdcType, params.state);
  }

  /**
     * Configs
     */
  listConfigs() { // todo remove limit=-1 when pagination in the UI exists
    return this.$http.get(`${this.baseUrl}/configs?limit=-1`);
  }

  /**
     * @param {Object} config   { name, username (optional), tenantInstance (optional) }
     */
  createConfigs(config) {
    return this.$http.post(`${this.baseUrl}/configs`, config);
  }

  /**
     * Returns a config
     * @param {string} id   Note: will accept the `id` OR `name`
     * @returns {SalesforceServiceConfiguration}
     */
  getConfigs(id) {
    const encodedID = encodeURIComponent(id); // the UI only uses this by `name`, we need to encode the "id" as it may have special characters in it
    return this.$http.get(`${this.baseUrl}/configs/${encodedID}`);
  }

  /**
     * Updates an existing config
     * @param {string} id       id of the resource to update
     * @param {object} config   { accessCode }
     */
  updateConfigs(id, config) {
    return this.$http.patch(`${this.baseUrl}/configs/${id}`, config);
  }

  deleteConfigs(id) {
    return this.$http.delete(`${this.baseUrl}/configs/${id}`);
  }

  /**
     * Tenant Secrets
     */

  /**
     * @param {string}       config      the config id of which to retrieve tenant secrets
     * @param {boolean}      refresh     setting this to true will fetch missing keys from Salesforce
     * @param {string|null}  sfdcType    what tenant secret type to filter on (ex. Data, Search Index, Analytics)
     * @param {string|null}  state       what state to filter on (active, archived, revoked, destroyed)
     */
  listSecrets(config, refresh = false, sfdcType = null, state = null) {

    if (sfdcType === '') {
      sfdcType = null;
    }

    if (state === '') {
      state = null;
    }

    return this.$http({
      url: `${this.baseUrl}/tenant-secrets`,
      params: {
        config,
        limit: -1, // todo remove limit=-1 when pagination in the UI exists
        refresh,
        sfdcType,
        sort: '-sfdcSecretVersion',
        state,
      },
    });
  }

  /**
     * Revokes a tenant secret
     * @param {object} entity   tenant secret
     * @returns {object}        returns the updated tenant secret
     */
  revokeSecret(entity) {
    return this.$http.post(`${this.baseUrl}/tenant-secrets/${entity.id}/revoke`).then(response => this.store.set(response.data.id, response.data));
  }

  /**
     * Restores a tenant secret
     * @param {object} entity   tenant secret
     * @returns {object}        returns the updated tenant secret
     */
  restoreSecret(entity) {
    return this.$http.post(`${this.baseUrl}/tenant-secrets/${entity.id}/restore`).then(response => this.store.set(response.data.id, response.data));
  }

  /**
     * Creates a new tenant secret
     * @param {string} configId
     * @param {string} sfdcType
     * @returns {*}
     */
  doSave(configId, sfdcType) {
    return this.$http.post(`${this.baseUrl}/tenant-secrets`, { configId, sfdcType });
  }

  /**
     * Returns the tenant-secret resource
     * @param {object} entity   tenant secret
     */
  doFetch(entity) {
    return this.$http.get(`${this.baseUrl}/tenant-secrets/${entity.id}`);
  }

  /**
     *  Returns info about the Connected App
     */
  getInfo() {
    return this.$http.get(`${this.baseUrl}/info`);
  }

  listKeyRotationPolicies(config) {
    return this.$http.get(`${this.baseUrl}/rotation-policies?config=${config}`);
  }

  createKeyRotationPolicy(rotationObject) {
    return this.$http.post(`${this.baseUrl}/rotation-policies`, rotationObject);
  }

  updateKeyRotationPolicy(rotationObject) {
    return this.$http.patch(`${this.baseUrl}/rotation-policies/${rotationObject.id}`, rotationObject);
  }

}
