<div class="page panel-width">
    <section>
        <div class="gem-header section-header">Credentials</div>
        <header class="gem-header sub-header">Create and manage credentials to help automate processes via APIs.</header>
        <p>
            <span class="no-resource-prompt">{{resourcePrompt}}</span>
        </p>

        <credentials-table
            [editable]="true"
            [tabledata]="platformCredentials"
            [type]="CredentialType.Platform">

            <button *requiresRole="'dpod.tenant.appowner,dpod.tenant.spadmin'" (click)="createPlatformCredentials()"
                class="btn btn-next float-end me-4" data-locator="create-credentials">
                Create Platform Credentials
            </button>
        </credentials-table>

    </section>
</div>
