<wizard [title]="'Add Salesforce Key Broker'" [(currentStepIdx)]="step"
        [lastSlideClose]="true">

    <terms-of-service-step name="Service Information" instructions="Service Information"></terms-of-service-step>

    <configure-step name="Configure your Service" nextButtonText="Next"
                    [service]="service"
                    [secretsStatusList]="secretsStatusList"
                    instructions="Give your service a name."></configure-step>

    <summary-step instructions="" previousButtonText="" [secretsStatusList]="secretsStatusList" [service]="serviceConfig" name="Summary"></summary-step>

</wizard>
