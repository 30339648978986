import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DATE_FORMAT, DATE_SEPARATOR } from './date-range-picker.constants';

interface ValidatorConfig {
  maxDate: string; // a date in the ISO format
  minDate: string; // a date in the ISO format
  dateLimit: number;
}

interface ParsedDateRange {
  from: Dayjs | null;
  to: Dayjs | null;
}

function isDateRangeInvalid(selectedDateRange: ParsedDateRange) {
  if (!selectedDateRange?.from || !selectedDateRange.to) {
    return true;
  }
  if (selectedDateRange.to.isBefore(selectedDateRange.from)) {
    return true;
  }
  return false;
}

function isStartDateInvalid(selectedDateRange: ParsedDateRange, minDate: string) {
  return selectedDateRange.from.isBefore(dayjs.utc(minDate));
}

function isEndDateInvalid(selectedDateRange: ParsedDateRange, maxDate: string) {
  return selectedDateRange.to.isAfter(dayjs.utc(maxDate));
}

function isDurationInvalid(selectedDateRange: ParsedDateRange, dateLimit: number) {
  return selectedDateRange.to.diff(selectedDateRange.from, 'days') > dateLimit;
}

/**
 * Given a string, this method tries to parse it and extract start and end dates from it.
 * If it fails to do so - the method returns null. Otherwise, it returns a ParsedDateRange object.
 * The method assumes the given dates are in UTC.
 * The dayjs library is used to parse the string - the ngx-daterangepicker-bootstrap library uses it.
 * @param dateRangeText The date range picker input value
 * @returns The parsed date range - either null or ParsedDateRange
 */
export function parseDateRangeText(dateRangeText: string): ParsedDateRange | null {
  if (!dateRangeText) {
    return null;
  }
  const rangeParts = dateRangeText.split(DATE_SEPARATOR);
  if (rangeParts?.length !== 2) {
    return null;
  }
  const startDateText = rangeParts[0].trim();
  const endDateText = rangeParts[1].trim();
  if (!startDateText || !endDateText) {
    return null;
  }
  dayjs.extend(utc);
  const startDate = dayjs.utc(startDateText, DATE_FORMAT, true).millisecond(0);
  const endDate = dayjs.utc(endDateText, DATE_FORMAT, true).millisecond(999);
  if (!startDate.isValid() || !endDate.isValid()) {
    return null;
  }
  return { from: startDate, to: endDate };
}

/**
 * This method implements an Angular Validator contract.
 * It can be used to validate values of DateRangePickerComponent in an Angular reactive form.
 * @param config A config for the validator. The config is specific to the instance of DateRangePickerComponent.
 * @returns ValidatorFn that can be used to validate DateRangePickerComponent in a reactive form
 */
export function dateRangeValidator(config: ValidatorConfig): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateRange = parseDateRangeText(control.value);
    if (isDateRangeInvalid(dateRange)) {
      return { invalidDateRange: true };
    }
    if (isStartDateInvalid(dateRange, config.minDate)) {
      return { invalidStartDate: true };
    }
    if (isEndDateInvalid(dateRange, config.maxDate)) {
      return { invalidEndDate: true };
    }
    if (isDurationInvalid(dateRange, config.dateLimit)) {
      return { invalidDuration: true };
    }
    return null;
  };
}
