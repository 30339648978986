<div class="browser d-flex flex-column align-items-center">
    <div class="boxed gem-card p-4">
        <h4 class="color-primary">Sorry, this browser's not supported.</h4>
        <div class="alert alert-light-gray mt-3 mb-0">
            <p>Please log in to Data Protection on Demand using a recent version of
            one of the following browsers:</p>
            <ul class="browser-list list-style-none mt-3 mb-0 ms-0 p-0">
                <li><a href="https://www.google.com/chrome/" externalLink>Google Chrome</a></li>
                <li><a href="https://www.mozilla.org/en-US/firefox/" externalLink>Mozilla Firefox</a></li>
                <li><a href="https://www.microsoft.com/en-ca/windows/microsoft-edge" externalLink>Microsoft Edge</a></li>
                <li><a href="https://www.apple.com/ca/safari/" externalLink>Safari</a></li>
            </ul>
        </div>
    </div>
</div>
