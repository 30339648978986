import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { TermSelection } from './eval-convert.component';
import { AvailableTerm, EvalConvertTermsService } from './eval-convert-terms.service';
import { AvailableTermIds } from './eval-convert.constants';

@Component({
  selector: 'eval-convert-terms-component',
  styleUrls: ['./eval-convert.terms.scss'],
  templateUrl: './eval-convert.terms.component.html',
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => EvalConvertTermsComponent)}
  ]
})
export class EvalConvertTermsComponent extends WizardStepBaseComponent implements OnChanges {

  @Input() availableTerms: AvailableTerm[];
  @Input() termSelection: TermSelection;
  @Output() termSelectionChange: EventEmitter<TermSelection> = new EventEmitter<TermSelection>();

  private customAvailableTerm: AvailableTerm;

  constructor(private termsService: EvalConvertTermsService) {
    super();
  }

  ngOnChanges() {
    this.customAvailableTerm = this.availableTerms.find(term => term.id === AvailableTermIds.CUSTOM);
  }

  checked(id: string) {
    this.updateSelectedTerm(parseInt(id, 10));
  }

  getChecked(term: AvailableTerm): boolean {
    return this.termSelection.id === term.id;
  }

  customDurationChanged(newDuration: number) {
    this.customAvailableTerm.duration = newDuration;
    this.updateSelectedTerm(AvailableTermIds.CUSTOM);
  }

  customRequestedActionDateChanged(newRequestedActionDate: string) {
    this.customAvailableTerm.requestedActionDate = newRequestedActionDate;
    this.updateSelectedTerm(AvailableTermIds.CUSTOM);
  }

  private updateSelectedTerm(id: number) {
    this.termSelection = {
      id,
      duration: id === AvailableTermIds.CUSTOM ? this.customAvailableTerm.duration : id,
      requestedActionDate: id === AvailableTermIds.CUSTOM ? this.customAvailableTerm.requestedActionDate : this.termsService.getFirstDayOfMonth(undefined, 1),
    };
    this.termSelectionChange.emit(this.termSelection);
  }
}
