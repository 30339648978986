import { Component, OnDestroy } from '@angular/core';
import { LogsService } from './logs.service';
import { AuditLogFilters } from './logs.model';
import { DialogService, ProgressModal } from '@app/components';
import { auditLogsLabels } from './logs.constants';
import { Subscription } from 'rxjs';
import { DownloadService } from '@app/shared/services/download.service';
import { AuditLogsTableService } from './audit-logs-table/audit-logs-table.service';

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnDestroy {
  displayedLogFile: Blob;

  private progressModal: ProgressModal;
  private auditLogsSubscription: Subscription;
  private tableIsRendering = false;

  constructor(
    private logsService: LogsService,
    private dialogService: DialogService,
    private downloadService: DownloadService,
    private auditLogsTableService: AuditLogsTableService
  ) {}

  /**
   * This function is created to simplify unit testing of API error handling
   */
  handleApiError(err: any) {
    this.onFileProcessed();
    throw err;
  }

  displayLog(filters: AuditLogFilters) {
    this.auditLogsSubscription = this.loadLogFile(filters).subscribe({
      next: logFile => {
        this.tableIsRendering = true;
        this.displayedLogFile = logFile;
      },
      error: err => this.handleApiError(err)
    });
  }

  downloadLog(filters: AuditLogFilters) {
    this.auditLogsSubscription = this.loadLogFile(filters).subscribe({
      next: logFile => {
        this.downloadService.downloadFile(
          logFile,
          this.logsService.generateAuditLogFileName(filters),
          auditLogsLabels.logFileDownloadArtifact
        );
        this.onFileProcessed();
      },
      error: err => this.handleApiError(err)
    });
  }

  onFileProcessed() {
    this.tableIsRendering = false;
    this.progressModal?.close();
  }

  cancelCallback = () => {
    if (this.tableIsRendering) {
      this.auditLogsTableService.cancel();
    }
    this.onFileProcessed();
    this.auditLogsSubscription?.unsubscribe();
  };

  ngOnDestroy() {
    this.auditLogsSubscription?.unsubscribe();
  }

  private loadLogFile(filters: AuditLogFilters) {
    this.progressModal = this.dialogService.progress(
      auditLogsLabels.loadingLogsText,
      auditLogsLabels.loadingLogsSubText,
      this.cancelCallback
    );
    return this.logsService.getAuditLogs(filters);
  }

}
