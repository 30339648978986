import {NgModule} from '@angular/core';
import {SharedModule} from '@app/shared';
import {ReportsComponent} from './reports.component';
import {MonthlyReportComponent} from './monthly-report.component';
import {SummaryReportComponent} from './summary-report.component';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';

import * as AuthScopes from '../auth/roles.constants';

export const reportsState = {
  name: 'reports',
  url: '/reports',
  params: {
    requiresRole: `${AuthScopes.admin},${AuthScopes.spadmin},${AuthScopes.operator}`,
  },
  component: ReportsComponent
};

@NgModule({
  declarations: [ReportsComponent, MonthlyReportComponent, SummaryReportComponent],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: [reportsState]
    }),
  ],
  providers: [],
})
export class ReportsModule {
}
