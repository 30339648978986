import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SalesforceDetailsModule } from './details/salesforce-details.module';
import { SalesforceSecretsTableComponent } from './details/salesforce-secrets-table/salesforce-secrets-table.component';
import { GenerateMultipleSecretTypesComponent } from './wizard/generate-multiple-secret-types.component';
import './salesforce.component.scss';
import {
  SalesforceWizardComponent
} from '@app/features/gem-services/cloud/salesforce/wizard/salesforce-wizard.component';
import { SharedModule } from '@app/shared';
import { TermsOfServiceModule } from '@app/features/gem-services/cloudHSM/wizard/common/terms-of-service';
import {
  ConfigureStepSalesforceComponent
} from '@app/features/gem-services/cloud/salesforce/wizard/configure.step.component';
import {
  SummaryStepSalesforceComponent
} from '@app/features/gem-services/cloud/salesforce/wizard/summary.step.component';
import {
  KeyRotationPolicyComponent
} from '@app/features/gem-services/cloud/salesforce/details/key-rotation-policy.component';

@NgModule({
  declarations: [
    SalesforceWizardComponent,
    ConfigureStepSalesforceComponent,
    SummaryStepSalesforceComponent,
    GenerateMultipleSecretTypesComponent,
  ],
  imports: [
    SalesforceDetailsModule,
    CommonModule,
    SharedModule,
    TermsOfServiceModule,
  ],
  providers: [],
})
export class SalesforceModule {
}

// Angular1
import SalesforceErrorResponseInterceptor from './salesforce-error-response.interceptor';
import tenantSecretGenerationFailed from './dialogs/modal-tenant-secret-generation-failed.component';
import generateMultipleSecret from './dialogs/modal-generate-multiple-secret.component';
import SalesforceService from './salesforce.service';
import detailsModule from './details';
import angularHaro from '@app/components/angular-haro';

export default angular.module('app.gem-services.salesforce', [
  detailsModule, angularHaro
])
  // the following 2 can be removed with the upgrade of the salesforce details page
  .component('tenantSecretGenerationFailed', tenantSecretGenerationFailed)
  .component('generateMultipleSecret', generateMultipleSecret)
  .service('SalesforceService', SalesforceService)
  .service('SalesforceErrorResponseInterceptor', SalesforceErrorResponseInterceptor)
  // can be removed with the upgrade of the salesforce details page
  .directive('generateMultipleSecretTypes', downgradeComponent({component: GenerateMultipleSecretTypesComponent}) as angular.IDirectiveFactory)
  .directive('keyRotationPolicy', downgradeComponent({component: KeyRotationPolicyComponent}))
  .directive('salesforceSecretsTable', downgradeComponent({component: SalesforceSecretsTableComponent}))
  .name;

