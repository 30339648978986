import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

/**
 * Renders HTML passed to it (https://stackoverflow.com/a/39630507/3425961)
 *
 * NOTE: Should only be used with static HTML and NEVER be used with user input
 */
@Pipe({name: 'bypassSecurityTrustHtml'})
export class BypassSecurityTrustHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {
  }

  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
