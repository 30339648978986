import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tenant } from '../tenant.model';

@Component({
  selector: 'delete-tenant-modal',
  templateUrl: './delete-tenant-modal.component.html',
})
export class DeleteTenantModalComponent implements OnInit {
  @Input() tenant: Tenant;
  @ViewChild('deleteForm', { static: true }) private deleteForm: NgForm;

  verifyName: string;
  enteredName = '';

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.verifyName = this.tenant.name;
  }

  delete() {
    this.activeModal.close(this.tenant.id);
  }

  onTenantNameChange() {
    if (this.isDisabled()) {
      this.deleteForm.controls['tenantName'].setErrors({ invalid: true });
    }
  }

  isDisabled() {
    return this.enteredName !== this.verifyName;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
