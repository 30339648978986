import { InjectionToken } from '@angular/core';

export const WindowToken = new InjectionToken<Window>('$window');

export function windowFactory(): Window {
  return window;
}

export const WindowProvider = {
  provide: WindowToken,
  useFactory: windowFactory
};
