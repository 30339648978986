import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';
import { SharedModule } from '../../shared';
import { UsersEditComponent } from './users-edit.component';

@NgModule({
  declarations: [UsersEditComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class UsersEditModule {
}

// AngularJS module
export const usersEditModule = angular.module('users.edit', [])
  .directive('usersEdit', downgradeComponent({ component: UsersEditComponent }))
  .name;
