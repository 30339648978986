import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { AuditLog, AuditLogWorkerTask, PageDescriptor, SortDescriptor, SortDirection, WorkerResponseMessage } from '../logs.model';
import { PageEvent } from '@angular/material/paginator';
import { AuditLogsTableService } from './audit-logs-table.service';
import {
  DISPLAYED_COLUMNS,
  FILTERABLE_COLUMNS,
  FILTER_DEBOUNCE_TIME,
  META_PREFIX,
  PROGRESS_THRESHOLD_FILTERING,
  PROGRESS_THRESHOLD_SORTING,
  SORT_PRIMARY,
  SORT_SECONDARY,
  TABLE_PAGE_SIZE,
  auditLogsLabels
} from '../logs.constants';
import { Subject, debounceTime } from 'rxjs';
import { DialogService, ProgressModal } from '@app/components/gem-dialogs/dialog.service';

@Component({
  selector: 'audit-logs-table',
  templateUrl: './audit-logs-table.component.html',
  styleUrls: ['./audit-logs-table.component.scss']
})
export class AuditLogsTableComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() logFile: Blob;
  @Output() fileProcessed = new EventEmitter();

  @ViewChild(DpodTableComponent, { static: true }) dpodTable: DpodTableComponent;

  tableHidden = true;
  displayedColumns = DISPLAYED_COLUMNS;
  metaPrefix = META_PREFIX;

  // An array is used instead of an instance of MatTableDataSource to disable filtering/sorting/paging in the main browser thread.
  // Please do NOT change it to MatTableDataSource.
  dataSource: AuditLog[] = [];
  activeSort: SortDescriptor = {
    field: SORT_PRIMARY.field,
    direction: SORT_PRIMARY.direction
  };

  private filterValue = '';
  private filterSubject: Subject<string>;
  private pageIndex = 0;
  private progressModal: ProgressModal;
  private totalCount = 0; // Total count of all log records in a gzip file, NOT only those that meet the applied filter

  constructor(private auditLogsTableService: AuditLogsTableService, private dialogService: DialogService) {
    this.auditLogsTableService.createWorker(this.displayPage);
  }

  ngOnInit(): void {
    this.initFilterSubject();
  }

  ngOnChanges() {
    if (this.logFile) {
      this.auditLogsTableService.initData(this.logFile, this.buildPageDescriptor());
    }
  }

  ngAfterViewInit() {
    this.dpodTable.paginator.pageSize = TABLE_PAGE_SIZE;
    this.dpodTable.paginator.pageIndex = 0;
  }

  ngOnDestroy(): void {
    this.filterSubject.complete();
    this.auditLogsTableService.destroyWorker();
  }

  /**
   * This method is called by the browser every time the audit-logs-table.worker emits a new message.
   * The method has to be an arrow function so that the this value references the AuditLogsTableComponent instance.
   */
  displayPage = (workerResponse: WorkerResponseMessage) => {
    switch (workerResponse.data.task) {
    case AuditLogWorkerTask.INIT:
      this.handleWorkerInitMessage(workerResponse);
      return;
    case AuditLogWorkerTask.CALCULATE_PAGE:
      this.handleWorkerCalculatePageMessage(workerResponse);
    }
  };

  onPageChanged(pageEvent: PageEvent) {
    // Do NOT show a progress modal here - page calculation never takes longer than 10ms.
    this.requestNewPage(pageEvent.pageIndex);
  }

  onSortChanged(sort: Sort) {
    this.activeSort.field = sort.active;
    this.activeSort.direction = this.convertSortDirection(sort.direction);
    if (this.dpodTable?.paginator?.length > PROGRESS_THRESHOLD_SORTING) {
      /**
       * Show a progress modal if the number of logs that meet the applied filter exceeds the threshold.
       * The number of logs that meet the applied filter is used because the sorting will be performed over them,
       * and not over all the records in the gzip file.
       * It does not make sense to delay showing of the modal - the modal is appeared with animation.
       * The animation takes ~500ms to complete - so delaying the modal will lead only to a page twitch.
       * Showing the modal for any amount of records will also lead to the page twitch.
       * The twitch means the modal won't appear but the page will jump to the very top automatically
       * after the page data received from the worker.
       */
      this.progressModal = this.dialogService.progress(auditLogsLabels.loading);
    }
    this.requestNewPage(this.dpodTable.paginator.pageIndex);
  }

  onFilter(value: string) {
    this.filterSubject.next(value);
  }

  onMetaSwitchClick(log: AuditLog) {
    log._metaShown = !log._metaShown;
  }

  /**
   * This method enables filter input debouncing.
   * It makes the filter procesing logic wait for 500ms of user inactivity before handling the filter output value.
   * This makes the table more efficient, as it will process the filter value only after the user stopped typing.
   */
  private initFilterSubject() {
    this.filterSubject = new Subject<string>();
    this.filterSubject.pipe(
      debounceTime(FILTER_DEBOUNCE_TIME)
    ).subscribe(value => this.handleFilterChanged(value));
  }

  private handleFilterChanged(value: string) {
    const oldValue = this.filterValue || '';
    this.filterValue = value?.toLocaleLowerCase() || '';
    if (this.filterValue !== oldValue) {
      if (this.totalCount > PROGRESS_THRESHOLD_FILTERING) {
        /**
       * Show a progress modal if the total number of logs in the gzip file exceeds the threshold.
       * The total number of the logs in the gzip file is used because they all will be processed if the filter is changed.
       * It does not make sense to delay showing of the modal - the modal is appeared with animation.
       * The animation takes ~500ms to complete - so delaying the modal will lead only to a page twitch.
       * Showing the modal for any amount of records will also lead to the page twitch.
       * The twitch means the modal won't appear but the page will jump to the very top automatically
       * after the page data received from the worker.
       */
        this.progressModal = this.dialogService.progress(auditLogsLabels.loading);
      }
      this.requestNewPage(0);
    }
  }

  /**
   * This method converts a sortDirection value provided by Angular into a custom SortDirection enum.
   * Please read the comments in logs.model to know more about the custom SortDirection enum.
   */
  private convertSortDirection(sortDirection: string) {
    switch (sortDirection) {
    case 'asc':
      return SortDirection.ASC;
    case 'desc':
      return SortDirection.DESC;
    default:
      return SortDirection.NONE;
    }
  }

  /**
   * This method is one of PMO's requirements for the Audit Logs table.
   * The reason behind the requirement is that logs contain a lot of repetitive information,
   * and if we don't apply secondary sorting the order of rows in the table will be unstable.
   * Please find the use cases listed below.
   * If the user has no sorting specified the function outputs time desc, resourceId asc.
   * If the user sorts by time the function outputs time (asc or desc), resourceId asc.
   * If the user sorts by resourceId the function outputs resourceId (asc or desc), time desc.
   * If the user sorts by status (any other field): status (asc or desc), time desc, resourceId asc.
   */
  private buildSortDescriptors(): SortDescriptor[] {
    if (!this.activeSort.field || !this.activeSort.direction) {
      // If no sort column then we sort by time desc, resourceId asc
      return [SORT_PRIMARY, SORT_SECONDARY];
    }
    // First, we apply the sort rule specified by the user
    const sortDescriptors: SortDescriptor[] = [this.activeSort];
    if (SORT_PRIMARY.field === this.activeSort.field) {
      // If the user-applied sorting is by time then we append resourceId asc
      sortDescriptors.push(SORT_SECONDARY);
      return sortDescriptors;
    }
    // If the user-applied sorting is NOT by time then we append time desc
    sortDescriptors.push(SORT_PRIMARY);
    if (SORT_SECONDARY.field === this.activeSort.field) {
      // If the user-applied sorting is by resourceId then we do not need to add any secondary sorting
      return sortDescriptors;
    }
    // The user-applied sorting is neither by time nor by resourceId, so we append resourceId asc
    sortDescriptors.push(SORT_SECONDARY);
    return sortDescriptors;
  }

  private buildPageDescriptor(pageIndex = 0): PageDescriptor {
    this.pageIndex = pageIndex;
    return {
      limit: TABLE_PAGE_SIZE,
      offset: pageIndex * TABLE_PAGE_SIZE,
      filterDescriptor: {
        value: this.filterValue,
        fields: FILTERABLE_COLUMNS,
      },
      sortDescriptors: this.buildSortDescriptors()
    };
  }

  private requestNewPage(pageIndex: number) {
    this.auditLogsTableService.calculatePage(this.buildPageDescriptor(pageIndex));
  }

  private handleWorkerInitMessage(workerResponse: WorkerResponseMessage) {
    this.fileProcessed.emit();
    this.processNewTotalCount(workerResponse.data.totalCount);
    this.handleWorkerCalculatePageMessage(workerResponse);
  }

  private handleWorkerCalculatePageMessage(workerResponse: WorkerResponseMessage) {
    this.progressModal?.close();
    this.dataSource = workerResponse.data.data;
    if (this.dpodTable.paginator) {
      this.dpodTable.paginator.length = workerResponse.data.paginatorLength;
      // Restore the current page index because sometimes it can be reset to 0 (e.g., when the user has changed the filter)
      this.dpodTable.paginator.pageIndex = this.pageIndex;
    }
  }

  private processNewTotalCount(totalCount: number) {
    this.totalCount = totalCount;
    this.tableHidden = !totalCount;
    if (this.tableHidden) {
      this.dialogService.confirm({
        yesLabel: auditLogsLabels.noLogsModalButton,
        noLabel: '',
        title: auditLogsLabels.noLogsModalTitle,
        content: auditLogsLabels.noLogsModalContent
      });
    }
  }
}
