import angular from 'angular';
import serviceCardTemplate from './service-card.ng1.html';
export * from './service-card.component';

// "use strict";

// AngularJS
function ServiceCardController($element) {
  return {
    $onInit() {
      $element.attr('data-title', this.title);
      if (this.locator) {
        $element.attr('data-locator', this.locator);
      }
      $element.on('click', this.action);
    }
  };
}
ServiceCardController.$inject = ['$element'];

export default angular.module('dpaas.serviceCard', [])
// bindings:
// title - card title
// content - main text of the card
// icon - path to an icon to display on the card (optional)
// svg - alias to an svg to display on the card (optional)
// action - a bound function to call when the card is clicked
// size - the size of the icon/img, in pixels (optional).
//   if specified, overrides the size specified in the CSS
  .component('serviceCard', {
    bindings: {
      title: '@title',
      content: '@content',
      locator: '@locator',
      icon: '@?',
      svg: '@?',
      action: '&',
    },
    transclude: true,
    template: serviceCardTemplate,
    controller: ServiceCardController
  }).name;
