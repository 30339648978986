import { loadRemoteModule, LoadRemoteModuleScriptOptions } from '@angular-architects/module-federation';
import { AfterContentInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MfeComponents, MfeRegistryService } from './mfe-registry.service';

enum LoadingStates {
  NotStarted = 0,
  InProgress,
  Success,
  Failed
}

/**
 * A component that allows to use components defined in MFE modules.
 * Please use it as follows:
 *
 * <mfe-wrapper-component
 *   [component]="MfeComponents.HsmServiceDetails"
 *   [inputs]="hsmServiceDetailsInputs"
 *   [outputs]="hsmServiceDetailsOutputs"
 * />
 * , having `hsmServiceDetailsInputs` and `hsmServiceDetailsOutputs` defined in the TS controller:
 *
 * hsmServiceDetailsInputs = { serviceId: '12345' };
 * hsmServiceDetailsOutputs = { anyEvent: () => {} };
 *
 * Please make sure that the TS controller has the following lines as well (so that its template can reference the MfeComponents enum):
 *
 * import { MfeComponents } from '@app/mfe/mfe-registry.service';
 * ...
 * MfeComponents = MfeComponents;
 */

@Component({
  selector: 'mfe-wrapper-component',
  templateUrl: './mfe-wrapper.component.html',
  styleUrls: ['./mfe-wrapper.component.scss']
})
export class MfeWrapperComponent implements AfterContentInit {
  @Input() component: MfeComponents;
  @Input() inputs?: Record<string, any>;
  @Input() outputs?: Record<string, (...args: any[]) => void>;
  @ViewChild('mfePlaceholder', {read: ElementRef, static: true}) mfePlaceholder: ElementRef;

  LoadingStates = LoadingStates;
  currentLoadingState = LoadingStates.NotStarted;

  constructor(@Inject(DOCUMENT) private doc: Document, private mfeRegistry: MfeRegistryService) {
  }

  async ngAfterContentInit() {
    if (!this.component) {
      return;
    }
    const componentConfiguration = this.mfeRegistry.getMfeComponentConfiguration(this.component);
    if (!componentConfiguration) {
      return;
    }
    try {
      this.currentLoadingState = LoadingStates.InProgress;
      await this.loadMfeModule(componentConfiguration);
    } catch (err) {
      console.error(`Error loading ${this.component}`, err);
      this.currentLoadingState = LoadingStates.Failed;
      // A human-friendly message is added and the error is thrown to be handled by the Angular global handler
      err.message = 'The underlying components are currently unavailable. Please refresh the page or try again later.';
      throw err;
    }
    this.currentLoadingState = LoadingStates.Success;
    this.mfePlaceholder.nativeElement.appendChild(this.createComponent());
  }

  // This method is created and made public solely for the unit tests
  loadMfeModule(componentConfiguration: LoadRemoteModuleScriptOptions) {
    return loadRemoteModule(componentConfiguration);
  }

  // This method is made public solely for the unit tests
  createComponent() {
    const element = this.doc.createElement(this.component);
    if (this.inputs) {
      Object.keys(this.inputs).forEach(inputName => element[inputName] = this.inputs[inputName]);
    }
    if (this.outputs) {
      Object.keys(this.outputs).forEach(outputName => {
        // Each value in `outputs` is a function - so the function is explicitly called and its arguments are passed in when the output event happens
        element.addEventListener(outputName, outputEvent => this.outputs[outputName](outputEvent['detail']));
      });
    }
    return element;
  }
}
