import { Component, Input, forwardRef } from '@angular/core';
import {TenantCreateBody} from '../tenant.model';
import { WizardStepBaseComponent } from '@app/components';
import { Validators } from '@angular/forms';

export const countriesRequiringStates = ['United States', 'Canada', 'Australia'];
@Component({
  selector: 'address-step',
  templateUrl: './address.step.component.html',
  providers: [{provide: WizardStepBaseComponent, useExisting: forwardRef(() => AddressStepComponent)}]
})
export class AddressStepComponent extends WizardStepBaseComponent {
  @Input() tenant: TenantCreateBody;

  // on change of the country we reset the state to empty/unselected
  // we cannot use a template-defined required validator here:
  // when it's used in the template, it produces the NG0100 error
  countryChange() {
    this.tenant.billingAddress.state = undefined;
    if (this.stepForm.controls['state']) {
      this.stepForm.controls['state'].setValidators(
        countriesRequiringStates.includes(this.tenant.billingAddress.country) ? [Validators.required] : []
      );
      this.stepForm.controls['state'].updateValueAndValidity();
    }
  }
}
