import { Component, Input } from '@angular/core';

@Component({
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
})
export class ProgressModalComponent {
  @Input() text: string;
  @Input() subText: string;
  @Input() cancelCallback: () => void;
}
