<div class="table-holder">

    <credentials-table
        [showClientID]="showClientID"
        [useServiceBroker]="useServiceBroker"
        [tabledata]="credentials"
        [type]="CredentialType.Service"
        [description]="'Create Service Credentials to access and consume services using the DPoD API.'"
        [metadata]="service">

        <!-- extra buttons shown above table -->
        <button *ngIf="isSameSubscriberGroup()" (click)="createServiceCredentials()"
            [attr.data-locator]="'create-credentials'" class="btn btn-next float-end me-3">
            Create Service Credentials
        </button>

    </credentials-table>
</div>
