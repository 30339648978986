import { Pipe, PipeTransform } from '@angular/core';
import { DocsService } from '../services';

/**
 * Send a string to this pipe and it will be turned into an absolute link to the
 * documentation website.
 *
 *
 * Example:
 * ```html
 *  <a href="{{ 'installing_a_linux_service_client' | docLinkById }}">Click here for more info</a>
 * ```
 *
 * will return the `DOCS_BASE_URL + /dpod/services/hsmod_services/hsmod_linux_client/`
 * ex. https://thalesdocs.com/dpod/services/hsmod_services/hsmod_linux_client/
 */
@Pipe({ name: 'docLinkById' })
export class DocLinkByIdPipe implements PipeTransform {
  constructor(private docsService: DocsService) { }

  transform(value: string): string {
    return this.docsService.getDocsLinkByPortalId(value);
  }
}
