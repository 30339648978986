import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import duration, { Duration } from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

@Injectable({providedIn: 'root'})
export class DateTimeHelperService {

  constructor() {
    dayjs.extend(duration);
    dayjs.extend(customParseFormat);
  }

  formatDate(date: string | Date, format: string, inputFormat?: string) {
    if (inputFormat) {
      return dayjs(date, inputFormat).format(format);
    }
    return dayjs(date).format(format);
  }

  duration(time: string): Duration {
    return dayjs.duration(time);
  }
}

export { Duration } from 'dayjs/plugin/duration';
