<div class="tile-selection">

    <div class="tile tile-height" *ngFor="let selection of serviceAgreement?.terms.mbus">
        <div class="picture">
            <img class="card-icon" *ngIf="getImageUrl(selection)" src="{{getImageUrl(selection)}}" alt="" />
        </div>

        <div class="title">
            <strong>{{serviceNames[selection.serviceType.shortCode] || selection.serviceType.shortCode}}</strong>
        </div>

        <div class="quantity info-section" *ngIf="selection.quantity">
            Quantity<strong>{{selection.quantity}}</strong>
        </div>
        <div class="plan info-section" *ngIf="showingPlan[selection.serviceType.id]">
            Plan<strong>{{showingPlan[selection.serviceType.id]}}</strong>
        </div>
    </div>

</div>
