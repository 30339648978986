import ServiceBase from '@app/components/service/service-base';

export default class AccountsDataApi extends ServiceBase {

  protected $q: any; // $q
  protected http: any; // $http

  constructor(haro, $http, $q, baseUrl) {
    super(haro, baseUrl);
    this.http = $http;
    this.$q = $q;
  }

  // Override resync to swallow error. This is a workaround to prevent 403's from appearing
  // to App Owners when they try to list users.
  resync() {
    return super.resync()
      .catch(error => console.warn(`Could not sync with server; ${error.status} received`));
  }

  doCreate(entity) {
    return this.http.post(this.baseUrl, entity);
  }

  doDelete(id) {
    return this.http.delete(`${this.baseUrl}/${encodeURIComponent(id)}`);
  }

  doSave(entity) {
    return this.http.patch(`${this.baseUrl}/${encodeURIComponent(entity.id)}`, entity);
  }

  doFetch(url) {
    return this.http.get(url);
  }

  doResync() {
    return this.http.get(this.baseUrl);
  }
}
