import { Inject, Injectable } from '@angular/core';
import { IHttpResponse } from 'angular';

import { Observable, ReplaySubject } from 'rxjs';
import { HttpService } from '@app/ajs-upgraded-providers';
import ServiceBase from '@app/components/service/service-base';
import { RequiresRoleService } from '@app/shared/services/requires-role.service';
import { DownloadService } from '@app/shared/services/download.service';
import { Haro } from '@app/shared/services/haro.service';
import { AuthService } from '../auth';
import {
  EvaluationStatus,
  Tenant,
  TenantAccountType,
  TenantAdmin,
  TenantCreateBody,
  TenantParentInfo,
  TenantSettings
} from './tenant.model';
import cloneDeep from 'lodash/cloneDeep';
import identity from 'lodash/identity';

import * as roles from '../auth/roles.constants';
import { BackofficeService } from '@app/shared/services/backoffice.service';
import { single } from 'rxjs/operators';
import { from } from 'rxjs/internal/observable/from';
import { DialogService } from '@app/components';
import { parseError } from '@app/shared/services/global-error-handler';

const encode = encodeURIComponent;

@Injectable()
export class TenantsService extends ServiceBase {
  /**
   * Clients can subscribe to this stream to receive an event when the user uploads a new logo.
   * If the logo was previously set, it will be "replayed" to new subscribers.
   */
  public logoStream: Observable<Blob>;

  // Note that ReplaySubject retains the last emitted value and delivers it to new subscribers
  private logoSubject = new ReplaySubject<Blob>(1);

  private currentTenantId: string;

  private showing = false;

  constructor(
    @Inject(Haro) public haro: any,
    @Inject(HttpService) private http: any,
    private authService: AuthService,
    private requiresRoleService: RequiresRoleService,
    private downloadService: DownloadService,
    private backofficeService: BackofficeService,
    private dialogService: DialogService
  ) {
    super(haro, '/v1/tenants');
    this.currentTenantId = authService.getTenantId();
    this.logoStream = this.logoSubject.asObservable();
    if (authService.isEmailVerified() && requiresRoleService.hasRole([roles.spadmin, roles.operator])) {
      this.resync();
    }
  }

  annotate(t: any): Tenant {
    // The server sometimes sends us tenants with no billingAddress. Normalize that away
    if (!t.billingAddress) {
      t.billingAddress = {
        streetAddress: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      };
    }

    return t;
  }

  doDelete(id: string): Promise<void> {
    return Promise.resolve(this.http.delete(`${this.baseUrl}/${encode(id)}`));
  }

  doCreate(data: TenantCreateBody) {
    return this.http.post(this.baseUrl, data);
  }

  /**
   * Fetches a tenant from the server.
   */
  doFetch(url: string): Promise<Tenant> {
    const promise = this.http.get(url);
    promise.then(response => {
      // Fetch the admin info for the tenant, then update the data store. Note that
      // doFetch() won't wait on this operation. If clients care about admin info,
      // they should use a subscriber to get notified of model updates.
      this.injectAdmin(response.data)
        .then(tenant => {
          this.set(tenant);
          // not sync, return tenant data that we've received
          this.backofficeService.getAccountStatus(tenant.id);
          return tenant;
        });
    });
    return promise;
  }

  /**
   * Lists all tenants on the server.
   *
   * NOTE: this method returns tenants without the synthetic `admin` field. Callers who wish to
   * have the `admin` field populated must call `fetchAdminInfo()` manually.
   */
  doResync() {
    return this.http.get(this.baseUrl)
      .then(tenants => {
        // don't wait for this response so the tenant data that we have can be returned to the UI
        this.backofficeService.listAccountStatuses();
        return tenants;
      });
  }

  doSave(entity) {
    delete entity.parent; // remove the parent entity as it is used exclusively for reparenting
    return this.http.patch(`${this.baseUrl}/${encodeURIComponent(entity.id)}`, entity)
      .then(response => {
        // once we save we lose the `admin` fields.  We fetch them again because down the road they may be editable
        return this.injectAdmin(response.data)
          .then(tenant => {
            response.data = tenant;
            return response;
          });
      });
  }

  /**
   * Fetches admin info for the given tenants. Updates their entries in the data store.
   *
   * @param tenants The tenants to fetch admin info for. These objects are not mutated.
   * @returns A promise resolving after the tenants have been updated in the data store.
   */
  fetchAdminInfo(tenants: Tenant[]): Promise<void> {
    return Promise.all(tenants.map(t => this.injectAdmin(t)))
      .then(updatedTenants => updatedTenants.forEach(t => this.set(t)));
  }

  /**
   * @param tenant The tenant. This object is not modified.
   * @returns Promise resolving to a copy of the given tenant with the `admin` field populated.
   * If the admin info could not be fetched, the original tenant is returned.
   */
  injectAdmin(tenant: Tenant): Promise<Tenant> {
    if (tenant.admin) {
      // If tenant admin info was already fetched, return as-is.
      return Promise.resolve(tenant);
    }

    return this.fetchAdmin(tenant.id)
      .then(response => {
        const clone = cloneDeep(tenant);
        clone.admin = response.data;
        return clone;
      })
      .catch(e => {
        // Log and continue
        console.log(`Failed to fetch admin info for tenant ${tenant.id}`, e);
        return tenant;
      });
  }

  /**
   * Retrieves info about a tenant's admin user
   * @param id The tenant guid
   * @returns The admin info
   */
  fetchAdmin(id): Promise<IHttpResponse<TenantAdmin>> {
    return this.http.get(`${this.baseUrl}/${encode(id)}/admin`);
  }

  /**
   * Resets the password of a tenant's administrator
   * @param id The tenant guid
   * @returns The admin's new, temporary password
   */
  resetAdminPassword(id): Promise<string> {
    return this.http.patch(`${this.baseUrl}/${encode(id)}/admin/reset`)
      .then(response => response.data.temporaryPassword)
      .catch(response => {
        throw response.data;
      });
  }

  /**
   * @param tenantId
   * @returns Promise resolving if the reset succeeded
   */
  resetAdminMfaToken(tenantId: string): Promise<void> {
    return this.http.post(`${this.baseUrl}/${tenantId}/admin/resetMfaToken`)
      .then(() => undefined)
      .catch(response => {
        throw response.data;
      });
  }

  /**
   * Downloads the usage report for this tenant and sub-tenants.
   *
   * @param startDate date in ISO format
   * @param endDate date in ISO format
   */
  getUsageReport(startDate: string, endDate: string): Promise<void> {
    return this.http.get(`${this.baseUrl}/usageReport?startDate=${startDate}&endDate=${endDate}`)
      .then(response => {
        const file = new Blob([response.data as string], {type: 'text/csv'});
        this.downloadService.downloadFile(file, `DPoD_TenantServicesReport_${startDate.substring(0, 7)}.csv`, 'report');
      },
      response => {
        throw response.data;
      });
  }

  /**
   * Downloads the service summary report.
   */
  getServicesSummaryFile(): Promise<void> {
    return this.http.get(`${this.baseUrl}/servicesSummaryFile`)
      .then(response => {
        const file = new Blob([response.data], {type: 'text/csv'});
        this.downloadService.downloadFile(file, 'DPoD_ServicesSummary.csv', 'report');
      },
      response => {
        throw response.data;
      });
  }

  /**
   * @returns The total count of provisioned service quota across all tenants.
   */
  getProvisionedQuota(): number {
    return this.getAll().reduce((sum, t: Tenant) => sum + t.serviceQuota, 0);
  }

  /**
   * @returns The URL of the logo for the tenant that the user is logged into.
   */
  getLogoUrl(): string {
    return `${this.baseUrl}/logo`;
  }

  // takes the account type and if the data exists from Maestro, then we return that account type
  // note: this only covers the account type and does not cover the status like "Expired"
  getAccountType(tenant: Tenant): TenantAccountType | '' {
    // we don't have tight type checking yet throughout the app, ensure tenant is not undefined
    if (tenant) {
      // if the tenant is a service provider, there's no need to continue after
      if (tenant.accountType === TenantAccountType.serviceProvider) {
        return TenantAccountType.serviceProvider;
      }

      if (this.backofficeService.accountStatusMap) {
        const status = this.backofficeService.accountStatusMap.get(tenant.id);

        if (status) {
          switch (status.evaluationStatus) {
          // the two states fall under as the same account type
          case EvaluationStatus.evaluating:
          case EvaluationStatus.expired:
          case EvaluationStatus.inAgreement:
            return TenantAccountType.subscriber;
          }
        }

        // if we have no maestro data, we return nothing until the tenant account type are fetched from maestro
        // the reason being is we don't want the UI to display one account type and then display another
        // going forward we'll probably use `ghost loading` to ensure fields have something until the data is returned
        if (this.backofficeService.accountStatusMap.size > 0) {
          // we have maestro data returned but we don't have data on this tenant, return dpaas account type
          return tenant.accountType;
        }
      }
    }

    // we have NO maestro data returned yet, therefore we display nothing
    return '';
  }

  /**
   * Uploads a new logo for this service provider tenant and its descendants.
   * After a successful upload the new logo, will be broadcast to `logoStream` subscribers.
   *
   * @param file
   */
  setLogo(file: File): Promise<void> {
    const id = this.currentTenantId;
    return this.http.put(`${this.baseUrl}/${encode(id)}/logo`, file, {
      headers: {'Content-Type': 'image/png'},
      transformRequest: identity, // turn off angular body parsing
    }).then(response => {
      this.logoSubject.next(file);
      return response.data;
    }).catch(error => {
      const parsedError = parseError(error);
      if (!this.showing) {
        this.showing = true;
        this.dialogService.error(parsedError)
          .finally(() => this.showing = false);
      }
      return Promise.reject(error);
    });
  }

  updateTenantSettings(tenantSettings: TenantSettings): Promise<TenantSettings> {
    return this.http.patch(`${this.baseUrl}/settings`, tenantSettings)
      .then(response => response.data);
  }

  getTenantSettings(): Promise<TenantSettings> {
    return this.http.get(`${this.baseUrl}/settings`)
      .then(response => response.data);
  }

  getParentTenantInfo(): Observable<TenantParentInfo> {
    return from<Observable<TenantParentInfo>>(
      this.http.get(`${this.baseUrl}/parent`)
        .then(res => res.data)
    ).pipe(single());
  }

}
