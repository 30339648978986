<form #stepForm="ngForm">

    <div class="row mb-4">
        <div class="col-sm-12">
            Would you like to create a new Azure resource group or select an existing one?
        </div>
    </div>

    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">

                    <div class="row">
                        <div class="col-sm-5">
                            <label>
                                <input type="radio" name="groupRadio" [(ngModel)]="o365Config.groupRadio"
                                       (click)="groupChanged(checkboxOpts.new)"
                                       value="new"/>
                                Create a new group
                            </label>

                            <label class="">
                                <input type="radio" name="groupRadio" [(ngModel)]="o365Config.groupRadio"
                                       (click)="groupChanged(checkboxOpts.existing)"
                                       value="existing"/>
                                Select an existing group
                            </label>
                        </div>

                        <div class="col-sm-7">

                            <div class="row">
                                <div class="col-sm-12" *ngIf="o365Config.groupRadio === 'new'">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label class="control-label" for="newGroupName">New group name</label>
                                            <input class="form-control" [pattern]="resourceGroupRegex" id="newGroupName"
                                                   data-locator="newGroupField" name="newGroupName" #name="ngModel"
                                                   validationIcon
                                                   [(ngModel)]="o365Config.newGroupName"
                                                   required/>

                                            <div *ngIf="stepForm.controls.newGroupName?.dirty"
                                                 id="error-message-newGroupName"
                                                 class="text-danger error-message"
                                                 role="alert">
                                                <div *ngIf="stepForm.controls.newGroupName?.invalid">Group name does not
                                                    follow the required pattern.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label class="control-label" for="location">Select database location</label>
                                            <select [(ngModel)]="o365Config.location" class="form-control"
                                                    size
                                                    id="location"
                                                    [disabled]="locationsList.length === 0"
                                                    data-locator="locationDropdown"
                                                    name="location"
                                                    required>
                                                <option [ngValue]="null" disabled selected>Select a Location</option>
                                                <option *ngFor="let location of locationsList"
                                                        [ngValue]="location">{{location.displayName}}
                                                </option>
                                            </select>
                                            <small class="d-inline-block">Location will also apply to Key Vault</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6" *ngIf="o365Config.groupRadio === 'existing'">
                                    Select group
                                    <br/>
                                    <!-- readonly ensures that validation of the form fails -->
                                    <!-- disabling the element would allow the user to continue -->
                                    <!-- this is perhaps something that UX should look at in the future -->
                                    <select [(ngModel)]="o365Config.selectedGroup" class="form-control" size
                                            [ngClass]="{ 'has-options': existingResourceGroups.length !== 0 }"
                                            data-locator="existingGroupDropdown"
                                            [attr.readonly]="existingResourceGroups.length === 0"
                                            required
                                            name="selectedGroup" id="selectedGroup">
                                        <option *ngFor="let existingGroup of existingResourceGroups"
                                                [ngValue]="existingGroup">
                                            {{existingGroup.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">

                    <div class="row">

                        <div class="col-sm-12">

                            <div class="row">

                                <div class="col-sm-12" *ngIf="o365Config.keyVaultRadio === 'new'">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-5">

                                                    <label class="control-label" for="newKeyVaultName">New Key Vault
                                                        Name</label>
                                                    <div class="input-group">
                                                        <div class="input-group-text pointer">{{keyVaultPrefix}}</div>

                                                        <input class="form-control" data-locator="newKeyVaultName"
                                                               id="newKeyVaultName"
                                                               name="newKeyVaultName"
                                                               #name="ngModel"
                                                               (keyup)="checkKeyVaultValidation(o365Config.newKeyVaultName)"
                                                               (change)="!checkKeyVaultValidation(o365Config.newKeyVaultName)"
                                                               [(ngModel)]="o365Config.newKeyVaultName" validationIcon
                                                               required/>
                                                    </div>

                                                    <div *ngIf="stepForm.controls.newKeyVaultName?.dirty"
                                                         id="error-message-newKeyVaultName"
                                                         class="text-danger error-message"
                                                         role="alert">
                                                        <div *ngIf="stepForm.controls.newKeyVaultName?.invalid">Vault
                                                            name
                                                            must be between 1-12 alphanumeric characters. The name must
                                                            end
                                                            with a letter or digit, and not contain
                                                            consecutive hyphens.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-sm-7">
                                                    <div class="alert alert-custom-warning p-0">
                                                        <div class="form-control p-2 h-auto">
                                                            <label>
                                                                <input type="checkbox" name="newKeyVaultCheckbox"
                                                                       required
                                                                       [(ngModel)]="newKeyVaultCheckbox"/>
                                                                I understand and accept that the creation of a new Key
                                                                Vault
                                                                may
                                                                incur premium costs in my Azure account.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
