import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '@app/shared/services/window';

export enum MfeGlobalEvent {
  LogOut = 'LogOutCustomEvent'
}

/**
 * This service is intended to be a general-purpose helper for the MFE architecture.
 * As for now, its functionality is limited to:
 *   1. Providing an ability to notify all loaded MFE modules using window-level events (the notifyMicroFrontends method)
 */
@Injectable()
export class MfeService {

  constructor(@Inject(WindowToken) private window: Window) { }

  notifyMicroFrontends(globalEvent: MfeGlobalEvent) {
    this.window.dispatchEvent(new CustomEvent(globalEvent));
  }
}
