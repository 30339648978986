<div class="modal-body row">
    <h5 class="mb-2">
        <svg *ngIf="state === 'done' ; else bang" class="icon-svg title-icon">
            <use xlink:href="#check-round" />
        </svg>

        <ng-template #bang>
            <svg class="icon-svg title-icon"><use xlink:href="#bang-triangle" /></svg>
        </ng-template>

        {{this.getTitle()}}
    </h5>
</div>


<ng-container [ngSwitch]="state">
    <div *ngSwitchCase="'start'">
        {{this.getMessage()}}
    </div>

    <div *ngSwitchCase="'progress'" class="d-flex">
        <spinner class="d-block mt-2"></spinner> <span class="mt-3 ms-4">Resetting multi-factor authentication token…</span>
    </div>

    <div *ngSwitchCase="'done'">
        The multi-factor authentication token has been successfully reset.
    </div>
</ng-container>


<div class="row g-0">
    <div class="float-sm-start">

        <ng-container *ngIf="state === 'start'">
            <button class="btn btn-next" name="reset" type="button" (click)="reset()">Reset</button>
            <button class="btn btn-default" name="cancel" type="button" (click)="cancel()">Cancel</button>
        </ng-container>

        <ng-container *ngIf="state === 'done'">
            <button class="btn btn-next" name="close" type="button" (click)="cancel()">Close</button>
        </ng-container>
    </div>
</div>

