import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UnsupportedBrowserComponent } from './unsupported-browser.component';
import { unsupportedBrowserHook } from './unsupported-browser.hook';
import { UserAgentSniffer } from './user-agent-sniffer.service';

export const unsupportedBrowserState = {
  name: 'unsupportedBrowser',
  params: {
    requiresRole: false, // A non logged-in user can view this state
  },
  component: UnsupportedBrowserComponent,
};

@NgModule({
  declarations: [UnsupportedBrowserComponent],
  imports: [
    CommonModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: [unsupportedBrowserState],
      config: unsupportedBrowserHook,
    }),
  ],
  exports: [],
  providers: [
    UserAgentSniffer,
  ],
})
export class UnsupportedBrowserModule {
}
