import { Component, TemplateRef, ViewChild } from '@angular/core';
import { noPlans, plansError } from '@app/features/accounts/subscribers.messages';

@Component({
  selector: 'ise-error-messages',
  templateUrl: './ise-error-messages.component.html'
})
export class IseErrorMessagesComponent {
  @ViewChild('emptyPlanListMessage') emptyPlanListMessage: TemplateRef<any>;
  @ViewChild('retrievingPlansErrorMessage') retrievingPlansErrorMessage: TemplateRef<any>;

  noPlans = noPlans;
  plansError = plansError;
}
