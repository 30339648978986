import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@app/components';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import * as Roles from '@app/features/auth/roles.constants';
import { RequiresRoleService } from '@app/shared/services';
import { BackofficeService } from '@app/shared/services/backoffice.service';
import { StateService } from '@uirouter/core';
import cloneDeep from 'lodash/cloneDeep';
import { Subscription } from 'rxjs';
import { DeleteTenantModalComponent } from '../delete-tenant-modal/delete-tenant-modal.component';
import { Tenant, TenantAccountType } from '../tenant.model';
import { getStatus, getStatusLabel } from '../tenant.util';
import { TenantsService } from '../tenants.service';

@Component({
  selector: 'tenants-table',
  templateUrl: './tenants-table.component.html',
  styleUrls: ['./tenants-table.component.scss']
})
export class TenantsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<Tenant>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  // used by template
  displayedColumns = ['accountStatus', 'name', 'accountType', 'admin', 'country', 'actions'];
  dataSource = new MatTableDataSource<Tenant>();
  // end of template variables

  private isFirstRender = true;
  private dataSub: Subscription;

  constructor(
    private stateService: StateService,
    private tenantsService: TenantsService,
    private backofficeService: BackofficeService,
    private requiresRoleService: RequiresRoleService,
    private dialogService: DialogService,
  ) {
    if (this.requiresRoleService.hasRole(Roles.spadmin)) {
      // For SP admins, add the Service Selection Status column
      this.displayedColumns.splice(this.displayedColumns.indexOf('name') + 1, 0, 'election-status');
    }
  }

  ngOnInit() {
    this.dataSource.data = this.tenantsService.getAll();
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);

    this.dataSub = this.tenantsService.subscribe(this.onTenantsChanged.bind(this));
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;

    // Initial render: TODO is this necessary
    this.table.renderRows();
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe();
  }

  /**
   * Invoked when the tenants DataStore emits an event
   * NOTE: in Mock mode, this callback is invoked many times, especially upon page reload
   * Should this process be made more efficient?
   *
   * @param event Array [id, Tenant] emitted by the TenantsService when a tenant is added/removed
   */
  onTenantsChanged(event: string | [string, Tenant]) {
    const tenantId = Array.isArray(event) ? event[0] : event;
    const isNew = this.dataSource.data.findIndex(t => t.id === tenantId) === -1;
    this.dataSource.data = this.tenantsService.getAll();
    this.table.renderRows();

    const paginator = this.dpodTable.paginator;
    if (this.isFirstRender) {
      // No page change event is emitted for the initial page, so kickstart pageChanged
      // in order to fetch the admin names.
      this.isFirstRender = false;
      this.onPageChanged({
        pageIndex: 0,
        pageSize: paginator.pageSize,
        previousPageIndex: -1,
        length: this.dataSource.data.length,
      } as PageEvent);
    } else if (isNew) {
      // A new tenant was added: go to the page that it's on
      const sortedData = this.dataSource.sortData(this.dataSource.data.slice(), this.dataSource.sort);
      const index = sortedData.findIndex(t => t.id === tenantId);
      if (index !== -1) {
        paginator.pageIndex = Math.floor(index / paginator.pageSize);
      }
    }
  }

  getStatus(tenant: Tenant) {
    return getStatus(tenant);
  }

  getStatusLabel(tenant: Tenant) {
    return getStatusLabel(tenant);
  }

  isOperator() {
    return this.requiresRoleService.hasRole(Roles.operator);
  }

  getAdminName(tenant: Tenant): string {
    // TODO synthetic field for admin name label
    const admin = tenant.admin;
    return admin ? `${admin.givenName} ${admin.familyName}` : '…';
  }

  /**
   * returns string on the account status (from Maestro) for this tenant row
   * no need to test for Purple Packet enabled as this column will only display if enabled
   * @param tenantId
   * @returns {string}
   */
  getElectionsStatus(tenantId: string): string {
    const accountType = this.tenantsService.getAccountType(this.tenantsService.get(tenantId));

    // if not service provider and maestro data has returned
    // service providers return nothing and we only want to show the column data if we have it returned
    if (accountType !== TenantAccountType.serviceProvider && this.backofficeService.accountStatusMap) {
      const accountStatus = this.backofficeService.accountStatusMap.get(tenantId);

      if (accountStatus && accountStatus.agreementApprovalStatus) {
        switch (accountStatus.agreementApprovalStatus) {
        case 'Approved':
          return 'Approved';
        case 'Requested':
          return 'Requires Approval';
        case 'NotRequested':
          return 'Not Submitted';
        }
      }

      // all the tenants are legacy
      return 'N/A';
    }

    // no maestro response yet
    return '';
  }

  getAccountTypeLabel(tenant: Tenant) {
    return this.backofficeService.getAccountTypeLabel(tenant);
  }

  showDetails(tenantId: string) {
    this.stateService.go('tenants.details', { id: tenantId });
  }

  deleteTenantDialog(id: string) {
    const tenant = cloneDeep(this.tenantsService.get(id));
    const modalRef = this.dialogService.open<DeleteTenantModalComponent>(DeleteTenantModalComponent);
    modalRef.componentInstance.tenant = tenant;
    modalRef.result.then(() => this.deleteTenant(tenant.id));
  }

  deleteTenant(id: string) {
    const progress = this.dialogService.progress('Deleting tenant…');
    return this.tenantsService.delete(id)
      .catch(error => this.dialogService.error(error))
      .finally(progress.close);
  }

  /**
   * Callback invoked when paging through the table. Fires off requests to populate the admin column
   * for the currently viewed page of tenants.
   */
  onPageChanged(pageEvent: PageEvent) {
    if (pageEvent.pageIndex === pageEvent.previousPageIndex) {
      return;
    }
    const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
    const endIndex = startIndex + pageEvent.pageSize;
    const tenantsOnPage: Tenant[] = this.dataSource.filteredData.slice(startIndex, endIndex);

    if (!tenantsOnPage.length) {
      return;
    }

    this.tenantsService.fetchAdminInfo(tenantsOnPage);
    // Note no .then() needed here.. the subscription should update the tabledata for us
  }

  /**
   * See https://material.angular.io/components/table/api
   * @param tenant
   * @param columnName
   */
  sortingDataAccessor(tenant: Tenant, columnName: string): string {
    switch (columnName) {
    case 'accountStatus':
      return getStatus(tenant);
    case 'accountType':
      return this.backofficeService.getAccountTypeLabel(tenant);
    case 'admin':
      // Sort by family name
      return tenant.admin && `${tenant.admin.familyName} ${tenant.admin.givenName}`.toLowerCase();
    case 'country':
      return tenant.billingAddress && tenant.billingAddress.country;
    case 'election-status':
      return this.getElectionsStatus(tenant.id).toLowerCase();
    default:
      // Otherwise the column name matches the field name
      return tenant[columnName];
    }
  }

  /**
   * See https://material.angular.io/components/table/api
   * @param tenant
   * @param filter
   */
  filterPredicate(tenant: Tenant, filter: string): boolean {
    // Reuse the sorting data accessor to provide the data to check the filter against.
    // If any column's sorting data matches the filter, return true.
    return this.displayedColumns.some(column => {
      const sortingData = this.sortingDataAccessor(tenant, column);
      return typeof sortingData === 'string' && sortingData.toLowerCase().includes(filter.toLowerCase());
    });
  }

  onFilter(value: string) {
    this.dataSource.filter = value;
  }

}
