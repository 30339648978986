import { Inject, Injectable } from '@angular/core';
import { IHttpService } from 'angular';
import { DateTimeHelperService } from '@app/shared/services/date-time-helper.service';
import { HttpService, VHsmApiService } from '@app/ajs-upgraded-providers';
import ServiceBase from '../../components/service/service-base';
import { HSMonDemandCreatedService } from '../gem-services/cloudHSM/cloudHSM.model';
import {
  ApiClient,
  ApiClientCreateParams,
  ApiClientSecret,
  ApiClientUI,
  APIClientUpdateParams,
  ApiClientWithSecret,
  CredentialRole
} from './credentials.interface';
import { Haro } from '../../shared/services/haro.service';

@Injectable()
export class CredentialsService extends ServiceBase {

  constructor(
    @Inject(Haro) haro: any,
    @Inject(HttpService) private http: IHttpService,
    @Inject(VHsmApiService) private vhsmService: any,
    private dateTimeHelper: DateTimeHelperService,
  ) {
    super(haro, '/v1/credentials/clients');

    vhsmService.subscribe(this.onServiceChange.bind(this));
  }

  getKey() {
    return 'clientId';
  }

  /** Fetch clients for a single service only */
  resyncService(serviceId: string) {
    // NOTE: we're not using use super.resync() here since it removes "old keys" from the store assuming
    // the complete set of resources is resynced each time. That's not the case when syncing service creds
    return this.doResync([serviceId])
      .then(response => {
        const items = response.data['content'] as ApiClient[];
        items.forEach(g => this.set(this.annotate(g)));
        return this.getAllService(serviceId);
      });
  }

  /** Returns all service clients (optionally filtered by `serviceId`) */
  getAllService(serviceId: string = null) {
    return this.getAll().filter((client: ApiClientUI) => {
      if (client.role === CredentialRole.service) {
        const serviceIds = client.serviceIds;
        return serviceId ? serviceIds.includes(serviceId) : true;
      }
      return false;
    });
  }

  /** Returns all platform clients */
  getAllPlatform() {
    return this.getAll().filter((client: ApiClientUI) => client.role !== CredentialRole.service);
  }

  create(params: ApiClientCreateParams): Promise<ApiClientWithSecret> {
    // We can't rely on super.create() because it will fetch() again, losing the
    // client secret. Secret is returned only by initial create, not subsequent fetch.
    // TODO refactor service-base or distinguish between 201/200 response to avoid this
    return this.doCreate(params)
      .then(response => this.set(this.annotate(response.data)))
      .then(record => record[1]);
  }

  resetSecret(credentialId: string): Promise<ApiClientSecret> {
    // Note: using explicit Promise.resolve() ensures return type is native `Promise` not AngularJS `IPromise`.
    return Promise.resolve(this.http.post<ApiClientSecret>(`${this.baseUrl}/${credentialId}/resetSecret`, {}))
      .then(response => response.data);
  }

  doCreate(params: ApiClientCreateParams): Promise<any> {
    return Promise.resolve(this.http.post(this.baseUrl, params));
  }

  doResync(serviceIds: string[] = []) {
    const params = {
      ...(serviceIds.length ? { serviceIds } : {})
    };
    return Promise.resolve(this.http.get(this.baseUrl, { params }));
  }

  doFetch(credentialId: string) {
    return Promise.resolve(this.http.get(`${this.baseUrl}/${credentialId}`));
  }

  doDelete(credentialId: string) {
    return Promise.resolve(this.http.delete(`${this.baseUrl}/${credentialId}`));
  }

  doSave(creds: ApiClient) {
    // Currently, only the name field can be changed
    const params: APIClientUpdateParams = {
      name: creds.name
    };
    return Promise.resolve(this.http.patch(`${this.baseUrl}/${creds.clientId}`, params));
  }

  /**
   * @returns Name of the service that the ApiClient is for
   */
  getServiceName(client: ApiClient): string {
    if (!client.serviceIds || !(client.serviceIds.length)) {
      return null;
    }
    const id = client.serviceIds[0];
    const service: HSMonDemandCreatedService = this.vhsmService.get(id);
    return service ? service.name : id;
  }

  annotate(c: ApiClient): ApiClientUI {
    return {
      ...c,
      formattedCreatedAt: this.dateTimeHelper.formatDate(c.createdAt, 'DD-MMM-YYYY HH:mm'),
      serviceName: this.getServiceName(c),
    };
  }

  // Called when a service was created or deleted through the /v1/services API client.
  // Update any creds in our data store
  private onServiceChange(change: string | [string, HSMonDemandCreatedService]) {
    // TODO if typeof service == 'string' servce was deleted, remove any creds it had?
  }
}
