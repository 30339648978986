import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SubscriberGroup, User } from '@app/features/accounts/accounts.model';
import { SubscribersApi } from '@app/features/accounts/api/subscribersApi.service';
import { UsersApi } from '@app/features/accounts/api/usersApi.service';
import { AuthService } from '@app/features/auth';
import { ResetMfaDialogComponent } from '@app/shared/components/reset-mfa-dialog/reset-mfa-dialog.component';
import { Subscription } from 'rxjs';
import { DpodTableComponent } from '../dpod-table/dpod-table.component';
import { DialogService } from '../gem-dialogs';
import { ResetPasswordDialogComponent } from '../password-reset/reset-password-dialog.component';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { ConfigToken } from '@dpod/gem-ui-common-ng';

export const columnNames = [
  'username',
  'accountRole', // optional
  'subscriberGroup', // optional
  'givenName',
  'familyName',
  'createdByUsername',
  'actions',
];
const deletePrompt = 'This User will no longer be able to log in to Data Protection on Demand, '
  + 'and may lose access to their Services. Do you wish to continue?';

@Component({
  selector: 'users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Overrides the list of columns to show. */
  @Input() displayedColumns = columnNames.slice();
  /**
   * What ui-router state to visit when a username cell is clicked. The state **must**
   * accept a parameter `'id'`, in which we'll provide the id of the user being edited.
   */
  @Input() editState = 'accounts.users.details';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<User>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;
  dataSource = new MatTableDataSource<User>();
  showResetPasswordOption = true;

  private groupsMap = new Map<string, SubscriberGroup>(); // group id -> group
  private dataSub: Subscription;
  private subscriberGroupsSub: Subscription;
  private defaultFilterPredicate: (u: User, f: string) => boolean;

  constructor(
    private usersApi: UsersApi,
    private subscribersApi: SubscribersApi,
    private dialogService: DialogService,
    private authService: AuthService,
    @Inject(ConfigToken) private config: DpodUiConfig
  ) { }

  /** Optional filter applied to the Users returned by the UsersApi service */
  @Input() filter = (user: User) => true;

  ngOnInit() {
    // this option will not be displayed when fully migrated to One Welcome
    // this is just to support the functionality until we use UAA
    this.showResetPasswordOption = !this.config.FF_ONE_WELCOME;

    this.defaultFilterPredicate = this.dataSource.filterPredicate;
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);
    this.dataSub = this.usersApi.subscribe(this.onUsersChanged.bind(this));
    this.subscriberGroupsSub = this.subscribersApi.subscribe(this.onSubscriberGroupsChanged.bind(this));
    this.dataSource.data = this.usersApi.getAll().filter(this.filter);

    this.onSubscriberGroupsChanged();
  }

  ngAfterViewInit() {
    // Paginator and sort are not available until ngAfterViewInit
    this.dataSource.paginator = this.dpodTable.paginator;
    this.dataSource.sort = this.sort;

    // Set initial data & render
    this.onUsersChanged();
  }

  /**
   * @returns What ui-router state id we should visit when a username cell is clicked.
   */
  getEditState(): string {
    return this.editState;
  }

  ngOnDestroy() {
    this.dataSub.unsubscribe();
    this.subscriberGroupsSub.unsubscribe();
  }

  /**
   * Get the name of the subscriber group the user is a member of.
   * TODO: this only works if we happen to have already fetched the subscriber groups
   * and the desired group is among them. Should be improved to fetch the needed groups.
   */
  getSubscriberGroup(user: User): string {
    const groupId = user.subscriberGroups[0];
    if (!groupId) {
      return 'None';
    }

    const group = this.groupsMap.get(groupId);
    return group ? group.name : '…';
  }

  getAccountRole(user: User): string {
    return user.accountRole === 'admin' ? 'Administrator' : 'Application Owner';
  }

  onSubscriberGroupsChanged(event?: string | [string, SubscriberGroup]) {
    this.groupsMap = new Map(this.subscribersApi.getAll().map(group => [group.id, group]));
  }

  /**
   * Invoked when the the users DataStore emits an event
   */
  onUsersChanged(event?: string | [string, User]) {
    this.dataSource.data = this.usersApi.getAll().filter(this.filter);
    this.table.renderRows();
    const userId = event && event[0];
    if (userId) {
      // If a new user was added, go to the page that it's on
      const sortedData = this.dataSource.sortData(this.dataSource.data.slice(), this.dataSource.sort);
      const index = sortedData.findIndex(u => u.id === userId);
      if (index !== -1) {
        this.dpodTable.paginator.pageIndex = Math.floor(index / this.dpodTable.paginator.pageSize);
      }
    }
  }

  async deleteUser(userId: string) {
    await this.dialogService.confirm({
      title: 'Delete User?',
      content: deletePrompt,
      yesLabel: 'Delete',
      noLabel: 'Cancel',
    });

    const progress = this.dialogService.progress('Deleting user…');
    try {
      await this.usersApi.delete(userId);
    } catch (error) {
      this.dialogService.error(error);
    } finally {
      progress.close();
    }
  }

  /**
   * Determines if the row is of the user logged in
   * @param {string} username  the email of the user row
   * @return boolean  returns true if the row is of the logged in user
   */
  isLoggedInUserRow(username: string) {
    return username === this.authService.getIdentity().email;
  }

  /**
   * Determines if this user can be deleted.  A user cannot be deleted if they are root admin or trying to delete themselves
   * @param {User} user
   * @return {boolean}  returns true if the user can be deleted
   */
  canDeleteUser(user: User): boolean {
    return !user.rootAdmin && !this.isLoggedInUserRow(user.username);
  }

  resetUserPassword(userId: string) {
    const ref = this.dialogService.open<ResetPasswordDialogComponent>(ResetPasswordDialogComponent);
    ref.componentInstance.userId = userId;
  }

  changePassword() {
    this.dialogService.entityFn(result => {
      const {oldPassword, newPassword} = result;
      return this.usersApi.changePassword(oldPassword, newPassword);
    }, 'changeUserPassword', null /* resolves*/, 'Changing password...');
  }

  resetMfaToken(userId: string) {
    const modal = this.dialogService.open<ResetMfaDialogComponent>(ResetMfaDialogComponent, { windowClass: 'mfa-modal' }).componentInstance;
    modal.tenantId = this.authService.getTenantId(); // same tenant we're logged into
    modal.userId = userId;
  }

  /**
   * See https://material.angular.io/components/table/api
   * @param tenant
   * @param filter
   */
  filterPredicate(user: User, filter: string): boolean {
    // Special case for filtering against Subscriber Group name and Role, since the default
    // filter won't handle those correctly
    if (this.getSubscriberGroup(user).toLowerCase().includes(filter) ||
        this.getAccountRole(user).toLowerCase().includes(filter)) {
      return true;
    }
    // Otherwise delegate to the default filtering implementation
    return this.defaultFilterPredicate(user, filter);
  }

  onFilter(value: string) {
    this.dataSource.filter = value;
  }

}
