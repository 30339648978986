<div class="details-panel main-panel">
    <div class="details-header gem-header panel-header">New Service Elections Requiring Approval</div>

    <div *ngIf="!tenantStatus || !hasServiceAgreementData() || !(tilesStream | async)">
        <div class="details-panel-section">
            <div class="not-submitted">
                <svg class="mb-2 ghost-item">
                    <use xlink:href="#pencil-paper"/>
                </svg>
                <div class="ghost-item empty-block"></div>
                <div class="ghost-item empty-block"></div>
            </div>
        </div>
    </div>

    <div *ngIf="hasServiceAgreementData() && (tilesStream | async)">

        <div *ngIf="hasNotSubmittedServiceAgreement() || errorHasOccurredWithData()" class="details-panel-section">
            <div class="not-submitted">
                <svg class="mb-2">
                    <use xlink:href="#pencil-paper"/>
                </svg>
                <div class="message">
                    <span *ngIf="errorHasOccurredWithData()">
                        An error has occurred retrieving the service agreement
                    </span>
                    <span *ngIf="!errorHasOccurredWithData()">
                        Once {{tenant.name}} has submitted a service subscription, it
                        will
                        appear here for approval.
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="!errorHasOccurredWithData()">
            <div *ngIf="hasSubmittedServiceAgreement()" class="details-panel-section">

                <p *ngIf="statusChangeMessage !== null" class="spinner-message">
                    <spinner></spinner>
                    <span>{{statusChangeMessage}}...</span>
                </p>

                <p *ngIf="statusChangeMessage === null">
                    <button (click)="approveServiceAgreement()" class="btn btn-approve">
                        Approve
                    </button>
                    <button (click)="rejectServiceAgreement()" class="btn btn-reject">Reject</button>
                </p>

                <div class="row mb-4">
                    <div class="col-sm-12">
                        <label>Term</label>
                        <br/>
                        <strong *ngIf="serviceAgreement">{{serviceAgreement?.terms.duration}} months</strong>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <label>Services</label>
                        <br/>
                        <tenant-details-service-elections-service-list
                            [tiles]="tilesStream | async"
                            [serviceAgreement]="serviceAgreement"></tenant-details-service-elections-service-list>
                    </div>
                </div>

            </div>

            <div *ngIf="hasApprovedServiceAgreement()" class="details-panel-section" data-locator="approved-tenant-view">

                <div class="row mb-4">
                    <div class="col-sm-3">
                        <label>Term</label>
                        <br/>
                        <strong *ngIf="serviceAgreement">{{serviceAgreement?.terms.duration}} months</strong>
                    </div>
                    <div class="col-sm-3">
                        <label>Term Start</label>
                        <br/>
                        <strong>{{tenantStatus?.agreementStartDate | convertDate:undefined:'YYYY-MM-DD'}}</strong>
                    </div>
                    <div class="col-sm-3">
                        <label>Term End</label>
                        <br/>
                        <strong>{{tenantStatus.agreementEndDate | convertDate:undefined:'YYYY-MM-DD'}}</strong>
                    </div>
                    <div class="col-sm-3">
                        <label>Status</label>
                        <br/>
                        <strong class="color-approved">Approved</strong>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <label>Services</label>
                        <br/>
                        <tenant-details-service-elections-service-list
                            [tiles]="tilesStream | async"
                            [serviceAgreement]="serviceAgreement"></tenant-details-service-elections-service-list>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
