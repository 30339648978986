import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { TenantSubscription } from '@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface';
import { TermsOfServiceMessages } from '@app/features/gem-services/cloudHSM/wizard/common/terms-of-service/terms-of-service.messages';
import { TilesService } from '@app/shared/services';
import { TenantSubscriptions } from '@app/features/gem-services/services/tenant-subscription/subscription.constants';

import termsOfService from '../../../../../../static/dpod-tos.html';
import termsOfServiceCtaas from '../../../../../../static/ctaas-tos.html';
import serviceSpecificTermsPayshield from '../../../../../../static/payshield-tos.html';

@Component({
  selector: 'terms-of-service-step',
  templateUrl: './terms-of-service.step.component.html',
  styleUrls: ['../../../cloudHSM.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => TermsOfServiceStepComponent)}
  ]
})
export class TermsOfServiceStepComponent extends WizardStepBaseComponent implements OnInit {
  @Input() serviceTypeName: string;
  @Input() subscriptionInfo: TenantSubscription[];
  @Input() serviceCode = '';

  termsOfService: string;
  serviceSpecificTerms = '';

  constructor(private tilesService: TilesService) {
    super();
  }

  ngOnInit() {
    // load payshield TOS only for payshield with production subscription
    if (this.serviceCode === 'payshield' &&
      this.tilesService.doesSubscriptionExist(this as any, TenantSubscriptions.TYPE.PRODUCTION, TenantSubscriptions.STATE.ACTIVE)) {
      this.serviceSpecificTerms = serviceSpecificTermsPayshield;
    }
    // setting tos file
    this.termsOfService = this.serviceCode === 'ctaas' ? termsOfServiceCtaas : termsOfService;
  }


  getWizardTitle() {
    if (this.hasNoSubscriptionOrActiveTrialSubscription()) {
      return TermsOfServiceMessages.TRY_SERVICE_TITLE;
    }
    return TermsOfServiceMessages.CREATE_SERVICE_TITLE;
  }

  getTosInfo() {
    if (this.hasNoSubscriptionOrActiveTrialSubscription()) {
      return TermsOfServiceMessages.TRY_SERVICE_TEXT.replace('service_type', this.serviceTypeName);
    }
    return TermsOfServiceMessages.CREATE_SERVICE_TEXT;
  }

  hasNoSubscriptionOrActiveTrialSubscription() {
    return this.tilesService.hasNoSubscriptionOrActiveTrialSubscription(this as any);
  }
}
