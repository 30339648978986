import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonFormat'
})
export class JsonFormatPipe implements PipeTransform {
  transform(value: any = null, prefix = ''): string {
    return `${prefix}${JSON.stringify(value, null, 2)}`;
  }
}
