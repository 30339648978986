<div class="details-panel main-panel">
    <div class="details-panel-section">
        <div class="row">
            <div class="col-sm-2">Service Name</div>
            <div class="col-sm-2">Service Type</div>
            <div class="col-sm-2">Created</div>
            <div class="col-sm-2">Partition Serial Number</div>
            <div [attr.data-locator]="'fips-locator'" *ngIf="showFips()" class="col-sm-2">FIPS Restrictions</div>
        </div>

        <div class="row fw-bold">
            <div class="col-sm-2">{{service.name}}</div>
            <div class="col-sm-2">{{service.formattedServiceType}}</div>
            <div class="col-sm-2">{{service.formattedCreatedAt}}</div>
            <div class="col-sm-2 text-nowrap">{{service.partition_serial_number}}</div>
            <div [attr.data-locator]="'fips-locator'" *ngIf="showFips()" class="col-sm-2">{{getFipsLabel()}}</div>
        </div>
        <br/>
    </div>
</div>
