import module from './_init';

/**
 * Directive that applies some standard validation-related ng-model options to a field.
 *
 * <form>
 *   <input name="confirm" ng-model="$ctrl.confirm" validation-debounce />
 * </form>
 */
module.directive('validationDebounce', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: false,
    link: function(scope, elem, attr, ngModel) {
      ngModel.$overrideModelOptions({
        updateOn: 'default blur',
        debounce: {
          blur: 0,
          default: 500,
        },
      });
    },
  };
});
