import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TenantsService } from '../../features/tenant/tenants.service';

@Component({
  selector: 'gem-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss']
})
export class BannerImageComponent implements OnInit, OnDestroy {
  @ViewChild('banner', { static: true }) bannerImage: ElementRef;

  private logoSub: Subscription;

  constructor(private tenantsService: TenantsService) { }

  ngOnInit() {
    this.logoSub = this.tenantsService.logoStream.subscribe(this.onLogo.bind(this));
  }

  ngOnDestroy() {
    this.logoSub.unsubscribe();
    this.revoke();
  }

  onLogo(blob: Blob) {
    this.revoke();
    this.bannerImage.nativeElement.src = URL.createObjectURL(blob);
  }

  // Revoke any existing URL that we had. This prevents leaks when the logo changes
  revoke() {
    URL.revokeObjectURL(this.bannerImage.nativeElement.src);
  }

  getLogoUrl() {
    return this.tenantsService.getLogoUrl();
  }

}
