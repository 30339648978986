<div class="page panel-width">
    <ul ngbNav #navOuter="ngbNav" class="nav-pills main-tabs" [class.app-owner]="isAppOwnerOnly()" [activeId]="tenantActiveTabId">
        <li ngbNavItem="tabServicesTenant" id="tabServicesTenant">
            <button ngbNavLink data-locator="tab-services">View Services</button>
            <ng-template ngbNavContent>
                <div *ngIf="hasSynced" class="panel-width">
                    <section *ngIf="!isAppOwnerOnly()">
                        <services-table *ngIf="hasServices()"></services-table>

                        <div *ngIf="!hasServices()">
                            <header class="gem-header section-subheader">You haven’t added any services yet.
                            </header>
                            <div *ngIf="hasTiles">
                                Click on the ‘Add Service’ tab to deploy and manage services.
                            </div>
                            <div *ngIf="!hasTiles">
                                Visit the <a href="https://cloud.google.com/marketplace" externalLink>Google Cloud Marketplace</a> to add a service.
                            </div>
                        </div>
                    </section>

                    <div *ngIf="isAppOwnerOnly()">
                        <ul ngbNav #navInner="ngbNav" class="nav-pills" [activeId]="hasServices() ? appOwnerActiveTabId : 'tabAddNew'">
                            <li ngbNavItem="tabServices" id="tabServices" *ngIf="hasServices()">
                                <button ngbNavLink data-locator="tab-my-services">My Services</button>
                                <ng-template ngbNavContent>
                                    <services-table></services-table>
                                </ng-template>
                            </li>

                            <li ngbNavItem="tabAddNew" *ngIf="hasTiles" id="tabAddNew">
                                <button ngbNavLink data-locator="tab-add-service">Add Service</button>
                                <ng-template ngbNavContent><ng-container *ngTemplateOutlet="addServicesContent"></ng-container></ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="navInner"></div>
                    </div>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem="tabAdd" *ngIf="isTenantAdmin() && hasTiles" data-locator="tab-add" id="tabAdd">
            <button ngbNavLink data-locator="tab-add-service">Add Service</button>
            <ng-template ngbNavContent><ng-container *ngTemplateOutlet="addServicesContent"></ng-container></ng-template>
        </li>

        <li ngbNavItem="tabConfigure" *ngIf="hasTiles">
            <button ngbNavLink data-locator="tab-configure-services">Configure Services</button>
            <ng-template ngbNavContent>
                <services-marketplace></services-marketplace>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="navOuter"></div>
</div>

<ise-error-messages #iseErrorMessages></ise-error-messages>

<ng-template #clientPrompt let-ctx>
    <p>Your {{ctx.serviceType}} service has been added to Data Protection on Demand.</p>
    <p>Do you wish to create a Service Client to connect to this service? If you select "Not Now", you can create one later from
        the service detail page.</p>
</ng-template>

<ng-template #addServicesContent>
    <div *ngIf="isSubscriptionApiDown">
        <header class="gem-header section-subheader">Service provisioning is currently not available. Please refresh the page or try again later.
        </header>
    </div>

    <ng-container *ngIf="!isSubscriptionApiDown">
        <div *ngIf="!hasServices()" class="mb-4">
            <div class="gem-header section-header color-primary">You haven't added any
                services yet.
            </div>
            <span class="no-resource-prompt">Click on a tile below to get started.</span>
            <hr class="mt-3 mb-4"/>
        </div>

        <tiles-create-view [serviceCategoriesStream]="this.serviceCategories" [tilesStream]="this.tiles$" (tileClick)="tileClick($event)">
        </tiles-create-view>
    </ng-container>
</ng-template>

<ng-template #payshieldTrialExists>
    You already have a trial subscription to this service. You can purchase the service in your <a [uiSref]="'subscriptions'">subscriptions</a>.
</ng-template>
