import {Component, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TenantCreateBody} from '../tenant.model';
import { WizardStepBaseComponent } from '@app/components';
import { RequiresRoleService } from '@app/shared/services';
import { TenantsService } from '../tenants.service';
import slugify from 'slugify';

import * as Roles from '../../../features/auth/roles.constants';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';

@Component({
  selector: 'account-info-step',
  templateUrl: './account-info.step.component.html',
  styleUrls: ['./account-info.step.component.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => AccountInfoStepComponent)}
  ]
})
export class AccountInfoStepComponent extends WizardStepBaseComponent implements OnInit {
  @ViewChild('stepForm', { static: true }) form: NgForm;

  @Input() tenant: TenantCreateBody;
  @Input() domainSuffix: string;
  @Input() accountType: string;
  @Input() maxServiceQuota: number;
  @Input() defaultServiceQuota: number;
  canHostNameUpdate = true;

  constructor(
    public tenantsApi: TenantsService,
    private requiresRoleService: RequiresRoleService,
    @Inject(ConfigToken) public config: DpodUiConfig
  ) {
    super();
  }

  ngOnInit() {
    if (this.config.FF_ONE_WELCOME) {
      // The hostname is required by the DPoD API; it doesn't matter for OW, so it's set to a hardcoded value for OW
      // TODO:UAA Remove hostname from the model after UAA is gone
      this.tenant.hostname = 'dpodhostname';
    }
    slugify.extend({'_': '-'});
  }

  isCreatingServiceProvider() {
    return this.tenant.accountType === 'service provider';
  }

  showQuotaCount() {
    return !(this.requiresRoleService.hasRole(Roles.operator)) && this.tenantsApi.getAll().length > 0;
  }

  tenantNameChange(tenantName) {
    if (this.canHostNameUpdate && !this.config.FF_ONE_WELCOME) {
      this.tenant.hostname = tenantName ? slugify(tenantName, { remove: /[!@#$%^&*()+=`~:;"'<>,.?/{}[\]\\|]/g, lower: true }) : '';
    }
  }

  hostNameChange(hostName: string) {
    // whenever the hostname is empty, indicate tenantNameChange method that host name can be updated
    this.canHostNameUpdate = !hostName;
  }
}
