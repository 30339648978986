import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';
import {IKeyRotationPolicy, IntervalType} from './key-broker.interface';
import {sfdcTypes} from '../salesforce.constants';
import {DatePipe} from '@angular/common';

/**
 * Key Rotation Component
 *
 * Presentational component.  This is due in part to that this may be reused in the future
 * Has the ability to turn on/off, set a month/year and make an API call to the parent component
 *
 */
@Component({
  selector: 'key-rotation',
  styleUrls: ['./key-rotation.component.scss'],
  templateUrl: './key-rotation.component.html',
})
export class KeyRotationComponent implements OnChanges {

  // an array of rotation policies received from the parent component
  @Input() rotationPolicies: IKeyRotationPolicy[] = [];

  // whether an update is currently being made outside of this component
  @Input() updating = false;

  // if an outside error occurs, we want to disable this component
  @Input() disabled = false;

  // whether the policies can be edited or not (ex. Tenant Admin would not be allowed to)
  @Input() canEdit = false;

  // optionally used if the rotation date has never been set, but a secret has been generated we can figure out the
  // supposed next rotation date by using the date of the last generated secret
  @Input() lastTenantSecretGeneratedDate: string = null;

  // emits to the parent component to do something with our changed policy
  @Output() setKeyRotationPolicy: EventEmitter<IKeyRotationPolicy> = new EventEmitter<IKeyRotationPolicy>();

  // when policy is enabled this will be true // todo with multiple policy types this may be removed
  enabled = false;

  // when policy is being changed this will be true
  editing = false;

  // currently selected policy
  selectedRotationPolicy: IKeyRotationPolicy = {
    'id': null,
    'uri': null,
    'account': null,
    'application': null,
    'devAccount': null,
    'createdAt': null,
    'name': null,
    'updatedAt': null,
    'configID': null,
    'secretType': null,
    'intervalType': IntervalType.month, // initially selected
    'intervalValue': 6, // initially selected
    'active': null,
    'meta': null, // unknown what this object will possibly contain
    'lastSecretDate': null,
    'lastSecretID': null,
    'nextSecretDate': null,
  };

  // binds with the fields/text in the UI
  private keyRotationFields: IKeyRotationPolicy;

  // dropdown display value (this should automatically change when `editPolicy` is called)
  private intervalSelection: string = null;

  constructor(private datePipe: DatePipe) {
    this.keyRotationFields = Object.assign({}, this.selectedRotationPolicy); // just didn't want to have two big empty objects
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editing = false;
    if (this.hasRotationPolicies()) {
      // todo this will need to be updated when we have different rotation policies depending on secret type
      // for now, we're going to look for the secret with type `Data` (if the user doesn't specify anything, the backend defaults to `Data`)
      this.selectedRotationPolicy = this.rotationPolicies.find(p => p.secretType === 'Data');
      this.enabled = !!this.selectedRotationPolicy?.active; // todo when we have multiple, we can enable/disable on secret type
    }
  }

  cancelSetKeyRotationPolicy(): void {
    this.editing = false;

    if (!this.hasRotationPolicies()) { // we want to return them to the same state they were if they did not have any policies
      this.enabled = false;
    }
  }

  // returns text in the format of "number type"
  // does not pluralize, that's done in the template
  getKeyRotationText(): string {
    if (this.selectedRotationPolicy.id !== null) {
      const {intervalType, intervalValue} = this.selectedRotationPolicy; // todo this will need to be updated when we have different rotation policies depending on secret type
      return `${intervalValue} ${intervalType}`;
    }
    return null;
  }

  hasRotationPolicies(): boolean {
    if (this.rotationPolicies) {
      return !!this.rotationPolicies.length;
    }
    return false;
  }

  // used in the UI to determine if the wording should be pluralized
  getRotationIntervalValue(): number {
    if (this.selectedRotationPolicy.id !== null) {
      return this.selectedRotationPolicy.intervalValue;
    }
    return null;
  }

  // used in the information banner inside the rotation policy
  getRotationDateText(): string {
    let string = '';
    if (this.selectedRotationPolicy) {

      const {lastSecretDate, nextSecretDate} = this.selectedRotationPolicy;

      const intervalValue: number = parseInt(`${this.keyRotationFields.intervalValue}`, 10);

      const nullLastSecretDate = (lastSecretDate === null);

      // if the secret has never rotated, we do not want to display this text
      if (this.selectedRotationPolicy.lastSecretID && !nullLastSecretDate) {
        string = this.displayRotatedText(lastSecretDate, string);
      }

      if (nextSecretDate === null) { // rotation policy has never run
        string = this.getFutureDateText(nullLastSecretDate, intervalValue, string);
      } else { // rotation policy has never run
        string = this.getNextSecretDateString(nullLastSecretDate, lastSecretDate, nextSecretDate, intervalValue, string);
      }
    }

    return `${string}.`;
  }

  displayRotatedText(lastSecretDate: string, string: string): string {
    const lastSecretDateFormat = this.transform(lastSecretDate), lastSecretTimeFormat = this.transform(lastSecretDate, 'HH:mm');
    string += this.canEdit ? 'Your' : 'These';
    string += ` ${sfdcTypes[this.selectedRotationPolicy.secretType]}`;
    string += ` secrets were last rotated on <strong class="date">${lastSecretDateFormat}</strong> at <strong>${lastSecretTimeFormat}</strong>.  `;
    return string;
  }

  getFutureDateText(nullLastSecretDate: boolean, intervalValue: number, string: string): string {
    let date: Date = new Date(); // will be used if rotation has never occurred and secret generation hasn't occurred

    // if a rotation has never occurred but the user has generated secrets, we want the future date to be from the last generated secret
    if (nullLastSecretDate && this.lastTenantSecretGeneratedDate !== null) { // lastSecretDate should be null if nextSecretDate is null as well
      date = new Date(this.lastTenantSecretGeneratedDate);
    }
    const nextSecretDate: Date = this.getFutureDate(date, intervalValue, this.keyRotationFields.intervalType);
    string += this.getNextRotationDateText(new Date(nextSecretDate), false);

    return string;
  }

  getNextSecretDateString(nullLastSecretDate: boolean, lastSecretDateString: string, nextSecretDateString: string, intervalValue: number, string: string): string {
    if (this.editing) {
      const date = !nullLastSecretDate ? new Date(lastSecretDateString) : new Date();
      const nextSecretDate: Date = this.getFutureDate(date, intervalValue, this.keyRotationFields.intervalType);
      string += this.getNextRotationDateText(nextSecretDate, false);
    } else {
      string += this.getNextRotationDateText(new Date(nextSecretDateString), true);
    }
    return string;
  }

  // this takes the `date` and returns a string with the future date coming form the intervalValue/intervalType
  getNextRotationDateText(nextSecretDate: Date, isSet: boolean): string {

    const todaysDate: Date = new Date();

    let string = 'The next rotation ';
    string += isSet ? 'is' : 'will be'; // if the user has set a rotation policy or not
    string += ' scheduled for ';

    // if the next schedule date falls before today
    if (nextSecretDate.getTime() <= todaysDate.getTime()) {
      string += '<strong>today</strong>';
    } else {
      const nextSecretDateFormat = this.transform(nextSecretDate.toString()),
        nextSecretTimeFormat = this.transform(nextSecretDate.toString(), 'HH:mm');
      string += `<strong class="date">${nextSecretDateFormat}</strong> at <strong>${nextSecretTimeFormat}</strong>`;
    }

    return string;
  }

  // whether or not to display the "info" pane of when the last rotation, next rotation will occur
  showRotationDateText(): boolean {
    return (this.editing || (!this.editing && this.hasRotationPolicies())) && this.rotationWillHappen()[0] === false;
  }

  // transform date strings into human readable strings
  transform(date: string, format = 'longDate'): string {
    const locale = 'en-US';
    return this.datePipe.transform(date, format, null, locale);
  }

  // supply a date, it will add a number of months/years to that date and return it
  getFutureDate(date: Date, intervalValue: number, intervalType: IntervalType): Date {
    switch (intervalType) {
    case IntervalType.month:
      date.setMonth(date.getMonth() + intervalValue);
      break;
    case IntervalType.year:
      date.setFullYear(date.getFullYear() + intervalValue);
      break;
    default:
      console.error('unknown intervalType specified');
    }

    return date;
  }

  /**
   * Returns an array that will warn the user that the selected intervalValue will make the secret rotate if saved
   * @returns {(boolean | string)[]}  Will return [boolean, string]
   */
  rotationWillHappen(): [boolean, string] {
    const dateTime = new Date().getTime();

    if (this.selectedRotationPolicy.lastSecretDate === null || !this.editing) { // todo remove this.editing when supporting multiple secret types
      return [false, null];
    }

    const rotationDate: Date = new Date(this.selectedRotationPolicy.lastSecretDate);
    const {intervalType, intervalValue} = this.keyRotationFields;
    switch (intervalType) {
    case IntervalType.month:
      rotationDate.setMonth(rotationDate.getMonth() + intervalValue);
      break;
    case IntervalType.year:
      rotationDate.setFullYear(rotationDate.getFullYear() + intervalValue);
      break;
    default:
      console.error('unknown intervalType specified');
    }

    const futureDate: Date = this.getFutureDate(new Date(), intervalValue, intervalType);

    const rotationTime: number = rotationDate.getTime();

    return [dateTime > rotationTime && rotationTime > 0, `After today, the next rotation is scheduled for <strong>${this.transform(futureDate.toString())}</strong> at <strong>
        ${this.transform(futureDate.toString(), 'HH:mm')}</strong>`];
  }

  togglePolicy(): void {
    this.enabled = !this.enabled;

    // the user has no rotation policies so we immediately go to edit mode (if they are an app owner)
    if (this.enabled && !this.hasRotationPolicies() && this.canEdit) {
      this.editPolicy();
    }

    // if we toggle and we have one selected, we make an api call
    if (this.selectedRotationPolicy.id !== null) {
      const rotationPolicy: IKeyRotationPolicy = this.selectedRotationPolicy;
      rotationPolicy.active = this.enabled;
      this.savePolicy(rotationPolicy);
    }
  }

  editPolicy(): void {
    this.editing = true;
    this.keyRotationFields = {...this.selectedRotationPolicy}; // clone object, fills in the form
    const {intervalValue, intervalType} = this.keyRotationFields;
    this.setDropdownValue(intervalValue, intervalType);
  }

  setDropdownValue(intervalValue = 6, intervalType: IntervalType = IntervalType.month): void {
    const intervalTypeFormat: string = intervalValue === 1 ? intervalType : `${intervalType}s`;
    this.intervalSelection = `${intervalValue} ${intervalTypeFormat}`;
  }

  // splits the string up and sets the `keyRotationFields` values
  updateIntervalValues(dropdownValue: string): void {
    const [intervalValue, intervalType] = dropdownValue.split(' ');
    this.keyRotationFields.intervalValue = parseInt(intervalValue, 10);
    this.keyRotationFields.intervalType = IntervalType[intervalType.slice(-1) === 's' ? intervalType.slice(0, intervalType.length - 1) : intervalType];
  }

  // calls parent component
  savePolicy(rotationPolicy: IKeyRotationPolicy): void {

    if (rotationPolicy.active === null) { // todo due to the weird set up where enabling is the same as active, fix with multi secret types
      rotationPolicy.active = true;
      rotationPolicy.secretType = 'Data';
    }

    this.setKeyRotationPolicy.emit(rotationPolicy);
  }

}

import module from './_init';

module.directive('keyRotation', downgradeComponent({component: KeyRotationComponent}) as angular.IDirectiveFactory);
