<form #form>
    <div class="form-inline">
        <ng-container *ngIf="showUnlimitedToggle">
            <label class="form-check-label">
                <input type="radio" name="quotaType" class="form-control me-sm-2"
                    [checked]="isUnlimitedChecked"
                    (change)="setUnlimited()"
                    (blur)="onTouched($event)"
                    data-locator="quota-unlimited">Unlimited
            </label>

            <label class="form-check-label ms-sm-4 me-sm-2">
                <input type="radio" name="quotaType" class="form-control me-sm-2"
                    [checked]="!isUnlimitedChecked"
                    (change)="setCustom()"
                    (blur)="onTouched($event)"
                    data-locator="quota-set">Set
            </label>
        </ng-container>

        <input #quotaField #quotaModel="ngModel" type="number" class="form-control max-services"
            min="0" max="{{maxQuota}}" step="1"
            name="quotaValue"
            [disabled]="isUnlimitedChecked"
            [required]="!isUnlimitedChecked"
            [placeholder]="isUnlimitedChecked ? '' : 'Enter number'"
            [ngModel]="getQuotaViewValue()"
            (ngModelChange)="quotaModelChange($event)"
            (blur)="onTouched($event)"
            >

        <div *ngIf="quotaModel.invalid" id="invalidQuota" class="text-danger error-message">
            <div *ngIf="quotaModel.errors?.min || quotaModel.errors?.max" id="invalid-quota-msg">
                HSM on Demand Service Quota must be between 0 and {{maxQuota}}
            </div>
        </div>
    </div>
</form>
