import {Component, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

const emailRegex = /^[_A-Za-z0-9+-]+(\.[_A-Za-z0-9+-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;

/**
 * Validates an ng-model field as an email address.
 * When invalid, sets $error.email on the field.
 *
 * <form name="myForm">
 *   <input name="emailField" ng-model="$ctrl.email" email />
 *
 *   <div ng-messages="myForm.emailField.$error">
 *     <div ng-message="email">Please enter a valid email address</div>
 *   </div>
 * </form>
 */

// Using this with `type="email"` seems to make no difference.  This is still used
// no unicode support yet in the email address / username
@Component({
  selector: '[email]',
  template: '',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailComponent), multi: true}
  ],
})
export class EmailComponent implements Validator {

  validate(c: AbstractControl) {
    const value: string = c.value;
    const isValid: boolean = value !== null && (value.length === 0 || (emailRegex.test(value) && value.length < 255));
    if (!isValid) {
      return {email: true};
    }
    return null;
  }

}
