<div class="gem-card">
   <div class="svg-wrap">
      <svg class="card-icon" *ngIf="svg">
          <use attr.xlink:href="{{svg}}"></use>
      </svg>
      <img [src]="icon" alt="icon" *ngIf="icon" role="presentation"/>
   </div>
   <div class="gem-card-text">
      <p class="title">{{title}}</p>
      <div>
         {{content}}
      </div>
   </div>
</div>
