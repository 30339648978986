import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { Tile, TileServicePlan } from '@app/features/marketplace/tiles.interface';
import { TilesService } from '@app/shared/services';
import capitalize from 'lodash/capitalize';

@Component({
  selector: 'payshield-configure',
  templateUrl: './payshield-configure.component.html',
  styleUrls: ['./payshield-configure.component.scss'],
  providers: [
    { provide: WizardStepBaseComponent, useExisting: forwardRef(() => PayshieldConfigureComponent) }
  ]
})
export class PayshieldConfigureComponent extends WizardStepBaseComponent implements OnChanges, OnInit {
  @Input() servicePlans: TileServicePlan[];
  @Input() tile: Tile;
  @Output() serviceName = new EventEmitter<string>();
  @Output() subscriptionTier = new EventEmitter<any>();
  @Output() dataCenter = new EventEmitter<any>();

  serviceNameInternal = '';
  subscriptionTiers = [];
  selectedSubscriptionTier: any = '';
  selectedDataCenter: any = '';
  subscriptionTierDisabled = false;

  constructor(private tilesService: TilesService) {
    super();
  }

  ngOnInit() {
    this.setConfigureStepData();
  }

  ngOnChanges(): void {
    this.setConfigureStepData();
  }

  setConfigureStepData() {
    this.initTiersAndDataCentersFromServicePlan();
    this.selectDefaultTier();
  }

  nameChange() {
    this.serviceName.emit(this.serviceNameInternal);
  }

  onSubscriptionTierChange() {
    this.subscriptionTier.emit(this.selectedSubscriptionTier);
    this.selectDefaultDataCenter();
  }

  onDataCenterChange() {
    this.dataCenter.emit(this.selectedDataCenter);
  }

  selectDefaultTier() {
    // TODO: the hasNoSubscriptionOrActiveTrialSubscription may be replaced with something else after the purchase flow is supported
    if (this.tilesService.hasNoSubscriptionOrActiveTrialSubscription(this.tile)) {
      this.selectedSubscriptionTier = this.subscriptionTiers.find(tier => tier.free) || '';
      if (this.selectedSubscriptionTier) {
        this.subscriptionTierDisabled = true;
      }
      this.onSubscriptionTierChange();
      return;
    }
    // has subscriptions and no active trial subscription
    this.subscriptionTiers = this.subscriptionTiers.filter(tier => !tier.free);
    // only set default when subscribed to one tier
    if (this.tile.subscriptionInfo.length === 1) {
      this.selectedSubscriptionTier = this.subscriptionTiers.find(tier => tier.subscribedTo);
      if (this.selectedSubscriptionTier) {
        this.onSubscriptionTierChange();
        return;
      }
    }
    // If there is only one tier - select it
    if (this.subscriptionTiers.length === 1) {
      this.selectedSubscriptionTier = this.subscriptionTiers[0];
      this.onSubscriptionTierChange();
    }
  }

  // returns true if the tier has been purchased and false if it has not
  // Many-to-One relationship for Subscription Plan to Provisioning Plan
  isTierSubscribedTo(tierName) {
    return this.tile.subscriptionInfo.some(purchase => capitalize(purchase.plan).includes(capitalize(tierName)));
  }

  private initTiersAndDataCentersFromServicePlan() {
    if (this.servicePlans) {
      this.subscriptionTiers = this.servicePlans.map(plan => ({
        label: plan.name,
        code: plan.name,
        free: plan.free,
        dataCenters: plan.schemas?.service_instance?.create?.parameters?.properties?.datacenter?.['enum'] || [],
        subscribedTo: this.isTierSubscribedTo(plan.name)
      }));
    }
  }

  private selectDefaultDataCenter() {
    const tierDataCenters = this.selectedSubscriptionTier?.dataCenters;
    // If there is only one data center in the tier - select it
    this.selectedDataCenter = tierDataCenters?.length === 1 ? tierDataCenters[0] : '';
    this.onDataCenterChange();
  }
}
