import { environment } from '@env/environment';
import { LoadRemoteModuleScriptOptions } from '@angular-architects/module-federation';
import { Injectable } from '@angular/core';

// This enum contains all the components that are coming from the MFE modules
export enum MfeComponents {
  HsmServiceDetails = 'hsm-service-details',
  PayShieldServiceDetails = 'payShield-service-details'
}

// This service contains definitions of the MFE modules that dpod-ui consumes
@Injectable()
export class MfeRegistryService {
  // All remote MFEs are configured here
  private remotes: Record<string, LoadRemoteModuleScriptOptions> = {
    hsmServiceUi: {
      type: 'script',
      // remoteEntry may vary depending on the deployment
      // if an MFE module should be loaded from a remote server in prod - please inject `ConfigToken` and read the remote address from there
      remoteEntry: environment.production ? 'hsm-service-ui/remoteEntry.js' : 'http://localhost:8081/remoteEntry.js',
      remoteName: 'hsmServiceUi',
      exposedModule: './web-components'
    },
    payShieldServiceUi: {
      type: 'script',
      // remoteEntry may vary depending on the deployment
      // if an MFE module should be loaded from a remote server in prod - please inject `ConfigToken` and read the remote address from there
      remoteEntry: environment.production ? 'payshield-service-details/remoteEntry.js' : 'https://storage.googleapis.com/thales-cpl-mfe-poc/payshield-service-details/remoteEntry.js',
      remoteName: 'payshieldServiceDetails',
      exposedModule: './web-components'
    }
  };

  // This const maps each MFE-provided component to its MFE module
  private mfeComponentsInRemotes: Record<MfeComponents, LoadRemoteModuleScriptOptions> = {
    [MfeComponents.HsmServiceDetails]: this.remotes.hsmServiceUi,
    [MfeComponents.PayShieldServiceDetails]: this.remotes.payShieldServiceUi
  };

  getMfeComponentConfiguration(component: MfeComponents) {
    return this.mfeComponentsInRemotes[component];
  }
}
