<main class="main-viewport-holder main-viewport-holder-fullscreen d-flex flex-column align-items-center">

    <img class="pb-1 verify-image" src="images/dpod-icon-verify-email.svg" alt="envelope icon" role="presentation">

    <h3 class="p-4">Welcome! Please verify your email to get started.</h3>

    <p class="pt-1 mx-2 verify-box text-center">
        We have sent an email to {{email}}. Please click on the verification button in the email to
        get started using Thales Data Protection on Demand.<br>

        <span *ngIf="state === States.INITIAL">
        If you have not received it, <button class="resend border-0 p-0" (click)="resend()" role="button">click here</button> to resend.
        </span>
        <span *ngIf="state === States.WORKING">Resending…</span>
    </p>

</main>
