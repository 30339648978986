import { dpodScopeRegex, unverifiedScopeSuffix } from './auth.constants';
import { tenantIdParam } from './auth.interface';

function getScopeMatch(scopes: readonly string[], sguidRequested = false): RegExpExecArray {
  for (const s of scopes) {
    const match = dpodScopeRegex.exec(s);
    if (match && !sguidRequested) {
      return match;
    }
    if (match && match[2]) {
      return match;
    }
  }
  return null;
}

/**
 * Given the `scope` claim from the access token, returns the id of the tenant
 * that the user is logged into.
 *
 * @returns The tenant id, or `null`
 */
export function getTenantId(scopes: readonly string[]): string {
  const match = getScopeMatch(scopes);
  return match ? match[1] : null;
}

/**
 * Given the `scope` claim from the access token, check if the user belongs to the subscriber
 * group with id `sguid`.
 *
 * @returns `true` or `false`
 */
export function hasSubscriberGroupId(scopes: readonly string[], sguid: string): boolean {
  for (const s of scopes) {
    const match = getScopeMatch([s], true);
    if (match && match[2] === sguid) {
      return true;
    }
  }
  return false;
}

/**
 * Given the `scope` claim from the access token, returns whether the logged-in-user's
 * email address has been verified.
 *
 * @returns `true` if their email is verified, `false` if not verified
 */
export function isEmailVerified(scopes: readonly string[]): boolean {
  for (const s of scopes) {
    if (s.endsWith(unverifiedScopeSuffix)) {
      return false;
    }
  }
  return true;
}

function readUriQueryParams(uri: string) {
  if (!uri) {
    return null;
  }
  const queryStringStart = uri.indexOf('?');
  if (queryStringStart === -1 || queryStringStart === uri.length - 1) {
    return null;
  }
  return new URLSearchParams(uri.substring(queryStringStart + 1));
}

/**
 * Given the URI (that can be either path+query string or hash+query string) and the tenant id,
 * appends the tenant id as a query param into the URI only if the tenant id is not empty.
 */
export function addTenantIdIntoUri(uri: string, tenantId: string) {
  if (!tenantId) {
    return uri;
  }
  let queryParams = readUriQueryParams(uri);
  if (!queryParams) {
    queryParams = new URLSearchParams();
  }
  queryParams.append(tenantIdParam, tenantId);
  const queryStringStart = uri.indexOf('?');
  if (queryStringStart === -1) {
    return `${uri}?${queryParams.toString()}`;
  }
  return `${uri.substring(0, queryStringStart)}?${queryParams.toString()}`;
}

/**
 * Given the URI (that can be either path+query string or hash+query string), extract the tenant id
 * as a query param. If there is no tenant id present in the URI, the method returns empty string.
 */
export function extractTenantIdFromUri(uri: string) {
  if (!uri) {
    return '';
  }
  return readUriQueryParams(uri)?.get(tenantIdParam) || '';
}

/**
 * Given the URI (that can be either path+query string or hash+query string), removes the tenant id
 * query param from it. If there is no tenant id present in the URI, the method does not modify the URI.
 */
export function removeTenantIdFromUri(uri: string) {
  if (!uri) {
    return uri;
  }
  const queryParams = readUriQueryParams(uri);
  if (!queryParams?.has(tenantIdParam)) {
    return uri;
  }
  queryParams.delete(tenantIdParam);
  const queryStringStart = uri.indexOf('?');
  const queryParamsAsString = queryParams.toString();
  // queryParams.size doesn't work in old browsers
  if (!queryParamsAsString) {
    return uri.substring(0, queryStringStart);
  }
  return `${uri.substring(0, queryStringStart)}?${queryParamsAsString}`;
}
