<div class="modal-body row">
    <h5 class="mb-2 d-flex align-items-center">
        Redirect Notice
    </h5>

    <div *ngIf="!showTerms">
        You will be redirected our partner site where you will be able to set up your service. By clicking "Proceed"
        you agree to the Redirect Terms.
    </div>

    <div *ngIf="showTerms" class="terms">
        You are about to be transferred to an external site run by a third party (“External Site”). By clicking
        “Proceed” you agree to the following: Thales and its affiliated entities (collectively “Thales”) MAKE NO
        WARRANTIES OF ANY KIND AND disclaim all liability and assume no responsibility with respect to: (i) any
        non-Thales offering made available by a third party; or (ii) the information, or any use of the information, on
        the External Site. Thales assumes or undertakes NO LIABILITY for any loss or damage suffered as a result of the
        use or misuse of the information on the External Site or any non-Thales offering made available by a third
        party.
    </div>

    <div class="mt-3 mb-3 w-100">
        <button data-locator="terms-button" class="fake-link-button" (click)="showTerms = !showTerms">
            {{ showTerms ? "Hide Redirect Terms" : "Show Redirect Terms" }}
        </button>
    </div>

    <div class="float-sm-start">
        <a [href]="url" (click)="onClick()" externalLink>
            <button class="btn btn-next" name="reset" type="button" ngbAutofocus>Proceed</button>
        </a>
        <button class="btn btn-default ms-2" name="cancel" type="button" (click)="modal.dismiss()">Cancel</button>
    </div>
</div>
