import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  @ViewChild('contentSlot', {read: ViewContainerRef, static: true}) contentSlot: ViewContainerRef;
  @ViewChild('static', {static: true}) staticTemplate: TemplateRef<any>;

  title: string;
  content?: any;
  context?: { [key: string]: any };
  yesLabel = 'Yes';
  noLabel = 'No';

  componentRef: ComponentRef<any> = null;
  viewRef: ViewRef = null;

  constructor(
    private activeModal: NgbActiveModal,
    private moduleCFR: ComponentFactoryResolver,
    private injector: Injector,
  ) { }

  ngOnInit() {
    const ctx = this.context || {};
    if (typeof this.content === 'string') {
      // For this case, we really just want contentSlot to contain a single TextNode. There must be
      // an easier way than using a whole template
      const str = this.content;
      ctx.text = str;
      this.content = this.staticTemplate;
    }

    if (this.content instanceof TemplateRef) {
      this.viewRef = this.contentSlot.createEmbeddedView(this.content, {
        $implicit: ctx,
      });
    } else if (this.content) {
      const componentFactory = this.moduleCFR.resolveComponentFactory(this.content);
      const component = this.componentRef = this.contentSlot.createComponent(componentFactory);
      Object.assign(component.instance, ctx);
      this.viewRef = component.hostView;
    } else {
      throw new Error('No content provided for modal');
    }
  }

  ngOnDestroy() {
    if (this.viewRef && !this.viewRef.destroyed) {
      this.viewRef.destroy();
      this.viewRef = null;
    }
  }

  close() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
