<h5 class="mb-3">
    <svg class="icon-svg" viewBox="0 0 16 16">
        <use xlink:href="#kmip"/>
    </svg>
    Key Information
</h5>


<div class="form-group row">
    <div class="col-sm-4">
        <strong>
            <label for="key-name">Key Name</label>
        </strong>
    </div>
    <div class="col-sm-8">
        <div class="d-flex">
            <input data-locator="key-name" id="key-name" #keyName class="me-1 form-control" [value]="key.name"
                   readonly/>
            <copy-input data-locator="copy-key" [ngxClipboard]="keyName"
                        (click)="hideAllCopiedMessages(); copiedKeyName = true"></copy-input>
        </div>
        <gem-feedback class="d-block mt-1" text="Copied to clipboard!" [(trigger)]="copiedKeyName"></gem-feedback>
    </div>
</div>

<div class="form-group row">
    <div class="col-sm-4">
        <strong>
            <label for="key-id">Key ID</label>
        </strong>
    </div>
    <div class="col-sm-8">
        <div class="d-flex">
            <input data-locator="key-id" id="key-id" #keyId class="me-1 form-control" [value]="key.id" readonly/>
            <copy-input data-locator="copy-id" [ngxClipboard]="keyId"
                        (click)="hideAllCopiedMessages(); copiedKeyId = true"></copy-input>
        </div>
        <gem-feedback class="d-block mt-1" text="Copied to clipboard!" [(trigger)]="copiedKeyId"></gem-feedback>
    </div>
</div>

<hr class="mt-3"/>

<div class="form-group row">
    <div class="col-sm-4">
        <strong>
            <label>
                Status
            </label>
        </strong>
        <br/>
        {{key.state}}
    </div>
    <div class="col-sm-4">
        <strong>
            <label>
                Created
            </label>
        </strong>
        <br/>
        {{key.formattedCreatedAt}}
    </div>
    <div class="col-sm-4">
        <strong>
            <label>
                Last Accessed
            </label>
        </strong>
        <br/>
        {{key.formattedUpdatedAt}}
    </div>
</div>

<div class="row g-0">
<button class="btn btn-next" data-locator="save-and-close" (click)="closeModal.emit(key)">Save and Close</button>
</div>
