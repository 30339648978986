export * from './banner-image.component';

// AngularJs compatibility module
import { BannerImageComponent } from './banner-image.component';
import { downgradeComponent } from '@angular/upgrade/static';
import angular from 'angular';

export default angular.module('gem.bannerImage', [])
  .directive('gemBannerImage', downgradeComponent({component: BannerImageComponent}))
  .name;
