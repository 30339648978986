import { Inject, Injectable } from '@angular/core';
import { HttpService } from '@app/ajs-upgraded-providers';
import { IHttpResponse, IHttpService } from 'angular';
import {
  ServiceAgreement,
  ServiceAgreementDetails,
  Tenant,
  TenantAccountStatus, Plan, OrderRequest, MBU, Marketplace, OrderFeatures
} from '@app/features/tenant/tenant.model';
import * as roles from '../../features/auth/roles.constants';
import { RequiresRoleService } from '.';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { AuthService } from '@app/features/auth';
import { getAccountTypeLabel } from '@app/features/tenant/tenant.util';
import { defer } from 'rxjs/internal/observable/defer';
import { from } from 'rxjs';
import { TenantSubscription } from '@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface';
import { DialogService } from '@app/components';

/**
 * Makes requests directly to the Mozart microservice which communicates with Maestro
 */
@Injectable()
export class BackofficeService {

  // emits every TenantAccountStatus that is fetched from the server as they occur.
  public accountStatusStream: Observable<TenantAccountStatus>;
  public accountStatusMap: Map<string, TenantAccountStatus>;
  public serviceAgreement: ServiceAgreementDetails;


  private baseUrl = '/v1/backoffice';
  private accountStatusSubject = new Subject<TenantAccountStatus>();

  constructor(
    @Inject(HttpService) private http: IHttpService,
    private authService: AuthService,
    private requiresRoleService: RequiresRoleService,
    private dialogService: DialogService
  ) {
    this.accountStatusStream = this.accountStatusSubject.asObservable();
  }

  getAccountStatus(tenantId: string): Promise<TenantAccountStatus | void> {
    if (this.requiresRoleService.hasRole(roles.operator)) {
      // SP tenants directly underneath the operator are not screened, so won't
      // have a DB entry in Maestro. Don't bother fetching their status. Remove
      // this check if we start screening across the board.
      return Promise.resolve();
    }

    return this.http.get(`${this.baseUrl}/accountStatuses/${tenantId}`)
      .then((response: IHttpResponse<TenantAccountStatus>) => {
        this.setAccountStatus([response.data]);
        return response.data;
      }).catch(e => {
        if (e.status !== 404) {
          throw e;
        }
        this.setAccountStatus([{
          tenantId,
          evaluationStartDate: null,
          evaluationEndDate: null,
          evaluationStatus: null,
          agreementApprovalStatus: null,
        }]);
      });
  }

  /**
   * Returns the account status of all tenant child under a SP
   */
  listAccountStatuses(): Promise<TenantAccountStatus[] | void> {
    if (this.requiresRoleService.hasRole(roles.spadmin)) {
      return this.http.get(`${this.baseUrl}/accountStatuses`).then(
        (response: IHttpResponse<TenantAccountStatus[]>) => {
          this.setAccountStatus(response.data);
          return response.data;
        }
      ).catch(e => {
        if (e.status !== 404) {
          throw e;
        }
      });
    }

    return Promise.resolve();
  }

  /**
   * Returns the service agreement of a tenant child
   * @param tenantId
   */
  getServiceAgreement(tenantId: string): Promise<ServiceAgreementDetails> {
    return this.http.get(`${this.baseUrl}/serviceAgreements/${tenantId}`)
      .then((response: IHttpResponse<ServiceAgreementDetails>) => {
        this.serviceAgreement = response.data;
        return response.data;
      });
  }

  /**
   * Used by Service Providers to reject a service agreement submitted by their tenant child
   * Proceeds to fetch account status afterwards and update all subscribers of that status
   * @param tenantId
   */
  rejectServiceAgreement(tenantId: string): Promise<TenantAccountStatus | void> {
    return this.http.delete(`${this.baseUrl}/serviceAgreements/${tenantId}`)
      .then(() => this.getAccountStatus(tenantId));
  }

  /**
   * Used by Service Providers to approve a service agreement submitted by their tenant child
   * Proceeds to fetch account status afterwards and update all subscribers of that status
   * @param tenantId
   */
  approveServiceAgreement(tenantId: string): Promise<TenantAccountStatus | void> {
    return this.http.patch(`${this.baseUrl}/serviceAgreements/${tenantId}`, {})
      .then(() => this.getAccountStatus(tenantId));
  }

  /**
   * Used by Eval Tenant Administrators to submit a service agreement to their Service Provider
   * @param agreement
   */
  submitServiceAgreement(agreement: ServiceAgreement): Promise<any> {
    return this.http.post(`${this.baseUrl}/serviceAgreements`, agreement)
      .catch(err => {
        // Angular Zone doesn't reject a promise if the promise has a finally block.
        // Throwing an error won't help here, the global error handler won't be triggered, we have to invoke dialogService.error manually.
        // https://github.com/angular/angular/issues/27840
        // https://github.com/angular/angular/issues/47562
        this.dialogService.error(err);
        return Promise.reject(err);
      })
      .finally(() => {
        // Submitting a service agreement affects the TenantAccountStatus for the tenant that we
        // are logged into. Re-fetch from the server to update the data store.
        return this.getAccountStatus(this.authService.getTenantId());
      });
  }

  /**
   * This is a helper method that will generate and return an orderRequest
   * @param selectedMbu - The selected mbu info in the service agreement
   * @param requestedActionDate - when this order should be in effect
   * @param duration - the number of months selected
   */
  getOrderRequest(selectedMbu: MBU, requestedActionDate: string, duration: number): OrderRequest {
    const identity = this.authService.getIdentity();
    const term = `P${duration}M`;
    const features: OrderFeatures = {};
    if (selectedMbu?.quantity != null) { // != checks for both null and undefined
      features.quantity = selectedMbu.quantity;
    }
    // build the orderRequest
    // The status is sent as "UNAPPROVED" for both ISE and ASE
    // only the mandatory fields are filled here
    return {
      marketplaceName: Marketplace.DPOD,
      creationDate: new Date().toISOString(),
      status: 'UNAPPROVED',
      submitter: {
        email: identity?.email,
        userId: this.authService.getUserId(),
        givenName: 'user', // UI does not have this info - Mozart anyway gets the user profile from Shepherd in backend
        familyName: 'user' // UI does not have this info - Mozart anyway gets the user profile from Shepherd in backend
      },
      items: [{
        action: 'CREATE', // it is always CREATE for both ISE and ASE
        serviceType: selectedMbu?.serviceType?.shortCode,
        externalServiceType: selectedMbu?.serviceType?.shortCode,
        servicePlan: selectedMbu?.planId,
        externalServicePlan: selectedMbu?.planId,
        requestedActionDate: requestedActionDate,
        term: term,
        features
      }]
    };
  }

  /**
   * The new implementation of service elections when submitting ISE and ASE
   * The UI directly calls the /orders POST instead of /serviceAgreements POST
   * @param order - the orderRequest containing order info
   */
  submitOrder(order: OrderRequest): Promise<any> {
    const tenantId = this.authService.getTenantId();
    return this.http.post(`${this.baseUrl}/tenants/${tenantId}/orders`, order)
      .catch(err => {
        this.dialogService.error(err);
        return Promise.reject(err);
      })
      .finally(() => {
        // Submitting a service agreement affects the TenantAccountStatus for the tenant that we
        // are logged into. Re-fetch from the server to update the data store.
        return this.getAccountStatus(this.authService.getTenantId());
      });
  }

  /**
   * Returns the terms of service of a Service Provider to the tenant admin
   */
  getTermsOfServicePDF(): Promise<any> {
    return this.http.get(`${this.baseUrl}/tos/${this.authService.getTenantId()}`,
      {responseType: 'arraybuffer'});
  }

  public getAccountTypeLabel(tenant: Tenant) {
    return getAccountTypeLabel(tenant.accountType);
  }


  /**
   * Gets the list of subscriptions for the tenant id.
   * @param tenantId
   * @returns an Observable of TenantSubscription list
   */
  listSubscriptions(tenantId?: string): Observable<TenantSubscription[]> {
    const endpoint = tenantId ? `/subscriptions?tenantId=${tenantId}` : '/subscriptions';

    // use defer on a promise because Observables are lazy and Promises are always eager and get fired immediately
    return defer(() => from(this.http.get<TenantSubscription[]>(`${this.baseUrl}${endpoint}`)
      .then(res => res.data)));
  }

  /**
   * Gets the list of plans from a serviceType.
   * @param serviceType short code
   * @returns an Observable of a list of all plans
   */
  getServicePlan(serviceType: string): Observable<Plan[]> {
    return defer(() => from(this.http.get<Plan[]>(`${this.baseUrl}/products/${serviceType}`)
      .then(res => res.data)));
  }

  /**
   * Takes the list of account statuses, sets them to a map and pushes updates to subscribers
   * of `accountStatusStream`
   * @param accountStatuses
   */
  private setAccountStatus(accountStatuses: TenantAccountStatus[]): Map<string, TenantAccountStatus> {
    // initializes the map, letting components know that data has returned
    if (!this.accountStatusMap) {
      this.accountStatusMap = new Map();
    }
    accountStatuses.forEach(accountStatus => {
      this.accountStatusMap.set(accountStatus.tenantId, accountStatus);
      this.accountStatusSubject.next(accountStatus);
    });
    return this.accountStatusMap;
  }
}
