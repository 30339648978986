export interface IKeyRotationPolicy {
  'id': string;
  'uri': string;
  'account': string;
  'application': string;
  'devAccount': string;
  'createdAt': string;
  'name': string;
  'updatedAt': string;
  'configID': string;
  'secretType': string;
  'intervalType': IntervalType;
  'intervalValue': number;
  'active': boolean;
  'meta': object;
  'lastSecretDate': string;
  'lastSecretID': string;
  'nextSecretDate': string;
}

export enum IntervalType {
  month = 'month',
  year = 'year',
}

