<div class="page panel-width">
    <div *ngIf="showHeaderTabs" class="tab-tabset">
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a class="nav-link" data-locator="tab-users" uiSref=".users" uiSrefActive="active">Users</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-locator="tab-subscriber-groups" uiSref=".subscribers" uiSrefActive="active">Subscriber Groups</a>
            </li>
        </ul>
    </div>

    <ui-view></ui-view>
    <div ui-view="accountsSubView"></div>
</div>
