import {Component, Input} from '@angular/core';

/**
 * Takes the Bootstrap alert and wraps it with a component that can include an icon.  Text inside will be transcluded
 *
 * Note: Comes with zero styling and resorts to Bootstrap styling.  Additional `class` styling should be added to the
 *       entire application or to the individual component where this will be used
 *
 *       ex. <alert-with-icon [icon]="'#info-round'" [class]="'alert-custom-warning'">
                    <strong>Hello</strong> World!
                </alert-with-icon>
 */
@Component({
  selector: 'alert-with-icon',
  templateUrl: './alert-with-icon.html',
  styleUrls: ['./alert-with-icon.component.scss'],
})
export class AlertWithIconComponent {

  // currently set up to use SVG icons
  @Input() icon: string = null;

  // classes to give to the parent element
  @Input() class: string = null;

  // uses the bootstrap alert dismiss
  @Input() isDismissible = false;

}
