import { downgradeInjectable } from '@angular/upgrade/static';
import angular from 'angular';
import { AuthRequestInterceptor } from './auth-request.interceptor.ng1';
import { AuthService } from './auth.service';
import { SessionExpiryRequestResponseInterceptor } from './session-expiry-request-response.interceptor.ng1';
import { SessionExpiryService } from './session-expiry.service';
import SanitizerDecorator from './bearer-sanitizer.decorator.ng1';

import ValidStates from './valid-states.constants';
import * as roles from './roles.constants';
import { AuthUAAService } from '@app/features/auth/auth-uaa.service';

export { AuthService } from './auth.service';
export { AuthUAAService } from './auth-uaa.service';

// AngularJS module for compatibility with legacy code
export default angular.module('app.auth', [])
  .constant('AuthScopes', roles)
  .constant('ValidStates', ValidStates)
  .decorator('$exceptionHandler', SanitizerDecorator)
  .service('AuthService', downgradeInjectable(AuthService))
  .service('AuthUAAService', downgradeInjectable(AuthUAAService))
  .service('AuthRequestInterceptor', AuthRequestInterceptor)
  .service('SessionExpiry', downgradeInjectable(SessionExpiryService))
  .service('SessionExpiryRequestResponseInterceptor', SessionExpiryRequestResponseInterceptor)
  .name;
