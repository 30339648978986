import template from '../templates/modal-tenant-secret-generation-failed.html';

class TenantSecretGenerationFailedController {

  $onInit() {
    this.errors = this.resolve.errors || [];
  }

  // returns an array of string to display in the UI
  getErrorMessages() {
    return this.errors.map(item => item.status.message);
  }

}

export default {
  template,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: TenantSecretGenerationFailedController,
};
