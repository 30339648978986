import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { ServicesTableComponent } from '@app/components/services-table/services-table.component';
import { UsersTableComponent } from '@app/components/users-table/users-table.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import 'angularjs-dropdown-multiselect';
import { UsersEditModule } from '@app/components/users-edit';
import { SharedModule } from '@app/shared';
import { AccountsComponent } from './accounts.component';
import { accountsStates } from './accounts.states';
import { SubscribersApi } from './api/subscribersApi.service';
import { UsersApi } from './api/usersApi.service';
import './dialogs/modal-changeUserPassword.component';
import './dialogs/modal-createSubscribers.component';
import './dialogs/modal-createUser.component';
import { SubscriberGroupsTableComponent } from './subscriber-groups-table/subscriber-groups-table.component';
import { SubscriberGroupsComponent } from './subscriber-groups/subscriber-groups.component';
import { SubscriberGroupEditComponent } from './subscribers-edit/subscriber-group-edit.component';
import './subscribers-edit/subscribers-edit.component';
import './subscribers.component';
import './users.component';
import './users.scss';
import { UsersComponent } from './users/users.component';
import { EkmsApi } from '@app/features/linkage/ekmsApi.service';

@NgModule({
  declarations: [
    AccountsComponent,
    UsersComponent,
    SubscriberGroupsComponent,
    SubscriberGroupEditComponent,
    UsersComponent,
    SubscriberGroupsTableComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    UsersEditModule,
    UIRouterUpgradeModule.forChild({
      states: accountsStates,
    }),
  ]
})
export class AccountsModule {
}

// default export, remove when this feature has no more AngularJS consumers
// eslint-disable-next-line @typescript-eslint/no-var-requires
export default require('./_init').default
  .directive('subscriberGroupsList', downgradeComponent({ component: SubscriberGroupsTableComponent }))
  .directive('usersTable', downgradeComponent({ component: UsersTableComponent }))
  .directive('servicesTable', downgradeComponent({ component: ServicesTableComponent }))
  .service('UsersApi', downgradeInjectable(UsersApi))
  .service('EkmsApi', downgradeInjectable(EkmsApi))
  .service('SubscribersApi', downgradeInjectable(SubscribersApi))
  .name;
