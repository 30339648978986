<div class="row">
    <div class="col-sm-12">
        <label class="color-primary">
            Select the type(s) of Tenant Secrets you want to generate:</label>
    </div>
</div>

<ng-container *ngFor="let secret of sfdcTypes; let i = index">
    <div class="row" *ngIf="i % 2 == 0">
        <div class="col-sm-6">
            <label>
                <input type="checkbox"
                       [checked]="isChecked(secret.val)"
                       (click)="toggleTenantSecret(secret.val)"
                       value="{{secret.val}}"/>
                {{secret.name}}
            </label>
        </div>
        <div *ngIf="sfdcTypes[i + 1]" class="col-sm-6">
            <label>
                <input type="checkbox"
                       [checked]="isChecked(sfdcTypes[i + 1].val)"
                       (click)="toggleTenantSecret(sfdcTypes[i + 1].val)"
                       value="sfdcTypes[i + 1].val"/>
                {{sfdcTypes[i + 1].name}}
            </label>
        </div>
    </div>
</ng-container>
