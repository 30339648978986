import { Component, Input, OnInit } from '@angular/core';
import { WizardComponent } from '@app/components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tile, TileServicePlan } from '@app/features/marketplace/tiles.interface';
import { ServiceCreationWizard } from '@app/features/gem-services/cloudHSM/wizard/service.wizard-interface';
import { ServiceBrokerService , TilesService } from '@app/shared/services';

@Component({
  selector: 'payshield-wizard',
  templateUrl: './payshield-wizard.component.html',
  styleUrls: ['./payshield-wizard.component.scss']
})
export class PayshieldWizardComponent extends WizardComponent implements OnInit, ServiceCreationWizard {
  @Input() tile: Tile;
  @Input() servicePlan: TileServicePlan[];
  @Input() serviceType: string;
  @Input() loading = false;

  serviceName: string;
  subscriptionTier: { label: string; code: string } = { label:'', code:'' };
  dataCenter: { label: string; code: string } = { label:'', code:'' };
  serviceTypeName: string;
  modalTitle: string;

  constructor(activeModal: NgbActiveModal, private serviceBrokerService: ServiceBrokerService, private tileService: TilesService) {
    super(activeModal);
  }

  ngOnInit() {
    this.serviceTypeName = this.tile?.name || this.serviceType;
    this.modalTitle = this.getModalTitle();
  }

  finish(): void {
    const creatorFn = () => {
      const createParams = {
        datacenter: this.dataCenter
      };

      // One of {tileId, servicePlan} or {serviceType} is required
      return this.serviceBrokerService.create({
        name: this.serviceName,
        tileId: this.tile.id,
        servicePlan: this.subscriptionTier.code,
        serviceType: this.serviceType,
        createParams,
      });
    };
    this.close(creatorFn);
  }

  getModalTitle() {
    if (this.tileService.hasNoSubscriptionOrActiveTrialSubscription(this.tile)) {
      return `Add ${this.serviceTypeName}`;
    }
    return `Purchase ${this.serviceTypeName}`;
  }
}
