<!-- Do NOT use dataLength for this table - dataLength implies filtering is performed in the main browser thread -->
<dpod-table (filter)="onFilter($event)" (page)="onPageChanged($event)" [hidden]="tableHidden">
  <table #table mat-table multiTemplateDataRows
         matSort [matSortActive]="activeSort.field" [matSortDirection]="activeSort.direction"
         (matSortChange)="onSortChanged($event)" [dataSource]="dataSource"
         aria-describedby="Audit logs table">
    <!-- Meta switch pseudo column -->
    <ng-container matColumnDef="metaSwitch">
      <th scope="col" mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let log" class="meta-switch-column col-fixed-width" tabindex="0" role="button"   
          (click)="onMetaSwitchClick(log)" (keyup.enter)="onMetaSwitchClick(log)"
          [title]="log._metaShown ? 'Hide log metadata' : 'Show log metadata'">
        <svg class="meta-switch-content col-fixed-width" [class.meta-switch-content-shown]="log._metaShown">
          <use xlink:href="#arrow-down"/>
        </svg>
      </td>
    </ng-container>

    <!-- Status column -->
    <ng-container matColumnDef="status">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The outcome of the action">Status</th>
      <td mat-cell *matCellDef="let log" class="status-column">
        <span [title]="log.status">{{log.status}}</span>
      </td>
    </ng-container>

    <!-- Source column -->
    <ng-container matColumnDef="source">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The source of the log event">Source</th>
      <td mat-cell *matCellDef="let log" class="source-column">
        <span [title]="log.source">{{log.source}}</span>
      </td>
    </ng-container>

    <!-- Resource ID column -->
    <ng-container matColumnDef="resourceID">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The unique ID of the resource where the log event occurred">Resource ID</th>
      <td mat-cell *matCellDef="let log" class="resourceID-column">
        <span [title]="log.resourceID">{{log.resourceID}}</span>
      </td>
    </ng-container>

    <!-- Actor ID column -->
    <ng-container matColumnDef="actorID">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The unique ID of the actor who triggered the action on the resource">Actor ID</th>
      <td mat-cell *matCellDef="let log" class="actorID-column">
        <span [title]="log.actorID">{{log.actorID}}</span>
      </td>
    </ng-container>

    <!-- Action column -->
    <ng-container matColumnDef="action">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The action taken on the resource">Action</th>
      <td mat-cell *matCellDef="let log" class="action-column">
        <span [title]="log.action">{{log.action}}</span>
      </td>
    </ng-container>

    <!-- Time column -->
    <ng-container matColumnDef="time">
      <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef title="The time of the action in RFC3339 format">Time (UTC)</th>
      <td mat-cell *matCellDef="let log" class="time-column col-fixed-width">
        <span [title]="log.time">{{log.time}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="meta">
      <td mat-cell *matCellDef="let log" [attr.colspan]="displayedColumns.length">
        <div class="meta-cell-content">{{log.meta | jsonFormat:metaPrefix}}</div>
      </td>
    </ng-container>

    <!-- Rows definition -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let log; columns: displayedColumns;" class="audit-log-data-row"></tr>
    <tr mat-row *matRowDef="let log; columns: ['meta']" [class.meta-hidden]="!log._metaShown"></tr>
  </table>
</dpod-table>
