'use strict';

import _module from './_init';
import template from './navbar.component.html';

class NavbarController {
}

export default _module.component('gemNavbar', {
  controller: NavbarController,
  bindings: {
    states: '<',
  },
  template,
});
