<h5 class="mb-3">
    <svg class="icon-svg icon-svg-big">
        <use xlink:href="#info-round" />
    </svg>
    Here is Your New Client Secret
</h5>

<hr class="modal-full-hr mt-3 mb-3" />

<div class="form-group row">
    <div class="col fw-bold">
        <label>Credential Name</label>
    </div>
    <div class="col text-end">{{credentialName}}</div>
</div>

<div class="form-group row">
    <div class="col fw-bold">
        <label>Credential Type</label>
    </div>
    <div class="col text-end">{{credentialType}}</div>
</div>

<div *ngIf="serviceName" class="form-group row">
    <div class="col fw-bold">
        <label>Service Name</label>
    </div>
    <div class="col text-end">{{serviceName}}</div>
</div>


<alert-with-icon [icon]="'#bang-triangle'" [class]="'alert-custom-warning not-saved-warning'">
    Please copy and save your <strong>Client Secret</strong> in a secure location; it will not be stored.
</alert-with-icon>

<form>
    <div class="form-group row mb-0">
        <div class="col fw-bold">
            <label>Client secret</label>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-sm-11">
            <input class="form-control me-1" name="secret" value="{{secret}}" #secretField readonly>
        </div>

        <div class="col-sm-1 p-0">
            <copy-input [ngxClipboard]="secretField" (cbOnSuccess)="copied = true"></copy-input>
        </div>
    </div>
</form>

<hr class="modal-full-hr mt-4" />

<div class="row g-0">
    <div class="col-sm-3">
        <div class="float-sm-start">
            <button class="btn btn-next" name="next" type="button" data-error-ok (click)="activeModal.close()">Close</button>
        </div>
    </div>
    <div class="col">
        <gem-feedback class="d-block mt-1" text="Client Secret copied to clipboard" [(trigger)]="copied"></gem-feedback>
    </div>
</div>
