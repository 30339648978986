<div class="page panel-width" *ngIf="isSubscriptionsTabAvailable()">
    <ul ngbNav #nav="ngbNav" class="nav-pills main-tabs">
        <li ngbNavItem="subscriptions" id="tabServiceSubscriptions">
            <button ngbNavLink data-locator="tab-subscriptions">Subscriptions</button>
            <ng-template ngbNavContent>
                <tenant-subscriptions [sectionHeader]="'Service Subscriptions'"></tenant-subscriptions>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
