import { Component } from '@angular/core';
import { DocsService } from '@app/shared/services';

@Component({
  selector: 'gem-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  constructor(public docsService: DocsService) {}
}
