import { Component, Input } from '@angular/core';
import { FipsState, HSMonDemandCreatedService } from '@app/features/gem-services/cloudHSM/cloudHSM.model';
import { ServiceShortCode } from '@app/features/gem-services/services.constants';

/**
 * Shows configuration details about a service, such as the service certificate fingerprint
 * and the service type.
 */
@Component({
  selector: 'service-configuration',
  templateUrl: './service-configuration.component.html',
})
export class ServiceConfigurationComponent {
  // the service object containing the configuration detail
  @Input() service: HSMonDemandCreatedService = {} as HSMonDemandCreatedService;

  /**
   * Returns a friendly label to display in the UI
   */
  getFipsLabel(): 'Enabled' | 'Disabled' {
    return this.service.fipsState === FipsState.Fips ? 'Enabled' : 'Disabled';
  }

  showFips(): boolean {
    // todo this is not the best solution long term and is more because there's no current way
    // returned from the backend response to know whether we should display the fips section or not
    return this.service.serviceType !== ServiceShortCode.p2pe;
  }
}
