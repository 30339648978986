import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogService, WizardComponent } from '@app/components';
import { ServiceCreationWizard } from '@app/features/gem-services/cloudHSM/wizard/service.wizard-interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesforceService } from '@app/ajs-upgraded-providers';
import {
  SalesforceServiceConfiguration,
  sfdcTypes
} from '@app/features/gem-services/cloud/salesforce/salesforce.constants';
import { ServiceBrokerService } from '@app/shared/services';
import { SecretStatus } from '@app/features/gem-services/cloud/salesforce/salesforce.common';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { PROGRESS_CREATE_MSG } from '@app/features/gem-services/services.constants';

@Component({
  selector: 'salesforce-wizard',
  templateUrl: './salesforce-wizard.component.html',
})
export class SalesforceWizardComponent extends WizardComponent implements ServiceCreationWizard, OnInit {

  @Input() tile: Tile;
  @Input() servicePlan: string;
  @Input() serviceType: string;
  @Input() code: string;
  @Input() service = {
    name: '',
  };

  accessToken: string; // created from the `code`
  secretsStatusList: SecretStatus[] = [];
  // the serviceConfig will be populated once the wizard re-opens after connecting to Salesforce
  // initially when opening the wizard this value will be null
  serviceConfig: SalesforceServiceConfiguration | null = null;

  constructor(_activeModal: NgbActiveModal, private dialogService: DialogService, private sbs: ServiceBrokerService,
              @Inject(SalesforceService) private salesforceService) {
    super(_activeModal);
  }

  get step(): number {
    return this.currentStepIdx;
  }

  /**
   * After step 2 we proceed to create the service which will close the Salesforce wizard and re-open it on the summary step
   * @param step
   */
  set step(step: number) {
    // add the `serviceConfig` check to differentiate from the ngOnInit which will generate secrets
    if (step === 2 && this.serviceConfig === null) {
      this.createService();
    } else {
      this.currentStepIdx = step;
    }
  }

  ngOnInit(): void {
    if (!this.secretsStatusList.length) {
      this.secretsStatusList = Object.entries(sfdcTypes).map((item, idx) => {
        return {
          generate: false,
          name: item[1],
          value: item[0],
          status: {
            message: 'not generating'
          },
        };
      });
    }

    // if we open up on the second step, with a service configuration we begin to generate secrets
    if (this.currentStepIdx === 2 && this.serviceConfig !== null) {
      this.startGeneratingSecrets();
    }
  }

  /**
   * Creates an array binding for the UI.  Secret status is updated when the api calls are resolved
   * These secrets are all intended to generate in parallel
   **/
  /* eslint-disable-next-line require-await */
  async startGeneratingSecrets(): Promise<void> {
    // returns index of the secret in the status list
    // used to update the correct status on the summary page
    const getSecretIndexByValue = name => this.secretsStatusList.findIndex(item => item.value === name);

    // get list of keys that we plan to generate
    for (const secret of this.secretsStatusList) {
      if (secret.generate) {
        secret.status.message = 'pending';
        // todo this structure of status.message/status in the case it throws an error should be updated as it's pointless
        // once the details page is updated we should just move to just a string for the status or status string, error string
        // `save` proceeds to generate a tenant secret
        this.salesforceService.save(this.serviceConfig.id, secret.value)
          .then(() => this.secretsStatusList[getSecretIndexByValue(secret.value)].status.message = 'success')
          .catch(error => this.secretsStatusList[getSecretIndexByValue(secret.value)].status = error);
      }
    }
  }

  createService() {
    const params = {
      name: this.service.name,
      tileId: this.tile.id,
      deviceType: 'cryptovisor_fips',
      serviceType: 'salesforce_key_broker',
      servicePlan: 'base',
      createParams: {
        accessCode: this.code,
      },
    };
    const promise = () => {
      const progress = this.dialogService.progress(PROGRESS_CREATE_MSG);
      let createdService;
      return this.sbs.create(params)
        .then(createdServiceInstanceUrl => {
          createdService = {
            service_id : createdServiceInstanceUrl.service_id
          };
          return this.salesforceService.getConfigs(this.service.name);
        }) // config is created at this point, retrieve the summary
        .then(res => res.data)
        .then((serviceConfig: SalesforceServiceConfiguration) => {
          progress.close();
          const modal = this.dialogService.open<SalesforceWizardComponent>(SalesforceWizardComponent, {
            windowClass: 'modal-wide',
          });
          const servicesWizard = modal.componentInstance;
          servicesWizard.tile = this.tile;
          servicesWizard.serviceType = this.serviceType;
          servicesWizard.servicePlan = this.serviceType; // same as serviceType
          servicesWizard.currentStepIdx = 2; // sets the current step
          servicesWizard.secretsStatusList = this.secretsStatusList;
          servicesWizard.serviceConfig = serviceConfig;
          servicesWizard.code = this.code;

          // the createdService object is expected by services.component to retrieve service_id
          return createdService;
        })
        .finally(() => progress.close());
    };
    this.close(promise);
  }

}
