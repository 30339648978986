import appModule from './_init';
import template from './tenant-app.html';

const tabLabels = {
  accounts: 'Accounts',
  credentials: 'Credentials',
  reports: 'Reports',
  services: 'Services',
  spadminusers: 'Users',
  tenants: 'Tenants',
  administration: 'Administration',
  subscriptions: 'Subscriptions',
  logs: 'Logs',
};

// Sort order for tabs: lower numbers come first
const tabOrder = {
  tenants: 0,
  accounts: 1,
  services: 2,
  reports: 3,
  spadminusers: 4,
  credentials: 5,
  administration: 6,
  subscriptions: 7,
  logs: 8,
};

function byTabOrder(a, b) {
  return tabOrder[a] - tabOrder[b];
}

class TenantAppController {

  constructor($scope, AuthService, SessionExpiry, AuthScopes, ValidStates, $injector) {
    this.$scope = $scope;
    this.authService = AuthService;
    this.authScopes = AuthScopes;
    this.validStates = ValidStates;
    this.sessionExpiry = SessionExpiry;
    this.$location = $injector.get('$location');
    this.ekmsapi = $injector.get('EkmsApi');
    this.accountStatusService = $injector.get('AccountStatusService');
    this.injector = $injector;

    this.$scope.showApp = !this.isAdminAndVerified();

    this.$scope.navItems = [];
    if (this.isLoggedIn() && this.authService.isEmailVerified()) {
      // Call of this method ensures that account status is fetched,
      // so that the rest of dpod-ui can use account data
      this.accountStatusService.getAccountStatus();
    }
    if (this.isLoggedIn() && this.isAdminAndVerified()) {
      this.ekmsapi.getStatus()
        .then(res => this.getSignupStatus(res),
          err => this.displayDpodUI());
    } else {
      this.$scope.navItems = this.getNavItems();
    }
    // After the user selects their tenant the DPoD-UI has to update user menu in the header
    // TODO:UAA Remove ? after UAA is gone
    this.tenantChangedSubscription = this.authService.tenantChangedSubject?.subscribe(() => this.$scope.navItems = this.getNavItems());
  }

  getSignupStatus(res) {
    const account = res.data.accounts.find(v => v.marketplace === 'GOOGLE');
    if (account) {
      const {state: associationState, id: marketplaceAccountId} = account;
      if (associationState === 'PENDING') {
        this.$location.path('/linkage')
          .search({marketplace_account_id: marketplaceAccountId});
        this.$scope.showApp = true;
      } else if (associationState === 'LINKED') {
        this.$scope.navItems = this.getNavItems();
        this.$location.path('/services');
        this.$scope.showApp = true;
      } else {
        this.displayDpodUI();
      }
    } else {
      this.displayDpodUI();
    }
  }

  displayDpodUI() {
    this.$scope.navItems = this.getNavItems();
    this.$scope.showApp = true;
  }

  isRedirecting() {
    return this.authService.isRedirecting();
  }

  isLoggedIn() {
    return this.authService.getIdentity() && !this.sessionExpiry.isSessionEnded();
  }

  // ng1 `requires-role` doesn't prevent the component from firing, therefore we use the ng-if to prevent it from rendering all together
  isAdminAndVerified() {
    return this.authService.hasScope(this.authScopes.admin) && this.authService.isEmailVerified();
  }

  getNavItems() {
    const authService = this.authService;
    const authScopes = this.authScopes;
    const validStates = this.validStates;

    // Collect all the states that we have access to
    const states = new Set();
    const add = states.add.bind(states);

    if (authService.isEmailVerified()) {
      Object.keys(validStates)
        .forEach(role => {
          if (authService.hasScope(authScopes[role])) {
            validStates[role].forEach(add);
          }
        });
    }
    return Array.from(states)
      .sort(byTabOrder)
      .map(state => ({text: tabLabels[state] || `!${state}!`, state}));
  }

  $onDestroy() {
    // TODO:UAA Remove ? after UAA is gone
    this.tenantChangedSubscription?.unsubscribe();
  }
}

appModule.component('tenantApplication', {
  controller: TenantAppController,
  template,
});
