// use this directive to restrict which elements can be seen by a user with a given role.
//    example: <div requires-role="example.role"></div>
// when this directive is active, it checks RequiredRoleService to see if the current
// user has the specified role.  if not, the element is hidden with the CSS style
// 'display: none'.  the comments on RequiredRoleService explain how to configure it
// to check the user credentials.
// note: only one role can be specified in the attribute value. if no role is specified,
// the directive does nothing.
export const RequiresRoleDirectiveFactory = function(RequiresRoleService) {
  return {
    restrict: 'A',
    scope: {
      role: '@requiresRole',
    },
    link(scope, elem) {
      if (!scope.role) {
        return;
      }
      // future work: support lists of roles (requires-role="[admin,operator]")

      // hide the element, because simply removing the element from the DOM won't trigger angular's
      // cleanup code -- see https://github.com/angular/angular.js/issues/14376#issuecomment-232129209
      // for more details
      if (!RequiresRoleService.hasRole(scope.role)) {
        elem.hide();
      }
    },
  };
};
RequiresRoleDirectiveFactory.$inject = ['RequiresRoleService'];
