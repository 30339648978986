import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/angularjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  @Input() prevLabel;
  @Input() currLabel;

  constructor(private _stateService: StateService) { }

  // clicking the parent level on the breadcrumb should navigate one level up
  navigate() {
    this._stateService.go('^');
  }

}
