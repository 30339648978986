<form #stepForm="ngForm">
    <ng-container *ngIf="selectedServiceType">
        <div id="selected-service" class="service-headers"></div>
        <div class="others" *ngIf="tiles && tiles.length">
            <div data-locator="tileSelected" *ngFor="let tile of tiles">
                <tile-select *ngIf="tile.enabled && tile.shortCode == selectedServiceType" (quantityChange)="selectedTile($event)" [tile]="tile" [showQuantity]="showQuantity" [initQty]="1"></tile-select>
            </div>
        </div>
    </ng-container>
    <div class="tile-holder" *ngIf="!selectedServiceType && tiles && tiles.length" >
        <div data-locator="tile" *ngFor="let tile of tiles">
            <tile-select *ngIf="tile.enabled && tile.shortCode != selectedServiceType" (quantityChange)="selectedTile($event)" [tile]="tile" [showQuantity]="true"></tile-select>
        </div>
    </div>
    <input type="hidden" name="tileSelection" [(ngModel)]="tileSelection" required/>

    <ng-container>
        <section>
            <header data-locator="category-name" class="gem-header section-subheader tile-category-header"></header>
            <div class="pt-2 pb-4 card-deck deck-align">
            <card-plan *ngFor="let plan of servicePlans; first as isFirst; last as isLast"
                       [cardTitle]="plan.displayName"
                       [cardDetail]="plan.description"
                       [selected]="selectedPlan === plan"
                       [first]="isFirst"
                       [last]="isLast"
            >
                <div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-next btn-next-new btn-inside-card" [ngClass]="{'btn-inside-card-selected': selectedPlan === plan}" (click)="onPlanSelect(plan)">
                            {{ selectedPlan !== plan ? 'Select' : '&#10003;'  }}
                        </button>
                    </div>
                </div>
            </card-plan>
            </div>
        </section>
    </ng-container>
</form>
