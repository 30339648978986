import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantsService } from '../../../features/tenant/tenants.service';
import { UsersApi } from '../../../features/accounts/api/usersApi.service';
import { AuthService } from '../../../features/auth';

enum State {
  Start = 'start',
  Progress = 'progress',
  Done = 'done',
}

/**
 * Dialog to reset the MFA token for a tenant admin or for a regular user.
 */
@Component({
  selector: 'reset-mfa-dialog',
  templateUrl: './reset-mfa-dialog.component.html',
})
export class ResetMfaDialogComponent implements OnInit {
  /**
   * GUID of the tenant that the reset will act on
   */
  @Input() tenantId: string;

  /**
   * GUID of the user whose MFA token will be reset. Optional, if not provided the tenant admin's token will be reset.
   */
  @Input() userId: string;

  state = State.Start;

  // Whether the user is resetting their own MFA token (true) or someone else's (false).
  private isSelfReset = false;

  // Whether we're resetting the tenant admin's MFA token or another user
  private isAdminReset = false;

  private service: ApiService;

  constructor(
    private modal: NgbActiveModal,
    private injector: Injector,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.isSelfReset = this.userId === this.authService.getUserId();
    this.isAdminReset = !this.userId;
    if (this.isAdminReset) {
      this.service = this.injector.get(TenantsService);
    } else {
      this.service = this.injector.get(UsersApi);
    }
  }

  getTitle() {
    return this.state === 'done' ? 'Multi-factor Authentication Token Reset' : 'Reset Multi-Factor Authentication Token?';
  }

  getMessage(): string {
    if (this.isSelfReset) {
      return `Data Protection on Demand will reset your multi-factor authentication token.
        You will be required to register for multi-factor authentication again on next sign in.`;
    }
    return `Data Protection on Demand will reset the multi-factor authentication token for this user.
      This user will be required to register for multi-factor authentication again on next sign in.`;
  }

  cancel() {
    this.modal.dismiss();
  }

  /**
   * Perform the reset operation
   * Note: The promise is returned solely for unit testing purposes
   */
  reset() {
    this.state = State.Progress;

    let promise;
    if (this.isAdminReset) {
      promise = (this.service as TenantsService).resetAdminMfaToken(this.tenantId);
    } else {
      promise = (this.service as UsersApi).resetMfaToken(this.tenantId, this.userId);
    }

    return promise.then((/* undefined*/) => {
      this.state = State.Done;
    })
      .catch(error => {
      // close ourself, allow the error to be handled by global error dialog
        this.cancel();
        throw error;
      });
  }
}

type ApiService = TenantsService | UsersApi;
