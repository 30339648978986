/** Decoded JWT token in the implicit flow */
export interface JWT {
  email: string;
  exp: number;
  iss: string;
  scope: string[];
  user_id: string;
}

/** Subset of the claims in the user's access token that Hodor provides to us. */
export interface HodorJwtClaims {
  email: string;
  iss: string;
  scope: string[];
  user_id: string;
}

/**
 * Interface implemented by auth services
 *
 * TODO: after Hodor is enabled, remove this and just use AuthServiceHodor
 */
export interface IAuthService {
  /** @deprecated */
  getToken(): string;

  getTenantId(): string;

  hasSubscriberGroupId(sguid: string): boolean;

  hasScope(s: string): boolean;

  isEmailVerified(): boolean;

  isRedirecting(): boolean;

  isStateValid(s: string): boolean;

  login(returnTo?: string): void;

  logout(): void;

  /** @deprecated */
  reset(): void;
}

export enum LoginAction {
  LoggedIn = 'LoggedIn',
  MultiChoice = 'MultiChoice', // Support in dpod-ui later (multi-tenant case)
  OwRedirect = 'OwRedirect',
  DpodRedirect = 'DpodRedirect' // Support in dpod-ui later (multi-tenant case)
}

export interface LoginState {
  loginAction: LoginAction;
  dpodUiState?: string; // dpod-ui state restored after back-redirect from OW
  redirectionUrl?: string; // Support in dpod-ui later (multi-tenant case)
}

export const sessionLoggedOut = 'sessionLoggedOut';
export const selectTenantStateId = 'selectTenant';

// TODO: The property names can change
export interface TenantAuthority {
  authorities: string[];
  id: string;
  name: string;
  region: string;
  userId: string;
}

// Tenant id query/hash param
export const tenantIdParam = 'tenantId';

// Local storage keys
export const dpodUiTokenKey = 'dpodui-access_token';
export const dpodTenantIdKey = 'dpod-tenant-id';
