<form #stepForm="ngForm">
    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">
                    <div class="row">
                        <div class="col-sm-4">
                            <label for="service-name" class="control-label">Service Name</label>
                            <input id="service-name" #name="ngModel" name="serviceName" class="form-control"
                                   minlength="4"
                                   maxlen="45"
                                   [(ngModel)]="service.name" validationDebounce required validationIcon
                                   trim="blur"
                                   autofocus>
                            <div *ngIf="name.dirty" id="error-message-serviceName" class="text-danger error-message"
                                 role="alert">
                                <div *ngIf="name.errors?.required">Please enter a service name</div>
                                <div *ngIf="name.errors?.maxlen">Maximum 45 characters</div>
                                <div *ngIf="name.errors?.minlength">Minimum 4 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="details-panel">
                <div class="details-panel-section">
                    <div class="row">
                        <div class="col-sm-4">

                            <label for="azure-subscription" class="control-label">Select Azure Subscription</label>
                            <select [(ngModel)]="azureSubscription" size class="form-control"
                                    (change)="azureSubscriptionChange.emit(azureSubscription)"
                                    data-locator="azureSubscription"
                                    [disabled]="azureSubscriptionList.length === 0"
                                    name="azure-subscription" id="azure-subscription" required>
                                <option value="undefined" disabled selected>Select a Subscription</option>
                                <option *ngFor="let sub of azureSubscriptionList" [ngValue]="sub">
                                    {{sub.displayName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>
