import { Component, forwardRef, Input } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { ProtectVServiceCreateParams } from './protectV.interface';

/**
 * ProtectV Configure Step
 */
@Component({
  selector: 'configure-step',
  templateUrl: './configure.step.component.html',
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => ConfigureStepComponent)}
  ]
})
export class ConfigureStepComponent extends WizardStepBaseComponent {

  @Input() service: ProtectVServiceCreateParams;
  @Input() credentials = {
    credentialsName: '',
  };

  // if the credentials name has not been interacted with, we generate a value for it
  generateCredName(event: KeyboardEvent) {
    const hasBeenAltered = this.stepForm.controls.credentialsName.touched;
    if (!hasBeenAltered) {
      let credentialsName = 'VMES_';
      const serviceNameField = event.target as HTMLInputElement;
      credentialsName += serviceNameField.value.trim().replace(/\s/g, '_');

      this.credentials.credentialsName = credentialsName;

      // ensure validation is also ran against the credentials name field
      // We're using the 'dirty' state here rather than 'touched' to force the validation
      // icon to appear.
      this.stepForm.controls.credentialsName.updateValueAndValidity();
      this.stepForm.controls.credentialsName.markAsDirty();
    }
  }

}
