import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { WizardStepBaseComponent } from './wizard-step';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.scss'],
  providers: []
})
export class WizardComponent implements AfterContentInit {
  @Input() title: string;
  @Input() showSteps = true;
  @Input() loading = false;

  // Setting this to true yields a final page with only a Close button (no Back/Cancel)
  @Input() lastSlideClose = false;

  /** Emits an event when the wizard is closing due to completion. Delivers the result object (if any).
   */
  @Output() submitResponse: EventEmitter<any> = new EventEmitter<any>();

  // two way binding for current step allows parent component to control the step
  @Input() currentStepIdx = 0;
  @Output() currentStepIdxChange = new EventEmitter<number>();

  /** Emits an event when the wizard is cancelled. */
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  // This holds the transcluded step child elements
  @ContentChildren(WizardStepBaseComponent, {descendants: true})
  public stepChildren: QueryList<WizardStepBaseComponent>;

  // step model
  private steps: WizardStepBaseComponent[] = [];

  constructor(private _activeModal: NgbActiveModal) {}

  // This runs after our <ng-content> children have initialized
  ngAfterContentInit() {
    this.steps = this.stepChildren.toArray();

    // For some reason during the initial render we get 0 steps so we must subscribe and
    // update later.
    this.stepChildren.changes.subscribe(newSteps => this.steps = newSteps.toArray());
    this.gotoStep(this.currentStepIdx, true);
  }

  currentStep() {
    return this.steps[this.currentStepIdx];
  }

  getStepNames(): string[] {
    return this.steps.map(item => item.name);
  }

  gotoStep(newStepIdx, isInit = false) {
    const stepCount = this.steps.length;
    if (newStepIdx === stepCount && !isInit) {
      this.submitResponse.emit(); // wizard is closing
    } else {
      this.currentStepIdx = newStepIdx;
      // tell the steps whether they are selected
      this.steps.forEach((step, i) => {
        step.active = (newStepIdx === i);
      });
      this.currentStepIdxChange.emit(newStepIdx);
    }
  }

  getStepClasses(stepIndex) {
    const currentStep = this.currentStepIdx;
    return {
      complete: stepIndex < currentStep,
      active: stepIndex === currentStep,
      todo: stepIndex > currentStep,
    };
  }

  showClose() {
    return this.isLastSlide() && this.lastSlideClose;
  }

  isLastSlide() {
    return this.currentStepIdx === this.steps.length - 1;
  }

  isCurrentStepValid() {
    const form = this.currentStep().stepForm;
    return !form || form.valid;
  }

  nextLabel() {
    return this.isLastSlide() ? 'Finish' : 'Next';
  }

  getSteps() {
    return this.steps;
  }

  dismiss() {
    this.cancel.emit();
    this.getActiveModal().dismiss();
  }

  /**
   * Subclasses should call this function to complete the wizard
   * @param result
   */
  close(result?: any) {
    this.submitResponse.emit(result);
    this.getActiveModal().close(result);
  }

  private getActiveModal() {
    return this._activeModal;
  }

}
