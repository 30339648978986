<div *ngIf="selected" class="box blue"></div>

<button class="card col"
        [ngClass]="{ 'card-selected': selected, 'card-first': first, 'card-last': last }"
        [disabled]="true"
>
    <div class="card-top">
        <div class="card-title-container">
            <h6 class="card-title">{{cardTitle}}</h6>
        </div>

        <div class="justify-content-center align-items-end" (click)="$event.stopPropagation()" data-locator="plan-select-button">
            <ng-content></ng-content>
        </div>

        <div class="card-sub-padding"></div>
    </div>
    <mat-divider></mat-divider>
    <div class="card-middle card-text-container">
        Includes
    </div>
    <mat-divider> </mat-divider>

    <div class="card-bottom card-text-container my-1">
        <p class="my-3 text-start card-detail">{{cardDetail}}</p>
    </div>
</button>
