import { Component, OnInit } from '@angular/core';
import { AbstractTilesViewComponent } from './AbstractTilesView';

/**
 * Renders marketplace tiles organized into categories.
 */
@Component({
  selector: 'tiles-configure-view',
  templateUrl: './tiles-configure-view.component.html',
  styleUrls: ['./tiles-view.component.scss']
})
export class TilesConfigureViewComponent extends AbstractTilesViewComponent implements OnInit {
}
