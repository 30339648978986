<form #stepForm="ngForm">
    <div class="details-panel main-panel">
        <div *ngFor="let term of availableTerms" class="details-panel-section">
            <radio-button (check)="checked($event)" [attr.data-locator]="'term-' + term.id" checked="{{getChecked(term)}}" name="{{term.id}}"
                          text="{{term.label}}"
                          value="{{term.id}}">
            </radio-button>
            <select (ngModelChange)="customDurationChanged($event)"
                    *ngIf="getChecked(term) && term.durationOptions && term.durationOptions.length" [ngModel]="term.duration"
                    class="form-control select-control" data-locator="custom-duration"
                    name="selectedDuration"
                    size>
                <option *ngFor="let durationOption of term.durationOptions"
                        [ngValue]="durationOption.id">
                    {{durationOption.label}}
                </option>
            </select>
            <div *ngIf="getChecked(term) && term.requestedActionDateOptions && term.requestedActionDateOptions.length"
                 class="control-with-label">
                <label class="control-label" for="selectedRequestedActionDate">Beginning</label>
                <select (ngModelChange)="customRequestedActionDateChanged($event)" [ngModel]="term.requestedActionDate"
                        class="form-control select-control" data-locator="custom-requested-action-date"
                        name="selectedRequestedActionDate"
                        size>
                    <option *ngFor="let requestedActionDateOption of term.requestedActionDateOptions"
                            [ngValue]="requestedActionDateOption">
                        {{requestedActionDateOption | date: 'MMMM d, y'}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</form>
