import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import * as AuthScopes from '../auth/roles.constants';
import { AdministrationComponent } from './administration.component';
import { AppLogoComponent } from './app-logo/app-logo.component';
import { ImgUploadComponent } from './img-upload/img-upload.component';
import { InvalidImageModalComponent } from './img-upload/invalid-image.modal.component';
import { TenantRegistrationComponent } from './tenant-registration/tenant-registration.component';

const administrationStates: Ng2StateDeclaration[] = [
  {
    url: '/administration',
    name: 'administration',
    component: AdministrationComponent,
    params: {
      requiresRole: AuthScopes.spadmin,
    },
  },
];

@NgModule({
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: administrationStates,
    }),
  ],
  declarations: [
    AdministrationComponent,
    AppLogoComponent,
    ImgUploadComponent,
    InvalidImageModalComponent,
    TenantRegistrationComponent,
  ]
})
export class AdministrationModule { }
