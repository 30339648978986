import { RedirectParams, showRedirectModal, URLQueryParams } from '@app/features/gem-services/cloud/office365/keybroker.common';
import { notSecretErrorStatusMessages } from './salesforce.constants';

/**
 * Prompts the user if they want to be redirected to Salesforce to authorize keybroker
 * note: this gets the info call via the old way client-prov, can be removed when Salesforce is set up with the new service instances
 * @param {object} redirectComponentParams  Params that will be passed into the promptRedirect Component
 * @param {object} urlParams                Params that will be appended to the end of the redirect URL string
 * @return {Promise}
 */
export async function redirectSalesforcePrompt(redirectComponentParams: RedirectParams = {}, urlParams: URLQueryParams = {}): Promise<void> {
  try {
    const configResponse = await this.salesforceService.getInfo();
    configResponse.data.codeResponseAuthURL += '&prompt=login%20consent'; // requires user to authorize with Salesforce with every service creation
    showRedirectModal.call(this, configResponse.data, redirectComponentParams, urlParams);
  } catch (error) {
    this.dialogService.error(error);
  }
}

// internal wizard interface to control the status of a generated secret
export interface SecretStatus {
  name: string; // display name
  value: string;
  generate: boolean;
  status: {
    message: string;
  };
}

/**
 * opens dialog that displays the errors returned from generating secret(s)
 * @param   secretsStatusList   array that contains name, status.message (to act like the response from back end)
 * @return  void
 */
export function learnMoreAboutSecretsError(secretsStatusList: SecretStatus[]) {
  const errors = secretsStatusList.filter(item => {
    if (!notSecretErrorStatusMessages.includes(item.status.message)) {
      if (!item.status.message) { // should only occur if `message` was not returned (secret type does not exist)
        item.status.message = `Error trying to generate a ${item.name} secret. Please ensure your Salesforce organization supports this secret type.`;
      }
      return true;
    }
    return false;
  });

  this.dialogService.openAJS('tenantSecretGenerationFailed', {
    errors,
  }, {
    // this modal by default opens behind the wizard and isn't displayed
    // we add this class so that it has a higher z-index than the wizard
    // this occurs because this is using $uibModal and doesn't see that an additional modal is open
    // this can be able to be removed when we update the modal to NG2
    windowClass: 'tenant-secret-generation-failed',
  });
}
