<div class="page">
  <section>
    <div class="gem-header section-header">Logs</div>
    <header class="gem-header sub-header">Search for audit logs</header>
    <div class="panel-width">
      <audit-logs-filter (displayLog)="displayLog($event)" (downloadLog)="downloadLog($event)"></audit-logs-filter>
      <audit-logs-table [logFile]="displayedLogFile" (fileProcessed)="onFileProcessed()"></audit-logs-table>
    </div>
  </section>
</div>
