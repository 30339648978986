import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '@app/components/gem-dialogs';
import { ResetMfaDialogComponent } from '@app/shared/components/reset-mfa-dialog/reset-mfa-dialog.component';
import { UsersApi } from '../../features/accounts/api/usersApi.service';
import { AuthService } from '../../features/auth';
import * as Roles from '../../features/auth/roles.constants';
import { MetricsService } from '@dpod/gem-ui-common-ng';
import { ClipboardService } from 'ngx-clipboard';
import { GemToastService } from '@dpod/gem-ui-common-ng/toast';
import { Subscription } from 'rxjs';

interface MenuItem {
  text: string;
  action: () => void;
  locator: string;
}

@Component({
  selector: 'gem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userName = '';
  userRole = '';
  tenantId = '';
  MENU_OPTIONS: MenuItem[];

  private tenantChangedSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private usersApi: UsersApi,
    private dialogService: DialogService,
    private metrics: MetricsService,
    public clipboard: ClipboardService,
    public toastService: GemToastService,
  ) {
    this.buildMenuOptions();
  }

  ngOnInit() {
    this.setIdentity();
    // After the user selects their tenant the DPoD-UI has to update user info in the header
    // TODO:UAA Remove ? after UAA is gone
    this.tenantChangedSubscription = this.authService.tenantChangedSubject?.subscribe(() => {
      this.buildMenuOptions();
      this.setIdentity();
    });
  }

  ngOnDestroy(): void {
    // TODO:UAA Remove ? after UAA is gone
    this.tenantChangedSubscription?.unsubscribe();
  }

  copyToClipboard(e: Event) {
    e.stopPropagation(); // When we click on clipboard icon, it should not open the menu items. For this, we need stopPropagation here
    this.toastService.success('Tenant ID copied to clipboard');
  }

  setIdentity() {
    const identity = this.authService.getIdentity();
    if (!identity) {
      return;
    }
    this.userName = identity.email;
    this.userRole = this.getRoleLabel();
    this.tenantId = this.authService.getTenantId();

    // Use the email as the unique id that identifies users for metrics purposes
    this.metrics.identify(identity.email);
  }

  changePassword() {
    const identity = this.authService.getIdentity();
    if (!identity) {
      throw new Error('You must be logged in to change your password.'); // should not happen
    }
    this.dialogService.entityFn(result => {
      const {oldPassword, newPassword} = result;
      return this.usersApi.changePassword(oldPassword, newPassword);
    }, 'changeUserPassword', null /* resolves*/, 'Changing password...');
  }

  resetMfaToken() {
    const modal = this.dialogService.open<ResetMfaDialogComponent>(ResetMfaDialogComponent, {windowClass: 'mfa-modal'}).componentInstance;
    modal.userId = this.authService.getUserId();
    modal.tenantId = this.authService.getTenantId();
  }

  getRoleLabel(): string {
    const authService = this.authService;
    const scopes = Roles;
    switch (true) {
    case authService.hasScope(scopes.admin):
      return 'Tenant Administrator';
    case authService.hasScope(scopes.owner):
      return 'Application Owner';
    case authService.hasScope(scopes.spadmin):
      return 'Service Provider Admin';
    case authService.hasScope(scopes.operator):
      return 'DPoD Operator';
    }
    return ''; // should not happen
  }

  private buildMenuOptions() {
    this.MENU_OPTIONS = [
      {
        text: 'Sign Out',
        action: this.authService.logout.bind(this.authService),
        locator: 'logout',
      },
    ];
    // We don't need to use FF_ONE_WELCOME here since authService is constructed based on FF_ONE_WELCOME
    if (!this.authService.isTenantSelectionRequired()) {
      // The following options are available only inside a tenant since they are going through Cambio
      this.MENU_OPTIONS = [
        {
          text: 'Change Password',
          action: this.changePassword.bind(this),
          locator: 'change-password',
        },
        {
          text: 'Reset MFA Token',
          action: this.resetMfaToken.bind(this),
          locator: 'reset-mfa',
        },
        ...this.MENU_OPTIONS
      ];
    }
  }
}
