/* /// <reference types="angular" />
/// <reference types="angular-ui-bootstrap" /> */

// This file contains AngularJS services that have been "upgraded", making them
// available for injection into Angular code.

import { InjectionToken, Provider } from '@angular/core';
import { StateService } from '@uirouter/angularjs';
import { IQService } from 'angular';
import { IModalStackService } from 'angular-ui-bootstrap';

// Makes a AngularJS-to-Angular provider from the given injection token and factory
// that retrieves the corresponding AngularJS service.
export function ajsProvider<T>(provide: InjectionToken<T>, factory: (i: ng.auto.IInjectorService) => any): Provider {
  return {
    provide,
    useFactory: factory,
    deps: ['$injector']
  };
}

// I wish there was a better way to do this. I want to parameterize a single useFactory
// function but AOT does not seem to permit that.

export function ajsFactory$http(i: ng.auto.IInjectorService): any {
  return i.get('$http');
}
export function ajsFactory$injector(i: ng.auto.IInjectorService): any {
  return i.get('$injector');
}
export function ajsFactory$location(i: ng.auto.IInjectorService): any {
  return i.get('$location');
}
export function ajsFactory$sce(i: ng.auto.IInjectorService): any {
  return i.get('$sce');
}
export function ajsFactory$state(i: ng.auto.IInjectorService): any {
  return i.get('$state');
}
export function ajsFactory$stateParams(i: ng.auto.IInjectorService): any {
  return i.get('$stateParams');
}
export function ajsFactory$q(i: ng.auto.IInjectorService): any {
  return i.get('$q');
}
export function ajsFactoryAuthScopes(i: ng.auto.IInjectorService): any {
  return i.get('AuthScopes');
}
export function ajsFactorySalesforceService(i: ng.auto.IInjectorService): any {
  return i.get('SalesforceService');
}
export function ajsFactoryProtectVService(i: ng.auto.IInjectorService): any {
  return i.get('ProtectVService');
}
export function ajsFactoryVHsmApiService(i: ng.auto.IInjectorService): any {
  return i.get('VHsmApiService');
}
export function ajsFactoryServiceBrokerService(i: ng.auto.IInjectorService): any {
  return i.get('ServiceBrokerService');
}
export function ajsFactory$uibModal(i: ng.auto.IInjectorService): any {
  return i.get('$uibModal');
}
export function ajsFactory$uibModalStack(i: ng.auto.IInjectorService): any {
  return i.get('$uibModalStack');
}

// ********************************************************************
// AngularJS built-in services
// ********************************************************************

// AngularJS services are typed as TypeScript interfaces. These cannot be injected on, so we
// define tokens instead. Callers must add the annotation @Inject(TokenName) to constructors
// that need injection of one of these services.
// Example:
//
// import {LocationService} from './ajs-upgraded-providers'
// constructor(@Inject(LocationService) private $location: ng.ILocationService) {}

export const HttpService = new InjectionToken<ng.IHttpService>('$http');
export const IHttpServiceProvider = ajsProvider(HttpService, ajsFactory$http);

export const InjectorService = new InjectionToken<ng.auto.IInjectorService>('$injector');
export const IInjectorServiceProvider = ajsProvider(InjectorService, ajsFactory$injector);

export const LocationService = new InjectionToken<ng.ILocationService>('$location');
export const ILocationServiceProvider = ajsProvider(LocationService, ajsFactory$location);

export const SCEService = new InjectionToken<ng.ISCEService>('$sce');
export const ISCEServiceProvider = ajsProvider(SCEService, ajsFactory$sce);

export const State = new InjectionToken<StateService>('$state');
export const StateServiceProvider = ajsProvider(State, ajsFactory$state);

export const StateParams = new InjectionToken<StateService>('$stateParams');
export const StateParamsServiceProvider = ajsProvider(StateParams, ajsFactory$stateParams);

export const Q = new InjectionToken<IQService>('$q');
export const QProvider = ajsProvider(Q, ajsFactory$q);

// ********************************************************************
// First-party application services
// ********************************************************************

export const AuthScopes = new InjectionToken<any>('AuthScopes');
export const AuthScopesProvider = ajsProvider(AuthScopes, ajsFactoryAuthScopes);

export const SalesforceService = new InjectionToken<any>('SalesforceService');
export const SalesforceServiceProvider = ajsProvider(SalesforceService, ajsFactorySalesforceService);

export const ProtectVService = new InjectionToken<any>('ProtectVService');
export const ProtectVServiceProvider = ajsProvider(ProtectVService, ajsFactoryProtectVService);

export const VHsmApiService = new InjectionToken<any>('VHsmApiService');
export const VHsmApiServiceProvider = ajsProvider(VHsmApiService, ajsFactoryVHsmApiService);

export const ServiceBrokerService = new InjectionToken<any>('ServiceBrokerService');
export const ServiceBrokerServiceProvider = ajsProvider(ServiceBrokerService, ajsFactoryServiceBrokerService);

// ********************************************************************
// Third-party services
// ********************************************************************

type IModalService = ng.ui.bootstrap.IModalService;
export const IModalServiceToken = new InjectionToken<IModalService>('$uibModal');
export const IModalServiceProvider = ajsProvider(IModalServiceToken, ajsFactory$uibModal);
export const IModalStackServiceToken = new InjectionToken<IModalStackService>('$uibModalStack');
export const IModalStackServiceProvider = ajsProvider(IModalStackServiceToken, ajsFactory$uibModalStack);
