import { Component, Input } from '@angular/core';
import { MetricsService } from '@dpod/gem-ui-common-ng';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tile-redirect-dialog',
  styleUrls: ['./tile-redirect-dialog.component.scss'],
  templateUrl: './tile-redirect-dialog.component.html',
})
export class TileRedirectDialogComponent {
  @Input() url: string;
  @Input() serviceType: string;

  showTerms = false;

  constructor(public modal: NgbActiveModal, private metrics: MetricsService) {
  }

  onClick() {
    this.modal.dismiss();
    this.metrics.track('partnerTile.visit', { url: this.url, serviceType: this.serviceType });
  }
}
