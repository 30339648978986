<h5>{{ service.status | underscoreToSpace | titlecase }}</h5>
<ng-container *ngIf="staticMessage">
    <p> {{staticMessage}}
        <span *ngIf="isStatusFailed">
            <a class="support-link" href="https://supportportal.thalesgroup.com/csm" externalLink>Thales Customer Support</a> to raise a support ticket.
        </span>
    </p>
</ng-container>
<ng-container *ngIf="service.statusMessage">
<!-- added this in the innerHTML specifically because Payshield use case can send links like <a href"abc@example.com">abc@example.com,/a> -->
    <p [innerHTML]="service.statusMessage"></p>
</ng-container>


<div class="row g-0">
    <div class="float-sm-start">
        <button class="btn btn-next" name="cancel" type="button"
                (click)="self.dismiss()">Close</button>
    </div>
</div>
