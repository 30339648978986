import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { AuthService } from '@app/features/auth';
import { BackofficeService } from '@app/shared/services/backoffice.service';
import { TenantSubscription } from '@app/features/gem-services/services/tenant-subscription/tenant-subscription.interface';
import { TilesService } from '@app/shared/services';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { take } from 'rxjs/operators';

export enum TableSyncStatus {
  Syncing,
  Synced,
  Error
}

@Component({
  selector: 'tenant-subscriptions',
  templateUrl: './tenant-subscriptions.component.html',
})
export class TenantSubscriptionsComponent implements OnInit {
  @Input() tenantId: string;
  @Input() sectionHeader: string;
  syncStatus = TableSyncStatus.Syncing; // we display nothing until we get the initial callback for services
  subscriptionsList: TenantSubscription[];
  services = {};
  public tilesWithSubscriptionInfo: Tile[];

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any,
              private backofficeService: BackofficeService,
              private tilesService: TilesService) { }

  ngOnInit(): void {
    const tenantUUID = this.tenantId;
    if (this.areSubscriptionsAvailable()) {
      forkJoin({
        subscriptions: this.backofficeService.listSubscriptions(tenantUUID).pipe(take(1)),
        tiles: this.tilesService.tiles().pipe(take(1))
      }).subscribe({
        next: result => {
          this.subscriptionsList = result.subscriptions;
          this.tilesWithSubscriptionInfo = result.tiles.map(t => ({
            ...t,
            subscriptionInfo: result.subscriptions ? this.tilesService.getSubscriptionInfo(t.shortCode, result.subscriptions) : null,
          }));
          this.tilesWithSubscriptionInfo.forEach(t => {
            this.services[t.shortCode] = t.name;
          });
          this.syncStatus = TableSyncStatus.Synced;
        },
        error: err => {
          this.syncStatus = TableSyncStatus.Error;
          throw err;
        }
      });
    }
  }

  // extra check to avoid calling services if user is not allowed
  areSubscriptionsAvailable(): boolean {
    return (this.tenantId && (this.authService.hasScope(this.scopes.spadmin) || this.authService.hasScope(this.scopes.operator))) // SP/Op Tenant subscriptions section
        || (this.authService.hasScope(this.scopes.admin) || this.authService.hasScope(this.scopes.owner)); // Subscriptions Tab
  }

}
