import { Component, Inject, Input } from '@angular/core';
import { VHsmApiService } from '@app/ajs-upgraded-providers';
import { DialogService } from '@app/components';
import { HSMonDemandCreatedService } from '../cloudHSM.model';
import { HSMService } from '../cloudHSM.service';

/**
 * HSMoD service details windows/linux download component
 */
@Component({
  selector: 'download-client-details',
  styleUrls: ['./download-client-details.component.scss'],
  templateUrl: './download-client-details.html',
})
export class DownloadClientDetailsComponent {

  @Input() service: HSMonDemandCreatedService;
  @Input() open = false; // if true, the download section will render opened

  constructor(
    private dialogService: DialogService,
    @Inject(VHsmApiService) private vHsmApiService: HSMService,
  ) { }

  downloadClient(clientType: 'windows' | 'linux'): void {
    this.dialogService.entityFn(clientName => {
      return this.vHsmApiService.bind(this.service.service_id, clientName, clientType)
        .then(() => this.vHsmApiService.resync());
    },
    'gemCreateClient', {
      existingClients: this.service.clients
    }, 'Preparing service client...');
  }

}
