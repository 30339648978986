'use strict';
import serviceCard from '../../components/service-card';
import cloudHSMModule from './cloudHSM';
import salesforceModule from './cloud/salesforce';
import protectvModule from './cloud/protectV';
import dialogsModule from '@app/components/gem-dialogs';
import redirectPrompt from '../../components/prompt-redirect';
import featureToggles from '../feature-toggles';
import requiresRoleModule from '../../components/requires-role';
import o365Module from './cloud/office365';
import mfeModule from '@app/mfe';

export default angular.module('app.gem-services', [
  cloudHSMModule, salesforceModule, serviceCard, dialogsModule, redirectPrompt, featureToggles, protectvModule,
  requiresRoleModule, o365Module, mfeModule,
]);
