import { Inject, Injectable } from '@angular/core';
import { AuditLogWorkerTask, PageDescriptor, WorkerRequestMessage, onDataReceivedCallback } from '../logs.model';
import { AUDIT_LOGS_WORKER_FACTORY } from '../logs.constants';

@Injectable()
export class AuditLogsTableService {
  worker: Worker;

  constructor(@Inject(AUDIT_LOGS_WORKER_FACTORY) private createWorkerFactory) {}

  createWorker(onDataReceived: onDataReceivedCallback) {
    this.destroyWorker();
    this.worker = this.createWorkerFactory();
    this.worker.onmessage = onDataReceived;
  }

  destroyWorker() {
    this.worker?.terminate();
  }

  initData(logFile: Blob, pageDescriptor: PageDescriptor) {
    this.postWorkerMessage({
      task: AuditLogWorkerTask.INIT,
      logFile,
      pageDescriptor
    });
  }

  calculatePage(pageDescriptor: PageDescriptor) {
    this.postWorkerMessage({
      task: AuditLogWorkerTask.CALCULATE_PAGE,
      pageDescriptor
    });
  }

  cancel() {
    this.postWorkerMessage({
      task: AuditLogWorkerTask.CANCEL
    });
  }

  private postWorkerMessage(message: WorkerRequestMessage) {
    this.worker.postMessage(message);
  }
}
