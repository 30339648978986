import {Component, forwardRef} from '@angular/core';
import { WizardStepBaseComponent } from './wizard-step';

/**
 * Simple wizard step that displays the content projected into it. This component
 * is suitable for creating a wizard step that doesn't contain any form logic.
 *
 * <wizard-step><b>My Step</b></wizard-step>
 */
@Component({
  selector: 'wizard-step',
  templateUrl: './wizard-step.component.html',
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => WizardStepComponent)}
  ]
})
export class WizardStepComponent extends WizardStepBaseComponent {
}
