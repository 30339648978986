import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-plan',
  templateUrl: './card-plan.html',
  styleUrls: ['./card-plan.scss'],
})
export class CardPlanComponent {

  @Input() cardTitle: string;
  @Input() cardDetail: string;
  @Input() selected: boolean;
  @Input() first: boolean;
  @Input() last: boolean;

}
