import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gem-download-modal',
  templateUrl: './download-modal.component.html',
})
export class DownloadModalComponent implements OnInit, OnDestroy {
  @Input() filename: string;
  @Input() blob: Blob;
  @Input() artifact = 'file';

  private objectUrl: string;

  constructor(public activeModal: NgbActiveModal,
    private DomSanizer: DomSanitizer,) { }

  ngOnInit() {
    this.objectUrl = URL.createObjectURL(this.blob);
  }

  ngOnDestroy() {
    URL.revokeObjectURL(this.objectUrl);
  }

  downloadBlob() {
    const filename = this.filename;
    if (typeof window.navigator['msSaveBlob'] === 'function') {
      // Edge doesn't want to download the objectURL using the HTML5 download API
      // so use the legacy MS API
      window.navigator['msSaveBlob'](this.blob, filename);
    } else {
      const document = window.document;
      const a = document.createElement('a');
      a.href = this.objectUrl;
      a.target = '_blank';
      a.download = filename;

      const element = document.body.appendChild(a);
      // This click works without being blocked because we're already in the context of a user click event
      a.click();
      document.body.removeChild(element);
    }
  }

  click(event: Event) {
    this.downloadBlob();
    this.activeModal.dismiss();
  }
}
