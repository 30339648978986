<wizard [title]="modalTitle" [loading]="loading" (submitResponse)="finish()">

    <terms-of-service-step name="Service Information" instructions="Service Information"
                           [serviceTypeName]="serviceTypeName" [serviceCode]="'payshield'" [subscriptionInfo]="tile.subscriptionInfo">
    </terms-of-service-step>

    <payshield-configure name="Configure" instructions="Configure Instructions"
                         [servicePlans]="servicePlan"
                         [tile]="tile"
                         (serviceName)="serviceName = $event"
                         (subscriptionTier)="subscriptionTier = $event"
                         (dataCenter)="dataCenter = $event"/>

    <payshield-summary name="Summary" instructions="Summary"
                       [serviceName]="serviceName"
                       [subscriptionTier]="subscriptionTier"
                       [dataCenter]="dataCenter"/>
</wizard>
