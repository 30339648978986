import accountsModule from './_init';
import template from './subscribers.component.html';

class GemSubscriberGroupsController {
  constructor($state, DialogService, SubscribersApi) {
    this.$state = $state;
    this.DialogService = DialogService;
    this.api = SubscribersApi;
  }

  openWizard() {
    this.DialogService.openAJS('createSubscriberGroup')
      .then(entity => this.createSubscriberGroup(entity));
  }

  hasData() {
    return this.api.getAll().length > 0;
  }

  createSubscriberGroup(entity) {
    const progress = this.DialogService.progress('Preparing subscriber group...');
    return this.api.create(entity)
      .catch(error => {
        this.DialogService.error(error);
        progress.close();
      })
      .finally(() => progress.close());
  }
}

accountsModule.component('gemSubscriberGroups', {
  controller: GemSubscriberGroupsController,
  template,
});
