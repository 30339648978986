import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '@app/components/gem-dialogs';
import { KeybrokerService } from '@app/shared/services/keybroker.service';
import { AzureKey, AzureKeyFormatted, Office365KeybrokerServiceInfo } from '../office365.common';
import { Office365Service } from '../office365.service';
import { HSMonDemandCreatedService } from '../../../cloudHSM/cloudHSM.model';
import { AuthService } from '@app/features/auth';

@Component({
  selector: 'azure-details',
  templateUrl: './office365-details.component.html',
})
export class AzureDetailsComponent implements OnInit {

  @Input() service: HSMonDemandCreatedService;
  keys: AzureKey[] = null; // keep `null` to prevent parts of the UI showing until the API call has returned
  updatingKeysTable = true;
  azureInfo: Office365KeybrokerServiceInfo;

  constructor(private office365Service: Office365Service, private keybrokerService: KeybrokerService, private dialogService: DialogService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.resync();
    this.keybrokerService.getServiceInfo<Office365KeybrokerServiceInfo>(this.getServiceId()).then(response => this.azureInfo = response.data);
  }

  getServiceId(): string {
    return this.service.service_id;
  }

  generateKey() {
    const progress = this.dialogService.progress('Generating key...');
    this.keybrokerService.create({
      serviceId: this.getServiceId(),
    }).finally(() => progress.close());
  }

  canGenerateKeys(): boolean {
    return !this.updatingKeysTable;
  }

  isSameSubscriberGroup() {
    if (this.service) {
      return this.authService.hasSubscriberGroupId(this.service.subscriberGroup);
    }
    return false;
  }

  // assuming server side filtering
  // resyncs the list of Azure keys
  resync(): void {
    this.updatingKeysTable = true;
    this.keybrokerService.resync({
      serviceId: this.service.service_id,
    }, 'resources')
      .then((keys: AzureKeyFormatted[]) => this.keys = keys)
      .finally(() => this.updatingKeysTable = false);
  }

}
