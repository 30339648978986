<div class="row">
    <div class="col form-group">
        <div class="details-panel">
            <div class="details-panel-section">
                <div class="row mb-2">
                    <div class="col-sm-2">
                        Service Name
                    </div>
                    <div class="col-sm-10">
                        <strong>{{ serviceName }}</strong>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-2">
                        Service Tier
                    </div>
                    <div class="col-sm-10">
                        <strong>{{ subscriptionTier?.label }}</strong>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                        Data Center
                    </div>
                    <div class="col-sm-10">
                        <strong>{{ dataCenter }}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
