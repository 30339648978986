import {Component, ElementRef, HostBinding, OnDestroy, OnInit} from '@angular/core';
import { NgModel } from '@angular/forms';

/**
 * Displays success/error icon depending on whether the element's NgModel is valid or invalid.
 *
 * <input name="example" validationIcon>
 */
@Component({
  selector: '[validationIcon][ngModel]',
  template: `
      <span class="wrapper">
        <ng-template [ngIf]="model.valid && (model.control.dirty)"> <!--edited?-->
          <svg focusable="false" class="icon-svg input-icon input-success">
            <use xlink:href="#check"></use>
          </svg>
        </ng-template>

        <ng-template [ngIf]="!model.valid && (model.control.dirty)"> <!--edited?-->
          <svg focusable="false" class="icon-svg input-icon input-error">
            <use xlink:href="#warning"></use>
          </svg>
        </ng-template>
      </span>
`,
})
export class ValidationIconComponent implements OnInit, OnDestroy {
  @HostBinding('class.with-icons') withIcons = true;
  private iconNode: Node;

  constructor(public model: NgModel, private el: ElementRef) {}

  ngOnInit() {
    // This is a hack. Hoist up the icon so it's a sibling of the host element rather than a child.
    // We do this because our host elements are usually <inputs>s which cannot have children, so
    // the icon would not be displayed otherwise.
    const nativeElement = this.el.nativeElement;
    this.iconNode = nativeElement.parentNode.insertBefore(nativeElement.querySelector('.wrapper'), nativeElement.nextSibling);
  }

  ngOnDestroy() {
    if (this.iconNode) {
      this.iconNode.parentNode.removeChild(this.iconNode);
    }
  }

}
