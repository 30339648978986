import { Inject, Injectable } from '@angular/core';
import { HttpService, Q } from '@app/ajs-upgraded-providers';
import { RequiresRoleService } from '@app/shared/services';
import { Haro } from '@app/shared/services/haro.service';
import { AuthService } from '../../auth';
import AccountsDataApi from './accountsDataApi';
import * as roles from '../../auth/roles.constants';

@Injectable()
export class UsersApi extends AccountsDataApi {

  constructor(
    @Inject(Haro) haro,
    @Inject(HttpService) $http,
    @Inject(Q) $q,
    private authService: AuthService,
    private requiresRoleService: RequiresRoleService,
  ) {
    super(haro, $http, $q, '/v1/users');
    this.resync();
  }

  resync() {
    if (this.authService.isEmailVerified() && this.requiresRoleService.hasRole([roles.admin, roles.spadmin])) {
      return super.resync();
    }
    return this.$q.resolve();

  }

  changePassword(oldPassword, newPassword) {
    return this.http.patch(`${this.baseUrl}/changePassword`, {
      oldPassword,
      newPassword,
    })
      .then(response => response.data)
      .catch(response => { throw response.data; });
  }

  /**
   * @returns {promise} resolving to the user's temporary password
   */
  resetPassword(id) {
    return this.http.patch(`${this.baseUrl}/${encodeURIComponent(id)}/resetPassword`)
      .then(response => response.data.temporaryPassword)
      .catch(response => { throw response.data; });
  }

  /**
   * Resets the MFA token for a given user in a given tenant
   * @param tenantId
   * @param userId The user guid
   * @returns Promise resolving to undefined if the request succeeded
   */
  resetMfaToken(tenantId: string, userId: string) {
    const queryParams = { tenantId };
    return this.http.post(`${this.baseUrl}/${encodeURIComponent(userId)}/resetMfaToken`, undefined, { params: queryParams });
  }

  /**
   * Sends a request to resend the user's verification code by email.
   * @param userId The user guid
   * @returns Promise resolving to or rejecting if an error happened
   */
  sendVerification(userId: string): Promise<void> {
    return this.http.post(`${this.baseUrl}/${encodeURIComponent(userId)}/sendVerification`);
  }
}
