import {
  Component,
  Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tile } from '@app/features/marketplace/tiles.interface';

@Component({
  templateUrl: './marketing-tile-modal.component.html',
  styleUrls: ['./marketing-tile-modal.component.scss'],
})
export class MarketingTileModalComponent {
  @Input() parentTile: Tile = null;
  @Input() marketingTile: Tile = null;

  closeLabel = 'Close';

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  close() {
    this.activeModal.close();
  }
}
