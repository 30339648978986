import { Pipe, PipeTransform } from '@angular/core';

/**
 * Send a string to this pipe and the hyphens will be replaced with a space
 */
@Pipe({ name: 'underscoreToSpace' })
export class UnderscoreToSpacePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value?.replace(/_/g, ' ') || '';
  }
}
