import { Component, Input } from '@angular/core';

/**
 * Show steps of a wizard/form
 */
@Component({
  selector: 'steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent {

  @Input() steps: string[] = [];
  @Input() currentStepIdx: number | string = 0; // todo angularjs passed number becomes string

  getStepClasses(stepIndex) {
    const currentStep = parseInt(this.currentStepIdx as string, 10);
    return {
      complete: stepIndex < currentStep,
      active: stepIndex === currentStep,
      todo: stepIndex > currentStep,
    };
  }

}
