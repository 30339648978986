import { Component, Inject } from '@angular/core';
import { AuthScopes } from '@app/ajs-upgraded-providers';
import { AuthService } from '@app/features/auth';

@Component({
  selector: 'subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})

export class SubscriptionsComponent {

  constructor(private authService: AuthService,
              @Inject(AuthScopes) private scopes: any) {}

  isSubscriptionsTabAvailable(): boolean {
    return this.authService.hasScope(this.scopes.admin) || this.authService.hasScope(this.scopes.owner);
  }

}
