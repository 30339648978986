import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ServiceInstance } from '@app/shared/services/service-broker.interface';
import { DATE_FORMAT, DATE_SEPARATOR } from '@app/components/date-range-picker/date-range-picker.constants';

@Injectable()
export class AuditLogsFilterService {

  constructor() {
    // The dayjs library is used by the ngx-daterangepicker-bootstrap library.
    // The utc plugin is added to dayjs - the date range picker works only in UTC.
    dayjs.extend(utc);
  }

  getMaxDate() {
    const isoString = dayjs.utc().endOf('day').toISOString();
    // The 'Z' in the end should be removed so that the ngx-daterangepicker-bootstrap library treats the date as it is in UTC
    return this.removeTimeZone(isoString);
  }

  getMinDate() {
    const isoString = dayjs.utc().add(-1, 'years').startOf('day').toISOString();
    // The 'Z' in the end should be removed so that the ngx-daterangepicker-bootstrap library treats the date as it is in UTC
    return this.removeTimeZone(isoString);
  }

  generateDefaultValue(maxDate: string) {
    const parsedMaxDate = dayjs.utc(maxDate);
    return `${parsedMaxDate.startOf('day').format(DATE_FORMAT)}${DATE_SEPARATOR}${parsedMaxDate.format(DATE_FORMAT)}`;
  }

  processServices(services: ServiceInstance[]) {
    return services.map(service => ({
      id: service.service_id,
      name: service.name
    })).sort((service1, service2) => service1.name.toLocaleLowerCase().localeCompare(
      service2.name.toLocaleLowerCase()
    ));
  }

  private removeTimeZone(isoString: string) {
    return isoString.substring(0, isoString.length - 1);
  }
}
