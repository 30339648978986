import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { selectTenantStateId } from '../auth/auth.interface';
import { SelectTenantComponent } from './select-tenant.component';
import * as AuthScopes from '../auth/roles.constants';
import { TenantCardComponent } from '@app/components/tenant-card/tenant-card.component';
import { CommonModule } from '@angular/common';

const selectTenantState = {
  name: selectTenantStateId,
  url: '/select-tenant',
  params: {
    nextState: null,
    tenants: [],
    // A special fake state for the Select Tenant page. See ../auth/roles.constants for more info.
    requiresRole: `${AuthScopes.multiTenant}`
  },
  component: SelectTenantComponent,
};

@NgModule({
  declarations: [SelectTenantComponent, TenantCardComponent],
  imports: [
    UIRouterUpgradeModule.forChild({
      states: [selectTenantState],
    }),
    CommonModule
  ],
})
export class SelectTenantModule {
}
