import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelperService } from '@app/shared/services/date-time-helper.service';

/*
 * Uses dayjs package to convert a date into a different format string
 * example usage: | convertDate: "D-MMM-YYYY H:mm"}}
 * has default conversion string if nothing is passed
 */
@Pipe({name: 'convertDate'})
export class ConvertDatePipe implements PipeTransform {
  constructor(private dateTimeHelper: DateTimeHelperService) {}

  transform(value: string, exponent = 'D-MMM-YYYY', inputFormat?: string): string {
    if (value === null) {
      return '';
    }
    return this.dateTimeHelper.formatDate(value, exponent, inputFormat);
  }
}
