import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy
} from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

const CUSTOM_TOOLTIP = 'customTooltip';

/**
 * A directive that wrap CustomTooltipComponent and adds custom tooltip to the target element.
 * The tooltip is shown on mouse enter and hidden on mouse leave.
 *
 * Example:
 * ```html
 * <div [customTooltip]="'My custom tooltip'"></div>
 * <div customTooltip="My custom tooltip"></div>
 * ````
 */
@Directive({
  selector: `[${CUSTOM_TOOLTIP}]`,
})
export class CustomTooltipDirective implements OnDestroy {
  @Input(CUSTOM_TOOLTIP) text: string;

  tooltipInstance: ComponentRef<CustomTooltipComponent> | undefined;

  constructor(
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.createTooltip();
    this.showTooltip();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hideTooltip();
  }

  ngOnDestroy() {
    this.destroyTooltip();
  }

  private createTooltip() {
    if (this.tooltipInstance) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent);
    this.tooltipInstance = componentFactory.create(this.injector);
    this.appRef.attachView(this.tooltipInstance.hostView);
    document.body.appendChild(this.tooltipInstance.location.nativeElement);
  }

  private showTooltip() {
    if (!this.tooltipInstance) {
      return;
    }
    const {top, bottom, left} = this.elementRef.nativeElement.getBoundingClientRect();
    this.tooltipInstance.instance.text = this.text;
    this.tooltipInstance.instance.top = top;
    this.tooltipInstance.instance.bottom = bottom;
    this.tooltipInstance.instance.left = left;
    this.tooltipInstance.location.nativeElement.style.display = 'block';
  }

  private hideTooltip() {
    if (!this.tooltipInstance) {
      return;
    }
    this.tooltipInstance.location.nativeElement.style.display = 'none';
  }

  private destroyTooltip() {
    if (!this.tooltipInstance) {
      return;
    }
    this.tooltipInstance.destroy();
    this.tooltipInstance = undefined;
  }
}
