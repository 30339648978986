import { columnNames as servicesTableColumnNames } from '@app/components/services-table/services-table.component';
import { columnNames as usersTableColNames } from '@app/components/users-table/users-table.component';
import * as msgs from '../subscribers.messages';
import template from './subscribers-edit.html';
import subscribersEditView from './_init';

export default class SubscribersEditController {
  constructor($state, DialogService, SubscribersApi) {
    this.api = SubscribersApi;
    this.groups = this.api.getAll();
    this.api.resync().then(groups => this.groups = groups);
    this.DialogService = DialogService;
    this.subscriber = {};
    this.editing = false;
    this.saved = false;

    this.placeholders = {};
    this.$state = $state;
    this.subscriberSubscription = null;

    // When showing the users for a subscriber group, hide some of the columns
    this.usersTableCols = usersTableColNames.filter(col => {
      switch (col) {
      case 'accountRole':
      case 'subscriberGroup':
      case 'createdByUsername':
        return false;
      }
      return true;
    });

    this.servicesTableCols = servicesTableColumnNames.filter(col => col !== 'subscriberGroup');

    // Filter the users shown in the table to only those who are members of
    // the Subscriber Group being viewed.
    this.isUserInSubscriberGroup = user => user.subscriberGroups.includes(this.id);

    // Similarly filter the services
    this.isServiceInSubscriberGroup = service => service.subscriberGroup === this.id;

    if (!this.id && $state.params) {
      // Allow the id to be provided via state params.
      this.id = $state.params.id;
    }
  }

  $onInit() {
    this.subscriberSubscription = this.api.subscribeTo(this.id, subscriber => {
      if (subscriber) {
        this.subscriber = angular.copy(subscriber);
        this.placeholders = subscriber;
      } else {
        this.$state.go('^');
      }
    }, true);
  }

  $onDestroy() {
    if (this.subscriberSubscription) {
      this.subscriberSubscription.unsubscribe();
    }
  }

  canDelete() {
    const group = this.subscriber;
    return !this.editing && group.totalServices === 0 && group.totalUsers === 0;
  }

  edit() {
    this.saved = false;
    this.editing = true;
  }

  cancel() {
    this.saved = false;
    this.editing = false;
    this.subscriber = angular.copy(this.placeholders);
  }

  save() {
    const progress = this.DialogService.progress(msgs.saving);
    this.api.save(this.subscriber).then(() => {
      this.saved = true;
      this.editing = false;
      this.placeholders = angular.copy(this.subscriber);
    })
      .finally(() => progress.close())
      .catch(error => this.DialogService.error(error));
  }

  delete() {
    return this.DialogService.confirm({
      title: msgs.confirmTitle,
      content: msgs.confirmText,
      yesLabel: 'Delete',
      noLabel: 'Cancel',
    })
      .then(() => {
        const progress = this.DialogService.progress(msgs.deleting);
        this.api.delete(this.id)
          .then(() => this.$state.go('^'))
          .finally(() => progress.close());
      });
  }


}

subscribersEditView.component('subscribersEdit', {
  template,
  bindings: {
    id: '<',
  },
  controller: SubscribersEditController,
});
