import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@app/components';
import { DpodTableComponent } from '@app/components/dpod-table/dpod-table.component';
import { KeybrokerService } from '@app/shared/services';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { AzureKey, AzureKeyFormatted } from '../office365.common';

export enum columnNamesEnum {
  'state' = 'state',
  'keyId' = 'keyId',
  'createdAt' = 'createdAt',
  'azureKeyId' = 'azureKeyId'
}

const columnNames = [
  columnNamesEnum.state,
  columnNamesEnum.keyId,
  columnNamesEnum.createdAt,
  columnNamesEnum.azureKeyId,
];

@Component({
  selector: 'office365-keys-list',
  styleUrls: ['./office365-details.table.keys.scss'],
  templateUrl: './office365-details.table.keys.html',
})
export class Office365DetailsTableKeysComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() serviceId: string; // what is this for?????

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('table', { static: true }) private table: MatTable<AzureKeyFormatted>;
  @ViewChild(DpodTableComponent, { static: true }) private dpodTable: DpodTableComponent;

  dataSource = new MatTableDataSource<AzureKeyFormatted>();
  readonly displayedColumns = columnNames.slice();

  private sub: Subscription;

  constructor(
    public clipboard: ClipboardService,
    public dialogService: DialogService,
    private keybrokerService: KeybrokerService,
  ) {
    this.sub = this.keybrokerService.subscribe((/* azure keys */) => {
      this.dataSource.data = this.keybrokerService.getAll();
    });
    this.dataSource.data = this.keybrokerService.getAll();
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor.bind(this);
  }

  sortingDataAccessor(azureKey: AzureKey, columnName: columnNamesEnum): string | Date {
    switch (columnName) {
    case columnNamesEnum.state:
      return azureKey.state;
    case columnNamesEnum.keyId:
      return azureKey.id;
    case columnNamesEnum.createdAt:
      return new Date(azureKey.createdAt);
    case columnNamesEnum.azureKeyId:
      return azureKey.details.azureKeyId;
    }

    return azureKey.state;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  copy(str: string) {
    this.clipboard.copyFromContent(str);
  }

  onFilter(text: string) {
    this.dataSource.filter = text;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.dpodTable.paginator;
    this.table.renderRows();
  }

}
