import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { MBU, ServiceAgreementDetails, TenantPlans, Plan } from '@app/features/tenant/tenant.model';
import { Tile } from '@app/features/marketplace/tiles.interface';
import { BackofficeService } from '@app/shared/services/backoffice.service';

@Component({
  selector: 'tenant-details-service-elections-service-list',
  templateUrl: './tenant-details-service-elections.service-list.component.html',
  styleUrls: ['./tenant-details-service-elections.component.scss'],
})
export class TenantDetailsServiceElectionsServiceListComponent implements OnChanges {
  @Input() serviceAgreement: ServiceAgreementDetails;
  @Input() tiles: Tile[];
  servicePlans: Plan[];
  showingPlan: TenantPlans = {};
  serviceNames: Record<string, string> = {};
  private imageUrls: Map<string, string> = new Map();

  constructor(private backofficeService: BackofficeService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.getServicePlans();
    if (this.tiles) {
      this.imageUrls = new Map(this.tiles.map(t => [t.shortCode, t.imageUrl]));
      this.serviceNames = this.tiles.reduce((acc, t) => {
        acc[t.shortCode] = t.name;
        return acc;
      }, {});
    }
  }

  getImageUrl(mbu: MBU): string {
    return this.imageUrls.get(mbu.serviceType.shortCode);
  }

  getServicePlans() {
    this.serviceAgreement?.terms.mbus.forEach(mbu => {
      this.backofficeService.getServicePlan(mbu.serviceType.shortCode).subscribe(result => {
        this.servicePlans = result['plans'];
        if (!this.servicePlans || !this.servicePlans.length) {
          console.error('We could not find any plans. Please try again later.');
        } else {
          this.findServicePlanName(mbu, this.servicePlans);
        }
      },
      error => {
        console.error(error);
      });
    });
  }

  findServicePlanName(mbu: MBU, servicePlans) {
    const planName = servicePlans.find(plan => plan.id === mbu.planId)?.displayName;
    this.showingPlan[mbu.serviceType.id] = planName;
  }
}
