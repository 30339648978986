import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import * as AuthScopes from '@app/features/auth/roles.constants';
import { SharedModule } from '@app/shared';
import { HttpClientModule } from '@angular/common/http';
import { LogsComponent } from './logs.component';
import { AuditLogsTableComponent } from './audit-logs-table/audit-logs-table.component';
import { AuditLogsFilterComponent } from './audit-logs-filter/audit-logs-filter.component';
import { AuditLogsFilterService } from './audit-logs-filter/audit-logs-filter.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DateRangePickerModule } from '@app/components/date-range-picker/date-range-picker.module';
import { LogsService } from './logs.service';
import { AuditLogsTableService } from './audit-logs-table/audit-logs-table.service';
import { AuditLogsWorkerFactory } from './audit-logs-table/audit-logs-table.worker.factory';

const logsStates: NgHybridStateDeclaration[] = [
  {
    component: LogsComponent,
    name: 'logs',
    url: '/logs',
    params: {
      requiresRole: `${AuthScopes.owner},${AuthScopes.admin}`,
    },
  },
];

@NgModule({
  declarations: [
    LogsComponent,
    AuditLogsTableComponent,
    AuditLogsFilterComponent
  ],
  providers: [
    AuditLogsFilterService,
    AuditLogsTableService,
    AuditLogsWorkerFactory,
    LogsService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    DateRangePickerModule,
    SharedModule,
    UIRouterUpgradeModule.forChild({
      states: logsStates,
    }),
  ]
})
export class LogsModule {}
