import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelperService } from '@app/shared/services/date-time-helper.service';

/**
 * Formats a string with a date as a "DD-MMM-YYYY".
 *
 * Example of usage:
 * ```html
 *  <div> {{ subscription.endDate | dateFormat }} </div>
 * ```
 **/

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private dateTimeHelper: DateTimeHelperService) {}

  transform(date: string): string {
    if (date) {
      return this.dateTimeHelper.formatDate(date, 'DD-MMM-YYYY');
    }
    return '';
  }

}
