import { Injectable, Provider } from '@angular/core';
import { AuthService } from '../../features/auth';

type Verifier = (role: string) => boolean;

const alwaysTrue: Verifier = () => true;

/**
 * Companion service for the requires-role directive. it exposes one method, hasRole(role).
 * The requires-role directive uses `hasRole()` to see if the role associated with the
 * directive is active. by default, this method always returns `true`.
 *
 * To be useful, the service has to be configured to check some source of truth about the
 * current user's credentials. this could be a static variable, localstore, or even an
 * angular service. wrap the check in a function that takes 'role' as an argument, and
 * pass it to the provider's `setVerifier()` method. `hasRole()` will delegate to that
 * function. For example, if you have a service called `AuthService`:
 *
 * ```typescript
 * RequiresRoleService.setVerifier(AuthService.hasScope.bind(AuthService));
 * ```
*/
@Injectable()
export class RequiresRoleService {
  private verifyFn: Verifier = alwaysTrue;

  setVerifier(verifyFn: Verifier) {
    this.verifyFn = verifyFn || this.verifyFn;
  }

  /**
   * @param roles Comma separated string or array
   * @returns Whether they have the role or not
   */
  hasRole(roles: string[] | string): boolean {
    const roleArr = typeof roles === 'string' ? roles.split(',') : roles;

    for (const role of roleArr) {
      const hasRole = this.verifyFn(role);

      if (hasRole) {
        return true;
      }
    }

    return false;
  }
}

export function RequiresRoleServiceFactory(authService: AuthService) {
  const rrs = new RequiresRoleService();
  rrs.setVerifier(authService.hasScope.bind(authService));
  return rrs;
}

/**
 * Provides an implementation of RequiresRoleService using AuthService.hasScope()
 * as the source of truth.
 */
export const RequiresRoleDefaultProvider: Provider = {
  provide: RequiresRoleService,
  useFactory: RequiresRoleServiceFactory,
  deps: [AuthService]
};
