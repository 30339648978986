<form #stepForm="ngForm">

    <alert-with-icon [icon]="'#info-round'" [class]="'alert-custom-info'">
        Enter the legally registered company name with no abbreviations or symbols
    </alert-with-icon>

    <section class="details-panel">
        <div class="details-panel-section">
            <fieldset>
                <legend class="d-none">Address information</legend>

                <div class="row form-group">

                    <div class="col-sm-4">
                        <label for="companyName" class="control-label">Company Name</label>
                        <input id="companyName" type="text" name="companyName" class="form-control"
                               required
                               minlength="3"
                               maxlen="255"
                               [(ngModel)]="tenant.companyName"
                               #companyName="ngModel">
                        <div *ngIf="companyName.dirty" id="error-message-company-name" class="text-danger error-message" role="alert">
                            <div *ngIf="companyName.errors?.minlength">Minimum 3 characters</div>
                            <div *ngIf="companyName.errors?.maxlen">Maximum 255 characters</div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label for="departmentName" class="control-label">Company Department</label>
                        <input id="departmentName" type="text" name="departmentName" class="form-control"
                               placeholder="Optional - ex. Marketing"
                               maxlen="32"
                               [(ngModel)]="tenant.departmentName"
                               #departmentName="ngModel">
                        <div *ngIf="departmentName.dirty" id="error-message-department-name" class="text-danger error-message" role="alert">
                            <div *ngIf="departmentName.errors?.maxlen">Maximum 32 characters</div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label for="country" class="control-label">Country</label>
                        <gem-country-select id="country" name="country"
                                            [(ngModel)]="tenant.billingAddress.country"
                                            (ngModelChange)="countryChange()" required
                                            ngDefaultControl>
                        </gem-country-select>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-sm-4">
                        <label for="city" class="control-label">City</label>
                        <input id="city" type="text" name="city" class="form-control" [(ngModel)]="tenant.billingAddress.city"
                               #city="ngModel" maxlen="64" required>
                        <div *ngIf="city.dirty" id="error-message-city-name" class="text-danger error-message" role="alert">
                            <div *ngIf="city.errors?.maxlen">Maximum 64 characters</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="state" class="control-label">State / Province / Region</label>
                        <gem-region-select id="state" name="state"
                                           [country]="tenant.billingAddress.country"
                                           [regionDefaultSelection]="'Select State / Province / Region'"
                                           [(ngModel)]="tenant.billingAddress.state" ngDefaultControl>
                        </gem-region-select>
                    </div>

                    <div class="col-sm-4">
                        <label for="zip" class="control-label">ZIP / Postal Code</label>
                        <input id="zip" type="text" name="zip" class="form-control" [(ngModel)]="tenant.billingAddress.zip"
                               #zip="ngModel" minlength="2" maxlength="12"
                               required>
                        <div *ngIf="zip.dirty" id="error-message-zip-code" class="text-danger error-message" role="alert">
                            <div *ngIf="zip.errors?.minlength">Minimum 2 characters</div>
                        </div>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col-sm-12">
                        <label for="streetAddress" class="control-label">Billing Address</label>
                        <input id="streetAddress" type="text" name="streetAddress" class="form-control"
                               [(ngModel)]="tenant.billingAddress.streetAddress"
                               #streetAddress="ngModel" maxlen="255"
                               required>
                        <div *ngIf="streetAddress.dirty" id="error-message-addr" class="text-danger error-message" role="alert">
                            <div *ngIf="streetAddress.errors?.maxlen">Maximum 255 characters</div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </section>
</form>
