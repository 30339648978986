import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  Output,
  ViewChild,
  ContentChild,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { WindowToken } from '@app/shared/services';
import { StateDeclaration, Transition, UIRouterGlobals } from '@uirouter/core';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { Subscription } from 'rxjs';

/**
 * Helper component for implementing a table with Angular material. DpodTableComponent gives
 * you a paginator, search filter, and visual styling. The text typed into the filter, and
 * the sort order, are persisted and restored from localStorage under certain conditions.
 *
 * DpodTableComponent does not create the Angular material table for you. You must define
 * that yourself, then wrap it inside `<dpod-table>`.
 *
 * ### Usage:
 * ```html
 * <dpod-table>
 *   <table mat-table [dataSource]="myDataSource">
 *     <!-- column definitions, row definitions, etc, go here -->
 *   </table>
 * </dpod-table>
 * ```
 *
 * #### Filtering
 * Use the `filter` event to filter your dataSource:
 * ```html
 * <dpod-table (filter)="onFilter($event)">
 *   <!-- … -->
 * </dpod-table>
 * ```
 *
 * ```typescript
 * applyFilter(filterValue: string) {
 *   this.dataSource.filter = filterValue;
 * }
 * ```
 *
 * #### Paginator
 * Do this to access the paginator in your table component:
 *
 * ```typescript
 * .@Component({ … })
 * class MyTable {
 *   .@ViewChild(DpodTableComponent, { static: true }) dpodTable: DpodTableComponent;
 *
 *   ngAfterViewInit() {
 *     this.dataSource.paginator = this.dpodTable.paginator;
 *   }
 * }
 * ```
 */

function featureName(state: StateDeclaration) {
  return state.name.substr(0, state.name.indexOf('.'));
}

/**
 * @returns Whether the transaction's start & end states are within the same "feature" (ie. top level
 * tab). This decision is based on a heuristic, which is that states in the same feature have the
 * same first segment in their names.
*/
function isBetweenSameFeature(tx: Transition) {
  const s1 = tx.from();
  const s2 = tx.to();
  if (s1.name === '' || s1.name === 'home') {
    return true; // special case: following redirect to the default state
  }
  return featureName(s1) === featureName(s2);
}

@Component({
  selector: 'dpod-table',
  templateUrl: './dpod-table.component.html',
  styleUrls: ['./dpod-table.component.scss']
})
export class DpodTableComponent implements OnChanges, AfterViewInit {
  private static initialLoad = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('filter', { static: true }) filterInput: ElementRef;

  // Sort may or may not be present, depends on the table using us
  @ContentChild(MatSort) sort: MatSort;

  /**
   * Emits when the user has filtered the table. Bind this event and use it
   * to filter your `dataSource`.
   */
  @Output() filter = new EventEmitter<string>();

  /**
   * Emits page events from the paginator.
   */
  @Output() page = new EventEmitter<PageEvent>();

  @Input() dataLength = 0;

  /**
   * Optional text to display above table
   */
  @Input() description?: string;

  private filterText: string;
  private storage: Storage;
  private sortSub: Subscription;

  constructor(
    private elementRef: ElementRef,
    private uiRouterGlobals: UIRouterGlobals,
    private zone: NgZone,
    @Inject(WindowToken) private window: Window,
  ) {
    this.storage = window.localStorage;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if there is currently more data than previously rendered, a new row is added
    // whenever a new row is added, clear the filter
    if (changes.dataLength && changes.dataLength.currentValue > changes.dataLength.previousValue) {
      this.filterText = this.filterInput.nativeElement.value = '';
      this.filter.emit(this.filterText);
      this.saveState();
    }
  }

  ngAfterViewInit() {
    const lastTx = this.uiRouterGlobals.transitionHistory.peekTail();

    // If we're coming from a router state in the same feature, load saved state
    // otherwise, clear state for the whole feature
    if (!DpodTableComponent.initialLoad && isBetweenSameFeature(lastTx)) {
      this.loadState();
    } else {
      this.clearFeatureState(lastTx.to());
    }

    if (this.sort) {
      this.sort.sortChange.subscribe(this.onSort.bind(this));
    }

    DpodTableComponent.initialLoad = false;
  }

  ngOnDestory() {
    if (this.sortSub) {
      this.sortSub.unsubscribe();
    }
  }

  onFilter(event: KeyboardEvent) {
    this.filterText = this.filterInput.nativeElement.value.trim().toLowerCase();
    this.filter.emit(this.filterText);
    this.saveState();
  }

  onSort(event: Sort) {
    this.saveState();
  }

  saveState() {
    // Save w/o causing a change detection cycle since this shouldn't impact any UI components.
    this.zone.runOutsideAngular(() => {
      const data: PersistState = {
        filter: this.filterText,
      };
      if (this.sort) {
        data.sort = {
          col: this.sort.active,
          dir: this.sort.direction,
        };
      }
      this.storage.setItem(this.storageKey(), JSON.stringify(data));
    });
  }

  loadState() {
    const saved = this.storage.getItem(this.storageKey());
    if (!saved) {
      return;
    }
    let data: PersistState;
    try {
      data = JSON.parse(saved);
    } catch (e) {
      return;
    }
    if (data.filter) {
      this.filterText = this.filterInput.nativeElement.value = data.filter;
      this.filter.emit(this.filterText);
    }
    if (this.sort) {
      this.sort.active = data.sort.col;
      this.sort.direction = data.sort.dir;
    }
  }

  /**
   * Removes any saved data for the entire feature corresponding to the given `StateDeclaration`.
   * This should be called when entering the feature from an unrelated feature, to ensure that
   * the feature being entered begins fresh and doesn't get old state loaded for some table.
   */
  clearFeatureState(state: StateDeclaration) {
    const prefix = `DpodTable_${featureName(state)}`;
    for (const key in this.storage) {
      if (key.startsWith(prefix)) {
        this.storage.removeItem(key);
      }
    }
  }

  getTableId(): string {
    // To disambiguate tables within the same state, generate a table-specific "id" by
    // concatenating the selectors of 3 closest ancestor components in the hierarchy. This isn't
    // bulletproof either but it's enough.
    let el: HTMLElement = this.elementRef.nativeElement;
    const names = [];
    while (el) {
      if (el.tagName.indexOf('-') !== -1) {
        names.push(el.tagName.toLowerCase());
      }
      el = el.parentElement;
    }
    names.reverse();
    return names.slice(-3).join('/');
  }

  private storageKey(): string {
    const stateName = this.uiRouterGlobals.current.name;
    return `DpodTable_${stateName}_${this.getTableId()}`;
  }
}

// Note: check for all these properties before using, do not assume that any are present
interface PersistState {
  filter?: string;
  sort?: { col: string; dir: SortDirection };
}
