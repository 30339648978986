<div class="page panel-width">
    <div class="gem-header section-header">Reports</div>

    <section [attr.data-locator]="'summary-report'" *ngIf="showSectionSummaryReports()">

        <header class="gem-header sub-header">Summary Report</header>
        <p>
            <span
                class="no-resource-prompt">Generate a report of the current state of services across all tenants.</span>
        </p>

        <summary-report></summary-report>
    </section>

    <section [attr.data-locator]="'monthly-reports'" *ngIf="showSectionMonthlyReports()">

        <header class="gem-header sub-header">Monthly Reports</header>

        <div class="details-panel main-panel reports-form mt-4">

            <!-- the following is for service providers, and not to be confused with the service usage report -->
            <monthly-report *ngIf="showReportMonthlyService()" [attr.data-locator]="ReportType.Services"
                            header="Service Report"
                            tagline="Generate a report of usage per service."
                            serviceMethod="getUsageBillingReport"
                            [reportType]="ReportType.Services"></monthly-report>

            <!-- The following is for tenant administrators and not to be confused with the service report -->
            <monthly-report *ngIf="showReportMonthlyServiceUsage()" [attr.data-locator]="ReportType.Services"
                            header="Service Usage Report"
                            tagline="Generate a report of per-month service usage."
                            [reportType]="ReportType.Services"></monthly-report>

        </div>
    </section>
</div>
