<h5 class="color-primary">Configure services for your users</h5>
<p>Click the toggle to enable or disable a service tile; your users will not be able to deploy disabled
    services.</p>
<hr/>

<tiles-configure-view [serviceCategoriesStream]="this.serviceCategories" [tilesStream]="this.tiles" (tileClick)="tileClick($event)"></tiles-configure-view>

<ng-template #disable let-context>
    Users you have created will no longer be able to deploy the <strong>{{ context.tileName }}</strong> service.
    <p class="mt-4">Services these users have already deployed will not be affected.</p>
</ng-template>

<ng-template #enable let-context>
    <p>The <strong>{{ context.tileName }}</strong> service will be accessible to all users you and other tenant administrator have created.</p>
    <p class="mt-4">Do you wish to continue?</p>
</ng-template>
