/**
 * Models an HSM on Demand service returned by `GET /v1/service/:id`
 *
 * TODO: extract common properties into a base Service interface
 */
export interface HSMonDemandCreatedService extends ServiceSyntheticFields {
  clients: HSMonDemandClient[];
  created_by_username: string;
  created_by: string;
  createdAt: string;
  device_type: string;
  name: string;
  partition_serial_number: string;
  service_id: string;
  serviceType: string;
  serviceVersion: string;
  subscriberGroup: string;
  dashboard_url: string;
  marketplace: string;
  status: string;
  statusMessage: string;
}

export interface HSMonDemandClient {
  created_by_username: string;
  created_by: string;
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}

/** Synthetic fields added by the client code */
interface ServiceSyntheticFields {
  formattedCreatedAt: string;
  formattedServiceType: string;
  fipsState: FipsState;
}

export enum FipsState {
  Fips = 'fips',
  NonFips = 'nonfips',
}

export const MarketplaceDPoD = 'DPoD';
