<form #stepForm="ngForm">
 <div class="row">
    <div class="col form-group">
        <div class="details-panel">
            <div class="details-panel-section">
                    <div class="col-sm-12">
                        <label for="serviceName" class="control-label">Service Name</label>
                        <input id="serviceName" #serviceNameField="ngModel" name="serviceName" class="form-control"
                               minlength="4"
                               maxlen="45"
                               [(ngModel)]="serviceNameInternal" validationDebounce required validationIcon
                               (input)="nameChange()"
                               trim="blur"
                               autofocus>
                        <div *ngIf="serviceNameField.dirty" id="error-message-serviceName" class="text-danger error-message"
                             role="alert">
                            <div *ngIf="serviceNameField.errors?.required">Please enter a service name</div>
                            <div *ngIf="serviceNameField.errors?.maxlen">Maximum 45 characters</div>
                            <div *ngIf="serviceNameField.errors?.minlength">Minimum 4 characters</div>
                        </div>
                    </div>

            </div>
        </div>

        <div *ngIf="showRemoveFipsSection()" class="details-panel">
            <div class="details-panel-section">
                <label class="col-form-label">
                    <input type="checkbox" class="modal-checkbox" [(ngModel)]="allowNonFips" name="allow-fips"
                           (change)="check()">
                    Remove FIPS restrictions
                </label>
            </div>
        </div>

        <alert-with-icon id="non-fips-warning" [icon]="'#bang-triangle'" [class]="'alert-custom-warning'"
                         *ngIf="allowNonFips && showRemoveFipsSection()">
            This service will be hosted on an HSM that supports non-FIPS algorithms.
            Please ensure that FIPS compliance is not a requirement for your service.
            Once the service is created, this selection cannot be changed.
        </alert-with-icon>
    </div>
</div>
</form>
