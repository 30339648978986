<div class="dialog">
    <div class="modal-body row">
        <h5>
            <svg class="icon-svg"><use xlink:href="#bang-triangle" class="alert-text" /></svg>
            Incorrect File Format
        </h5>
    </div>

    <p>Please ensure your image meets the following requirements:</p>

    <div class="requirements">
        <ul class="list-style-none">
            <li *ngFor="let req of requirements">{{req}}</li>
        </ul>
    </div>

    <p>For best results, a transparent background is also recommended.</p>

    <div class="row g-0">
        <div class="float-sm-start">
            <button class="btn btn-next" name="next" type="button" (click)="tryAgain()">Try Again</button>
            <button class="btn btn-default" name="cancel" type="button" (click)="activeModal.dismiss()">Cancel</button>
        </div>
    </div>
</div>
