<div class="row">
    <div class="col">
        <div *ngIf="isServiceCredentials()" class="details-panel">
            <div class="details-panel-section">
                <div class="row form-group">
                    <div class="col-sm-4">
                        Service Name
                    </div>
                    <div class="col-sm-8 text-end">
                        <strong>{{serviceName}}</strong>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        Credentials Name
                    </div>
                    <div class="col-sm-8 text-end">
                        <strong>{{credentialsName}}</strong>
                    </div>
                </div>
            </div>
        </div>

        <div class="details-panel">
            <div class="details-panel-section">
                <alert-with-icon [icon]="'#bang-triangle'" [class]="'alert-custom-warning'">
                    Please copy and save your
                    <strong>Client Secret</strong> in a secure location; it will not be stored.
                </alert-with-icon>

                <hr/>

                <div class="row">

                    <div class="col-sm-6">

                        <strong>Client ID</strong>

                        <div class="d-flex">
                            <input type="text" data-locator="api-client-api" class="form-control me-1" #clientId [(ngModel)]="apiClientId" name="api-client-api" readonly/>
                            <copy-input data-locator="copy-api-client-api" (cbOnSuccess)="hideAllCopiedMessages(); copiedId = true" [ngxClipboard]="clientId"></copy-input>
                        </div>

                        <div class="mt-1">
                            <gem-feedback text="Client ID copied to clipboard!" [(trigger)]="copiedId"></gem-feedback>
                        </div>

                    </div>

                    <div class="col-sm-6">

                        <strong>Client Secret</strong>

                        <div class="d-flex">
                            <input type="text" data-locator="api-client-secret" class="form-control me-1" #clientSecret [(ngModel)]="apiClientSecret" name="api-client-secret" readonly/>
                            <copy-input data-locator="copy-api-client-secret" (cbOnSuccess)="hideAllCopiedMessages(); copiedSecret = true;" [ngxClipboard]="clientSecret"></copy-input>
                        </div>

                        <div class="mt-1">
                            <gem-feedback text="Client secret copied to clipboard!" [(trigger)]="copiedSecret"></gem-feedback>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
