// this is an interceptor to simulate http delays in mocks. modified from:
// https://endlessindirection.wordpress.com/2013/05/18/angularjs-delay-response-from-httpbackend/
export default angular.module('mock.delay', ['mock.delay.time'])
  .decorator('$httpBackend', function($delegate, $timeout, HttpBackendDelay) {
    const delayMs = Number(HttpBackendDelay);
    if (isNaN(delayMs)) {
      throw new Error(`Expected a number: ${HttpBackendDelay}`);
    }

    const proxy = function(method, url, data, callback, headers) {
      function interceptor() {
        $timeout(() => callback(...arguments), delayMs);
      }
      return $delegate.call(this, method, url, data, interceptor, headers);
    };

    return Object.assign(proxy, $delegate);
  })
  .name;

// Wrap the delay time into a separate module so it's easier to override.
// The default value is 1 second
angular.module('mock.delay.time', [])
  .factory('HttpBackendDelay', ['$window', $window => {
    const delayStr = $window.localStorage.getItem('dpod.ajax.delay');
    const delay = parseInt(delayStr, 10);
    return isNaN(delay) ? 1000 : delay;
  }]);
