export const dateRangePickerLabels = {
  apply: 'Apply',
  cancel: 'Cancel',
  invalidDateRange: 'Please enter a valid date range',
  invalidEndDate: 'You cannot select a future date',
  invalidStartDate: 'You can only access audit logs generated in the past 12 months',
  invalidDuration: dateLimit => `Date range cannot exceed ${dateLimit} days`
};

export const DATE_SEPARATOR = ' - ';
// The format follow the Dayjs documentation https://day.js.org/docs/en/display/format
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const INPUT_PLACEHOLDER = `${DATE_FORMAT}${DATE_SEPARATOR}${DATE_FORMAT}`;
