<div class="summary">

    <ul ngbNav #nav="ngbNav" class="nav-pills">
        <li ngbNavItem="account" title="Account">
            <button ngbNavLink>Account</button>
            <ng-template ngbNavContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <caption class="d-none">Account information</caption>
                        <thead>
                            <tr>
                                <th scope="col" class="type">Account Type</th>
                                <!-- The Tenant Name is duplicated for OW because it is displayed on a different position in OW case -->
                                <th scope="col" class="name" *ngIf="!config.FF_ONE_WELCOME">Tenant Name</th>
                                <th scope="col" data-locator="tenant-hostname-th" *ngIf="!config.FF_ONE_WELCOME">Hostname</th>
                                <th scope="col" class="status">Account Status</th>
                                <th scope="col" class="name" *ngIf="config.FF_ONE_WELCOME">Tenant Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{accountType}}</td>
                                <!-- The Tenant Name is duplicated for OW because it is displayed on a different position in OW case -->
                                <td *ngIf="!config.FF_ONE_WELCOME">{{tenant.name}}</td>
                                <td data-locator="tenant-hostname-td" *ngIf="!config.FF_ONE_WELCOME">{{tenant.hostname}}&#x200b;{{domainSuffix}}</td>
                                <td>{{tenant.accountStatus === 'active' ? "Active" : "Suspended"}}</td>
                                <td *ngIf="config.FF_ONE_WELCOME">{{tenant.name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </li>

        <li ngbNavItem="address" title="Address">
            <button ngbNavLink>Address</button>
            <ng-template ngbNavContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <caption class="d-none">Address information</caption>
                        <tr>
                            <th scope="col" class="company-name">Company Name</th>
                            <th scope="col" class="department-name">Company Department</th>
                            <th scope="col" class="billing">Billing Address</th>
                        </tr>
                        <tr>
                            <td><optional [value]="tenant.companyName"></optional></td>
                            <td><optional [value]="tenant.departmentName"></optional></td>
                            <td><optional [value]="tenant.billingAddress.streetAddress"></optional></td>
                        </tr>
                    </table>

                    <table class="table table-summary mt-3">
                        <caption class="d-none">Address information continued</caption>
                        <thead>
                            <tr>
                                <th scope="col" class="city">City</th>
                                <th scope="col" class="state">State / Province / Region</th>
                                <th scope="col" class="zip">ZIP / Postal Code</th>
                                <th scope="col" class="country">Country</th>
                            </tr>
                        </thead>
                        <tr>
                            <td><optional [value]="tenant.billingAddress.city"></optional></td>
                            <td><optional [value]="tenant.billingAddress.state"></optional></td>
                            <td><optional [value]="tenant.billingAddress.zip"></optional></td>
                            <td><optional [value]="tenant.billingAddress.country"></optional></td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </li>

        <li ngbNavItem="adminUser" title="Admin User">
            <button ngbNavLink>Admin User</button>
            <ng-template ngbNavContent>
                <div class="table-summary-wrap">
                    <table class="table table-summary">
                        <caption class="d-none">Admin user information</caption>
                        <thead>
                            <tr>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Phone Number</th>
                                <th scope="col" class="email">Email Address</th>
                            </tr>
                        </thead>
                        <tr>
                            <td>{{tenant.adminGivenName}}</td>
                            <td>{{tenant.adminFamilyName}}</td>
                            <td><optional [value]="tenant.adminPhoneNumber"></optional></td>
                            <td>{{tenant.adminEmail}}</td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
