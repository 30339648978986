import { Directive, HostBinding, HostListener, ElementRef } from '@angular/core';
import { MetricsService } from '@dpod/gem-ui-common-ng';

/**
 * Marks an <A> as being a link to an external website.
 *
 * Usage:
 * <a href="https://example.org" externalLink>click here</a>
 *
 * TODO: use an approach like the one shown below to automatically apply this directive
 * https://coryrylan.com/blog/managing-external-links-safely-in-angular#angular-directive
 */
@Directive({
  selector: '[externalLink]'
})
export class ExternalLinkDirective {

  // Do not allow the linked page to interact with us via `window.opener`
  @HostBinding('attr.rel') rel = 'noopener';

  // Open in a new window
  @HostBinding('attr.target') target = '_blank';

  constructor(private metrics: MetricsService, private element: ElementRef<HTMLLinkElement>) { }

  @HostListener('click')
  onclick() {
    const link = this.element.nativeElement;
    this.metrics.track('externalLink.visit', { url: link.href, text: link.textContent });
  }
}
