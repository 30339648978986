/**
 * This hook intercepts transitions to the 'home' state and redirects the user
 * to the default UI-router state for their role.
 */
export default function homeHook($transitions, $state, $location, AuthService, AuthScopes) {
  'ngInject';

  const redirect = (/* transition*/) => {
    let dest;
    if (!AuthService.getIdentity() || (AuthService.hasScope(AuthScopes.owner) && !AuthService.hasScope(AuthScopes.admin))) {
      dest = 'services';
    } else if (AuthService.hasScope(AuthScopes.admin)) {
      dest = 'accounts';
    } else {
      dest = 'tenants';
    }

    console.log(`Redirecting to default state '${dest}'`);
    if ($state.includes(dest)) {
      // already in the destination state, just force-update the address bar to match
      const url = $state.href($state.current.name, $state.params, {}).replace(/^#!/, '');
      $location.url(url);
      return;
    }

    return $state.target(dest, null, { location: 'replace' });
  };

  // Priority is below the role hook's, since home only kicks in after
  $transitions.onBefore({ to: 'home' }, redirect, {priority: 25});
}
