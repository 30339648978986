import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from '@app/components';
import { ApiClientUI, CredentialType } from '../../../features/credentials/credentials.interface';
import { CredentialsService } from '../../../features/credentials/credentials.service';
import { HSMonDemandCreatedService } from '../../../features/gem-services/cloudHSM/cloudHSM.model';
import { ServiceBrokerService , RequiresRoleService } from '../../services';
import { NewCredentialsWizardComponent } from '../new-credentials-wizard/new-credentials-wizard.component';
import isEqual from 'lodash/isEqual';
import { AuthService } from '@app/features/auth';
import * as Roles from '@app/features/auth/roles.constants';

/**
 * TODO this component should be used for Platform credentials too
 */
@Component({
  selector: 'credentials-section',
  templateUrl: './credentials-section.component.html',
})
export class CredentialsSectionComponent implements OnInit, OnDestroy {

  @Input() service: HSMonDemandCreatedService;
  @Input() credentialsNamePrefix: string; // optional
  @Input() permissions: string[] = []; // extra permissions to show in the creation wizard
  @Input() useServiceBroker = false; // to use the service broker to generate credentials
  @Input() showClientID = true;
  @Input() wizard: any = NewCredentialsWizardComponent;

  readonly CredentialType = CredentialType;
  credentials: ApiClientUI[] = [];
  private sub: Subscription;

  constructor(private dialogService: DialogService,
              private credsService: CredentialsService,
              private serviceBrokerService: ServiceBrokerService,
              private authService: AuthService,
              private requiresRoleService: RequiresRoleService) {
  }

  ngOnInit() {
    // if we're to use the service broker, we'll use that for credentials instead of the creds service
    if (this.useServiceBroker) {
      this.serviceBrokerService.resync().catch(error => {
        this.handleBackendError(error);
      }); // TODO resyncService(this.service.service_id)
      this.sub = this.serviceBrokerService.subscribe(cred => {
        const creds = this.serviceBrokerService.getServiceCredentialsByServiceId(this.service.service_id);
        this.updateTable(creds);
      });
    } else {
      // Listen for changes and update
      this.credsService.resync().catch(error => {
        this.handleBackendError(error);
      }); // TODO resyncService(this.service.service_id)
      this.sub = this.credsService.subscribe(cred => {
        const creds = this.credsService.getAllService(this.service.service_id);
        this.updateTable(creds);
      });
    }
  }

  // Added in order to unit test error handling for ngOnInit
  handleBackendError(error) {
    throw error;
  }

  updateTable(creds) {
    if (!isEqual(this.credentials, creds)) {
      this.credentials.length = 0;
      this.credentials = creds;
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  createServiceCredentials() {
    const opts = { windowClass: 'modal-wide' };
    const wizard = this.dialogService.open<NewCredentialsWizardComponent>(this.wizard, opts).componentInstance;
    wizard.credentialsNamePrefix = this.credentialsNamePrefix || this.service.formattedServiceType.replace(/[^A-Za-z]/g, '');
    wizard.useServiceBroker = this.useServiceBroker;
    wizard.serviceId = this.service.service_id;
    wizard.serviceName = this.service.name;
    wizard.serviceType = this.service.serviceType;
    wizard.extraPermissions = this.permissions || [];
  }

  isSameSubscriberGroup(): boolean {
    return this.authService.hasSubscriberGroupId(this.service.subscriberGroup);
  }

  isAppOwner(): boolean {
    return this.requiresRoleService.hasRole(Roles.owner);
  }

  isSPAdmin(): boolean {
    return this.requiresRoleService.hasRole(Roles.spadmin);
  }
}
