import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TenantAuthority } from '@app/features/auth/auth.interface';

@Component({
  selector: 'tenant-card',
  templateUrl: './tenant-card.component.html',
  styleUrls: ['./tenant-card.component.scss']
})
export class TenantCardComponent {
  @Input() tenant!: TenantAuthority;
  @Output() tenantSelected = new EventEmitter<TenantAuthority>();

  selectTenant() {
    this.tenantSelected.emit(this.tenant);
  }
}
