import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProtectVKeyFormatted } from '../../protectV.constants';

@Component({
  selector: 'protectv-details-modal',
  styleUrls: ['./modal-protectV.scss'],
  templateUrl: './modal-protectV-key-details.component.html',
})
export class ProtectVKeysDetailsModalComponent {

  @Input() key: ProtectVKeyFormatted;
  @Output() closeModal: EventEmitter<ProtectVKeyFormatted> = new EventEmitter();
  copiedKeyName = false;
  copiedKeyId = false;

  hideAllCopiedMessages(): void {
    this.copiedKeyId = false;
    this.copiedKeyName = false;
  }

}
