import { Component } from '@angular/core';
import { ServiceCredentialSummaryComponent } from '@app/shared/components/credentials-table/service-credential-summary/service-credential-summary.component';
import { AuthService } from '../../../../auth';
import { keystoreEndpoint } from '../protectV.constants';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.step.component.html',
})
export class SummaryStepComponent extends ServiceCredentialSummaryComponent {

  copiedCommand = false;
  copiedOAuthUrl = false;
  copiedKeystoreUrl = false;

  constructor(private authService: AuthService) {
    super();
  }

  hideAllCopiedMessages(): void {
    this.copiedCommand = false;
    this.copiedOAuthUrl = false;
    this.copiedKeystoreUrl = false;
    super.hideAllCopiedMessages();
  }

  getProtectVCLICommand(): string {
    const oauthTokenEndpointUrl: string = this.getOAuthUrl();
    const keystoreEndpointUrl = this.getKeystoreUrl();
    return `pvmctl configdpod --url=${keystoreEndpointUrl} --tokenurl=${oauthTokenEndpointUrl} --clientid=${this.apiClientId} --clientsecret=${this.apiClientSecret}`;
  }

  getOAuthUrl(): string {
    return this.authService.getIdentity() ? this.authService.getIdentity().iss : '';
  }

  getKeystoreUrl(): string {
    return `${window.location.origin}${keystoreEndpoint}/`;
  }

}
