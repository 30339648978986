<div class="page">
    <section>
        <div class="gem-header section-header">Tenants</div>
        <div class="panel-width" *ngIf="hasTenants()">
            <tenants-table></tenants-table>
        </div>

        <div *ngIf="!hasTenants()">
            <p>You haven't added any tenants yet. Get started below.</p>
        </div>
    </section>

    <section>
        <div *ngIf="hasTenants()">
            <span class="gem-header section-header">Add Tenant</span>
        </div>

        <div class="card-grid">
            <div *ngFor="let card of cards">
                <service-card title="{{card.title}}" svg="{{card.svg}}" icon="{{card.icon}}"
                              content="{{card.content}}" (action)="card.action()"
                              locator="{{card.locator}}"></service-card>
            </div>
        </div>
    </section>
</div>
<ng-template #contactSupport>
    <p class="mt-3">Please contact <a class="support-link" externalLink href="https://supportportal.thalesgroup.com/csm">Thales Customer Support</a>
        if you would like further details about the status of your application.</p>
</ng-template>
