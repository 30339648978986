import { InjectionToken } from '@angular/core';
import { AuditLogService, SortDescriptor, SortDirection } from './logs.model';

export const auditLogsLabels = {
  all: 'All',
  loading: 'Loading...',
  loadingLogsText: 'Retrieving Logs...',
  loadingLogsSubText: 'This may take a few minutes depending on the volume of log events.',
  logFileDownloadArtifact: 'log file',
  noLogsModalButton: 'Ok',
  noLogsModalTitle: 'No Logs Found',
  noLogsModalContent: `No logs have been found for the specified service and date range.
    Please change the selected service or date range and try again.`,
  defaultErrorMessage: 'The underlying components are currently unavailable. Please try again later.'
};

export const ALL_SERVICES: AuditLogService = {
  id: '',
  name: auditLogsLabels.all
};

export const MAX_DURATION = 31;

export const AUDIT_QUERY_URL = '/v1/audit-log-exports';
export const AUDIT_LOG_ASSETS_URL = '/audit-log-assets';
export const LOG_FILE_RESPONSE_TYPE = 'blob';
export const POLLING_INTERVAL = 1000;

export const TABLE_PAGE_SIZE = 100; // PLM requirement
export const SORT_PRIMARY: SortDescriptor = {
  field: 'time',
  direction: SortDirection.DESC,
};
export const SORT_SECONDARY: SortDescriptor = {
  field: 'resourceID',
  direction: SortDirection.ASC,
};
export const DISPLAYED_COLUMNS = ['metaSwitch', 'status', 'source', 'resourceID', 'actorID', 'action', 'time'];
export const FILTERABLE_COLUMNS = DISPLAYED_COLUMNS.filter(column => column !== 'metaSwitch');
export const FILTER_DEBOUNCE_TIME = 500;
export const META_PREFIX = '"meta": ';

/**
 * A number of records that meet the applied filter that makes a progress modal appear when the user sorts data in the Audit Logs table.
 * The number is based on the research that showed sorting would take at least 500ms starting at this amount of filtered rows.
 */
export const PROGRESS_THRESHOLD_SORTING = 1200000;

/**
 * A number of total records that makes a progress modal appear when the user filters data in the Audit Logs table.
 * The number is based on the research that showed filtering would take at least 500ms starting at this amount of total log records.
 */
export const PROGRESS_THRESHOLD_FILTERING = 1000000;

export const AUDIT_LOGS_WORKER_FACTORY = new InjectionToken('AUDIT_LOGS_WORKER_FACTORY');
