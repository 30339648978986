<wizard [title]="modalTitle" (submitResponse)="finish()">
    <account-info-step
        name="Account Info" instructions="Enter general account information for this tenant."
        [tenant]="tenant" domainSuffix="{{getDomainSuffix()}}" accountType="{{getAccountTypeLabel()}}" [maxServiceQuota]="maxServiceQuota">
    </account-info-step>

    <address-step
        name="Address" instructions="Enter this tenant's address and other billing information. You can enter these details later if needed."
        [tenant]="tenant">
    </address-step>

    <admin-user-step
        name="Admin User" instructions="Create an admin user for this tenant account."
        [tenant]="tenant">
    </admin-user-step>

    <wizard-step name="Summary" instructions="Here are the details for this tenant account.">
        <summary-step [tenant]="tenant" accountType="{{getAccountTypeLabel()}}" domainSuffix="{{getDomainSuffix()}}"></summary-step>
    </wizard-step>
</wizard>
