import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TileSelection } from '@app/features/eval-convert/eval-convert.constants';
import { Tile } from '@app/features/marketplace/tiles.interface';

@Component({
  selector: 'tile-select',
  styleUrls: ['./tile-select.component.scss'],
  templateUrl: './tile-select.component.html',
})
export class TileSelectComponent implements OnInit {

  @Input() tile: Tile;
  @Input() initQty = 0;
  @Output() quantityChange: EventEmitter<TileSelection> = new EventEmitter<TileSelection>();
  @Input() showQuantity = false;
  quantity;

  ngOnInit() {
    this.quantity = this.initQty;
  }

  changeQuantity(change: number): void {
    if (
      (change < 0 && this.quantity === 0) || (change > 0 && this.quantity === 99)
    ) {
      return;
    }

    this.quantity += change;

    const emitObject = {
      tile: this.tile,
      quantity: this.quantity,
    };

    this.quantityChange.emit(emitObject);
  }

}
