export class TenantSubscriptions {
  static readonly TYPE = {
    TRIAL: 'TRIAL',
    PRODUCTION: 'PRODUCTION'
  };

  static readonly STATE = {
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    CANCELLED: 'CANCELLED'
  };
}

export enum Purchase {
  ISE = 'ISE',
  ASE = 'ASE'
}
