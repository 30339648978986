import { InjectionToken } from '@angular/core';
import PlacementsMappingFile from '@app/../../config/portal-link-placement.json';
import LinkMappingFile from '@app/../../config/portal-links.json';

type LinkId = string;

/**
 * A PortalLink represents a link to the documentation portal.
 */
export interface PortalLink {
  id: LinkId;
  title: string;
  url: string;
}

/**
 * A PortalLinkPlacement maps a given "screen" (UI router state) in the UI to a list of
 * PortalLink ids.
 */
export interface PortalLinkPlacement {
  screen: string;
  links: LinkId[];
}

export function linksFactory(): PortalLink[] {
  return LinkMappingFile.links;
}

/**
 * Injection token for the links data.
 */
export const LinksData = new InjectionToken<PortalLink[]>('PortalLinks', {
  // Defining factory here provides it in the application root, similar to
  // putting an explicit entry in `providers` of core.module.ts, but less verbose.
  factory: linksFactory,
});

export function placementsFactory(): PortalLinkPlacement[] {
  return PlacementsMappingFile.placements;
}

/**
 * Injection token for the placement data.
 */
export const PlacementsData = new InjectionToken<PortalLinkPlacement[]>('PortalLinkPlacements', {
  factory: placementsFactory,
});

