import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'card-tile',
  templateUrl: './card-tile.html',
  styleUrls: ['./card-tile.scss'],
})
export class CardTileComponent {

  @Input() imageUrl: string;
  @Input() disabled: boolean;
  @Input() cardTitle: string;
  @Input() tooltip = '';
  @Input() bodyClickable = false;
  @Output() bodyClick: EventEmitter<void> = new EventEmitter();

  /** Optional meta-attribute added to the `<card-tile>` */
  @HostBinding('attr.data-locator') @Input() locator: string = null;

  // Todo: can change `disabled` state
  // ngOnChanges(changes: SimpleChanges) {}

  onClick(event: Event) {
    if (!this.bodyClickable || this.disabled) {
      event.stopPropagation();
      return;
    }
    this.bodyClick.emit();
  }

}
