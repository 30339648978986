<dpod-table (filter)="onFilter($event)">
    <ng-content></ng-content>

    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">List of tenant secrets</caption>

        <!-- Status column -->
        <ng-container matColumnDef="status">
            <th scope="col" class="status-column" mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let secret" class="status-column text-center ps-0">
                <span [ngSwitch]="getState(secret)">
                    <span title="Active" *ngSwitchCase="'active'">
                        <svg class="icon-svg check align-middle">
                            <use xlink:href="#check" />
                        </svg>
                    </span>
                    <span title="Archived" *ngSwitchCase="'archived'">
                        <svg class="icon-svg archived align-middle">
                            <use xlink:href="#archived" />
                        </svg>
                    </span>
                    <span title="Revoked" *ngSwitchCase="'revoked'">
                        <svg class="icon-svg revoke align-middle">
                            <use xlink:href="#revoke" />
                        </svg>
                    </span>
                    <span title="Destroyed" *ngSwitchCase="'destroyed'">
                        <svg class="icon-svg destroyed align-middle">
                            <use xlink:href="#destroyed" />
                        </svg>
                    </span>
                </span>
            </td>
        </ng-container>

        <!-- Version column -->
        <ng-container matColumnDef="sfdcSecretVersion">
            <th scope="col" class="version-column" mat-header-cell mat-sort-header *matHeaderCellDef>Ver</th>
            <td mat-cell *matCellDef="let secret" class="version-column text-center ps-0">
                {{ secret.sfdcSecretVersion }}
            </td>
        </ng-container>

        <!-- Type column -->
        <ng-container matColumnDef="formattedSfdcType">
            <th scope="col" class="type-column" mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let secret" class="type-column text-center">
                {{ secret.formattedSfdcType }}
            </td>
        </ng-container>

        <!-- Created At column -->
        <ng-container matColumnDef="createdAt">
            <th scope="col" class="created-column" mat-header-cell mat-sort-header *matHeaderCellDef>Created At</th>
            <td mat-cell *matCellDef="let secret" class="created-at-column text-center">
                {{ secret.formattedCreatedAt }}
            </td>
        </ng-container>

        <!-- Created By column -->
        <ng-container matColumnDef="sfdcCreatedByUsername">
            <th scope="col" class="created-by-column" mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
            <td mat-cell *matCellDef="let secret" class="created-by-column word-break-break-all">
                {{ secret.sfdcCreatedByUsername }}
            </td>
        </ng-container>

        <!-- Modified At column -->
        <ng-container matColumnDef="modifiedAt">
            <th scope="col" class="last-modified-column" mat-header-cell mat-sort-header *matHeaderCellDef>Last Modified At</th>
            <td mat-cell *matCellDef="let secret" class="last-modified-column">
                {{ secret.formattedModifiedAt }}
            </td>
        </ng-container>

        <!-- Modified By column -->
        <ng-container matColumnDef="modifiedBy">
            <th scope="col" class="modified-by-column" mat-header-cell mat-sort-header *matHeaderCellDef>Modified By</th>
            <td mat-cell *matCellDef="let secret" class="modified-by-column word-break-break-all">
                {{ secret.sfdcModifiedByUsername }}
            </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
            <th scope="col" mat-header-cell *matHeaderCellDef data-locator="actions">Actions</th>
            <td mat-cell *matCellDef="let secret" class="actions-column text-center">
                <div class="action-cell" [ngSwitch]="getState(secret)">
                    <div class="icon-inside-icon">
                        <span title="Revoke Secret" class="grid-action" *ngSwitchCase="'archived'"
                            (click)="revoke.emit(secret)">
                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#revoke" />
                            </svg>
                        </span>
                        <span title="Restore Secret" class="grid-action" *ngSwitchCase="'revoked'"
                            (click)="restore.emit(secret)">
                            <svg class="icon-svg outer-icon">
                                <use xlink:href="#action-box" />
                            </svg>
                            <svg class="icon-svg inner-icon">
                                <use xlink:href="#restore" />
                            </svg>
                        </span>
                        <span *ngSwitchCase="'destroyed'">---</span>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let secret; columns: displayedColumns;" [attr.data-state]="getState(secret)">
        </tr>
    </table>
</dpod-table>
