import {Component, EventEmitter, Input, Output, HostBinding, HostListener} from '@angular/core';

@Component({
  selector: 'service-card',
  templateUrl: './service-card.html',
  styleUrls: ['./service-card.scss'],
})
export class ServiceCardComponent {

  @Input() @HostBinding('attr.data-title') title: string;
  @Input() content: string;
  @Input() @HostBinding('attr.data-locator') locator: string;
  @Input() icon: string;
  @Input() svg: string;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('click', ['$event.target'])
  private click($event) {
    this.action.emit($event);
  }
}
