import {Component, forwardRef, Input} from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.step.component.html',
  styleUrls: ['../cloudHSM.scss'],
  providers: [
    {provide: WizardStepBaseComponent, useExisting: forwardRef(() => SummaryStepComponent)}
  ]
})
export class SummaryStepComponent extends WizardStepBaseComponent {

  @Input() serviceName: string;

}
