import { Inject, Injectable } from '@angular/core';
import { Q } from '../../ajs-upgraded-providers';
import { DialogService } from './dialog.service';

@Injectable()
export class DialogServiceMock {
  $q: any;

  constructor(@Inject(Q) $q) {
    this.$q = $q;
  }

  openAJS() {
    return this.$q.resolve();
  }

  open() {
    const modalInstance = {
      componentInstance: {
        key: {},
        submitResponse: {
          subscribe: () => {}
        },
        destroy: {
          subscribe: func => { func({key: modalInstance.componentInstance.key}); }
        },
        restore: {
          subscribe: func => { func({key: modalInstance.componentInstance.key}); }
        },
        revoke: {
          subscribe: func => { func({key: modalInstance.componentInstance.key}); }
        },
        closeModal: {
          subscribe: () => {}
        },
        close: {
          subscribe: () => {}
        }
      },
      result: Promise.resolve(),
      close() {}
    };
    return modalInstance;
  }

  confirm() {
    return Promise.resolve();
  }

  progress() {
    return {
      close: () => {},
    };
  }

  error() {
    return this.confirm();
  }

  entityFn() {
    return this.openAJS();
  }

  close() {}

  dismissAll() {}
}

export const MockDialogServiceProvider = { provide: DialogService, useClass: DialogServiceMock };

// Export the legacy AngularJS module for compatibility
import angular from 'angular';
export default angular.module('dpaas.dialogServiceMock', [])
  .service('DialogService', DialogServiceMock)
  .name;
