import { Component, Inject, Input } from '@angular/core';
import { TenantCreateBody } from '../tenant.model';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';

@Component({
  selector: 'summary-step',
  templateUrl: './summary.step.component.html',
})
export class SummaryStepComponent {
  @Input() tenant: TenantCreateBody;
  @Input() accountType: string;
  @Input() domainSuffix: string;

  constructor(@Inject(ConfigToken) public config: DpodUiConfig) {
  }
}
