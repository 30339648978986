import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  /* eslint-disable @angular-eslint/no-input-rename */
  @HostBinding('class')
  @Input('class')
    classList = '';
}
