// file to hold common used short_codes for services where we have edge cases
export enum ServiceShortCode {
  p2pe = 'p2pe',
  luna_dke = 'luna_dke',
  payshield_na_lab = 'payshield_na_lab',
  payshield_eu_lab = 'payshield_eu_lab',
  payshield_na = 'payshield_na',
  payshield_eu = 'payshield_eu',
  ctaas = 'ctaas',
  ctaas_lab = 'ctaas_lab',
  ctaas_staging = 'ctaas_staging',
  ctaas_prod = 'ctaas_prod',
  vm_keystore = 'vm_keystore',
  azure = 'azure',
  salesforce_key_broker = 'salesforce_key_broker'
}

export const ctaasServices: string[] = [
  ServiceShortCode.ctaas,
  ServiceShortCode.ctaas_lab,
  ServiceShortCode.ctaas_staging,
  ServiceShortCode.ctaas_prod
];

export const payShieldServices: string[] = [
  ServiceShortCode.payshield_na,
  ServiceShortCode.payshield_na_lab,
  ServiceShortCode.payshield_eu,
  ServiceShortCode.payshield_eu_lab
];

export enum DownloadServiceShortCode {
  p2pe = '/third-party/assets/p2pe_artifacts/P2PE_Artifacts.zip',
  luna_dke = '/third-party/assets/dke_artifacts/DKE_Artifacts.zip',
  p2pe_msg = 'Download P2PE Service Software',
  luna_dke_msg = 'Download Luna DKE Service Software',
}

export enum ServiceStatus {
  Provisioning = 'PROVISIONING',
  Deprovisioning = 'DEPROVISIONING',
  Provisioned = 'PROVISIONED',
  ProvisioningFailed = 'PROVISIONING_FAILED',
  DeprovisioningFailed = 'DEPROVISIONING_FAILED',
  Deprovisioned = 'DEPROVISIONED',
  // static messages for popup dialogue
  PROVISIONING_MSG = 'The service is being provisioned. It should be available shortly.',
  DEPROVISIONING_MSG = 'The service is being deprovisioned. It should be removed shortly.',
  PROVISIONING_FAILED_MSG = 'An unexpected error has occurred. Please contact',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DEPROVISIONING_FAILED_MSG = 'An unexpected error has occurred. Please contact',
}

export const cloudProviderRegionMap = {
  'gcp': {
    'label': 'GCP',
    'regions': {
      'us-west1': 'us-west1 (Oregon)',
      'us-central1': 'us-central1 (Iowa)',
      'europe-west3': 'europe-west3 (Frankfurt)',
      'us-east1': 'us-east1 (South Carolina)',
    }
  }
};

export const PROGRESS_CREATE_MSG = 'Preparing service...';
export const PROGRESS_DELETE_MSG = 'Deleting service…';
