'use strict';

import cloudHSM from '../cloudHSM';
import salesforceModule from '../cloud/salesforce';

import dialogsModule from '@app/components/gem-dialogs';
import uibDropdown from 'angular-ui-bootstrap/src/dropdown';
import breadcrumb from '@app/components/breadcrumb';

export default angular.module('app.gem-services.details', [cloudHSM, salesforceModule, dialogsModule, uibDropdown, breadcrumb]);
