export * from './footer.component';

// AngularJS compatibility
import angular from 'angular';
import { FooterComponent } from './footer.component';
import { downgradeComponent } from '@angular/upgrade/static';

export default angular.module('gem.footer', [])
  .directive('gemFooter', downgradeComponent({component: FooterComponent}))
  .name;
