import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHelperService } from '@app/shared/services/date-time-helper.service';

/**
 * Send an iso-8601 formatted string to this pipe and it will be turned into a user friendly
 * subscription term duration. The method has been tuned to handle years,months and days; otherwise
 * will return the generic humanize method for duration
 *       interface Duration { humanize(withSuffix?: boolean): string;)}
 *
 * Example of usage:
 * ```html
 *  <div> {{ subscription.autoRenewalPeriod | termIso8601 }}</div>
 * ```
 * where subscription.autoRenewalPeriod = 'P1M' will return "Monthly"
 *       subscription.autoRenewalPeriod = 'P3M' will return "3 Months"
 **/

@Pipe({
  name: 'termIso8601'
})
export class TermIso8601Pipe implements PipeTransform {
  constructor(private dateTimeHelper: DateTimeHelperService) {}

  transform(duration: string): string {
    if (duration) {
      const d = this.dateTimeHelper.duration(duration);
      // future improvement in case we want to show terms in years when available, uncomment
      // if (d.asYears() >= 1) {
      //   return this.getTerm(d.asYears(), 'Year');
      // }
      if (d.asMonths() >= 1) {
        return this.getTerm(d.asMonths(), 'Month');
      }
      if (d.asDays() === 1) {
        return 'Daily';
      } else if (d.asDays() > 1) {
        return `${d.asDays()} Days`;
      }
      return d.humanize();

    }
    return '';
  }

  getTerm(amount: number, unit: string): string {
    if (amount === 1) {
      return `${unit}ly`;
    } else if (amount > 1) {
      return `${amount} ${unit}s`;
    }
  }

}
