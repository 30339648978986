<form #stepForm="ngForm">
    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">
                    <div class="col-sm-12">
                        <label for="service-name" class="control-label">Service Name</label>
                        <input id="service-name" #name="ngModel" name="serviceName" class="form-control" minlength="4"
                            maxlen="45" (keyup)="generateCredName($event)" [(ngModel)]="service.name" validationDebounce
                            required validationIcon trim="blur" autofocus>
                        <div *ngIf="name.dirty" id="error-message-serviceName" class="text-danger error-message"
                            role="alert">
                            <div *ngIf="name.errors?.required">Please enter a service name</div>
                            <div *ngIf="name.errors?.maxlen">Maximum 45 characters</div>
                            <div *ngIf="name.errors?.minlength">Minimum 4 characters</div>
                        </div>
                    </div>
                    <br>
                    <div class="col-sm-12">
                        <label for="credentials-name" class="control-label">Credentials Name</label>
                        <input id="credentials-name" #credsName="ngModel" name="credentialsName" class="form-control"
                            minlength="1" maxlen="64"
                            [ngModel]="credentials.credentialsName | replaceCharactersWith: ' ': '_'"
                            (ngModelChange)="credentials.credentialsName = $event" validationDebounce required
                            validationIcon>
                        <div *ngIf="credsName.dirty" id="error-message-credentials" class="text-danger error-message"
                            role="alert">
                            <div *ngIf="credsName.errors?.required">Please enter a name for the credentials</div>
                            <div *ngIf="credsName.errors?.maxlen">Maximum 64 characters</div>
                            <div *ngIf="credsName.errors?.minlength">Minimum 1 characters</div>
                        </div>
                    </div>
                </div>
            </div>

            <alert-with-icon [icon]="'#info-round'" [class]="'alert-custom-info'">
                You will be required to authenticate Data Protection On Demand with your
                ProtectV server using the API credentials generated in the next step.
            </alert-with-icon>
        </div>
    </div>
</form>
