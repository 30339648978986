<dpod-table [hidden]="syncStatus !== TableSyncStatus.Synced || (isAdminOwnerAndVerified && dataSource.data.length === 0)" (filter)="onFilter($event)" [dataLength]="dataSource.data.length">
    <ng-content></ng-content>
    <table #table mat-table matSort [dataSource]="dataSource">
        <caption class="d-none">Service Subscriptions</caption>

        <!-- Service Type column -->
        <ng-container matColumnDef="serviceType">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Service Type</th>
            <td mat-cell *matCellDef="let subscription" class="service-type-column">
                {{ getServiceTypeName(subscription.serviceType) }}
            </td>
        </ng-container>

        <!-- Status column -->
        <ng-container matColumnDef="state">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let subscription" class="state-column">
                {{ subscription.state | titlecase }}
            </td>
        </ng-container>

        <!-- Number column -->
        <ng-container matColumnDef="number">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Number</th>
            <td mat-cell *matCellDef="let subscription" class="number-column">
                {{ subscription.number | dashIfEmpty }}
            </td>
        </ng-container>

        <!-- Start Date column -->
        <ng-container matColumnDef="startDate">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let subscription" class="start-date-column">
                {{ subscription.startDate | dateFormat }}
            </td>
        </ng-container>

        <!-- End Date column -->
        <ng-container matColumnDef="endDate">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>End Date</th>
            <td mat-cell *matCellDef="let subscription" class="end-date-column">
                {{ subscription.endDate | dateFormat }}
            </td>
        </ng-container>

        <!-- Renewal column -->
        <ng-container matColumnDef="autoRenewalPeriod" *ngIf="isOperatorSPAndVerified">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Renewal</th>
            <td mat-cell *matCellDef="let subscription" class="renewal-column">
                {{ subscription.autoRenewalPeriod | termIso8601 | dashIfEmpty }}
            </td>
        </ng-container>

        <!-- Plan column -->
        <ng-container matColumnDef="plan">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Plan</th>
            <td mat-cell *matCellDef="let subscription" class="plan-column">
                {{ subscription.plan  | dashIfEmpty }}
            </td>
        </ng-container>

        <!-- Quantity column -->
        <ng-container matColumnDef="quantity">
            <th scope="col" mat-header-cell mat-sort-header *matHeaderCellDef>Service Qty.</th>
            <td mat-cell *matCellDef="let subscription" class="quantity-column text-center">
                {{ getQuantity(subscription.features) | dashIfEmpty }}
            </td>
        </ng-container>

        <!-- Purchase column -->
        <ng-container matColumnDef="purchase" *ngIf="isAdminOwnerAndVerified">
            <th scope="col" mat-header-cell *matHeaderCellDef class="white-font all-columns"></th>
            <td mat-cell *matCellDef="let subscription" class="purchase-service-column text-center">
                <a *ngIf="getServicePurchaseState(subscription.serviceType) === Purchase.ISE" href="javascript: void(0)" data-locator="purchase-service" (click)="convertEvalButton(subscription.serviceType, Purchase.ISE)">Purchase</a>
                <a *ngIf="getServicePurchaseState(subscription.serviceType) === Purchase.ASE" href="javascript: void(0)" data-locator="purchase-service" (click)="convertEvalButton(subscription.serviceType, Purchase.ASE)">Purchase More</a>
            </td>
        </ng-container>

        <!-- Rows definition -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let subscription; columns: displayedColumns;" >
        </tr>
    </table>
</dpod-table>

<header class="gem-header sub-header" *ngIf="syncStatus === TableSyncStatus.Synced && isAdminOwnerAndVerified && dataSource.data.length === 0">You don't have any service subscriptions yet.</header>
<header class="gem-header sub-header" *ngIf="syncStatus === TableSyncStatus.Error">There was an error retrieving subscriptions. Refresh the page or check back in a few minutes to try again.</header>

<ng-container *ngIf="syncStatus === TableSyncStatus.Syncing">
    <div class="subscriptions-spinner-wrapper"><spinner></spinner></div>
</ng-container>

<ise-error-messages #iseErrorMessages></ise-error-messages>
