import { InjectionToken, Provider } from '@angular/core';

export const Haro = new InjectionToken<any>('haro');

export function haroFactory(/* global: Window*/): any {
  return require('haro');
}

/**
 * Wraps the haro library into an Angular provider
 */
export const HaroProvider: Provider = {
  provide: Haro,
  useFactory: haroFactory,
};
