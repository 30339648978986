import { dpodTenantIdKey } from './auth.interface';

function request(config) {
  const identity = this.AuthService.getIdentity();
  if (!identity) {
    // User is not logged in; delay all requests indefinitely. We expect browser to
    // be redirected to the login page to resolve this.
    return new Promise(() => {});
  }

  const appConfig = this.injector.get('ConfigToken');
  if (appConfig.FF_USE_HODOR) {
    // Browser will attach session cookie automatically. Don't have to do anything
    return config;
  }

  // Implicit flow
  const token = this.AuthService.getToken();
  config.headers = config.headers || {};
  if (!config.headers.Authorization) { // this stops from overriding already set Bearer tokens
    config.headers.Authorization = `Bearer ${token}`;
  }

  const tenantId = window.localStorage.getItem(dpodTenantIdKey);
  if (tenantId && !config.headers.dpodTenantIdKey) {
    // Add tenant id in the request headers only if it exists in the local storage
    config.headers[dpodTenantIdKey] = tenantId;
  }

  return config;
}

/**
 * Legacy AJS $http interceptor.
 *
 * This can be removed when all our services are using Angular `HttpClient`
 */
export class AuthRequestInterceptor {
  private request: () => any;

  constructor(private $q, private AuthService, private injector) {
    // `this` binding is lost when Angular invokes these callbacks. Must bind explicitly
    this.request = request.bind(this);
  }
}
AuthRequestInterceptor.$inject = ['$q', 'AuthService', '$injector'];
