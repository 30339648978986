import { addTenantIdIntoUri } from '../auth/auth.utils';

// We cannot import sessionLoggedOut from auth.interface because login.hook is JS
const sessionLoggedOut = 'sessionLoggedOut';
const selectTenantStateId = 'selectTenant';

/**
 * This run block registers a Transition Hook which protects states that need authentication.
 * The hook runs when navigating to a state that requires authentication.
 *
 * It redirects to the login page when one of these is true:
 * - the user is not logged in
 * - the user's token has expired and this is the initial app load (i.e. transitioning
 *   from the empty state)
 *
 * It redirects to the "sessionLoggedOut" state when:
 * - the user's token has expired and this is NOT the initial app load
 */
export default function loginHook($transitions, AuthService, $injector) {
  'ngInject';

  // Loaded lazily to avoid bootstrapping problems
  function getSessionExpiry() {
    return $injector.get('SessionExpiry');
  }

  function getAppConfig() {
    return $injector.get('ConfigToken');
  }

  function redirectToLogin(transition, returnToPath) {
    transition.abort();
    AuthService.login(returnToPath);
    return false; // Cancels the ongoing ui-router transition
  }

  function handleOWMultiChoice(transition, returnToPath, loginState) {
    if (transition.to().name === selectTenantStateId) {
      // Transition to Select Tenant is in progress; simply allow it
      return true;
    }
    // The user has to select a tenant; redirect them to the Select Tenant screen
    return transition.router.stateService.target(
      selectTenantStateId,
      { nextState: loginState.dpodUiState || returnToPath, tenants: AuthService.getAllUserTenants() },
      { location: 'replace' }
    );
  }

  function handleOWLoggedIn(loginState) {
    if (loginState.dpodUiState === undefined) {
      // There is no dpod-ui state to restore; the transition is allowed
      return true;
    }
    // dpodUiState is defined only when the user has just got the token
    // Redirect the user to the restored dpod-ui state
    AuthService.redirectToDpodUiState(loginState.dpodUiState);
    // Cancel the current transition since the user is being redirected
    return false;
  }

  function handleOneWelcome(transition, returnToPath) {
    const isSessionExpired = getSessionExpiry().isSessionEnded();
    return AuthService.determineLoginState()
      .then(loginState => {
        if (isSessionExpired) {
          if (transition.from().name === '') {
            return redirectToLogin(transition, returnToPath);
          }
          if (transition.to().name !== sessionLoggedOut) {
            return transition.router.stateService.target(sessionLoggedOut, { expiredState: returnToPath }, { location: false });
          }
        }
        switch (loginState.loginAction) {
        case 'MultiChoice':
          return handleOWMultiChoice(transition, returnToPath, loginState);
        case 'LoggedIn':
          return handleOWLoggedIn(loginState);
        case 'OwRedirect':
          return redirectToLogin(transition, returnToPath);
        }
      });
  }

  function handleUaa(transition, returnToPath) {
    const isSessionExpired = getSessionExpiry().isSessionEnded();
    if (!AuthService.getIdentity() || (isSessionExpired && transition.from().name === '')) {
      // User either hasn't logged in, or is loading the app with an expired token.
      // Redirect to the login page.
      AuthService.login(returnToPath);
      return transition.abort();
    }

    if (isSessionExpired && transition.to().name !== sessionLoggedOut) {
      return transition.router.stateService.target(sessionLoggedOut, {expiredState: returnToPath}, { location: false });
    }
    return undefined;
  }

  // This matcher activates when the destination state needs login. Currently all states
  // require login by default. If a state does NOT require login, it must opt out by
  // declaring `requiresRole: false` in its params.
  // TODO: Params are for state inputs, not metadata. requiresRole should probably
  // go in state.data.
  const matcher = {
    to: state => {
      const params = state.params;
      return !params.requiresRole || params.requiresRole.value() !== false;
    },
  };
  // The function is a transition hook. It MUST return a promise; it cannot be async or contain await
  const redirect = transition => {
    const stateHref = transition.router.stateService.href(transition.to().name, transition.params());
    let returnToPath = (stateHref || '').slice('#!'.length);
    const owEnabled = getAppConfig().FF_ONE_WELCOME;
    if (owEnabled) {
      const tenantId = AuthService.readTenantIdFromUrl();
      if (tenantId) {
        // The tenantId param should be added in returnToPath manually because of legacy UI-Router
        returnToPath = addTenantIdIntoUri(returnToPath, tenantId);
      }
      return handleOneWelcome(transition, returnToPath);
    }
    return handleUaa(transition, returnToPath);
  };

  // Register the hook with the TransitionsService
  $transitions.onBefore(matcher, redirect, {priority: 100});

  $transitions.onBefore({/* match all*/}, transition => {
    if (AuthService.isRedirecting()) {
      // Block all transitions while the page is unloading
      return transition.abort();
    }
  }, {priority: 100});
}
