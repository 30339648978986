/** Name of the cookie in which Hodor provides the decoded JWT claims. */
export const jwtCookieName = 'dpod.user';

/** Suffix appended to a scope indicating that the user possessing it has not verified their email. */
export const unverifiedScopeSuffix = '.unverified';

/**
 * Regex for matching the JWT scope that encodes the tenant id and subscriber group id
 * - Match group 1: tenant id
 * - Match group 2: subscriber group id (if present)
 */
export const dpodScopeRegex = /dpod\.tuid\.([\w-]+)(?:\.sguid\.([\w-]+))?/;
