import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eval-convert-submitted',
  templateUrl: './eval-convert-submitted.modal.component.html',
})
export class EvalConvertSubmittedModalComponent {

  @Input() parentName: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

}
