<alert-with-icon *ngIf="!subscriptionTierDisabled" [icon]="'#info-round'" [class]="'alert-custom-info'">
    This service can only be created in a tier that you have subscribed to. Subscriptions can be purchased in the subscriptions tab.
</alert-with-icon>
<form #stepForm="ngForm">
    <div class="row">
        <div class="col form-group">
            <div class="details-panel">
                <div class="details-panel-section">
                    <div class="col-sm-6">
                        <label for="serviceName" class="control-label">Service Name</label>
                        <input id="serviceName" #serviceNameField="ngModel" name="serviceName" class="form-control validation-icon-right-aligned"
                               minlength="4" maxlen="45" (input)="nameChange()" trim="blur" autofocus
                               [(ngModel)]="serviceNameInternal" validationDebounce required validationIcon>
                        <div *ngIf="serviceNameField.dirty" id="error-message-serviceName" class="text-danger error-message" role="alert">
                            <div *ngIf="serviceNameField.errors?.required">Please enter a service name</div>
                            <div *ngIf="serviceNameField.errors?.maxlen">Maximum 45 characters</div>
                            <div *ngIf="serviceNameField.errors?.minlength">Minimum 4 characters</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="subscriptionTier">Service Tier</label>
                        <select id="subscriptionTier" name="subscriptionTier" [(ngModel)] ="selectedSubscriptionTier"
                                class="form-control filter-select" required #subscriptionTier="ngModel"
                                (change)="onSubscriptionTierChange()"
                                [disabled]="subscriptionTierDisabled">
                            <option value="" disabled>Select a Service Tier</option>
                            <option *ngFor="let tier of subscriptionTiers" [ngValue]="tier" [disabled]="!tier.subscribedTo">
                                {{ tier.label }} <span *ngIf="!subscriptionTierDisabled && !tier.subscribedTo"> - Not subscribed</span>
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-6">
                        <label for="dataCenter">Data Center</label>
                        <select id="dataCenter" name="dataCenter" [(ngModel)] ="selectedDataCenter"
                                class="form-control filter-select" required #dataCenter="ngModel"
                                (change)="onDataCenterChange()">
                            <option value="" disabled>Select a Data Center</option>
                            <option *ngFor="let dataCenter of selectedSubscriptionTier?.dataCenters" [ngValue]="dataCenter">
                                {{ dataCenter }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
