import serviceCardModule from '../../components/service-card';
import confirmInput from '../../components/confirm-input';
import validationIcon from '../../components/validation-icon';
import email from '../../components/email';
import subscribersEdit from './subscribers-edit';
import angularHaro from '../../components/angular-haro';
import uibTabs from 'angular-ui-bootstrap/src/tabs';

export default angular.module('app.accounts', [
  serviceCardModule, confirmInput, validationIcon, subscribersEdit,
  email, 'angularjs-dropdown-multiselect', angularHaro, uibTabs,
]);
