/**
 * Maps from a role name (i.e. a key in the AuthScopes map) to a list of ui-router
 * states that users with that role are allowed to access.
 */
export default {
  admin: [
    'services',
    'accounts',
    'reports',
    'subscriptions',
    'logs',
  ],
  spadmin: [
    'tenants',
    'reports',
    'spadminusers',
    'credentials',
    'administration',
  ],
  owner: [
    'credentials',
    'services',
    'subscriptions',
    'logs',
  ],
  operator: [
    'reports',
    'tenants',
  ],
};
