import { SessionExpiryService } from './session-expiry.service';

function request(config) {
  this.init();

  if (this.sessionExpiry.isSessionEnded()) {
    console.log(`Session expired, cancelling request to ${config.url}`);
    this.sessionExpiry.logout();
    return this.$q.reject(new Error('Session expired'));
  }

  try {
    this.sessionExpiry.stopTimer();
  } finally {
    /* continue */
  }
  return config;
}

function response(isError, res) {
  this.init();

  try {
    this.sessionExpiry.startTimer();
  } finally {
    /* continue */
  }

  if (res.status !== 401) {
    // return unchanged (resolve or reject as it was before)
    return isError ? this.$q.reject(res) : res;
  }

  // Got 401 response, this means the session expired after the request was sent
  console.log(`Session expired, cancelling response from ${res.config.url}`);
  this.sessionExpiry.logout();
  return this.$q.reject(new Error('Session expired'));
}

/*
 * Adds session-expiry checks to AngularJS HTTP requests and responses.
 *
 * This can be removed when all our ajax calls are converted to Angular `HttpClient`
 */
export class SessionExpiryRequestResponseInterceptor {
  private $q;
  private $injector;
  private sessionExpiry: SessionExpiryService;

  private request: () => any;
  private response: () => any;
  private responseError: () => any;

  constructor($q, $injector) {
    this.$q = $q;
    this.$injector = $injector;
    this.request = request.bind(this);
    this.response = response.bind(this, false);
    this.responseError = response.bind(this, true);
  }

  init() {
    // obtain SessionExpiry lazily to avoid app bootstrapping problems
    if (!this.sessionExpiry) {
      this.sessionExpiry = this.$injector.get('SessionExpiry');
    }
  }
}
SessionExpiryRequestResponseInterceptor.$inject = ['$q', '$injector'];
