import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'gem-invalid-image-modal',
  templateUrl: './invalid-image.modal.component.html',
  styleUrls: ['./invalid-image.modal.component.scss']
})
export class InvalidImageModalComponent {
  @Input() requirements: string[];
  @Output() tryAgainClicked: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  tryAgain() {
    this.activeModal.dismiss();
    this.tryAgainClicked.emit();
  }
}
