import { InjectionToken, Provider } from '@angular/core';
import { DpodUiConfig } from '@app/core/dpod-ui-config';
import { WindowToken } from '@app/shared/services/window';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

export const oneWelcomeResponseType = 'code';
export const oneWelcomeScope = 'openid profile email';

export const UserManagerToken = new InjectionToken('user-manager');

export function UserManagerFactory(config: DpodUiConfig, window: Window) {
  // TODO: remove default values when OW is fully supported
  const authority = config.OW_AUTHORITY;
  const clientId = config.OW_CLIENT_ID;
  return new UserManager({
    authority,
    client_id: clientId,
    redirect_uri: window.location.origin,
    post_logout_redirect_uri: window.location.origin,
    response_type: oneWelcomeResponseType,
    scope: oneWelcomeScope,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage })
  });
}

export const UserManagerProvider: Provider = {
  provide: UserManagerToken,
  deps: [ConfigToken, WindowToken],
  useFactory: UserManagerFactory,
};
