import { Tile } from '@app/features/marketplace/tiles.interface';

export const convertStartState = 'eval';

// the tile selection coming from the tile-select component
export interface TileSelection {
  tile: Tile;
  quantity: number;
}

export const AvailableTermIds = {
  ONE_YEAR: 12,
  TWO_YEAR: 24,
  THREE_YEARS: 36,
  CUSTOM: -1,
};
