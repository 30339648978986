<form #stepForm="ngForm">
<fieldset>
    <legend class="d-none">Administrator information</legend>

    <div class="row form-group">
        <div class="col-sm-4">
            <label for="firstName" class="control-label">First Name</label>
            <input id="firstName" name="firstName" type="text" class="form-control" [(ngModel)]="tenant.adminGivenName"
                   #firstName="ngModel" maxlen="255" required>
            <div *ngIf="firstName.dirty" id="error-message-first-name" class="text-danger error-message" role="alert">
                <div *ngIf="firstName.errors?.maxlen">Maximum 255 characters</div>
            </div>
        </div>
        <div class="col-sm-4">
            <label for="lastName" class="control-label">Last Name</label>
            <input id="lastName" name="lastName" type="text" class="form-control" [(ngModel)]="tenant.adminFamilyName"
                   #lastName="ngModel" maxlen="255" required>
            <div *ngIf="lastName.dirty" id="error-message-last-name" class="text-danger error-message" role="alert">
                <div *ngIf="lastName.errors?.maxlen">Maximum 255 characters</div>
            </div>
        </div>
        <div class="col-sm-4">
            <label for="phone" class="control-label">Phone Number</label>
            <gem-phone-input [id]="'phone'" [name]='"phone"' [(ngModel)]="tenant.adminPhoneNumber" #phone="ngModel" required></gem-phone-input>
            <div *ngIf="phone.dirty && phone.invalid" class="text-danger error-message" role="alert">
                <div *ngIf="phone.errors?.phonenumber">Please enter a phone number.</div>
            </div>
        </div>
    </div>


    <div class="row form-group">
    <div class="col-sm-4">
        <label for="email" class="control-label">Email Address</label>
        <input id="email" name="email" type="text" class="form-control" [(ngModel)]="tenant.adminEmail" #email="ngModel" email required>
        <div *ngIf="email.dirty" id="error-message-email" class="text-danger error-message" role="alert">
            <div *ngIf="email.errors?.required">Please enter an email address</div>
            <div *ngIf="email.errors?.email && !email.errors.required">Please enter a valid email address</div>
        </div>
    </div>
    <div class="col-sm-4" data-locator="admin-create-password" *ngIf="!config.FF_ONE_WELCOME">
        <label for="password" class="control-label">Create Password</label>
        <input id="password" name="password" type="password" class="form-control" [(ngModel)]="tenant.adminPassword"
            #password="ngModel" minlength="10" validationIcon required>
        <div *ngIf="password.dirty" id="error-message-pass" class="text-danger error-message" role="alert">
            <div *ngIf="password.errors?.minlength">Minimum 10 characters</div>
        </div>
    </div>
    <div class="col-sm-4" data-locator="admin-confirm-password" *ngIf="!config.FF_ONE_WELCOME">
        <label for="confirm" class="control-label">Confirm Password</label>
        <input id="confirm" name="confirm" type="password" class="form-control" [(ngModel)]="confirmPassword"
            confirmInput="password" validationIcon required>
    </div>
    </div>

    <br>

    <alert-with-icon [icon]="'#info-round'" [class]="'alert-custom-info'">
            When first logging in, this user will be required to {{config.FF_ONE_WELCOME ? 'set' : 'change'}} their password in order to
            successfully log in to Data Protection On Demand.
    </alert-with-icon>

</fieldset>
</form>
