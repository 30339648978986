export * from './requires-role.directive';

// AngularJS compatibility
import angular from 'angular';
import { RequiresRoleService } from '@app/shared/services';
import { RequiresRoleDirectiveFactory } from './requires-role.directive.ng1';

export default angular.module('gem.requiresRole', [])
  // Declaring the service and directive in AngularJS is simpler than introducing
  // a cross-framework dependency with downgrading.
  .service('RequiresRoleService', RequiresRoleService)
  .directive('requiresRole', RequiresRoleDirectiveFactory)
  .name;
