import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { WizardStepBaseComponent } from '@app/components';
import { TenantCreateBody } from '../tenant.model';
import { ConfigToken } from '@dpod/gem-ui-common-ng';
import { DpodUiConfig } from '@app/core/dpod-ui-config';

@Component({
  selector: 'admin-user-step',
  templateUrl: './admin-user.step.component.html',
  providers: [{provide: WizardStepBaseComponent, useExisting: forwardRef(() => AdminUserStepComponent)}]
})
export class AdminUserStepComponent extends WizardStepBaseComponent implements OnInit {
  @Input() tenant: TenantCreateBody;

  confirmPassword: string;

  constructor(@Inject(ConfigToken) public config: DpodUiConfig) {
    super();
  }

  ngOnInit() {
    if (this.config.FF_ONE_WELCOME) {
      // The password is required by the DPoD API; it doesn't matter for OW, so it's set to a hardcoded value for OW
      // TODO:UAA Remove password from the model after UAA is gone
      this.tenant.adminPassword = 'dpodpassword';
    }
  }
}
