const FIELD_TO_SKIP = ['status'];

function reviver(transform, key, value) {
  if (typeof value !== 'string' || FIELD_TO_SKIP.includes(key)) {
    return value;
  }
  return transform(value);
}

function injectJunk(str) {
  return `<b><del>xss</del></b> ${str} {{this}}`;
}

function fuzz(obj) {
  const str = JSON.stringify(obj);
  return JSON.parse(str, reviver.bind(null, injectJunk));
}

/**
 * Injects Angular and HTML syntax into every string-typed field in the
 * response object. Useful for fuzz testing the UI.
 */
function handleResponse(config) {
  const data = config.data;
  if (!data || typeof data !== 'object' || data instanceof Blob) {
    return config;
  }

  config.data = fuzz(data);
  return config;
}

export default angular.module('mock.xss', [])
  .config($httpProvider => {
    $httpProvider.interceptors.push(() => ({response: handleResponse}));
  })
  .name;
